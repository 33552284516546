/*

base color

*/

$color_gray_border: #e2e2e2;
$color_gray_01: #efefef;
$color_orange_main: #fe6400;
$color_red_01: #f20e03;

/*

text color

*/

$color_text : #333;

$color_link : #666;

$color_link_visited : #666;

$color_link_hover : #666;

$color_link_active : #666;


/*

recruit text color

*/

$color_recruit_link : #e43d14;

$color_recruit_link_visited : #e43d14;

$color_recruit_link_hover : #e43d14;

$color_recruit_link_active : #e43d14;



/*

border-color

*/

$color_border: #e0e0e0;