.site_footer {
	border-top: 1px $color_gray_border solid;
	min-width: $width_site;
	background-color: #fff;

	@include mq(sp) {
		min-width: 0;
	}

	&__header {
		display: flex;
		justify-content: center;
	}

	&__body {
		max-width: $width_site;
		margin: 20px auto 35px;
		padding: 0 20px;

		@include mq(sp) {
			padding: 0 15px;
			margin-bottom: 40px;
		}
	}

	&__footer {
		background-color: #e9e9e9;
		padding: 32px 20px 40px;

		@include mq(sp) {
			padding: 22px 15px 20px;
		}
	}

	&__logo {
		padding-right: 54px;
		padding-left: 54px;
		border: 1px $color_gray_border solid;
		border-bottom: none;
		transform: translate(0, -100%);
		background-color: #fff;

		@at-root body.en .site_footer__logo {
			height: 50px;
		}

		@include mq(sp) {
			padding-right: 45px;
			padding-left: 45px;
		}

		img {
			transform: translateY(17px);

			@include mq(sp) {
				width: 104px;

				@at-root body.en .site_footer__logo img {
					width: 55px;
				}
			}
		}
	}

	&__nav {
		display: flex;
		justify-content: space-between;

		@include mq(sp) {
			display: block;
			border-top: 1px $color_gray_border solid;
		}

		&__content {
			width: 240px;

			@at-root body.en .site_footer__nav__content {
				width: 320px;
			}

			@include mq(sp) {
				width: auto;
			}
		}

		&__list {
			&__item {
				margin-top: 30px;
				padding: 15px;

				@include mq(sp) {
					margin-top: 0;
					padding: 0;
				}

				&:first-child {
					margin-top: 0;
				}

				&.-parent {
					@include mq(sp, true) {
						background-color: #fff3f0;
					}
				}

				& > a {
					color: $color_orange_main;
					font-size: 1.4rem;
					font-weight: bold;
					text-decoration: none;
					@include hover_opacity(.7);

					@include mq(sp) {
						display: block;
						border-bottom: 1px $color_gray_border solid;
						padding: 12px 0;
						font-size: 1.4rem;
						color: #333;
					}

					&.-current {
						@include mq(sp, true) {
							position: relative;

							&::after {
								content:'';
								display: block;
								background-color: $color-orange-main;
								position: absolute;
								bottom: -1px;
								left: 13px;
								right: -2px;
								height: 1px;
							}
						}

						@include mq(sp) {
							color: $color-orange-main;
						}
					}

					&::before {
						@include icon($arrow-right);
						font-size: 1rem;
						margin-right: 4px;

						@include mq(sp) {
							color: $color_orange_main;
							margin-right: 10px;
						}
					}
				}
			}
		}

		&__child_list {
			margin-top: 10px;
			border-top: 1px #b2b2b2 dotted;

			@include mq(sp) {
				display: none;
			}

			&__item {
				margin-top: 8px;

				@at-root body.en .site_footer__nav__child_list__item {
					margin-left: 15px;
				}

				&>a {
					font-size: 1.3rem;
					text-decoration: none;
					color: #000;

					@include hover_opacity(.7);

					@at-root body.en .site_footer__nav__child_list__item > a {
						margin-left: -15px;
					}

					&::before {
						@include icon($arrow-right);
						font-size: 1rem;
						margin-right: 4px;
						color: $color_orange_main;

						@at-root body.en .site_footer__nav__child_list__item a.-current::before {
							position: relative;
							top: 3px;
						}
					}

					&.-current {
						@include mq(sp, true) {
							position: relative;

							@at-root body.en .site_footer__nav__child_list__item a.-current {
								display: inline-flex;
								top: -3px;
							}

							&::after {
								content:'';
								display: block;
								background-color: $color-orange-main;
								position: absolute;
								bottom: -1px;
								left: 13px;
								right: -2px;
								height: 1px;
							}
						}
					}
				}
			}
		}
		&__grandchild_list {
			margin-bottom: 10px;

			&__item {
				margin-top: 8px;
				margin-left: 30px;

				@at-root body.en .site_footer__nav__grandchild_list__item {
					margin-left: 15px;
				}

				&>a {
					font-size: 1.3rem;
					text-decoration: none;
					color: #000;
					@include hover_opacity(.7);

					&.-current {
						@include mq(sp, true) {
							position: relative;
							padding-bottom: 1px;
							display: inline; 
							background:linear-gradient(transparent calc(100% - 1px), $color_orange_main 1px);

							/*&::after {
								content:'';
								display: block;
								background-color: $color-orange-main;
								position: absolute;
								bottom: -3px;
								left: 0;
								right: 0;
								height: 1px;
							}*/
						}
					}
				}
			}
		}
	}

	&__link {
		display: flex;
		flex-wrap: wrap;
		max-width: 72em;
		margin: 0 auto;
		@include mq(sp) {
			justify-content: space-around;
		}

		&__item {
			margin: 8px 25px;

			@at-root body.en .site_footer__link__item:last-child {
				margin-left: 40px;

				@include mq(sp) {
					margin-left: 0;
				}
			}

			@include mq(sp) {
				margin: 0;
				margin-top: 10px;

				@at-root body.en .site_footer__link__item {
					margin: 0 10px;
					margin-bottom: 15px;
				}
			}

			a {
				color: #000;
				text-decoration: none;
				font-size: 1.3rem;
				transition: color .2s ease;

				@at-root body.en .site_footer__link__item:last-child a {
					margin-left: -20px;

					@include mq(sp) {
						margin-left: 0;
					}
				}

				@include hover_opacity(.7);

				&.-current {
					position: relative;

					&::after {
						content:'';
						display: block;
						background-color: $color-orange-main;
						position: absolute;
						bottom: -3px;
						left: 20px;
						right: 0;
						height: 1px;
					}
				}

				&::before {
					@include icon($arrow_right);
					font-size: 1rem;
					margin-right: 10px;
				}
			}
		}
	}
}

.copyright {
	margin-top: 22px;
	font-size: 1.1rem;
	font-family: $ff_en;
	text-align: center;

	@include mq(sp) {
		margin-top: 17px;
	}
}

.en .site_footer .site_footer__link{
	max-width:980px;
	&__item {
			margin: 8px 16px;
	}
}
