.accordion {
	&__title {
		cursor: pointer;
	}

	&__content {
		overflow: hidden;
		transition: height 1s ease;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 30px;
		}
	}
}
