* + .page_desc{
	margin-top: 30px;
}
.page_desc {
	text-align: center;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 2.4;

	@include mq(sp) {
		font-size: 1.4rem;
		line-height: 1.8;
	}
}
