body.top {
	.index_visual {

		&__inner {
			position: relative;
		}

		&__body {
			position: relative;
			// height: 100vh;

			@include mq(sp) {
				height: 500px;
			}
		}

		&__content {
			width: 100%;
			height: 100vh;
			min-height: 680px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: 50% 50% no-repeat;
			background-size: cover;

			@include mq(sp, true) {
				background-image: url(/assets/img/img_visual_01.jpg);
			}

			@include mq(sp) {
				height: 500px;
				min-height: 0;
				background-image: url(/assets/img/img_visual_01_sp.jpg);
			}
		}

		&__lead {
			img {
				@include mq(sp) {
					width: 314px;
				}
			}
		}

		&__desc {
			margin-top: 30px;

			@include mq(sp) {
				margin-top: 15px;

			}

			span {
				display: inline-block;
				padding: 5px 10px;
				font-size: 1.4rem;
				background: #fff;

				@include mq(sp) {
					font-size: 1.8vw;
					padding: 5px 5px;
				}
			}
		}

		&__pagination {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			width: 100%;

			@include mq(sp) {
				display: none;
			}

			&__body {
				display: flex;
			}

			&__footer {
				height: 6px;
				background: #000;
			}

			&__bar {
				height: 6px;
				width: 100%;
				background: $color_orange_main;
				transform: translateX(-100%);


				&.-init {
					transform: translateX(0%);
					transition: transform 4s linear;
				}
			}

			&__num {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 34px;
				height: 30px;
				appearance: none;
				background: #000;
				border: none;
				color: #fff;
				font-size: 1.6rem;
				font-family: $ff_en;
				cursor: pointer;
				transition: background-color .3s ease;

				&:focus {
					outline: none;
				}

				&:hover,
				&.-current {
					background-color: $color_orange_main;
				}
			}
		}

		&__scroll {
			position: absolute;
			bottom: 6px;
			left: 50%;
			z-index: 1;
			font-size: 1rem;
			color: #fff;
			font-family: $ff_en;
			letter-spacing: .2em;
			transform: translateX(-50%);

			&::after {
				@include icon($arrow_lg_right);
				display: block;
				margin: 15px auto 0;
				font-size: 2.4rem;
				transform: rotate(90deg);

				@include mq(sp) {
					font-size: 2rem;
					margin: 15px auto 0;
				}
			}
		}
	}

	.index_important {
		background-color: #efefef;

		&__inner {
			display: flex;

			@include mq(sp) {
				display: block;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $color_orange_main;
			flex-shrink: 0;
			width: 160px;

			@include mq(sp) {
				width: auto;
				padding: 15px 0;
			}
		}

		&__body {
			padding: 8px 32px;
			flex-grow: 1;

			@include mq(sp) {
				padding: 10px 20px;
			}
		}

		&__title {
			font-size: 1.4rem;
			color: #fff;
		}

		&__list {
			&__item {

				border-top: 1px dashed #333;

				&:first-child {
					border-top: none;
				}

				a {
					display: flex;
					text-decoration: none;
					transition: opacity .3s ease;
					line-height: 1.6;
					padding-top: 4px;
					padding-bottom: 6px;

					&:hover {
						opacity: 0.8;
					}

					&.permalink {
						&::after {
							content: none;
						}

						&.-blank {
							.index_important__list__item__title {
								&::after {
									@include icon($blank);
									font-size: 1rem;
									margin-left: 4px;
								}
							}
						}

						&.-pdf {
							.index_important__list__item__title {
								&::after {
									@include icon($pdf);
									font-size: 1.4rem;
									color: $color_orange_main;
									margin-left: 4px;
								}
							}
						}
					}
				}

				&__date {
					width: 90px;
					flex-shrink: 0;
				}
			}
		}
	}

	.index_carousel {
		margin-top: 40px;
		padding-bottom: 40px;

		@include mq(sp) {
			margin-top: 60px;
			margin-bottom: -20px;
		}

		&__inner {
			width: $width_site_lg;
			margin-left: auto;
			margin-right: auto;

			@include mq(sp) {
				width: auto;
			}
		}

		&__item {
			@include mq(sp) {
				padding: 0 27px;
			}

			&__thumb {
				width: 910px;
				margin: 0 auto;

				@include mq(sp) {
					width: auto;
				}

				img {
					&.hidden_pc {
						@include mq(sp, true) {
							display: none !important;
						}
					}
					&.hidden_sp {
						@include mq(sp) {
							display: none !important;
						}
					}
				}
			}
		}

		.slick-prev,
		.slick-next {
			appearance: none;
			border: none;
			background: #000;
			text-indent: -5000px;
			width: 50px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
			cursor: pointer;

			@include mq(sp, true) {
				@include hover-opacity(.7);
			}

			@include mq(sp) {
				width: 38px;
				height: 76px;
			}

			&::after {
				position: relative;
				top: -2px;
				font-size: 2rem;
				color: #fff;

				@include mq(sp) {
					font-size: 1.8rem;
				}
			}

			&:focus {
				outline: none;
			}
		}

		.slick-prev {
			left: 0;

			&::after {
				@include icon($arrow_lg_left);
			}
		}

		.slick-next {
			right: 0;

			&::after {
				@include icon($arrow_lg_right);
			}
		}

		.slick-dots {
			position: absolute;
			bottom: -40px;
			left: 0;
			width: 100%;
			text-align: center;
			height: 5px;

			li {
				display: inline-block;
				padding: 0 3px;
				height: 5px;

				&.slick-active button {
					background-color: $color_orange_main;
				}
			}

			button {
				appearance: none;
				width: 40px;
				height: 5px;
				border: none;
				background: #d9d9d9;
				text-indent: -5000px;
				transition: background-color .3s ease;

				@include mq(sp) {
					width: 30px;
				}

				&:focus {
					outline: none;
				}
			}
		}
	}

	.index_content {
		// padding-top: 20px;
		padding-bottom: 100px;

		@include mq(sp) {
			padding-top: 0;
			padding-bottom: 80px;
		}

		&__inner {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: $width_site_lg;
			margin-left: auto;
			margin-right: auto;

			@include mq(sp) {
				width: auto;
			}
		}

		&__content {
			margin-top: 40px;
			width: 507px;

			@include mq(sp) {
				width: auto;
				margin-top: 60px;
				// margin-top: 0;
			}

			&__header {
				position: relative;

				a {
					display: block;
					text-decoration: none;
					transition: opacity 1s ease;

					&:hover {
						opacity: .8;

						.index_content__thumb {
							img {
								transform: scale(1.1);
							}
						}
					}
				}
			}

			&__body {
				margin-top: 35px;

				@include mq(sp) {
					padding: 0 15px;
				}
			}
		}

		&__thumb {
			overflow: hidden;

			img {
				transition: transform 1s ease;
			}
		}

		&__title {
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 1;
			transform: translate(-50%, -50%);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 220px;
			height: 56px;
			text-align: center;
			font-size: 2rem;
			font-weight: bold;
			background: #fff;
			color: $color_text;

			@at-root body.en.top .index_content__title {
				width: 250px;
			}

			@include mq(sp) {
				width: 185px;

				@at-root body.en.top .index_content__title {
					width: 200px;
				}
			}
		}

		&__btn {
			position: absolute;
			right: 0;
			bottom: -20px;
			display: flex;
			align-items: center;
			padding-left: 30px;
			width: 220px;
			height: 64px;
			background: $color_orange_main;
			color: #fff;
			font-size: 1.3rem;
			box-sizing: border-box;

			&::after {
				@include icon($arrow_lg_right);
				position: absolute;
				right: 30px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.8rem;
			}
		}

		&__list {
			margin-top: -15px;
			display: flex;
			flex-wrap: wrap;

			&__item {
				margin-right: 15px;
				margin-top: 15px;

				&:last-child {
					margin-right: 0;
				}

				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: $color_text;
					font-size: 1.6rem;
					@include hover_opacity;

					@include mq(sp) {
						font-size: 1.3rem;
					}

					&::before {
						@include icon($arrow_right);
						font-size: 1rem;
						color: $color_orange_main;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.index_news {
		padding-top: 80px;
		padding-bottom: 80px;
		background-color: #f5f5f5;

		@include mq(sp) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&__inner {
			width: $width_site;
			margin-left: auto;
			margin-right: auto;

			@include mq(sp) {
				width: auto;
				padding: 0 15px;
			}
		}

		&__title {
			font-size: 2.8rem;
			text-align: center;

			@include mq(sp) {
				
				font-size: 2.1rem;
			}
		}

		&__ruby {
			margin-top: 6px;
			font-weight: bold;
			font-size: 2rem;
			text-align: center;
			color: $color_orange_main;
			font-family: $ff_en;

			@include mq(sp) {
				font-size: 1.5rem;
			}
		}

		&__body {
			margin-top: 40px;

			@include mq(sp) {
				margin-top: 25px;
				
			}
		}

		&__tab {
			display: flex;

			@include mq(sp) {
				overflow-x: scroll;
				padding-left: 1px;
				padding-bottom: 13px;
			}

			&__item {
				width: 20%;

				@include mq(sp) {
					width: 120px;
					flex-grow: 1;
					flex-shrink: 0;

				}

				a {
					position: relative;
					display: flex;
					justify-content: center;
					background: #fff;
					padding: 7px;
					font-size: 1.3rem;
					text-align: center;
					color: #000;
					text-decoration: none;
					border: 1px #000 solid;
					margin-left: -1px;
					transition: color .2s ease,
						border-color .2s ease;

					@include mq(sp) {
						align-items: center;
						font-size: 1rem;
						padding: 0 0;
						height: 32px;
					}

					&:hover {
						border-color: $color_orange_main;
						color: $color_orange_main;
						z-index: 1;
						opacity: 1;

						&::after {
							opacity: 1;
						}
					}

					&::after {
						content: '';
						position: absolute;
						bottom: -14px;
						left: 50%;
						display: block;
						border-top: 7px $color_orange_main solid;
						border-right: 5px transparent solid;
						border-bottom: 7px transparent solid;
						border-left: 5px transparent solid;
						transform: translateX(-50%);
						opacity: 0;
						transition: opacity .2s ease;
					}
				}

				&.-current a {
					border-color: $color_orange_main;
					color: $color_orange_main;
					z-index: 1;

					&::after {
						opacity: 1;
					}
				}
			}
		}

		&__list {
			margin-top: 20px;
			margin-bottom: 40px;

			@include mq(sp) {
				margin-top: 0;
				margin-bottom: 30px;
			}

			&__item {
				margin-top: 5px;
				border-bottom: 1px #000 dotted;

				@include mq(sp) {
					margin-top: 0;
				}

				a {
					display: flex;
					align-items: center;
					padding: 10px 0;
					color: $color_text;
					text-decoration: none;
					@include hover_opacity;

					@include mq(sp) {
						flex-wrap: wrap;
						padding: 15px 0;
					}

					&::after {
						@include mq(sp) {
							order: 4;
						}
					}
				}
			}

			&__date {
				flex-shrink: 0;
				width: 100px;

				@include mq(sp) {
					order: 2;
					width: 50%;
				}
			}

			&__category {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 70px;
				padding: 0 10px;
				flex-shrink: 0;
				background: #000;
				border-radius: 3px;
				color: #fff;
				font-size: 1rem;
				margin-right: 26px;

				@include mq(sp) {
					width: auto;
					order: 1;
					margin-right: 20px;
				}
			}

			&__title {
				@include mq(sp) {
					order: 3;
				}
			}
		}
	}

	.index_banner {
		background-color: #f5f5f5;
		padding-bottom: 80px;

		@at-root body.en.top .index_banner {
			padding-top: 60px;

			@include mq(sp) {
				padding-top: 40px;
			}
		}

		@include mq(sp) {
			padding-bottom: 70px;
		}

		&__item {
			box-shadow: 0 0 10px 0 rgba(#000, .1);
			margin: 10px 15px;

			@include mq(sp) {
				// width: 80%;
				width: 277px;
				margin: 10px 12px;
			}
		}

		.slick-prev,
		.slick-next {
			appearance: none;
			position: absolute;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 100px;
			border: none;
			background-color: #000;
			cursor: pointer;
			text-indent: -5000px;
			@include hover-opacity;

			@include mq(sp) {
				width: 38px;
				height: 76px;
			}

			&::after {
				color: #fff;
				
			}
		}

		.slick-prev {
			left: 0;

			&::after {
				@include icon($arrow_lg_left);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				font-size: 2rem;

				@include mq(sp) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 1.8rem;
				}
			}
		}

		.slick-next {
			right: 0;

			&::after {
				@include icon($arrow_lg_right);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				font-size: 2rem;

				@include mq(sp) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 1.8rem;
				}
			}
		}
	}
}
