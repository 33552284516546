/*

font

*/

.smaller {
	font-size:85% !important;
}

.larger {
	font-size:larger !important;
}

.bold{
	font-weight:bold !important;
}

.weight_normal{
	font-weight:normal !important;
}

.style_normal{
	font-style:normal !important;
}


/*

line height

*/

.lh_l{
	line-height: 1.75 !important;
}

.lh_m{
	line-height: 1.5 !important;
}

.lh_s{
	line-height: 1.3 !important;
}

.lh_xs{
	line-height: 1 !important;
}

/*

Color

*/
.color_accent {
	// color: $color_red_01;
}
.color_assort {
	// color: $color_assort;
}
