.input_select {
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 120px;

	&::before{
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		background: #fff;
		height: 100%;
		width: 100%;
	}

	&::after{
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: 10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 4px 0 4px;
		border-color: #333333 transparent transparent transparent;
	}

	select {
		appearance: none;
		background: transparent;
		position: relative;
		z-index: 1;
		height: 30px;
		box-sizing: border-box;
		padding: 0 20px 0 10px;
		border: 1px #333 solid;
		font-size: 1.6rem;
		width: 100%;
		border-radius: 0;

		&::-ms-expand {
			display: none;
		}

		&:focus {
			outline: none;
		}
	}


}