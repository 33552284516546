body.company_rinen {

	.rinen_desc {
		text-align: center;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: 2.4;

		@include mq(sp) {
			font-size: 1.4rem;
			line-height: 1.8;
		}
	}

	.rinen_credo {
		margin-top: 50px;
		margin-bottom: 80px;

		@include mq(sp) {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		&__thumb {
			img {
				@include mq(sp) {
					width: 292px;
				}
			}
			
		}
	}

	.rinen_access {

		&__data {
			overflow: hidden;

			@include mq(sp) {
				margin-top: -20px;
			}

			dt {
				clear: left;
				float: left;
				width: 160px;
				height: 26px;
				font-size: 2rem;
				font-family: $ff_en;
				font-weight: bold;
				letter-spacing: .1em;
				margin-top: 10px;
				color: $color_text;

				@include mq(sp) {
					float: none;
					font-size: 1.8rem;
					height: auto;
					width: auto;
					margin-top: 20px;
				}

				&:first-letter {
					color: $color_orange_main;
					font-size: 2.4rem;

					@include mq(sp) {
						font-size: 2.2rem;
					}
				}
			}

			dd {
				height: 26px;
				line-height: 26px;
				margin-left: 160px;
				font-size: 1.6rem;
				margin-top: 10px;
				color: $color_text;

				@include mq(sp) {
					float: none;
					height: auto;
					line-height: 1.6;
					margin-left: 1em;
					font-size: 1.4rem;
					margin-top: 0;
				}
			}
		}
	}
}
