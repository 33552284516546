@import url('https://fonts.googleapis.com/css?family=Oswald');

@mixin ff-oswald {
  font-family: 'Oswald', sans-serif;
}

@mixin icon( $iconfont ){
	font-family: 'icon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	text-indent: 0;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: $iconfont;
}
