@mixin hover_opacity($opacity: 0.8) {
	transition: opacity 0.4s ease;
	&:hover {
		opacity: $opacity;
		text-decoration: none;
	}
	&.current:hover {
		opacity: 1;
	}
}
