@charset "UTF-8";

/* -------------------------------------------------------------------------
	Web font
------------------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Leckerli+One);

/* -------------------------------------------------------------------------
	Foundation
------------------------------------------------------------------------- */
/* Vendor */
@import "foundation/vendor/compass/compass";
@import "foundation/vendor/slick";

/* Variable */
@import "foundation/variable/font";
@import "foundation/variable/brakepoints";
@import "foundation/variable/width";
@import "foundation/variable/color";

/* Function */
@import "foundation/function/font";
@import "foundation/function/math";

/* Mixin */
@import "foundation/mixin/media_query";
@import "foundation/mixin/margin";
@import "foundation/mixin/counter";
@import "foundation/mixin/grid";
@import "foundation/mixin/font";
@import "foundation/mixin/button";

/* Base */
@import "foundation/base/reset";
@import "foundation/base/base";
@import "foundation/base/svg";

/* -------------------------------------------------------------------------
	Layout
------------------------------------------------------------------------- */

@import "layout/main";
@import "layout/header";
@import "layout/footer";
@import "layout/container";
@import "layout/global_nav";
// @import "layout/local_nav";
// @import "layout/breadcrumbs";

/* -------------------------------------------------------------------------
	Object
------------------------------------------------------------------------- */

/* Component */

@import "object/component/message";
@import "object/component/hr";

/* Project */
@import "object/project/cms";
@import "object/project/page_header";
@import "object/project/page_body";
@import "object/project/page_heading";
@import "object/project/page_desc";
@import "object/project/page_nav";
@import "object/project/page_link";
@import "object/project/phone";
@import "object/project/table_basic";
@import "object/project/data_basic";
@import "object/project/pagination";
@import "object/project/permalink";
@import "object/project/btn";
@import "object/project/sec";
@import "object/project/sec_inner";
@import "object/project/sec_footer";
@import "object/project/title_primary";
@import "object/project/title_secondly";
@import "object/project/title_thirdly";
@import "object/project/title_fourthly";
@import "object/project/title_fifthly";
@import "object/project/title_sixthly";
@import "object/project/title_sevenly";
@import "object/project/title_eighthly";
@import "object/project/title_number";
@import "object/project/title_box";
@import "object/project/text";
@import "object/project/thumb";
@import "object/project/index_nav";
@import "object/project/accordion";
@import "object/project/list_link";
@import "object/project/list_decimal";
@import "object/project/list_entry";
@import "object/project/list_dots";
@import "object/project/list_disc";
@import "object/project/table_link";
@import "object/project/faq";
@import "object/project/frame_img";
@import "object/project/product_card";
@import "object/project/nav_entry";
@import "object/project/get_to_know";
@import "object/project/theme";
@import "object/project/form";
@import "object/project/input_text";
@import "object/project/input_checkbox";
@import "object/project/input_radio";
@import "object/project/input_select";
@import "object/project/input_textarea";
@import "object/project/page_to_top";
@import "object/project/breadcrumb";

/* Scope */
@import "object/scope/top";

/* About */
@import "object/scope/about/index";
@import "object/scope/about/human";

/* Company */
@import "object/scope/company/rinen";
@import "object/scope/company/officer";
@import "object/scope/company/history";
@import "object/scope/company/message";
@import "object/scope/company/network";

/* Enterprise */
@import "object/scope/enterprise/common";
@import "object/scope/enterprise/products";

/* Overseas */
@import "object/scope/overseas/index";

/* Recruit */
@import "object/scope/recruit/index";
@import "object/scope/recruit/requirements";
@import "object/scope/recruit/welfare";
@import "object/scope/recruit/about";

/* IR */
@import "object/scope/ir/index";
@import "object/scope/ir/highlight";
@import "object/scope/ir/investor";
@import "object/scope/ir/chukei";

/* Overseas */
@import "object/scope/socialenvironment/philanthropy";
@import "object/scope/socialenvironment/sdgs";

/* Sitemap */
@import "object/scope/sitemap";

/* Search */
@import "object/scope/search/index";

/* Utility */
@import "object/utility/clear";
@import "object/utility/align";
@import "object/utility/float";
@import "object/utility/text";
@import "object/utility/display";
@import "object/utility/image";
@import "object/utility/just_layout";
@import "object/utility/margin_padding";
@import "object/utility/grid";

/* sustainability */
@import "object/scope/sustainability/index";
@import "object/scope/sustainability/commitment";
@import "object/scope/sustainability/sustainability";
@import "object/scope/sustainability/environment";
@import "object/scope/sustainability/society";
@import "object/scope/sustainability/governance";