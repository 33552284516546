.list_decimal {
	font-size: 1.6rem;
	line-height: 1.9;
	color: $color_text;
	padding-left: 20px;
	margin-top: 10px;
	margin-bottom: 10px;

	@include mq(sp) {
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 1.4rem;
	}

	& > li {
		list-style: decimal;
	}

	&.-number {
		padding-left: 40px;
	}

}
