.table_link {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;

	@include mq(sp) {
		margin-top: 18px;
		margin-bottom: 18px;
	}

	td {
		line-height: $basic_lineheight;

		@include mq(sp) {
			display: block;
		}
	}

	a {
		text-decoration: none;
		color: $color_text;
		font-size: 1.4rem;
	}

	&__link {
		position: relative;
		padding-right: 20px;

		a {
			padding-left: 15px;
			display: inline-block;

			@include hover_opacity;

			&::before {
				@include icon($arrow-right);
				position: absolute;
				left: 0;
				top: 8px;
				font-size: 1rem;
				color: $color_orange_main;

			}
		}
	}

	&__pdf {
		text-align: right;

		@include mq(sp) {
			text-align: left;
			padding-left: 15px;
		}

		a {
			text-decoration: underline;

			@include mq(sp) {
				text-decoration: none;
			}

			&:hover {
				text-decoration: none;
			}
		}

		&::after {
			@include icon($pdf);
			font-size: 1.8rem;
			color: $color_red_01;
			vertical-align: middle;
			margin-left: 5px;

			@include mq(sp) {

			}
		}
	}
}
