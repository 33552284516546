.sec {
	margin-top: 60px;
	margin-bottom: 60px;
	counter-reset: section;

	@include mq(sp) {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	&.-md {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	&.-lg {
		margin-top: 80px;
		margin-bottom: 80px;

		@include mq(sp) {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}

	&.-xlg {
		margin-top: 120px;
		margin-bottom: 120px;

		@include mq(sp) {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}

	&.-gray {
		padding-top: 80px;
		padding-bottom: 80px;
		background-color: #f5f5f5;

		@include mq(sp) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
	}

	.page_header__box & {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}
