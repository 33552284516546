@font-face {
	font-family: "icon";
	src: url('../font/icon.eot');
	src: url('../font/icon.eot?#iefix') format('eot'),
		url('../font/icon.woff') format('woff'),
		url('../font/icon.ttf') format('truetype'),
		url('../font/icon.svg#icon') format('svg');
	font-weight: normal;
	font-style: normal;
}
.icon {
	display: inline-block;
	font-family: "icon";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
	width: 1.2857142857142858em;
	text-align: center;
}
.icon-arrow_left:before { content: "\EA01" }
.icon-arrow_lg_left:before { content: "\EA02" }
.icon-arrow_lg_right:before { content: "\EA03" }
.icon-arrow_right:before { content: "\EA04" }
.icon-blank:before { content: "\EA05" }
.icon-cret_down:before { content: "\EA06" }
.icon-nav_label:before { content: "\EA07" }
.icon-page_to_top:before { content: "\EA08" }
.icon-pagetop:before { content: "\EA09" }
.icon-pdf:before { content: "\EA0A" }


$arrow_left: "\EA01";
$arrow_lg_left: "\EA02";
$arrow_lg_right: "\EA03";
$arrow_right: "\EA04";
$blank: "\EA05";
$cret_down: "\EA06";
$nav_label: "\EA07";
$page_to_top: "\EA08";
$pagetop: "\EA09";
$pdf: "\EA0A";
