.faq {

	&__desc {
		text-align: center;
		font-size: 1.6rem;
		font-weight: bold;

		@include mq(sp) {
			font-size: 1.2rem;
		}
	}

	&__q {
		position: absolute;
		left: 38px;
		top: 0;
		font-size: 2.4rem;
		color: $color_orange_main;
		font-family: $ff_en;
		font-weight: bold;

		@include mq(sp) {
			left: 28px;
			font-size: 2rem;
		}
	}

	&__a {
		position: absolute;
		left: 42px;
		top: 32px;
		font-size: 2.4rem;
		color: $color_orange_main;
		font-family: $ff_en;
		font-weight: bold;

		@include mq(sp) {
			left: 0;
			top: 0;
			font-size: 2rem;
		}
	}

	&__question {
		position: relative;
		font-size: 1.6rem;
		font-weight: normal;
		color: $color_text;
		margin-bottom: 30px;
		padding: 4px 0 12px 60px;
		border-left: 4px #dcdcdc solid;
		border-bottom: 1px #dcdcdc solid;
		transition: border-color .3s ease;

		@include mq(sp) {
			font-size: 1.4rem;
			margin-bottom: 20px;
			padding: 4px 0 8px 46px;
			border-left-width: 3px;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 15px;
			top: 8px;
			display: block;
			width: 2px;
			height: 10px;
			background: $color_gray_border;
			transition: transform .3s ease,
				background-color .3s ease;
		}

		&::after {
			transform: rotate(90deg);
		}

		&.-active {
			border-color: $color_orange_main;

			&::before,
			&::after {
				transform: rotate(450deg);
				background-color: $color_orange_main;
			}
		}
	}

	&__answer {
		position: relative;
		padding: 30px 30px 30px 64px;

		@include mq(sp) {
			padding: 0 0 0 20px;
		}
	}
}
