body.recruit_about {
	.about_content {
		&__lead {
			margin-bottom: 35px;
			text-align: center;
			font-size: 2rem;

			@include mq(sp) {
				font-size: 1.4rem;
				margin-bottom: 15px;
				font-size: 1.8rem;
			}

			span {
				display: inline-block;
				font-size: 4.8rem;
				color: $color_orange_main;
				padding-top: 15px;
				font-weight: bold;

				@include mq(sp) {
					padding-top: 10px;
					font-size: 2.7rem;
				}
			}

			&.-sm {
				span {
					font-size: 3.2rem;

					@include mq(sp) {
						font-size: 2rem;
					}
				}
			}
		}

		&__desc {
			margin-top: 30px;
			font-size: 1.8rem;
			text-align: center;
			line-height: 1.8;

			@include mq(sp) {
				font-size: 1.6rem;
				margin-top: 15px;
				text-align: left;
			}
		}
	}

	.about_feature {

		margin-top: 20px;

		@include mq(sp) {
			margin-top: 10px;
		}

		&__thumb {
			text-align: center;
			margin-bottom: 20px;

			@include mq(sp) {
				margin-bottom: 10px;
			}
		}
		&__desc {
			font-size: 1.8rem;
			font-weight: bold;
			text-align: center;
		}
	}
}