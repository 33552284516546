body.enterprise_logistic {
	.logistic_figure{
		text-align: center;
		margin-top: 60px;
	}

	.logistic_gallery{
		@include display-flex;
		@include flex-wrap(wrap);
		@include justify-content(flex-start);
		@include align-items(stretch);
		@include align-content(stretch);
		@include flex-direction(row);

		margin-left: -30px;
		margin-top: -40px;
		> *{
			width: calc(1 / 5 * 100% - 30px);
			margin-left: 30px;
			margin-top: 40px;
		}
	}
}
