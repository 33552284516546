.table_basic {
	width: 100%;
	border-bottom: 1px $color_gray_border solid;
	box-sizing: border-box;
	border-collapse: separate;

	@include mq(sp) {
		border-bottom: none;
	}

	tr {
		&.-sp_child {
			@include mq(sp) {
				display: flex;
				flex-wrap: wrap;
				font-size: 1.2rem;
				border-bottom: 1px $color_gray_border solid;
			}

			td {
				@include mq(sp) {
				}
			}
		}
	}

	td,
	th {
		position: relative;
		padding: 15px;
		font-size: 1.4rem;
		color: $color_text;
		font-weight: normal;
		line-height: 1.6;

		&:first-child {
			@include mq(sp) {
			}
		}
	}

	thead {
		th {
			position: relative;
			background-color: #e2e2e2;
			border-right: 1px #fff solid;
			border-bottom: 1px #fff solid;

			@include mq(sp) {
				padding: 10px;
			}

			&:first-child {
				width: 180px;

				@include mq(sp) {
					width: 164px;
				}
			}
		}
	}

	tbody {

		th,
		td {
			padding: 15px;
			border-top: 1px $color_gray_border solid;
			vertical-align: middle;

			@include mq(sp) {
				padding: 10px;
			}
		}

		th {
			text-align: left;
			background-color: #efefef;

			@include mq(sp) {
				font-size: 1.2rem;
			}
		}

		th {
			width: 150px;
			padding: 15px;
			box-sizing: border-box;
			background: $color_gray_01;
			border-top: 1px $color_gray_border solid;
			vertical-align: top;
			text-align: left;
			font-weight: normal;

			@include mq(sp) {
				width: 100%;
				display: block;
				padding: 10px 15px;
				border-top: none;
			}

			&.-child {
				width: 180px;
				background-color: #f6f6f6;

				@include mq(sp) {
					width: 40%;
				}

				& + td {
					@include mq(sp) {
						display: flex;
						width: 60%;
						height: 100%;
						padding: 10px;
					}
				}
			}
		}

		

		td {
			text-align: right;
			border-right: 1px $color_gray_border solid;
			font-size: 1.4rem;

			@include mq(sp) {
				font-size: 1.2rem;
			}

			&:last-child {
				border-right: none;
			}

			box-sizing: border-box;

			@include mq(sp) {
				display: block;
				border-top: none;
				padding: 10px 0;
			}

			&.-left {
				text-align: left;
			}
		}

		
	}

	&__wrapper {
		@include mq(sp) {
			overflow-x: auto;
			padding-bottom: 10px;
		}
		
	}

	&.-th_25 {
		th {
			&:first-child {
				@include mq(sp, true) {
					width: 25%;
				}
			}
			
		}
	}

	&.-th_33 {
		th {
			@include mq(sp, true) {
				width: 33.333%;
			}
		}
	}

	&.-nowrap {
		@include mq(sp) {
			border-top: 1px $color_gray_border solid;
		}
		
		td,
		th {
			@include mq(sp) {
				width: auto;
				padding: 10px;
				border-bottom: 1px $color_gray_border solid;
				white-space: nowrap;
			}
		}

		tbody {
			td,th {
				@include mq(sp) {
					display: table-cell;
				}
			}
		}
	}

	&.-wrap {
		@include mq(sp) {
			border-top: 1px $color_gray_border solid;
		}
		
		td,
		th {
			@include mq(sp) {
				width: auto;
				padding: 10px;
				border-bottom: 1px $color_gray_border solid;
			}
		}

		th {
			@include mq(sp) {
				white-space: nowrap;
			}
		}

		tbody {
			td,th {
				@include mq(sp) {
					display: table-cell;
				}
			}
		}
	}

	
}
