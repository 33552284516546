body.company_investor {
	.investor_copy {
		&__desc {
			text-align: center;
			font-weight: bold;
			font-size: 1.6rem;
			margin-bottom: 20px;

			@include mq(sp) {
				font-size: 1.4rem;
				margin-bottom: 10px;
				text-align: left;
			}
		}

		&__lead {
			font-size: 3.2rem;
			text-align: center;
			font-weight: bold;
			line-height: 1.4;

			@include mq(sp) {
				text-align: left;
				font-size: 2rem;

			}

		}

		&__notes {
			text-align: center;
			font-size: 1.4rem;
			margin-top: 10px;

			margin-bottom: 50px;

			@include mq(sp) {
				margin-bottom: 25px;
				text-align: left;
				font-size: 1.2rem;
			}
		}
	}

}
