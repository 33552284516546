/*

プロジェクトにおける基本的なスタイル
（ページ全体の背景や、基本的なタイポグラフィ）
※ クラスは使わない

*/

// @import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
// @import url('https://fonts.googleapis.com/css?family=Bitter:400,700');
// @import url('https://fonts.googleapis.com/css?family=Abel');
@import url('https://fonts.googleapis.com/css?family=Ropa+Sans');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese');

html {
	font-size: 62.5%;
}
body{
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
	-webkit-text-size-adjust:100%;
	word-wrap:break-word;
	font-size: 1.4rem;
  color: #333;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "palt";
}
img {
	vertical-align: bottom;

	@include mq(sp) {
		max-width:100%;
		height:auto;
	}
}
a {

  &:link {
    color: $color_link;
  }
	&:hover {
		color: $color_link_hover;
		text-decoration:none;
	}
  &:visited {
    color: $color_link_visited;
  }
  &:active {
    color: $color_link_active;
  }
}

p {
	line-height: $basic_lineheight;
}
