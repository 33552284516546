body.company_officer {

	@at-root body.en.company_officer .page_header__inner {
		width: 500px;

		@include mq(sp) {
			width: auto;
		}
	}

	div.sec {
		.col_6 {
			@include mq(sp) {
				padding-top: 15px;
			}
		}
	}

	.officer_content {
		@at-root body.en.company_officer .officer_content {
			@include mq(sp, true) {
				display: flex;
				flex-direction: column;
				height: 100%;
			}
		}

		&__body {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-top: 15px;
			padding-bottom: 15px;
			box-sizing: border-box;
			height: 100%;

			@at-root body.en.company_officer .officer_content__body {
				@include mq(sp, true) {
					flex-grow: 1;
				}
			}
		}

		&__thumb {
			img {
				@include mq(sp) {
					width: 100%;
				}
			}
		}

		&__career {
			font-size: 1.4rem;
			color: #444444;
			line-height: 1.6;
			margin-bottom: 10px;

			&.-lg {
				font-size: 1.6rem;

				@include mq(sp) {
					font-size: 1.4rem;
					margin-top: -15px;
				}
			}

			@at-root body.en.company_officer .officer_content__career.-fit {
				@include mq(sp, true) {
					flex-grow: 1;
				}
			}

			@include mq(sp) {
				margin-bottom: 5px;
			}
		}

		&__name {
			color: #000;
			font-size: 2.4rem;
			line-height: 1.4;
			font-weight: bold;

			&.-lg {
				font-size: 2.8rem;

				@include mq(sp) {
					font-size: 1.8rem;
				}
			}

			&.-md {
				font-size: 2rem;

				@include mq(sp) {
					font-size: 1.8rem;
				}
			}

			@include mq(sp) {
				font-size: 1.8rem;
			}

			span {
				font-size: 1.4rem;
				margin-left: 1em;
			}
		}

		&__history {
			margin-top: 10px;
			color: $color_text;
			font-size: 1.4rem;
			line-height: 1.8;

			@include mq(sp) {
				margin-top: 5px;
			}

			@at-root body.en.company_officer .officer_content__history.-fit {
				@include mq(sp, true) {
					flex-grow: 1;
				}
			}
		}
	}
}
