.site_header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 3;
	width: 65px;
	height: 100%;
	background: $color_gray_01;
	box-shadow: 1px 0 4px rgba(#000, .2);

	@include mq(sp) {
		background: #fff;
		width: 100%;
		height: 47px;
		box-shadow: none;

	}

	&__inner {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}

.logo {
	width: 47px;
}

.nav_btn {
	flex-grow: 1;
	height: 100%;
	appearance: 0;
	border: none;
	background: none;
	cursor: pointer;

	@include mq(sp) {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0;
	}

	&:focus {
		outline: none;
	}

	&__bar {
		display: block;
		position: relative;
		width: 24px;
		height: 18px;
		margin: 0 auto;

		@include mq(sp) {
			margin: 0;
			width: 77px;
			height: 47px;
			background: #fff;

		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: 0;
			display: block;
			height: 2px;
			width: 24px;
			background: #000;

			@include mq(sp) {

				width: 17px;
				height: 1px;
			}
		}

		&::before {

			box-shadow: 0 6px 0 0 #000;
			transform: translateX(-50%);

			@include mq(sp) {
				left: 15px;
				top: 15px;
				box-shadow: 0 14px 0 0 #000;
				transform: none;
			}
		}

		&::after {
			top: 12px;
			transform: translateX(-50%);

			@include mq(sp) {
				width: 10px;
				left: 15px;
				top: 22px;
				transform: none;
			}

		}

		.nav_btn.-open & {
			&::before,
			&::after {

				@include mq(sp) {
				}
			}
			&::before {
				@include mq(sp) {
					transform: rotate(45deg);
					top: 22px;
					width: 15px;
					box-shadow: 0 0 0 0 #fff;
				}
			}

			&::after {
				@include mq(sp) {
					width: 15px;
					transform: rotate(-45deg);
				}

			}
		}
	}

	&__label {
		font-size: 1.2rem;
		font-family: $ff_en;
		letter-spacing: -.02em;
		font-weight: bold;

		@include mq(sp) {
			position: absolute;
			left: 40px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1rem;
			letter-spacing: 0;
		}

	}
}

.nav_lang {
	margin-right: 15px;
	margin-left: 15px;
	margin-bottom: 70px;

	&__list {
		&__item {
			margin-top: 1px;

			a,
			span {
				display: block;
				background-color: #fff;
				text-decoration: none;
				font-family: $ff_en;
				font-weight: bold;
				text-align: center;
				padding: 3px 0;
				color: #000;
				transition: color .2s ease,
				background-color .2s ease;
			}

			a:hover,
			&.-current span {
				background-color: $color_orange_main;
				color: #fff;
			}
		}
	}
}
