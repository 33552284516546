body.overseas {

	.table_basic tbody td{
		text-align: left !important;
	}

	.office_content{
		margin-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid #e2e2e2;

		&__list{
			float: left;
			width: 570px;

			@include mq(sp) {
				float: none;
				width: auto;
			}

			.office_content__title {
				margin-top: 15px;
				color: #444;
				line-height: 1.8;
				padding-left: 1.1em;
				text-indent: -1.1em;

				&:first-child{
					margin:0;
				}

				&::before {
					content: '・';
					color: #fe6400;
				}

			}

			.office_content__desc {
				color: #444;
				padding-left: 1em;
				line-height: 1.8;

				.tags {
					background-color: #fe6400;
					border-radius: 3px;
					color: #fff;
					display: inline-block;
					font-size: 1.1rem;
					margin: 5px 7px 5px 0;
					padding: 0 4px 0 5px;
				}

				a {
					color: #fe6400;
				}
			}
		}

		&__img{
			float: right;
			width: 275px;

			@include mq(sp) {
				float: none;
				margin: 30px auto 0;
			}
		}

	}

}