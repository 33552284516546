.list_dots{

	> li{
		line-height: 1.45;
		@include display-flex;
		@include flex-wrap(nowrap);
		@include justify-content(flex-start);
		@include align-items(flex-start);
		@include align-content(flex-start);
		@include flex-direction(row);
		&:before{
			@include border-radius(100%);
			content: "";
			background-color: $color_text;
			width: .6em;
			height: .6em;
			position: relative;
			top: .1em + .4em;
			margin-right: 3px;
		}

	}

	> * + *{
		margin-top: 3px;
	}
}