.title_sevenly {
	font-size: 2.8rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 20px;
	line-height: 1.6;

	@include mq(sp) {
		font-size: 1.8rem;
		margin-bottom: 10px;
	}
}
