@charset "UTF-8";
/* -------------------------------------------------------------------------
	Web font
------------------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Leckerli+One);
/* -------------------------------------------------------------------------
	Foundation
------------------------------------------------------------------------- */
/* Vendor */
/* Slider */
@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://fonts.googleapis.com/css?family=Ropa+Sans");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&display=swap&subset=japanese");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Variable */
/*

base font size

*/
/* $basic_font_size : 14, 87.5%; */
/* $basic_font_size : 15, 93.75%; */
/* $basic_font_size : 16, 100%; */
/*

brakepoints

*/
/*

width

*/
/*

base color

*/
/*

text color

*/
/*

recruit text color

*/
/*

border-color

*/
/* Function */
/*

フォントサイズをパーセントに変換して返す関数

```sass
.heading_lv1{
	font-size:fs(30);
}
```

指定が2重になる場合は、第2引数に親要素で指定したサイズを入れる

```sass
.heading_lv1{
	font-size:fs(30);
	span{
		font-size:fs(14,30);
	}
}
```

*/
/*

パーセントを返す関数

```sass
.section{
	margin-bottom:per(30px, 960px);
}
```

*/
/* Mixin */
/*

メディアクエリ

ブレークポイント定義
```sass
$breakpoints:(
	tablet: 959px,
	sp: 679px
);
```

● max-widthで指定する
```sass
@include mq(sp) {
	height:40px;
}
```
```output css
@media screen and (max-width: 679px) {
	height: 40px;
}
```

● 範囲指定する場合は、引数に2つ入れる
```sass
@include mq(sp tablet) {
	height:40px;
}
```
```output css
@media screen and (min-width: 680px) and (max-width: 959px) {
	height: 40px;
}
```

● min-widthで指定する場合は第2引数をtrueにする
```sass
@include mq(tablet, true) {
	height:40px;
}
```
```output css
@media screen and (min-width: 960px) {
	height: 40px;
}
```

*/
/*

ブレイクポイントごとのキーワードとマージンの組み合わせを作る

```sass
@include create_margins(
	(
		default:(
			l:50px,
			m:40px,
			s:30px,
			xs:20px
		),
		tablet:(
			l:40px,
			m:30px,
			s:25px
		),
		sp:(
			l:30px,
			m:25px,
			s:20px,
			xs:15px
		)
	)
)
```

それぞれのキーワードに対する上下左右のマージン用クラスを
ブレークポイントの数だけ出力します

```output css
.rmb_l { margin-bottom: 50px; }
.rmt_l { margin-top: 50px; }
.rmr_l { margin-right: 50px; }
.rml_l { margin-left: 50px; }
～略～

@media screen and (max-width: 959px) {
  .rmb_l { margin-bottom: 40px; }
  .rmt_l { margin-top: 40px; }
  .rmr_l { margin-right: 40px; }
  .rml_l { margin-left: 40px; }
	～略～
}

@media screen and (max-width: 679px) {
  .rmb_l { margin-bottom: 30px; }
  .rmt_l { margin-top: 30px; }
  .rmr_l { margin-right: 30px; }
  .rml_l { margin-left: 30px; }
	～略～
}
```

*/
/*

章番号用mixin (IE8以上)

キャプションに章番号をつける場合
```sass
$counterName: oreoreCounter;
.parent-section {
	@include resetCounter($counterName);
	h2 {
		@include addCounter($counterName, '第', '章');
	}
}
```

入れ子になってるリストに通し番号(1-1-1など）を付ける場合
```sass
$counterName: listCounter;
ol {
	@include resetCounter($counterName);
	li {
		@include addCounters($counterName, '-');
	}
}
```

*/
/*

グリッドレイアウト用mixin (IE8以上)

引数で分割数を指定して呼び出す
```sass
@include grid_system(12);
```

```html
<div class="grid--12 gutter--2">  ← このdivへの幅指定はNG
	<div class="grid__col--4"></div> ┐
	<div class="grid__col--4"></div> ├ 子要素は合計が分割数になるようにクラス名を付ける
	<div class="grid__col--4"></div> ┘
</div>
```

グリッドの間隔は「gutter--N」で指定する
通常はパーセントですが、「gutter--Npx」にするとピクセルになります。

ブレークポイントで変える場合は、「tablet_」「sp_」など、$breakpointsで定義したキーの接頭辞を付ける
```html
<div class="grid--12 gutter--20px tablet-gutter--15px sp_gutter--10px">
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
	<div class="grid__col--4 tablet-grid__col--6 sp_grid__col--12"></div>
</div>
```

*/
/* Base */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, main {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

input, textarea {
  margin: 0;
  padding: 0; }

small {
  font-size: smaller; }

sup {
  font-size: 0.7em;
  vertical-align: top; }

sub {
  font-size: 0.7em;
  vertical-align: baseline; }

/* YUI 3.9.1 (build 5852) Copyright 2013 Yahoo! Inc. http://yuilibrary.com/license/ */
/**
 * Percents could work for IE, but for backCompat purposes, we are using keywords.
 * x-small is for IE6/7 quirks mode.
 */
body {
  font: 81.25%/1.231 arial, sans-serif;
  *font-size: small;
  /* for IE */
  *font: x-small;
  /* for IE in quirks mode */ }

/**
 * Nudge down to get to 13px equivalent for these form elements
 */
/**
 * To help tables remember to inherit
 */
/**
 * Bump up IE to get to 13px equivalent for these fixed-width elements
 */
pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  *font-size: 108%;
  line-height: 100%; }

/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssfonts {
  display: none; }

/*

プロジェクトにおける基本的なスタイル
（ページ全体の背景や、基本的なタイポグラフィ）
※ クラスは使わない

*/
html {
  font-size: 62.5%; }

body {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  font-size: 1.4rem;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt"; }

img {
  vertical-align: bottom; }
  @media screen and (max-width: 768px) {
    img {
      max-width: 100%;
      height: auto; } }

a:link {
  color: #666; }

a:hover {
  color: #666;
  text-decoration: none; }

a:visited {
  color: #666; }

a:active {
  color: #666; }

p {
  line-height: 1.8; }

@font-face {
  font-family: "icon";
  src: url("../font/icon.eot");
  src: url("../font/icon.eot?#iefix") format("eot"), url("../font/icon.woff") format("woff"), url("../font/icon.ttf") format("truetype"), url("../font/icon.svg#icon") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  display: inline-block;
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.icon-arrow_left:before {
  content: "\EA01"; }

.icon-arrow_lg_left:before {
  content: "\EA02"; }

.icon-arrow_lg_right:before {
  content: "\EA03"; }

.icon-arrow_right:before {
  content: "\EA04"; }

.icon-blank:before {
  content: "\EA05"; }

.icon-cret_down:before {
  content: "\EA06"; }

.icon-nav_label:before {
  content: "\EA07"; }

.icon-page_to_top:before {
  content: "\EA08"; }

.icon-pagetop:before {
  content: "\EA09"; }

.icon-pdf:before {
  content: "\EA0A"; }

/* -------------------------------------------------------------------------
	Layout
------------------------------------------------------------------------- */
.main {
  min-width: 980px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .main {
      min-width: 0; } }

.site_header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 65px;
  height: 100%;
  background: #efefef;
  box-shadow: 1px 0 4px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px) {
    .site_header {
      background: #fff;
      width: 100%;
      height: 47px;
      box-shadow: none; } }
  .site_header__inner {
    display: flex;
    flex-direction: column;
    height: 100%; }

.logo {
  width: 47px; }

.nav_btn {
  flex-grow: 1;
  height: 100%;
  appearance: 0;
  border: none;
  background: none;
  cursor: pointer; }
  @media screen and (max-width: 768px) {
    .nav_btn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0; } }
  .nav_btn:focus {
    outline: none; }
  .nav_btn__bar {
    display: block;
    position: relative;
    width: 24px;
    height: 18px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .nav_btn__bar {
        margin: 0;
        width: 77px;
        height: 47px;
        background: #fff; } }
    .nav_btn__bar::before, .nav_btn__bar::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      display: block;
      height: 2px;
      width: 24px;
      background: #000; }
      @media screen and (max-width: 768px) {
        .nav_btn__bar::before, .nav_btn__bar::after {
          width: 17px;
          height: 1px; } }
    .nav_btn__bar::before {
      box-shadow: 0 6px 0 0 #000;
      transform: translateX(-50%); }
      @media screen and (max-width: 768px) {
        .nav_btn__bar::before {
          left: 15px;
          top: 15px;
          box-shadow: 0 14px 0 0 #000;
          transform: none; } }
    .nav_btn__bar::after {
      top: 12px;
      transform: translateX(-50%); }
      @media screen and (max-width: 768px) {
        .nav_btn__bar::after {
          width: 10px;
          left: 15px;
          top: 22px;
          transform: none; } }
    @media screen and (max-width: 768px) {
      .nav_btn.-open .nav_btn__bar::before {
        transform: rotate(45deg);
        top: 22px;
        width: 15px;
        box-shadow: 0 0 0 0 #fff; } }
    @media screen and (max-width: 768px) {
      .nav_btn.-open .nav_btn__bar::after {
        width: 15px;
        transform: rotate(-45deg); } }
  .nav_btn__label {
    font-size: 1.2rem;
    font-family: "Ropa Sans", sans-serif;
    letter-spacing: -.02em;
    font-weight: bold; }
    @media screen and (max-width: 768px) {
      .nav_btn__label {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1rem;
        letter-spacing: 0; } }

.nav_lang {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 70px; }
  .nav_lang__list__item {
    margin-top: 1px; }
    .nav_lang__list__item a,
    .nav_lang__list__item span {
      display: block;
      background-color: #fff;
      text-decoration: none;
      font-family: "Ropa Sans", sans-serif;
      font-weight: bold;
      text-align: center;
      padding: 3px 0;
      color: #000;
      transition: color .2s ease, background-color .2s ease; }
    .nav_lang__list__item a:hover,
    .nav_lang__list__item.-current span {
      background-color: #fe6400;
      color: #fff; }

.site_footer {
  border-top: 1px #e2e2e2 solid;
  min-width: 980px;
  background-color: #fff; }
  @media screen and (max-width: 768px) {
    .site_footer {
      min-width: 0; } }
  .site_footer__header {
    display: flex;
    justify-content: center; }
  .site_footer__body {
    max-width: 980px;
    margin: 20px auto 35px;
    padding: 0 20px; }
    @media screen and (max-width: 768px) {
      .site_footer__body {
        padding: 0 15px;
        margin-bottom: 40px; } }
  .site_footer__footer {
    background-color: #e9e9e9;
    padding: 32px 20px 40px; }
    @media screen and (max-width: 768px) {
      .site_footer__footer {
        padding: 22px 15px 20px; } }
  .site_footer__logo {
    padding-right: 54px;
    padding-left: 54px;
    border: 1px #e2e2e2 solid;
    border-bottom: none;
    transform: translate(0, -100%);
    background-color: #fff; }
    body.en .site_footer__logo {
      height: 50px; }
    @media screen and (max-width: 768px) {
      .site_footer__logo {
        padding-right: 45px;
        padding-left: 45px; } }
    .site_footer__logo img {
      transform: translateY(17px); }
      @media screen and (max-width: 768px) {
        .site_footer__logo img {
          width: 104px; }
          body.en .site_footer__logo img {
            width: 55px; } }
  .site_footer__nav {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site_footer__nav {
        display: block;
        border-top: 1px #e2e2e2 solid; } }
    .site_footer__nav__content {
      width: 240px; }
      body.en .site_footer__nav__content {
        width: 320px; }
      @media screen and (max-width: 768px) {
        .site_footer__nav__content {
          width: auto; } }
    .site_footer__nav__list__item {
      margin-top: 30px;
      padding: 15px; }
      @media screen and (max-width: 768px) {
        .site_footer__nav__list__item {
          margin-top: 0;
          padding: 0; } }
      .site_footer__nav__list__item:first-child {
        margin-top: 0; }
      @media screen and (min-width: 769px) {
        .site_footer__nav__list__item.-parent {
          background-color: #fff3f0; } }
      .site_footer__nav__list__item > a {
        color: #fe6400;
        font-size: 1.4rem;
        font-weight: bold;
        text-decoration: none;
        transition: opacity 0.4s ease; }
        .site_footer__nav__list__item > a:hover {
          opacity: 0.7;
          text-decoration: none; }
        .site_footer__nav__list__item > a.current:hover {
          opacity: 1; }
        @media screen and (max-width: 768px) {
          .site_footer__nav__list__item > a {
            display: block;
            border-bottom: 1px #e2e2e2 solid;
            padding: 12px 0;
            font-size: 1.4rem;
            color: #333; } }
        @media screen and (min-width: 769px) {
          .site_footer__nav__list__item > a.-current {
            position: relative; }
            .site_footer__nav__list__item > a.-current::after {
              content: '';
              display: block;
              background-color: #fe6400;
              position: absolute;
              bottom: -1px;
              left: 13px;
              right: -2px;
              height: 1px; } }
        @media screen and (max-width: 768px) {
          .site_footer__nav__list__item > a.-current {
            color: #fe6400; } }
        .site_footer__nav__list__item > a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          margin-right: 4px; }
          @media screen and (max-width: 768px) {
            .site_footer__nav__list__item > a::before {
              color: #fe6400;
              margin-right: 10px; } }
    .site_footer__nav__child_list {
      margin-top: 10px;
      border-top: 1px #b2b2b2 dotted; }
      @media screen and (max-width: 768px) {
        .site_footer__nav__child_list {
          display: none; } }
      .site_footer__nav__child_list__item {
        margin-top: 8px; }
        body.en .site_footer__nav__child_list__item {
          margin-left: 15px; }
        .site_footer__nav__child_list__item > a {
          font-size: 1.3rem;
          text-decoration: none;
          color: #000;
          transition: opacity 0.4s ease; }
          .site_footer__nav__child_list__item > a:hover {
            opacity: 0.7;
            text-decoration: none; }
          .site_footer__nav__child_list__item > a.current:hover {
            opacity: 1; }
          body.en .site_footer__nav__child_list__item > a {
            margin-left: -15px; }
          .site_footer__nav__child_list__item > a::before {
            font-family: 'icon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            text-indent: 0;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "";
            font-size: 1rem;
            margin-right: 4px;
            color: #fe6400; }
            body.en .site_footer__nav__child_list__item a.-current::before {
              position: relative;
              top: 3px; }
          @media screen and (min-width: 769px) {
            .site_footer__nav__child_list__item > a.-current {
              position: relative; }
              body.en .site_footer__nav__child_list__item a.-current {
                display: inline-flex;
                top: -3px; }
              .site_footer__nav__child_list__item > a.-current::after {
                content: '';
                display: block;
                background-color: #fe6400;
                position: absolute;
                bottom: -1px;
                left: 13px;
                right: -2px;
                height: 1px; } }
    .site_footer__nav__grandchild_list {
      margin-bottom: 10px; }
      .site_footer__nav__grandchild_list__item {
        margin-top: 8px;
        margin-left: 30px; }
        body.en .site_footer__nav__grandchild_list__item {
          margin-left: 15px; }
        .site_footer__nav__grandchild_list__item > a {
          font-size: 1.3rem;
          text-decoration: none;
          color: #000;
          transition: opacity 0.4s ease; }
          .site_footer__nav__grandchild_list__item > a:hover {
            opacity: 0.7;
            text-decoration: none; }
          .site_footer__nav__grandchild_list__item > a.current:hover {
            opacity: 1; }
          @media screen and (min-width: 769px) {
            .site_footer__nav__grandchild_list__item > a.-current {
              position: relative;
              padding-bottom: 1px;
              display: inline;
              background: linear-gradient(transparent calc(100% - 1px), #fe6400 1px);
              /*&::after {
								content:'';
								display: block;
								background-color: $color-orange-main;
								position: absolute;
								bottom: -3px;
								left: 0;
								right: 0;
								height: 1px;
							}*/ } }
  .site_footer__link {
    display: flex;
    flex-wrap: wrap;
    max-width: 72em;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      .site_footer__link {
        justify-content: space-around; } }
    .site_footer__link__item {
      margin: 8px 25px; }
      body.en .site_footer__link__item:last-child {
        margin-left: 40px; }
        @media screen and (max-width: 768px) {
          body.en .site_footer__link__item:last-child {
            margin-left: 0; } }
      @media screen and (max-width: 768px) {
        .site_footer__link__item {
          margin: 0;
          margin-top: 10px; }
          body.en .site_footer__link__item {
            margin: 0 10px;
            margin-bottom: 15px; } }
      .site_footer__link__item a {
        color: #000;
        text-decoration: none;
        font-size: 1.3rem;
        transition: color .2s ease;
        transition: opacity 0.4s ease; }
        body.en .site_footer__link__item:last-child a {
          margin-left: -20px; }
          @media screen and (max-width: 768px) {
            body.en .site_footer__link__item:last-child a {
              margin-left: 0; } }
        .site_footer__link__item a:hover {
          opacity: 0.7;
          text-decoration: none; }
        .site_footer__link__item a.current:hover {
          opacity: 1; }
        .site_footer__link__item a.-current {
          position: relative; }
          .site_footer__link__item a.-current::after {
            content: '';
            display: block;
            background-color: #fe6400;
            position: absolute;
            bottom: -3px;
            left: 20px;
            right: 0;
            height: 1px; }
        .site_footer__link__item a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          margin-right: 10px; }

.copyright {
  margin-top: 22px;
  font-size: 1.1rem;
  font-family: "Ropa Sans", sans-serif;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .copyright {
      margin-top: 17px; } }

.en .site_footer .site_footer__link {
  max-width: 980px; }
  .en .site_footer .site_footer__link__item {
    margin: 8px 16px; }

.container {
  position: relative;
  padding-left: 65px;
  min-width: 980px; }
  @media screen and (max-width: 768px) {
    .container {
      min-width: 0;
      padding-left: 0;
      padding-top: 47px; } }
  @media screen and (min-width: 769px) {
    .container.-open {
      padding-left: 220px; } }

.global_nav {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #efefef;
  transform: translateX(-100%);
  transition: transform .2s ease; }
  @media screen and (max-width: 768px) {
    .global_nav {
      height: auto;
      width: 100%;
      top: 47px;
      transform: none;
      display: none; } }
  @media screen and (min-width: 769px) {
    .nav_btn:hover + .global_nav, .global_nav:hover, .global_nav.-top {
      transform: translateX(0); } }
  @media screen and (max-width: 768px) {
    .global_nav.-open {
      display: block; } }
  .global_nav__inner {
    display: flex;
    flex-direction: column;
    height: 100%; }
    body.en .global_nav__inner {
      width: 220px; }
    @media screen and (max-width: 768px) {
      .global_nav__inner {
        height: 100vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 0 200px;
        box-sizing: border-box; }
        body.en .global_nav__inner {
          width: auto; } }
  .global_nav__logo {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 220px;
    height: 140px;
    box-sizing: border-box;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 768px) {
      .global_nav__logo {
        display: none; } }
  @media screen and (max-width: 768px) {
    .global_nav__list__item {
      position: relative; } }
  @media screen and (min-width: 769px) {
    .global_nav__list__item.hover > a {
      color: #fff;
      background-color: #fe6400; } }
  @media screen and (min-width: 769px) {
    .global_nav__list__item.hover .global_nav__child_list {
      opacity: 1;
      visibility: visible; } }
  .global_nav__list__item > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 55px;
    padding-left: 25px;
    text-decoration: none;
    font-size: 1.5rem;
    border-bottom: 1px #cdcdcd solid;
    font-weight: bold;
    color: #333;
    transition: color .2s ease, background-color .2s ease; }
    @media screen and (max-width: 768px) {
      .global_nav__list__item > a {
        height: auto;
        padding: 15px 25px;
        font-size: 1.4rem; } }
    @media screen and (min-width: 769px) {
      .global_nav__list__item > a:hover {
        color: #fff;
        background-color: #fe6400; } }
    .global_nav__list__item > a::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background: rgba(0, 0, 0, 0.2); }
      @media screen and (max-width: 768px) {
        .global_nav__list__item > a::after {
          width: 3px; } }
    @media screen and (max-width: 768px) {
      .global_nav__list__item > a.-active .global_nav__child_list {
        display: block; } }
  .global_nav__list__item__toggle {
    display: none; }
    @media screen and (max-width: 768px) {
      .global_nav__list__item__toggle {
        display: block;
        position: absolute;
        right: 20px;
        top: 14px;
        width: 20px;
        height: 20px;
        background: #fff;
        pointer-events: none; } }
    .global_nav__list__item__toggle::before, .global_nav__list__item__toggle::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      display: block;
      height: 2px;
      width: 10px;
      background: #333;
      transition: transform .2s ease;
      pointer-events: none; }
    .global_nav__list__item__toggle::after {
      transform: rotate(90deg); }
    .global_nav__list__item__toggle.-active::before, .global_nav__list__item__toggle.-active::after {
      transform: rotate(360deg); }
  .global_nav__child_list {
    overflow-y: auto;
    position: absolute;
    left: 220px;
    top: 0;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    width: 260px;
    height: 100%;
    padding-top: 110px;
    box-sizing: border-box;
    background: #fe6400;
    box-shadow: -2px 0 1px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease, visibility .2s ease; }
    @media screen and (max-width: 768px) {
      .global_nav__child_list {
        width: 100%; } }
    @media screen and (min-width: 769px) {
      .global_nav__list__item > a:hover + .global_nav__child_list, .global_nav__child_list:hover {
        opacity: 1;
        visibility: visible; } }
    @media screen and (max-width: 768px) {
      .global_nav__list__item:nth-child(2) .global_nav__child_list,
      .global_nav__list__item:nth-child(3) .global_nav__child_list,
      .global_nav__list__item:nth-child(4) .global_nav__child_list,
      .global_nav__list__item:nth-child(5) .global_nav__child_list,
      .global_nav__list__item:nth-child(6) .global_nav__child_list,
      .global_nav__list__item:nth-child(7) .global_nav__child_list {
        padding-top: 0; } }
    @media screen and (max-width: 768px) {
      .global_nav__child_list {
        display: none;
        position: static;
        opacity: 1;
        visibility: visible;
        background: none;
        box-shadow: none;
        padding-top: 0;
        background: rgba(255, 255, 255, 0.6); } }
    .global_nav__child_list__item > a {
      display: flex;
      color: #fff;
      padding: 10px 25px;
      text-decoration: none;
      font-size: 1.5rem;
      font-weight: bold;
      transition: background-color .2s ease; }
      @media screen and (min-width: 769px) {
        .global_nav__child_list__item > a:hover {
          background-color: #f20e03; } }
      @media screen and (max-width: 768px) {
        .global_nav__child_list__item > a {
          font-size: 1.3rem;
          color: #333;
          font-weight: normal;
          border-top: 1px solid #efefef; } }
  .global_nav__grandchild_list {
    margin-bottom: 20px; }
    .global_nav__grandchild_list__item {
      margin-left: 25px; }
      .global_nav__grandchild_list__item > a {
        position: relative;
        left: 0;
        display: flex;
        padding: 5px 0;
        color: #fff;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: bold;
        transition: padding .2s ease; }
        @media screen and (min-width: 769px) {
          .global_nav__grandchild_list__item > a:hover {
            padding-left: 10px; } }
        .global_nav__grandchild_list__item > a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          margin-right: 4px; }
          @media screen and (max-width: 768px) {
            .global_nav__grandchild_list__item > a::before {
              color: #fe6400;
              position: relative;
              top: 3px; } }
        @media screen and (max-width: 768px) {
          .global_nav__grandchild_list__item > a {
            font-size: 1.3rem;
            color: #333;
            font-weight: normal; } }
  .global_nav__link {
    flex-grow: 1;
    margin: 20px 10px 0;
    display: flex;
    justify-content: space-around; }
    .global_nav__link__item a {
      text-decoration: none;
      color: #333;
      transition: opacity 0.4s ease; }
      .global_nav__link__item a:hover {
        opacity: 0.8;
        text-decoration: none; }
      .global_nav__link__item a.current:hover {
        opacity: 1; }
  .global_nav__lang {
    margin: 0 10px 15px; }
    .global_nav__lang__list {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end; }
      @media screen and (max-width: 768px) {
        .global_nav__lang__list {
          margin-top: 40px;
          justify-content: center; } }
      .global_nav__lang__list__item {
        margin-right: 1px;
        width: 20%; }
        .global_nav__lang__list__item a,
        .global_nav__lang__list__item span {
          display: block;
          background-color: #fff;
          text-decoration: none;
          font-family: "Ropa Sans", sans-serif;
          font-weight: bold;
          text-align: center;
          padding: 3px 0;
          color: #000;
          transition: color .2s ease, background-color .2s ease; }
        .global_nav__lang__list__item a:hover,
        .global_nav__lang__list__item.-current span {
          background-color: #fe6400;
          color: #fff; }

@media screen and (min-width: 769px) {
  html.is_touch .is_touch_disable > a {
    pointer-events: none; } }

/* -------------------------------------------------------------------------
	Object
------------------------------------------------------------------------- */
/* Component */
/*

no support message

*/
.noscript,
.legacy_ie_message {
  background-color: #fff9d7;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1px; }

.legacy_ie_message {
  display: none; }
  .regacy_ie .legacy_ie_message {
    display: block; }

/*

no support message

*/
hr {
  border-top: 1px solid #e0e0e0; }
  hr.-lg {
    margin-top: 70px;
    margin-bottom: 70px; }
    @media screen and (max-width: 768px) {
      hr.-lg {
        margin-top: 40px;
        margin-bottom: 40px; } }

/* Project */
.cms__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 0 5px 25px;
  border-left: 5px #fe6400 solid; }
  @media screen and (max-width: 768px) {
    .cms__header {
      padding-left: 12px; } }

.cms__body {
  margin-top: 35px; }
  @media screen and (max-width: 768px) {
    .cms__body {
      margin-top: 30px; } }
  .cms__body h1 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 2.4rem;
    line-height: 1.6; }
    @media screen and (max-width: 768px) {
      .cms__body h1 {
        font-size: 2rem; } }
  .cms__body h2 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 1.8rem;
    line-height: 1.6; }
  .cms__body h3 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 1.6rem;
    line-height: 1.6;
    color: #fe6400; }
  .cms__body p {
    line-height: 2;
    font-size: 1.6rem;
    margin: 1em 0; }
    @media screen and (max-width: 768px) {
      .cms__body p {
        font-size: 1.4rem;
        line-height: 1.8; } }
  .cms__body ul {
    padding-left: 20px; }
    .cms__body ul li {
      list-style: disc; }
  .cms__body ol {
    padding-left: 20px; }
    .cms__body ol li {
      list-style: decimal; }
  .cms__body li {
    line-height: 2; }
    @media screen and (max-width: 768px) {
      .cms__body li {
        line-height: 1.8; } }
  .cms__body img {
    margin-top: 20px;
    margin-bottom: 20px; }

.cms__footer {
  margin-top: 60px; }
  @media screen and (max-width: 768px) {
    .cms__footer {
      margin-top: 30px; } }

.cms__category {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  background: #000;
  color: #fff;
  padding: 2px 20px;
  border-radius: 3px;
  margin-right: 20px; }
  @media screen and (max-width: 768px) {
    .cms__category {
      margin-right: 10px; } }

@media screen and (max-width: 768px) {
  .cms__date {
    font-size: 1.2rem; } }

.cms__title {
  width: 100%;
  font-size: 2.8rem;
  margin-top: 10px; }
  @media screen and (max-width: 768px) {
    .cms__title {
      font-size: 1.7rem; } }

.page_header {
  background: 50% 50% no-repeat;
  background-size: cover;
  padding: 0 20px; }
  @media screen and (min-width: 769px) {
    .page_header.-about::after {
      background: url(/assets/img/about/img_visual.jpg) 50% 50% no-repeat #222;
      background-size: cover; } }
  @media screen and (max-width: 768px) {
    .page_header.-about::after {
      background: url(/assets/img/about/img_visual_sp.jpg) 50% 40px no-repeat #222;
      background-size: cover; } }
  @media screen and (min-width: 769px) {
    .page_header.-about_technical::after {
      background: url(/assets/img/about/technical/img_visual.jpg) 50% 50% no-repeat #222;
      background-size: cover; } }
  @media screen and (max-width: 768px) {
    .page_header.-about_technical::after {
      background: url(/assets/img/about/technical/img_visual_sp.jpg) 50% 40px no-repeat #222;
      background-size: cover; } }
  @media screen and (min-width: 769px) {
    .page_header.-about_procurement::after {
      background: url(/assets/img/about/procurement/img_visual.jpg) 50% 50% no-repeat #222;
      background-size: cover; } }
  @media screen and (max-width: 768px) {
    .page_header.-about_procurement::after {
      background: url(/assets/img/about/procurement/img_visual_sp.jpg) 50% 40px no-repeat #222;
      background-size: cover; } }
  @media screen and (min-width: 769px) {
    .page_header.-about_information::after {
      background: url(/assets/img/about/information/img_visual.jpg) 50% 50% no-repeat #222;
      background-size: cover; } }
  @media screen and (max-width: 768px) {
    .page_header.-about_information::after {
      background: url(/assets/img/about/information/img_visual_sp.jpg) 50% 40px no-repeat #222;
      background-size: cover; } }
  @media screen and (min-width: 769px) {
    .page_header.-about_human::after {
      background: url(/assets/img/about/human/img_visual.jpg) 50% 50% no-repeat #222;
      background-size: cover; } }
  @media screen and (max-width: 768px) {
    .page_header.-about_human::after {
      background: url(/assets/img/about/human/img_visual_sp.jpg) 50% 40px no-repeat #222;
      background-size: cover; } }
  @media screen and (min-width: 769px) {
    .page_header.-company {
      background-image: url(/assets/img/company/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company {
      background-image: url(/assets/img/company/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_profile {
      background-image: url(/assets/img/company/profile/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_profile {
      background-image: url(/assets/img/company/profile/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_history {
      background-image: url(/assets/img/company/history/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_history {
      background-image: url(/assets/img/company/history/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_network {
      background-image: url(/assets/img/company/network/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_network {
      background-image: url(/assets/img/company/network/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_officer {
      background-image: url(/assets/img/company/officer/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_officer {
      background-image: url(/assets/img/company/officer/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_rinen {
      background-image: url(/assets/img/company/rinen/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_rinen {
      background-image: url(/assets/img/company/rinen/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-company_message {
      background-image: url(/assets/img/company/message/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-company_message {
      background-image: url(/assets/img/company/message/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir {
      background-image: url(/assets/img/ir/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir {
      background-image: url(/assets/img/ir/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_qualification {
      background-image: url(/assets/img/ir/qualification/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_qualification {
      background-image: url(/assets/img/ir/qualification/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_stockholderinformation {
      background-image: url(/assets/img/ir/stockholderinformation/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_stockholderinformation {
      background-image: url(/assets/img/ir/stockholderinformation/img_visual.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_investor {
      background-image: url(/assets/img/ir/investor/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_investor {
      background-image: url(/assets/img/ir/investor/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_calendar {
      background-image: url(/assets/img/ir/calendar/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_calendar {
      background-image: url(/assets/img/ir/calendar/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_electron {
      background-image: url(/assets/img/ir/electron/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_electron {
      background-image: url(/assets/img/ir/electron/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_faq {
      background-image: url(/assets/img/ir/faq/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_faq {
      background-image: url(/assets/img/ir/faq/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_governance {
      background-image: url(/assets/img/ir/governance/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_governance {
      background-image: url(/assets/img/ir/governance/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_highlight {
      background-image: url(/assets/img/ir/highlight/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_highlight {
      background-image: url(/assets/img/ir/highlight/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_library {
      background-image: url(/assets/img/ir/library/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_library {
      background-image: url(/assets/img/ir/library/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_documents {
      background-image: url(/assets/img/ir/documents/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_documents {
      background-image: url(/assets/img/ir/documents/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-ir_chukei {
      background-image: url(/assets/img/ir/chukei/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-ir_chukei {
      background-image: url(/assets/img/ir/chukei/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-enterprise {
      background-image: url(/assets/img/enterprise/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-enterprise {
      background-image: url(/assets/img/enterprise/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-enterprise_logistic {
      background-image: url(/assets/img/enterprise/products/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-enterprise_logistic {
      background-image: url(/assets/img/enterprise/products/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-enterprise_service {
      background-image: url(/assets/img/enterprise/service/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-enterprise_service {
      background-image: url(/assets/img/enterprise/service/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-enterprise_proposal {
      background-image: url(/assets/img/enterprise/solution/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-enterprise_proposal {
      background-image: url(/assets/img/enterprise/solution/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-enterprise_new-territory {
      background-image: url(/assets/img/enterprise/new-territory/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-enterprise_new-territory {
      background-image: url(/assets/img/enterprise/new-territory/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-information {
      background-image: url(/assets/img/information/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-information {
      background-image: url(/assets/img/information/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit {
      background-image: url(/assets/img/recruit/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit {
      background-image: url(/assets/img/recruit/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit_about {
      background-image: url(/assets/img/recruit/about/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit_about {
      background-image: url(/assets/img/recruit/about/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit_faq {
      background-image: url(/assets/img/recruit/faq/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit_faq {
      background-image: url(/assets/img/recruit/faq/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit_welfare {
      background-image: url(/assets/img/recruit/welfare/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit_welfare {
      background-image: url(/assets/img/recruit/welfare/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit_requirements {
      background-image: url(/assets/img/recruit/requirements/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit_requirements {
      background-image: url(/assets/img/recruit/requirements/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-recruit_entry {
      background-image: url(/assets/img/recruit/entry/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-recruit_entry {
      background-image: url(/assets/img/recruit/entry/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment {
      background-image: url(/assets/img/socialenvironment/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment {
      background-image: url(/assets/img/socialenvironment/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment_commitment {
      background-image: url(/assets/img/socialenvironment/commitment/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment_commitment {
      background-image: url(/assets/img/socialenvironment/commitment/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment_environment {
      background-image: url(/assets/img/socialenvironment/environment/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment_environment {
      background-image: url(/assets/img/socialenvironment/environment/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment_philanthropy {
      background-image: url(/assets/img/socialenvironment/philanthropy/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment_philanthropy {
      background-image: url(/assets/img/socialenvironment/philanthropy/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment_iso {
      background-image: url(/assets/img/socialenvironment/iso/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment_iso {
      background-image: url(/assets/img/socialenvironment/iso/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-socialenvironment_sdgs {
      background-image: url(/assets/img/socialenvironment/sdgs/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-socialenvironment_sdgs {
      background-image: url(/assets/img/socialenvironment/sdgs/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-sustainability {
      background-image: url(/assets/img/sustainability/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-sustainability {
      background-image: url(/assets/img/sustainability/img_visual_sp.jpg); } }
  .page_header.-sustainability .page_header__title {
    letter-spacing: 0.05em; }
    .page_header.-sustainability .page_header__title span {
      display: block; }
      @media screen and (min-width: 769px) {
        .page_header.-sustainability .page_header__title span:first-child {
          font-size: 3.6rem; }
        .page_header.-sustainability .page_header__title span:last-child {
          font-size: 2.4rem; } }
      @media screen and (max-width: 768px) {
        .page_header.-sustainability .page_header__title span:first-child {
          font-size: 2.4rem; }
        .page_header.-sustainability .page_header__title span:last-child {
          font-size: 1.8rem; } }
    .page_header.-sustainability .page_header__title::before {
      background: none; }
  @media screen and (min-width: 769px) {
    .page_header.-sustainability.-environment {
      background-image: url(/assets/img/sustainability/environment/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-sustainability.-environment {
      background-image: url(/assets/img/sustainability/environment/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-sustainability.-social {
      background-image: url(/assets/img/sustainability/society-customers/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-sustainability.-social {
      background-image: url(/assets/img/sustainability/society-customers/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-sustainability.-governance {
      background-image: url(/assets/img/sustainability/governance-corporate/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-sustainability.-governance {
      background-image: url(/assets/img/sustainability/governance-corporate/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-notfound {
      background-image: url(/assets/img/404/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-notfound {
      background-image: url(/assets/img/404/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-personal {
      background-image: url(/assets/img/personal/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-personal {
      background-image: url(/assets/img/personal/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-policy {
      background-image: url(/assets/img/policy/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-policy {
      background-image: url(/assets/img/policy/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-sitemap {
      background-image: url(/assets/img/sitemap/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-sitemap {
      background-image: url(/assets/img/sitemap/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-search {
      background-image: url(/assets/img/search/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-search {
      background-image: url(/assets/img/search/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-inquiry {
      background-image: url(/assets/img/inquiry/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-inquiry {
      background-image: url(/assets/img/inquiry/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-inquiry_account {
      background-image: url(/assets/img/inquiry/account/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-inquiry_account {
      background-image: url(/assets/img/inquiry/account/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-inquiry_ir {
      background-image: url(/assets/img/inquiry/ir/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-inquiry_ir {
      background-image: url(/assets/img/inquiry/ir/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-inquiry_recruit {
      background-image: url(/assets/img/inquiry/recruit/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-inquiry_recruit {
      background-image: url(/assets/img/inquiry/recruit/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-inquiry_other {
      background-image: url(/assets/img/inquiry/other/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-inquiry_other {
      background-image: url(/assets/img/inquiry/other/img_visual_sp.jpg); } }
  @media screen and (min-width: 769px) {
    .page_header.-overseas {
      background-image: url(/assets/img/overseas/img_visual.jpg); } }
  @media screen and (max-width: 768px) {
    .page_header.-overseas {
      background-image: url(/assets/img/overseas/img_visual_sp.jpg); } }
  .page_header.-lg {
    position: relative;
    padding-top: 150px;
    padding-bottom: 80px;
    background: none; }
    @media screen and (max-width: 768px) {
      .page_header.-lg {
        padding-top: 60px;
        padding-bottom: 35px; } }
    .page_header.-lg::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%; }
    .page_header.-lg .page_header__inner {
      flex-direction: column;
      height: auto; }
      @media screen and (max-width: 768px) {
        .page_header.-lg .page_header__inner {
          height: auto; } }
  .page_header__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 980px;
    height: 400px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 768px) {
      .page_header__inner {
        width: auto;
        height: 200px; } }
  .page_header__body {
    position: relative;
    text-align: center;
    padding-bottom: 20px;
    padding-left: 10px; }
    body.en .page_header__body {
      width: 100%; }
    @media screen and (max-width: 768px) {
      .page_header__body {
        padding-bottom: 20px; }
        body.en .page_header__body {
          width: auto; } }
    .page_header__body.test {
      padding-bottom: 25px;
      padding-top: 0; }
      @media screen and (max-width: 768px) {
        .page_header__body.test {
          padding-bottom: 17px;
          padding-top: 0; } }
  .page_header__title {
    margin-bottom: 10px;
    font-size: 3.2rem;
    letter-spacing: .2em;
    color: #000; }
    body.en .page_header__title {
      letter-spacing: .03em; }
    @media screen and (max-width: 768px) {
      .page_header__title {
        font-size: 2.4rem;
        margin-bottom: 5px;
        letter-spacing: .1em; } }
    .page_header__title::before {
      content: '';
      display: block;
      width: 60px;
      height: 2px;
      background-color: #fe6400;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); }
      @media screen and (max-width: 768px) {
        .page_header__title::before {
          width: 44px; } }
    .page_header__title-test {
      margin-bottom: 10px;
      font-size: 3.2rem;
      letter-spacing: .3em;
      color: #000; }
      @media screen and (max-width: 768px) {
        .page_header__title-test {
          font-size: 2.4rem;
          margin-bottom: 5px;
          letter-spacing: .1em; } }
      .page_header__title-test::after {
        content: '';
        display: block;
        width: 60px;
        height: 2px;
        background-color: #fe6400;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%); }
        @media screen and (max-width: 768px) {
          .page_header__title-test::after {
            width: 44px; } }
      @media screen and (max-width: 768px) {
        .page_header__title-test.-sp_white {
          color: #fff; } }
      @media screen and (min-width: 769px) {
        .page_header__title-test.-pc_white {
          color: #fff; } }
    @media screen and (max-width: 768px) {
      .page_header__title.-sp_white {
        color: #fff; } }
    @media screen and (min-width: 769px) {
      .page_header__title.-pc_white {
        color: #fff; } }
  .page_header__footer {
    margin-top: 80px; }
    body.en .page_header__footer {
      width: 100%; }
    @media screen and (max-width: 768px) {
      .page_header__footer {
        margin-top: 40px; }
        body.en .page_header__footer {
          width: auto; } }
  .page_header__lead {
    font-size: 3.2rem;
    text-align: center;
    font-weight: bold;
    color: #fff;
    line-height: 1.4; }
    @media screen and (max-width: 768px) {
      .page_header__lead {
        font-size: 2rem; } }
  .page_header__desc {
    margin-top: 20px;
    line-height: 2;
    font-size: 1.8rem;
    color: #fff;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .page_header__desc {
        margin-top: 10px;
        font-size: 1.4rem;
        text-align: left;
        line-height: 1.8; } }
  .page_header__box {
    width: 1060px;
    margin: 100px auto 0;
    background-color: #fff;
    padding: 80px 100px;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .page_header__box {
        width: auto;
        margin: 35px auto 0;
        padding: 40px 50px; }
        body.en .page_header__box {
          padding: 40px 30px; } }
    .page_header__box.-md {
      max-width: 865px; }
      @media screen and (max-width: 768px) {
        .page_header__box.-md {
          padding: 30px 20px; } }
    .page_header__box.-transparent {
      background: rgba(255, 255, 255, 0.9); }
    .page_header__box__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 50px; }
      @media screen and (max-width: 768px) {
        .page_header__box__header {
          flex-direction: column;
          margin-bottom: 30px; } }
    .page_header__box__copy {
      background: #fe6400;
      color: #fff;
      font-size: 2.4rem;
      font-weight: bold;
      width: 400px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center; }
      body.en .page_header__box__copy {
        font-size: 2.2rem;
        line-height: 1.4; }
      @media screen and (max-width: 768px) {
        .page_header__box__copy {
          font-size: 1.8rem;
          width: 100%; }
          body.en .page_header__box__copy {
            font-size: 1.8rem;
            line-height: 1.3; } }
    .page_header__box__cross {
      color: #fe6400;
      font-size: 3rem; }
      @media screen and (max-width: 768px) {
        .page_header__box__cross {
          font-size: 2rem; } }
    .page_header__box__lead {
      font-size: 3.2rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.5; }
      body.en .page_header__box__lead {
        font-size: 2.8rem; }
      @media screen and (max-width: 768px) {
        .page_header__box__lead {
          font-size: 2rem; }
          body.en .page_header__box__lead {
            font-size: 1.8rem; } }
      .page_header__box__lead span {
        color: #fe6400; }

.page_body {
  padding-top: 60px;
  padding-bottom: 180px;
  background-color: #fff; }
  @media screen and (max-width: 768px) {
    .page_body {
      padding-top: 30px;
      padding-bottom: 80px; } }
  .page_body__inner {
    margin-right: auto;
    margin-left: auto;
    width: 1060px; }
    @media screen and (max-width: 768px) {
      .page_body__inner {
        width: auto;
        padding-right: 15px;
        padding-left: 15px; } }
    .page_body__inner.-md {
      width: 865px; }
      @media screen and (max-width: 768px) {
        .page_body__inner.-md {
          width: auto; } }
    .page_body__inner.-second {
      margin-top: 80px; }
  .page_body .sec:first-child,
  .page_body .list_link:first-child {
    margin-top: 0; }
  .page_body .sec:last-child {
    margin-bottom: 0; }

.page_heading {
  text-align: center;
  font-weight: bold;
  font-size: 3.2rem; }
  @media screen and (max-width: 768px) {
    .page_heading {
      font-size: 2.0rem;
      text-align: left; } }

* + .page_desc {
  margin-top: 30px; }

.page_desc {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.4; }
  @media screen and (max-width: 768px) {
    .page_desc {
      font-size: 1.4rem;
      line-height: 1.8; } }

.page_nav {
  margin-top: 40px;
  margin-bottom: 80px; }
  @media screen and (max-width: 768px) {
    .page_nav {
      margin-top: 20px;
      margin-bottom: 40px; } }

.page_link .row .col_4 {
  padding-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .page_link .row .col_4 {
      flex: 50% 0 0;
      max-width: 50%;
      padding-right: 5px;
      padding-left: 5px;
      padding-bottom: 0; } }

.page_link__item {
  position: relative; }
  @media screen and (max-width: 768px) {
    .page_link__item {
      max-width: 510px;
      margin: 0 auto 20px; } }
  .page_link__item a {
    display: block;
    transition: opacity 1s ease; }
    .page_link__item a:hover {
      opacity: .8; }
      .page_link__item a:hover .page_link__thumb img {
        transform: scale(1.1); }

.page_link__thumb {
  overflow: hidden; }
  .page_link__thumb img {
    max-width: 100%;
    height: auto;
    transition: transform 1s ease; }

.page_link__body {
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(255, 255, 255, 0.9);
  transform: translate(-50%, -50%);
  min-height: 28px;
  min-width: 215px;
  padding-top: 10px;
  padding-bottom: 10px; }
  @media screen and (max-width: 768px) {
    .page_link__body {
      min-height: 21px;
      min-width: 120px;
      padding-top: 8px;
      padding-bottom: 8px; } }
  @media screen and (max-width: 768px) {
    .page_link__body.-lg {
      min-width: 120px;
      padding-top: 8px;
      padding-bottom: 8px; } }

.page_link__label {
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.4; }
  body.en .page_link__label.-about {
    line-height: 1.1; }
  @media screen and (max-width: 768px) {
    .page_link__label {
      font-size: 1.4rem; }
      body.en .page_link__label {
        font-size: 1.3rem; } }

.page_link__ruby {
  font-size: 1.3rem;
  color: #333;
  text-align: center; }
  body.en .page_link__ruby {
    line-height: 1.2;
    margin-top: 6px; }
  @media screen and (max-width: 768px) {
    .page_link__ruby {
      font-size: 1rem;
      line-height: 1.2;
      margin-top: 2px; }
      body.en .page_link__ruby {
        text-align: left; } }

.page_link__btn {
  position: absolute;
  right: 0;
  bottom: -23px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 165px;
  height: 46px;
  background: #fe6400;
  color: #fff;
  font-size: 1.2rem;
  box-sizing: border-box; }
  @media screen and (max-width: 768px) {
    .page_link__btn {
      padding-left: 10px;
      width: 100px;
      height: 32px;
      right: 0;
      bottom: -16px;
      font-size: 1rem; }
      .page_link__btn::after {
        right: 10px;
        font-size: 1.3rem; } }
  .page_link__btn::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem; }

.phone__disabled:hover {
  cursor: default; }

.table_basic {
  width: 100%;
  border-bottom: 1px #e2e2e2 solid;
  box-sizing: border-box;
  border-collapse: separate; }
  @media screen and (max-width: 768px) {
    .table_basic {
      border-bottom: none; } }
  @media screen and (max-width: 768px) {
    .table_basic tr.-sp_child {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.2rem;
      border-bottom: 1px #e2e2e2 solid; } }
  .table_basic td,
  .table_basic th {
    position: relative;
    padding: 15px;
    font-size: 1.4rem;
    color: #333;
    font-weight: normal;
    line-height: 1.6; }
  .table_basic thead th {
    position: relative;
    background-color: #e2e2e2;
    border-right: 1px #fff solid;
    border-bottom: 1px #fff solid; }
    @media screen and (max-width: 768px) {
      .table_basic thead th {
        padding: 10px; } }
    .table_basic thead th:first-child {
      width: 180px; }
      @media screen and (max-width: 768px) {
        .table_basic thead th:first-child {
          width: 164px; } }
  .table_basic tbody th,
  .table_basic tbody td {
    padding: 15px;
    border-top: 1px #e2e2e2 solid;
    vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .table_basic tbody th,
      .table_basic tbody td {
        padding: 10px; } }
  .table_basic tbody th {
    text-align: left;
    background-color: #efefef; }
    @media screen and (max-width: 768px) {
      .table_basic tbody th {
        font-size: 1.2rem; } }
  .table_basic tbody th {
    width: 150px;
    padding: 15px;
    box-sizing: border-box;
    background: #efefef;
    border-top: 1px #e2e2e2 solid;
    vertical-align: top;
    text-align: left;
    font-weight: normal; }
    @media screen and (max-width: 768px) {
      .table_basic tbody th {
        width: 100%;
        display: block;
        padding: 10px 15px;
        border-top: none; } }
    .table_basic tbody th.-child {
      width: 180px;
      background-color: #f6f6f6; }
      @media screen and (max-width: 768px) {
        .table_basic tbody th.-child {
          width: 40%; } }
      @media screen and (max-width: 768px) {
        .table_basic tbody th.-child + td {
          display: flex;
          width: 60%;
          height: 100%;
          padding: 10px; } }
  .table_basic tbody td {
    text-align: right;
    border-right: 1px #e2e2e2 solid;
    font-size: 1.4rem;
    box-sizing: border-box; }
    @media screen and (max-width: 768px) {
      .table_basic tbody td {
        font-size: 1.2rem; } }
    .table_basic tbody td:last-child {
      border-right: none; }
    @media screen and (max-width: 768px) {
      .table_basic tbody td {
        display: block;
        border-top: none;
        padding: 10px 0; } }
    .table_basic tbody td.-left {
      text-align: left; }
  @media screen and (max-width: 768px) {
    .table_basic__wrapper {
      overflow-x: auto;
      padding-bottom: 10px; } }
  @media screen and (min-width: 769px) {
    .table_basic.-th_25 th:first-child {
      width: 25%; } }
  @media screen and (min-width: 769px) {
    .table_basic.-th_33 th {
      width: 33.333%; } }
  @media screen and (max-width: 768px) {
    .table_basic.-nowrap {
      border-top: 1px #e2e2e2 solid; } }
  @media screen and (max-width: 768px) {
    .table_basic.-nowrap td,
    .table_basic.-nowrap th {
      width: auto;
      padding: 10px;
      border-bottom: 1px #e2e2e2 solid;
      white-space: nowrap; } }
  @media screen and (max-width: 768px) {
    .table_basic.-nowrap tbody td, .table_basic.-nowrap tbody th {
      display: table-cell; } }
  @media screen and (max-width: 768px) {
    .table_basic.-wrap {
      border-top: 1px #e2e2e2 solid; } }
  @media screen and (max-width: 768px) {
    .table_basic.-wrap td,
    .table_basic.-wrap th {
      width: auto;
      padding: 10px;
      border-bottom: 1px #e2e2e2 solid; } }
  @media screen and (max-width: 768px) {
    .table_basic.-wrap th {
      white-space: nowrap; } }
  @media screen and (max-width: 768px) {
    .table_basic.-wrap tbody td, .table_basic.-wrap tbody th {
      display: table-cell; } }

.data_basic dt,
.data_basic dd {
  line-height: 1.8; }

.data_basic dd {
  margin-left: 2em; }
  @media screen and (max-width: 768px) {
    .data_basic dd {
      margin-left: 1em; } }

.pagination, .pagination--sm {
  display: flex;
  justify-content: center;
  padding-top: 80px; }
  @media screen and (max-width: 768px) {
    .pagination, .pagination--sm {
      padding-top: 40px; } }
  .pagination--sm {
    border-top: none;
    padding-top: 60px;
    padding-bottom: 30px; }
    @media screen and (max-width: 768px) {
      .pagination--sm {
        padding-top: 30px;
        padding-bottom: 20px; } }
  .pagination__item a, .pagination__item--current a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #333;
    text-decoration: none;
    transition: color .3s ease, background-color .3s ease; }
    @media screen and (max-width: 768px) {
      .pagination__item a, .pagination__item--current a {
        width: 36px;
        height: 36px; } }
    .pagination__item a:hover, .pagination__item--current a:hover {
      color: #fff; }
  .pagination__item--current a {
    color: #fff; }
  .pagination__prev a, .pagination__prev_last a, .pagination__next a, .pagination__next_last a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    text-decoration: none;
    color: #333;
    transition: color .3s ease, background-color .3s ease; }
    .pagination__prev a:hover, .pagination__prev_last a:hover, .pagination__next a:hover, .pagination__next_last a:hover {
      color: #fff; }
    @media screen and (max-width: 768px) {
      .pagination__prev a, .pagination__prev_last a, .pagination__next a, .pagination__next_last a {
        width: 36px;
        height: 36px; } }
  .pagination__prev {
    transform: rotate(180deg);
    margin-right: 10px;
    font-size: 1rem; }
  .pagination__prev_last {
    border-right: none;
    font-size: 1.2rem; }
  .pagination__next {
    margin-left: 10px;
    font-size: 1rem; }
  .pagination__next_last {
    border-left: none;
    font-size: 1.2rem; }

.permalink.-orange {
  color: #fe6400; }

.permalink.-lg::after {
  font-size: 1.8rem; }

.permalink.-pdf::after {
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  color: #e00;
  margin-left: 4px;
  position: relative;
  top: 0; }

.permalink.-blank::after {
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  color: #333;
  margin-left: 4px;
  font-size: 1rem;
  position: relative;
  top: 0; }

.btn a,
.btn button {
  appearance: none;
  position: relative;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 220px;
  height: 62px;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.3rem;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: color .2s ease, background-color .2s ease, border-color .2s ease; }
  body.en.personal .btn.-orange a {
    max-width: 250px; }
  @media screen and (max-width: 768px) {
    .btn a,
    .btn button {
      max-width: 240px; } }

.btn.-border a,
.btn.-border button {
  background-color: #fff;
  color: #fe6400;
  border: 2px solid #fe6400;
  transition: opacity 0.4s ease; }
  .btn.-border a:hover,
  .btn.-border button:hover {
    opacity: 0.8;
    text-decoration: none; }
  .btn.-border a.current:hover,
  .btn.-border button.current:hover {
    opacity: 1; }

.btn.-orange a,
.btn.-orange button {
  background-color: #fe6400;
  color: #fff;
  transition: opacity 0.4s ease; }
  .btn.-orange a:hover,
  .btn.-orange button:hover {
    opacity: 0.8;
    text-decoration: none; }
  .btn.-orange a.current:hover,
  .btn.-orange button.current:hover {
    opacity: 1; }

.btn.-arrow a::after,
.btn.-arrow button::after {
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-size: 1.8rem;
  margin-left: 10px; }

.btn.-blank-r a,
.btn.-blank-r button {
  justify-content: flex-start;
  padding: 0 30px; }
  body.en .btn.-blank-r a,
  body.en .btn.-blank-r button {
    padding: 0 20px; }
  @media screen and (max-width: 768px) {
    .btn.-blank-r a,
    .btn.-blank-r button {
      padding: 0 20px; } }
  .btn.-blank-r a::after,
  .btn.-blank-r button::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem; }
    body.en .btn.-blank-r a::after,
    body.en .btn.-blank-r button::after {
      right: 15px; }
    @media screen and (max-width: 768px) {
      .btn.-blank-r a::after,
      .btn.-blank-r button::after {
        right: 15px; } }

.btn.-arrow-r a,
.btn.-arrow-r button {
  justify-content: flex-start;
  padding: 0 30px; }
  body.en .btn.-arrow-r a,
  body.en .btn.-arrow-r button {
    padding: 0 20px; }
  @media screen and (max-width: 768px) {
    .btn.-arrow-r a,
    .btn.-arrow-r button {
      padding: 0 20px; } }
  .btn.-arrow-r a::after,
  .btn.-arrow-r button::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem; }
    body.en .btn.-arrow-r a::after,
    body.en .btn.-arrow-r button::after {
      right: 15px; }
    @media screen and (max-width: 768px) {
      .btn.-arrow-r a::after,
      .btn.-arrow-r button::after {
        right: 15px; } }

.btn.-arrow-c a,
.btn.-arrow-c button {
  justify-content: center;
  padding: 0 30px; }
  @media screen and (max-width: 768px) {
    .btn.-arrow-c a,
    .btn.-arrow-c button {
      padding: 0 20px; } }
  .btn.-arrow-c a::after,
  .btn.-arrow-c button::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem; }
    @media screen and (max-width: 768px) {
      .btn.-arrow-c a::after,
      .btn.-arrow-c button::after {
        right: 15px; } }

.btn.-right a,
.btn.-right button {
  margin-right: 0; }

.btn.-left a,
.btn.-left button {
  margin-left: 0; }

@media screen and (max-width: 768px) {
  .btn.-sp-right a,
  .btn.-sp-right button {
    margin-right: 0; } }

.btn.-full a,
.btn.-full button {
  max-width: none; }

.btn.-wide-md a,
.btn.-wide-md button {
  max-width: 310px; }

.btn.-md a,
.btn.-md button {
  height: 55px; }

.btn.-lg a,
.btn.-lg button {
  height: 100px;
  font-size: 2.2rem; }
  @media screen and (max-width: 768px) {
    .btn.-lg a,
    .btn.-lg button {
      height: 75px;
      font-size: 1.6rem; } }

@media screen and (max-width: 768px) {
  .btn.-sp_p a,
  .btn.-sp_p button {
    padding: 0 15px; } }

.btn.-fz16 a,
.btn.-fz16 button {
  font-size: 1.6rem; }
  @media screen and (max-width: 768px) {
    .btn.-fz16 a,
    .btn.-fz16 button {
      font-size: 1.2rem; } }

.btn.-page_nav a,
.btn.-page_nav button {
  position: relative;
  color: #000;
  border: 1px #000 solid;
  font-size: 1.4rem;
  height: 50px;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .btn.-page_nav a,
    .btn.-page_nav button {
      text-align: center;
      font-size: 1.2rem;
      padding-right: 15px; }
      body.en .btn.-page_nav a,
      .btn.-page_nav button {
        font-size: 1rem; } }
  .btn.-page_nav a:hover,
  .btn.-page_nav button:hover {
    color: #fe6400;
    border-color: #fe6400; }
  .btn.-page_nav a::after,
  .btn.-page_nav button::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%); }
    @media screen and (max-width: 768px) {
      body.en .btn.-page_nav a::after,
      .btn.-page_nav button::after {
        right: 6px; } }

.btn.-page_nav.-lg a,
.btn.-page_nav.-lg button {
  font-size: 1.6rem; }
  @media screen and (max-width: 768px) {
    .btn.-page_nav.-lg a,
    .btn.-page_nav.-lg button {
      font-size: 1.3rem;
      height: 40px; } }

.btn.-page_nav.-orange a,
.btn.-page_nav.-orange button {
  border: none;
  color: #fff; }

.btn.-page_nav.-blue a,
.btn.-page_nav.-blue button {
  border: none;
  color: #fff;
  background-color: #3eaecc; }

.btn.-page_nav.-yellow a,
.btn.-page_nav.-yellow button {
  border: none;
  color: #fff;
  background-color: #ecb700; }

.btn.-page_nav.-green a,
.btn.-page_nav.-green button {
  border: none;
  color: #fff;
  background-color: #4dbc67; }

.btn.-page_nav.-purple a,
.btn.-page_nav.-purple button {
  border: none;
  color: #fff;
  background-color: #7989cf; }

.btn.-page_tab a,
.btn.-page_tab button {
  height: 52px;
  max-width: none;
  font-size: 1.4rem;
  color: #000;
  border: 1px #000 solid;
  transition: color .2s ease, border-color .2s ease; }
  @media screen and (max-width: 768px) {
    body.en .btn.-page_tab a,
    .btn.-page_tab button {
      font-size: 1rem;
      text-align: center; } }
  .btn.-page_tab a::after,
  .btn.-page_tab button::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    opacity: 0;
    border-top: 8px #fff solid;
    border-right: 5px transparent solid;
    border-bottom: 8px transparent solid;
    border-left: 5px transparent solid;
    transition: opacity .2s ease, border-top-color .2s ease; }

.btn.-page_tab.-current a,
.btn.-page_tab.-current button, .btn.-page_tab:hover a,
.btn.-page_tab:hover button {
  color: #fe6400;
  border-color: #fe6400; }
  .btn.-page_tab.-current a::after,
  .btn.-page_tab.-current button::after, .btn.-page_tab:hover a::after,
  .btn.-page_tab:hover button::after {
    opacity: 1;
    border-top-color: #fe6400; }

.text + .btn {
  margin-top: 20px; }

.sec {
  margin-top: 60px;
  margin-bottom: 60px;
  counter-reset: section; }
  @media screen and (max-width: 768px) {
    .sec {
      margin-top: 30px;
      margin-bottom: 30px; } }
  .sec.-md {
    margin-top: 30px;
    margin-bottom: 30px; }
  .sec.-lg {
    margin-top: 80px;
    margin-bottom: 80px; }
    @media screen and (max-width: 768px) {
      .sec.-lg {
        margin-top: 40px;
        margin-bottom: 40px; } }
  .sec.-xlg {
    margin-top: 120px;
    margin-bottom: 120px; }
    @media screen and (max-width: 768px) {
      .sec.-xlg {
        margin-top: 50px;
        margin-bottom: 50px; } }
  .sec.-gray {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f5f5f5; }
    @media screen and (max-width: 768px) {
      .sec.-gray {
        padding-top: 35px;
        padding-bottom: 35px; } }
  .page_header__box .sec:first-child {
    margin-top: 0; }
  .page_header__box .sec:last-child {
    margin-bottom: 0; }

.sec_inner {
  margin-right: auto;
  margin-left: auto;
  width: 1060px; }
  @media screen and (max-width: 768px) {
    .sec_inner {
      width: auto;
      margin-right: 15px;
      margin-left: 15px; } }
  .sec_inner.-md {
    width: 865px; }
    @media screen and (max-width: 768px) {
      .sec_inner.-md {
        width: auto; } }
  .sec_inner.-hang {
    padding-left: 30px; }
    @media screen and (max-width: 768px) {
      .sec_inner.-hang {
        padding-left: 10px; } }
  @media screen and (max-width: 768px) {
    .sec_inner.-sp_full {
      margin-right: 0;
      margin-left: 0; } }

.sec_footer {
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .sec_footer {
      margin-top: 20px; } }

.title_primary {
  text-align: left;
  font-size: 2.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  border-left: 5px solid #fe6400;
  padding: 5px;
  line-height: 1.45;
  padding-left: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  -webkit-align-content: center;
  align-content: center;
  -webkit-flex-direction: row;
  flex-direction: row; }
  .title_primary:after {
    -webkit-flex: 1 0 40%;
    flex: 1 0 40%;
    content: "";
    display: block;
    height: 3px;
    background-image: linear-gradient(-60deg, transparent 10%, #fe6400 10%, #fe6400 30%, transparent 30%, transparent 75%, #fe6400 75%, #fe6400);
    background-size: 3px 3px;
    margin-left: 30px; }
  @media screen and (max-width: 768px) {
    .title_primary {
      font-size: 2.2rem;
      margin-bottom: 20px;
      padding-left: 15px; } }

.title_secondly {
  font-size: 2.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  padding: 6px 0 6px 0;
  border-left: 5px #fe6400 solid;
  background: url(/assets/img/common/ico_dots_title.png) 0 50% repeat-x; }
  @media screen and (max-width: 768px) {
    .title_secondly {
      font-size: 1.7rem;
      margin-bottom: 25px;
      padding: 0;
      border-width: 5px;
      background: none; } }
  .title_secondly__inner {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    background: #fff;
    line-height: 1.6; }
    body.en .title_secondly__notes {
      margin-right: 30px; }
    @media screen and (max-width: 768px) {
      .title_secondly__inner {
        padding-left: 10px;
        padding-right: 10px;
        background: none; } }
  .title_secondly__notes {
    display: inline-block;
    position: relative;
    left: -10px;
    top: -6px;
    padding-right: 10px;
    font-size: 1.2rem;
    background: #fff; }
    @media screen and (max-width: 768px) {
      .title_secondly__notes {
        top: 0;
        padding-left: 10px; }
        body.en .title_secondly__notes {
          left: auto;
          top: -6px; } }

.title_thirdly {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  padding: 4px 0 12px 12px;
  border-left: 4px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid; }
  @media screen and (max-width: 768px) {
    .title_thirdly {
      font-size: 1.8rem;
      margin-bottom: 20px;
      padding: 4px 0 8px 12px;
      border-left-width: 3px; } }
  .title_thirdly span {
    font-size: 1.4rem; }
  .title_thirdly.-accordion {
    position: relative;
    padding-left: 30px;
    transition: border-color .3s ease;
    font-weight: normal; }
    @media screen and (max-width: 768px) {
      .title_thirdly.-accordion {
        font-size: 1.4rem; } }
    .title_thirdly.-accordion::before, .title_thirdly.-accordion::after {
      content: '';
      position: absolute;
      left: 15px;
      top: 8px;
      display: block;
      width: 2px;
      height: 10px;
      background: #e2e2e2;
      transition: transform .3s ease, background-color .3s ease; }
    .title_thirdly.-accordion::after {
      transform: rotate(90deg); }
    .title_thirdly.-accordion.-active {
      border-color: #fe6400; }
      .title_thirdly.-accordion.-active::before, .title_thirdly.-accordion.-active::after {
        transform: rotate(450deg);
        background-color: #fe6400; }

.title_fourthly {
  font-size: 2.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.4; }
  .title_fourthly.-lg {
    font-size: 3.2rem; }
    @media screen and (max-width: 768px) {
      .title_fourthly.-lg {
        font-size: 2.1rem; } }
  @media screen and (max-width: 768px) {
    .title_fourthly.-sp_l {
      text-align: left; } }
  @media screen and (max-width: 768px) {
    .title_fourthly {
      font-size: 1.8rem;
      margin-bottom: 20px; } }
  .title_fourthly__ruby {
    color: #fe6400;
    font-size: 1.8rem; }
    @media screen and (max-width: 768px) {
      .title_fourthly__ruby {
        font-size: 1.4rem; } }

.title_fifthly {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    .title_fifthly {
      font-size: 1.4rem; } }

.title_sixthly {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  padding: 6px 0 6px 14px;
  border-left: 3px #fe6400 solid; }
  @media screen and (max-width: 768px) {
    .title_sixthly {
      font-size: 1.5rem;
      margin-bottom: 25px;
      padding-top: 3px;
      padding-bottom: 3px; } }
  .title_sixthly.-md {
    margin-bottom: 20px; }

.title_sevenly {
  font-size: 2.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6; }
  @media screen and (max-width: 768px) {
    .title_sevenly {
      font-size: 1.8rem;
      margin-bottom: 10px; } }

.title_eighthly {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
  background-color: #eeeeee;
  font-weight: normal;
  padding: 10px 20px; }
  @media screen and (max-width: 768px) {
    .title_eighthly {
      font-size: 1.4rem; } }

.title_number {
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 25px;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .title_number {
      font-size: 1.4rem;
      margin-top: 10px;
      margin-bottom: 5px; } }
  .title_number::before {
    position: absolute;
    left: 0;
    top: 0;
    counter-increment: section;
    content: counter(section) ". ";
    font-weight: normal; }

.title_box {
  margin-bottom: 10px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #333;
  line-height: 1.4;
  padding-left: 50px;
  background: url(/assets/img/common/img_content_icon_02.png) 0 0 no-repeat;
  background-size: 37px auto; }
  @media screen and (max-width: 768px) {
    .title_box {
      font-size: 2rem;
      padding-left: 35px;
      background-size: 25px auto; } }

.text {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #333; }
  @media screen and (max-width: 768px) {
    .text {
      font-size: 1.4rem;
      line-height: 1.8; } }
  @media screen and (min-width: 769px) {
    .text + .text {
      margin-top: 1em; } }
  .text.-lg {
    font-size: 2rem;
    font-weight: bold; }
    .text.-lg + .text {
      margin-top: 1.5em; }
  .text.-md {
    font-size: 1.4rem; }
  .text.-sm {
    font-size: 1.2rem; }
  .text.-c {
    text-align: center; }
  .text.-r {
    text-align: right; }
  @media screen and (max-width: 768px) {
    .text.-sp_l {
      text-align: left; } }
  @media screen and (max-width: 768px) {
    .text.-sp_t {
      margin-top: 1em; } }
  .text.-hang {
    text-indent: -1em;
    padding-left: 1em; }
  .text.-summary {
    margin-bottom: 60px; }
    @media screen and (max-width: 768px) {
      .text.-summary {
        margin-bottom: 20px; } }
  .text.-lead {
    margin-bottom: 2em;
    margin-top: 2em; }
    .col > .text.-lead:first-child, .col_13 > .text.-lead:first-child, .col_12 > .text.-lead:first-child, .col_11 > .text.-lead:first-child, .col_10 > .text.-lead:first-child, .col_9 > .text.-lead:first-child, .col_8 > .text.-lead:first-child, .col_7 > .text.-lead:first-child, .col_6 > .text.-lead:first-child, .col_5 > .text.-lead:first-child, .col_4 > .text.-lead:first-child, .col_3 > .text.-lead:first-child, .col_2_5 > .text.-lead:first-child, .col_2 > .text.-lead:first-child, .col_1 > .text.-lead:first-child, .col_unit > .text.-lead:first-child {
      margin-top: 0; }
  .text.-desc {
    margin-bottom: 20px; }
  .text.-number {
    margin-left: 20px; }

.thumb {
  text-align: center; }
  .thumb img {
    max-width: 100%;
    height: auto; }
  .thumb figcaption {
    margin-top: 10px;
    text-align: left; }
    .thumb figcaption.-sm {
      margin-top: 5px;
      font-size: 1.2rem; }

.index_nav__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1060px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px; }
  @media screen and (max-width: 768px) {
    .index_nav__inner {
      width: auto;
      margin-top: -20px; } }

.index_nav__content {
  margin-top: 50px;
  width: 510px; }
  @media screen and (max-width: 768px) {
    .index_nav__content {
      width: auto;
      max-width: 510px;
      margin: 20px auto 0; } }
  .index_nav__content.-lg {
    width: auto;
    margin-top: 0; }
    @media screen and (max-width: 768px) {
      .index_nav__content.-lg {
        width: calc(100% + 30px);
        max-width: none;
        margin-top: 20px;
        margin: 20px -15px 0; } }
    .index_nav__content.-lg .index_nav__thumb img {
      max-width: 100%; }
  .index_nav__content.-md {
    width: 330px; }
    @media screen and (max-width: 768px) {
      .index_nav__content.-md {
        width: auto;
        max-width: 510px;
        margin: 20px auto 0; } }
    .index_nav__content.-md .index_nav__thumb img {
      max-width: 100%;
      width: 100%; }
    .index_nav__content.-md .index_nav__btn {
      width: 180px;
      height: 50px; }
  .index_nav__content__header {
    position: relative; }
    .index_nav__content__header a {
      display: block;
      text-decoration: none;
      transition: opacity 1s ease; }
      .index_nav__content__header a:hover {
        opacity: .8; }
        .index_nav__content__header a:hover .index_nav__thumb img {
          transform: scale(1.1); }
  .index_nav__content__body {
    margin-top: 35px; }

.index_nav__thumb {
  overflow: hidden; }
  .index_nav__thumb img {
    transition: transform 1s ease; }

.index_nav__title {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 195px;
  height: 56px;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  background: #fff;
  color: #333;
  white-space: nowrap; }
  body.en .index_nav__title {
    min-width: 250px;
    height: 60px;
    white-space: nowrap; }
  @media screen and (max-width: 768px) {
    .index_nav__title {
      min-width: 185px;
      font-size: 1.8rem;
      height: 48px; }
      body.en .index_nav__title {
        height: 58px; } }
  .index_nav__title.-wide {
    width: 300px; }
    body.en .index_nav__title.-wide {
      width: 330px; }
    @media screen and (max-width: 768px) {
      .index_nav__title.-wide {
        width: auto; }
        body.en .index_nav__title.-wide {
          width: 240px; }
        body.en.enterprise .index_nav__content:first-child .index_nav__title.-wide {
          height: 63px; } }
  .index_nav__title.-lg {
    font-size: 3.2rem;
    padding: 0 30px;
    height: 72px; }
    @media screen and (max-width: 768px) {
      .index_nav__title.-lg {
        font-size: 1.6rem;
        height: 40px;
        padding: 0 20px;
        min-width: 0;
        width: auto; } }

.index_nav__titles {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
  padding: 25px 20px;
  box-sizing: border-box;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  white-space: nowrap; }
  @media screen and (max-width: 768px) {
    .index_nav__titles {
      min-width: 230px;
      padding: 15px 0; } }
  .index_nav__titles__main {
    font-size: 2.4rem;
    font-weight: bold;
    color: #333;
    letter-spacing: .1em; }
    body.en .index_nav__titles__main {
      letter-spacing: 0; }
    @media screen and (max-width: 768px) {
      .index_nav__titles__main {
        font-size: 1.8rem; }
        body.en .index_nav__titles__main {
          font-size: 1.6rem; } }
    .index_nav__titles__main.-icon_01 {
      padding-top: 48px;
      background: url(/assets/img/about/img_content_icon_01.png) 50% 0 no-repeat;
      background-size: 40px 41px; }
      @media screen and (max-width: 768px) {
        .index_nav__titles__main.-icon_01 {
          padding-top: 30px;
          background-size: 30px auto; } }
  .index_nav__titles__sub {
    margin-top: 5px;
    font-size: 1.4rem;
    color: #333; }
    @media screen and (max-width: 768px) {
      .index_nav__titles__sub {
        font-size: 1.2rem; } }

.index_nav__btn {
  position: absolute;
  right: 0;
  bottom: -20px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 220px;
  height: 64px;
  background: #fe6400;
  color: #fff;
  font-size: 1.3rem;
  box-sizing: border-box;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .index_nav__btn {
      width: 200px;
      height: 50px;
      right: 15px;
      font-size: 1.2rem; } }
  .index_nav__btn::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem; }
  .index_nav__btn.-threeD {
    box-shadow: 0px 8px 7px 3px rgba(0, 0, 0, 0.15); }
    .index_nav__btn.-threeD::before {
      border-bottom: 4px solid #ff3600;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%; }

.index_nav__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: -20px;
  width: 99px;
  height: 90px;
  background: url(/assets/img/about/img_content_icon_02.png) 0 0 no-repeat;
  background-size: 99px 90px;
  color: #fff;
  font-size: 1.2rem;
  box-sizing: border-box;
  padding-right: 20px;
  padding-top: 26px; }
  @media screen and (max-width: 768px) {
    .index_nav__box {
      font-size: 1rem;
      width: 75px;
      height: 68px;
      padding-top: 20px;
      padding-right: 15px;
      background-size: 100% auto; } }
  .index_nav__box::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    transform: translateY(-50%);
    font-size: 1.8rem;
    margin-top: 15px; }
    @media screen and (max-width: 768px) {
      .index_nav__box::after {
        margin-top: 10px;
        font-size: 1.4rem; } }

.index_nav__list {
  margin-top: -15px;
  display: flex;
  flex-wrap: wrap; }
  .index_nav__list__item {
    margin-right: 15px;
    margin-top: 15px; }
    .index_nav__list__item:last-child {
      margin-right: 0; }
    .index_nav__list__item a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #333;
      font-size: 1.6rem;
      transition: opacity 0.4s ease; }
      .index_nav__list__item a:hover {
        opacity: 0.8;
        text-decoration: none; }
      .index_nav__list__item a.current:hover {
        opacity: 1; }
      @media screen and (max-width: 768px) {
        .index_nav__list__item a {
          font-size: 1.3rem; } }
      .index_nav__list__item a::before {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-size: 1rem;
        color: #fe6400;
        margin-right: 10px; }

.accordion__title {
  cursor: pointer; }

.accordion__content {
  overflow: hidden;
  transition: height 1s ease; }
  .accordion__content::after {
    content: '';
    display: block;
    width: 100%;
    height: 30px; }

.list_link {
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .list_link {
      margin-top: 18px;
      margin-bottom: 18px; } }
  .list_link.-md {
    margin-top: 20px;
    margin-bottom: 20px; }
    .list_link.-md .list_link__item a {
      font-size: 1.4rem; }
  .list_link.-sm {
    margin-top: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .list_link.-sm .list_link__item {
        margin: 10px 0; } }
    .list_link.-sm .list_link__item a {
      font-size: 1.4rem; }
  .list_link__item {
    margin: 18px 0; }
    @media screen and (max-width: 768px) {
      .list_link__item {
        margin: 10px 0; } }
    @media screen and (max-width: 768px) {
      .list_link__item:first-child {
        margin-top: 0; } }
    .list_link__item a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      color: #333;
      padding-left: 20px;
      font-size: 1.6rem;
      line-height: 1.8;
      transition: opacity 0.4s ease; }
      .list_link__item a:hover {
        opacity: 0.7;
        text-decoration: none; }
      .list_link__item a.current:hover {
        opacity: 1; }
      @media screen and (max-width: 768px) {
        .list_link__item a {
          font-size: 1.4rem; } }
      .list_link__item a::before {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-size: 1rem;
        position: absolute;
        left: 0;
        top: 9px;
        color: #fe6400; }
        @media screen and (max-width: 768px) {
          .list_link__item a::before {
            top: 6px;
            font-size: 1.2rem; } }
  .list_link__pdf {
    font-size: 1.4rem;
    margin-left: 10px; }
    @media screen and (max-width: 768px) {
      .list_link__pdf {
        display: block;
        margin-left: 0; } }
    .list_link__pdf::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      font-size: 1.8rem;
      color: #f20e03;
      position: relative;
      top: 3px; }
      @media screen and (max-width: 768px) {
        .list_link__pdf::after {
          vertical-align: middle;
          top: 0; } }

.list_decimal {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #333;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px; }
  @media screen and (max-width: 768px) {
    .list_decimal {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 1.4rem; } }
  .list_decimal > li {
    list-style: decimal; }
  .list_decimal.-number {
    padding-left: 40px; }

.list_entry {
  margin-top: 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .list_entry {
      margin-top: 25px;
      margin-bottom: 25px; } }
  .list_entry__item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-top: 1px #999 dotted; }
    @media screen and (max-width: 768px) {
      .list_entry__item {
        flex-wrap: wrap; } }
    .list_entry__item:last-child {
      border-bottom: 1px #999 dotted; }
  @media screen and (max-width: 768px) {
    .list_entry__date {
      order: 2; } }
  .list_entry__date + .list_entry__title {
    margin-left: 100px; }
    @media screen and (max-width: 768px) {
      .list_entry__date + .list_entry__title {
        margin-left: 0; } }
  .list_entry__category {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    width: 120px;
    margin: 0 16px;
    border-radius: 3px;
    background: #000;
    color: #fff;
    font-size: 1rem; }
    @media screen and (max-width: 768px) {
      .list_entry__category {
        order: 1;
        width: auto;
        padding: 0 10px;
        margin-left: 0; } }
  @media screen and (max-width: 768px) {
    .list_entry__title {
      order: 3;
      width: 100%; } }
  .list_entry__title a {
    display: block;
    color: #333;
    text-decoration: none;
    transition: color .3s ease; }
    .list_entry__title a:hover {
      color: #fe6400; }

.list_dots > li {
  line-height: 1.45;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
  -webkit-flex-direction: row;
  flex-direction: row; }
  .list_dots > li:before {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    content: "";
    background-color: #333;
    width: .6em;
    height: .6em;
    position: relative;
    top: 0.5em;
    margin-right: 3px; }

.list_dots > * + * {
  margin-top: 3px; }

.list_disc {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #333;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .list_disc {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: 1.4rem; } }
  .list_disc > li {
    list-style: disc; }

.table_link {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    .table_link {
      margin-top: 18px;
      margin-bottom: 18px; } }
  .table_link td {
    line-height: 1.8; }
    @media screen and (max-width: 768px) {
      .table_link td {
        display: block; } }
  .table_link a {
    text-decoration: none;
    color: #333;
    font-size: 1.4rem; }
  .table_link__link {
    position: relative;
    padding-right: 20px; }
    .table_link__link a {
      padding-left: 15px;
      display: inline-block;
      transition: opacity 0.4s ease; }
      .table_link__link a:hover {
        opacity: 0.8;
        text-decoration: none; }
      .table_link__link a.current:hover {
        opacity: 1; }
      .table_link__link a::before {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        font-size: 1rem;
        color: #fe6400; }
  .table_link__pdf {
    text-align: right; }
    @media screen and (max-width: 768px) {
      .table_link__pdf {
        text-align: left;
        padding-left: 15px; } }
    .table_link__pdf a {
      text-decoration: underline; }
      @media screen and (max-width: 768px) {
        .table_link__pdf a {
          text-decoration: none; } }
      .table_link__pdf a:hover {
        text-decoration: none; }
    .table_link__pdf::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      font-size: 1.8rem;
      color: #f20e03;
      vertical-align: middle;
      margin-left: 5px; }

.faq__desc {
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    .faq__desc {
      font-size: 1.2rem; } }

.faq__q {
  position: absolute;
  left: 38px;
  top: 0;
  font-size: 2.4rem;
  color: #fe6400;
  font-family: "Ropa Sans", sans-serif;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    .faq__q {
      left: 28px;
      font-size: 2rem; } }

.faq__a {
  position: absolute;
  left: 42px;
  top: 32px;
  font-size: 2.4rem;
  color: #fe6400;
  font-family: "Ropa Sans", sans-serif;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    .faq__a {
      left: 0;
      top: 0;
      font-size: 2rem; } }

.faq__question {
  position: relative;
  font-size: 1.6rem;
  font-weight: normal;
  color: #333;
  margin-bottom: 30px;
  padding: 4px 0 12px 60px;
  border-left: 4px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  transition: border-color .3s ease; }
  @media screen and (max-width: 768px) {
    .faq__question {
      font-size: 1.4rem;
      margin-bottom: 20px;
      padding: 4px 0 8px 46px;
      border-left-width: 3px; } }
  .faq__question::before, .faq__question::after {
    content: '';
    position: absolute;
    left: 15px;
    top: 8px;
    display: block;
    width: 2px;
    height: 10px;
    background: #e2e2e2;
    transition: transform .3s ease, background-color .3s ease; }
  .faq__question::after {
    transform: rotate(90deg); }
  .faq__question.-active {
    border-color: #fe6400; }
    .faq__question.-active::before, .faq__question.-active::after {
      transform: rotate(450deg);
      background-color: #fe6400; }

.faq__answer {
  position: relative;
  padding: 30px 30px 30px 64px; }
  @media screen and (max-width: 768px) {
    .faq__answer {
      padding: 0 0 0 20px; } }

.frame_img {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e0e0e0; }

.product_card, body[class*=enterprise] .enterprise_nav__collision {
  display: table;
  text-decoration: none; }
  .product_card__img, body[class*=enterprise] .enterprise_nav__img {
    display: table-cell; }
    .product_card__img img, body[class*=enterprise] .enterprise_nav__img img {
      max-width: 100%;
      height: auto; }
  * + .product_card__body, body[class*=enterprise] * + .enterprise_nav__body {
    margin-top: 10px; }
  .product_card__body, body[class*=enterprise] .enterprise_nav__body {
    display: table-caption;
    caption-side: bottom; }
  .product_card__heading {
    font-size: 1.6rem; }
    .product_card__heading + * {
      margin-top: 10px; }
  .product_card__text {
    font-size: 1.2rem; }

.nav_entry {
  display: flex;
  margin-bottom: 60px;
  padding-left: 1px; }
  @media screen and (max-width: 768px) {
    .nav_entry {
      overflow-x: auto;
      margin-bottom: 30px;
      padding-bottom: 14px;
      -webkit-overflow-scrolling: touch; } }
  .nav_entry__item {
    position: relative;
    width: 20%;
    margin-left: -1px; }
    @media screen and (max-width: 768px) {
      .nav_entry__item {
        width: auto; } }
    .nav_entry__item a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      border: 1px #000 solid;
      font-size: 1.3rem;
      text-decoration: none;
      color: #000;
      transition: color .2s ease, border-color .2s ease; }
      @media screen and (max-width: 768px) {
        .nav_entry__item a {
          height: 40px;
          padding: 0 20px;
          white-space: nowrap;
          font-size: 1.1rem; } }
      .nav_entry__item a::after {
        content: '';
        position: absolute;
        bottom: -14px;
        left: 50%;
        display: block;
        border-top: 7px #fe6400 solid;
        border-right: 5px transparent solid;
        border-bottom: 7px transparent solid;
        border-left: 5px transparent solid;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity .2s ease; }
    .nav_entry__item:hover, .nav_entry__item.-current {
      z-index: 1; }
      .nav_entry__item:hover a, .nav_entry__item.-current a {
        color: #fe6400;
        border-color: #fe6400; }
        .nav_entry__item:hover a::after, .nav_entry__item.-current a::after {
          opacity: 1; }

.get_to_know {
  margin-top: 70px;
  margin-bottom: -180px;
  padding-top: 50px;
  padding-bottom: 120px;
  background-color: #f7f7f7; }
  @media screen and (max-width: 768px) {
    .get_to_know {
      margin-top: 40px;
      padding-top: 40px;
      padding-bottom: 80px;
      margin-bottom: -80px; } }

.theme_orange {
  color: #fe6400; }

.form {
  margin-top: 30px; }
  .form__body {
    color: #333; }
    .form__body input[type="checkbox"] {
      margin-right: 10px; }
    .form__body input[type="radio"] {
      position: relative;
      top: -2px;
      margin-right: 10px;
      margin: 6px 10px; }
    .form__body input[type="text"] {
      appearance: none;
      border-radius: 0;
      padding: 5px;
      font-size: 1.6rem;
      border: 1px solid #666666;
      margin-right: 15px; }
      @media screen and (max-width: 768px) {
        .form__body input[type="text"] {
          box-sizing: border-box;
          max-width: 290px;
          margin-right: 10px; } }
      .form__body input[type="text"]:focus {
        outline: none; }
      .form__body input[type="text"][name="Public::Application::Userenquete_D__P__D_zip2"] + .ss_rightComment {
        display: inline-block;
        margin-top: 0; }
    .form__body textarea {
      appearance: none;
      border-radius: 0;
      padding: 5px;
      font-size: 1.6rem;
      border: 1px solid #666666;
      width: 100%;
      max-width: 500px;
      min-height: 150px;
      box-sizing: border-box; }
      .form__body textarea:focus {
        outline: none; }
    .form__body .ss_rightComment {
      color: #999999;
      font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        .form__body .ss_rightComment {
          display: block;
          margin-top: 5px;
          font-size: 1.2rem; } }
    .form__body .ss_bottomComment {
      width: 100%;
      margin-top: 5px;
      color: #999999;
      font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        .form__body .ss_bottomComment {
          display: block;
          margin-top: 5px;
          width: auto; } }
    .form__body .ss_upComment {
      width: 100%;
      margin-bottom: 5px;
      color: #999999;
      font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        .form__body .ss_upComment {
          display: block;
          margin-top: 5px;
          width: auto; } }
    .form__body .ss_leftComment {
      margin-right: 15px; }
      @media screen and (max-width: 768px) {
        .form__body .ss_leftComment {
          margin-right: 10px; } }
    @media screen and (max-width: 768px) {
      .form__body .ss_input {
        margin-top: 5px;
        margin-bottom: 5px; } }
    .form__body .label {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.1rem;
      color: #fe6400; }
    .form__body .ss_error_msg {
      color: #f20e03;
      font-size: 1.2rem;
      margin-bottom: 10px; }
    .form__body .label-warning {
      display: none; }
      .form__body .label-warning.ss_notnull {
        display: block; }
  .form__content {
    display: flex;
    border-bottom: 1px solid #e2e2e2; }
    @media screen and (max-width: 768px) {
      .form__content {
        display: block; } }
    .form__content:first-child {
      border-top: 1px solid #e2e2e2; }
    .form__content__header {
      display: flex;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      width: 230px;
      background: #efefef;
      padding: 15px 60px 15px 15px;
      box-sizing: border-box; }
      @media screen and (max-width: 768px) {
        .form__content__header {
          width: 100%;
          padding: 15px 40px 15px 15px; } }
    .form__content__body {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 15px 0 15px 30px; }
      @media screen and (max-width: 768px) {
        .form__content__body {
          padding: 15px 0; } }
    .form__content__require {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.1rem;
      color: #fe6400; }
    .form__content__title {
      font-size: 1.6rem;
      color: #333;
      line-height: 1.4; }
    .form__content__item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 1.6rem; }
    .form__content__prefix {
      font-size: 1.6rem;
      color: #333; }
      @media screen and (max-width: 768px) {
        .input_text + .form__content__prefix {
          margin-left: 20px; } }
    .form__content__example {
      color: #999999;
      font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        .form__content__example {
          margin-top: 5px;
          width: 100%; } }
    .form__content__notes {
      width: 100%;
      margin-top: 5px;
      color: #999999;
      font-size: 1.4rem; }
    .form__content__error {
      width: 100%;
      margin-top: 5px;
      color: #e00;
      font-size: 1.4rem; }
    .form__content__unit {
      margin-right: 10px;
      margin-left: -15px; }
      @media screen and (max-width: 768px) {
        .form__content__unit {
          margin-left: 10px; } }
  .form__footer {
    margin-top: 40px; }
    .form__footer > .btn {
      display: flex;
      justify-content: center; }
      .form__footer > .btn button {
        margin: 0 20px;
        font-size: 1.6rem; }
        @media screen and (max-width: 768px) {
          .form__footer > .btn button {
            margin: 0 10px; } }
        .form__footer > .btn button.btn-large {
          background: #efefef;
          color: #666; }
        .form__footer > .btn button.btn-primary {
          background: #fe6400;
          color: #fff; }

.input_text {
  appearance: none;
  border-radius: 0;
  padding: 5px;
  font-size: 1.6rem;
  border: 1px solid #666666;
  margin-right: 25px;
  width: 100%;
  max-width: 300px; }
  @media screen and (max-width: 768px) {
    .input_text {
      margin-right: 0;
      max-width: 100%; } }
  .input_text:focus {
    outline: none; }
  .input_text.-sm {
    max-width: 60px; }
  .input_text.-md {
    max-width: 100px; }
    @media screen and (max-width: 768px) {
      .input_text.-md {
        max-width: 100%; } }
  @media screen and (max-width: 768px) {
    .input_text.-sp_half {
      width: 29%; } }

.input_checkbox {
  margin-right: 20px; }
  .input_checkbox input {
    margin-right: 10px; }
  .input_checkbox span {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1.6rem; }

.input_radio {
  margin-right: 20px; }
  .input_radio input {
    margin-right: 10px; }
  .input_radio span {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 1.6rem; }

.input_select {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 120px; }
  .input_select::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    background: #fff;
    height: 100%;
    width: 100%; }
  .input_select::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: #333333 transparent transparent transparent; }
  .input_select select {
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 1;
    height: 30px;
    box-sizing: border-box;
    padding: 0 20px 0 10px;
    border: 1px #333 solid;
    font-size: 1.6rem;
    width: 100%;
    border-radius: 0; }
    .input_select select::-ms-expand {
      display: none; }
    .input_select select:focus {
      outline: none; }

.input_textarea {
  appearance: none;
  border-radius: 0;
  padding: 5px;
  font-size: 1.6rem;
  border: 1px solid #666666;
  width: 100%;
  max-width: 500px;
  min-height: 150px; }
  .input_textarea:focus {
    outline: none; }

.page_to_top {
  text-align: right;
  margin-top: 10px; }
  .page_to_top a {
    text-decoration: none;
    font-size: 1.3rem; }
    .page_to_top a::before {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      font-size: 1rem;
      font-weight: bold;
      color: #fe6400;
      margin-right: 5px; }

@media screen and (max-width: 768px) {
  .breadcrumb {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #fff; } }

.breadcrumb__list {
  padding: 10px 15px; }
  @media screen and (max-width: 768px) {
    .breadcrumb__list {
      padding: 6px 15px;
      white-space: nowrap; } }

.breadcrumb__item {
  display: inline-block;
  font-size: 1.1rem; }
  .breadcrumb__item::before {
    content: '/';
    margin: 0 10px;
    color: #333; }
  .breadcrumb__item:first-child::before {
    content: none; }
  @media screen and (max-width: 768px) {
    .breadcrumb__item:last-child {
      margin-right: 15px; } }

.breadcrumb a {
  transition: opacity 0.4s ease; }
  .breadcrumb a:hover {
    opacity: 0.8;
    text-decoration: none; }
  .breadcrumb a.current:hover {
    opacity: 1; }

.breadcrumb span {
  color: #333; }

.about .breadcrumb,
.about_human .breadcrumb,
.about_technical .breadcrumb,
.about_procurement .breadcrumb,
.about_information .breadcrumb {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0); }
  @media screen and (max-width: 768px) {
    .about .breadcrumb,
    .about_human .breadcrumb,
    .about_technical .breadcrumb,
    .about_procurement .breadcrumb,
    .about_information .breadcrumb {
      top: 45px; } }
  @media screen and (max-width: 768px) {
    .about .breadcrumb__item,
    .about_human .breadcrumb__item,
    .about_technical .breadcrumb__item,
    .about_procurement .breadcrumb__item,
    .about_information .breadcrumb__item {
      font-size: 1rem; } }
  .about .breadcrumb__item::before,
  .about_human .breadcrumb__item::before,
  .about_technical .breadcrumb__item::before,
  .about_procurement .breadcrumb__item::before,
  .about_information .breadcrumb__item::before {
    color: #eee; }
  .about .breadcrumb a,
  .about_human .breadcrumb a,
  .about_technical .breadcrumb a,
  .about_procurement .breadcrumb a,
  .about_information .breadcrumb a {
    color: #eee; }
  .about .breadcrumb span,
  .about_human .breadcrumb span,
  .about_technical .breadcrumb span,
  .about_procurement .breadcrumb span,
  .about_information .breadcrumb span {
    color: #fff; }

/* Scope */
body.top .index_visual__inner {
  position: relative; }

body.top .index_visual__body {
  position: relative; }
  @media screen and (max-width: 768px) {
    body.top .index_visual__body {
      height: 500px; } }

body.top .index_visual__content {
  width: 100%;
  height: 100vh;
  min-height: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: 50% 50% no-repeat;
  background-size: cover; }
  @media screen and (min-width: 769px) {
    body.top .index_visual__content {
      background-image: url(/assets/img/img_visual_01.jpg); } }
  @media screen and (max-width: 768px) {
    body.top .index_visual__content {
      height: 500px;
      min-height: 0;
      background-image: url(/assets/img/img_visual_01_sp.jpg); } }

@media screen and (max-width: 768px) {
  body.top .index_visual__lead img {
    width: 314px; } }

body.top .index_visual__desc {
  margin-top: 30px; }
  @media screen and (max-width: 768px) {
    body.top .index_visual__desc {
      margin-top: 15px; } }
  body.top .index_visual__desc span {
    display: inline-block;
    padding: 5px 10px;
    font-size: 1.4rem;
    background: #fff; }
    @media screen and (max-width: 768px) {
      body.top .index_visual__desc span {
        font-size: 1.8vw;
        padding: 5px 5px; } }

body.top .index_visual__pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%; }
  @media screen and (max-width: 768px) {
    body.top .index_visual__pagination {
      display: none; } }
  body.top .index_visual__pagination__body {
    display: flex; }
  body.top .index_visual__pagination__footer {
    height: 6px;
    background: #000; }
  body.top .index_visual__pagination__bar {
    height: 6px;
    width: 100%;
    background: #fe6400;
    transform: translateX(-100%); }
    body.top .index_visual__pagination__bar.-init {
      transform: translateX(0%);
      transition: transform 4s linear; }
  body.top .index_visual__pagination__num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 30px;
    appearance: none;
    background: #000;
    border: none;
    color: #fff;
    font-size: 1.6rem;
    font-family: "Ropa Sans", sans-serif;
    cursor: pointer;
    transition: background-color .3s ease; }
    body.top .index_visual__pagination__num:focus {
      outline: none; }
    body.top .index_visual__pagination__num:hover, body.top .index_visual__pagination__num.-current {
      background-color: #fe6400; }

body.top .index_visual__scroll {
  position: absolute;
  bottom: 6px;
  left: 50%;
  z-index: 1;
  font-size: 1rem;
  color: #fff;
  font-family: "Ropa Sans", sans-serif;
  letter-spacing: .2em;
  transform: translateX(-50%); }
  body.top .index_visual__scroll::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    display: block;
    margin: 15px auto 0;
    font-size: 2.4rem;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      body.top .index_visual__scroll::after {
        font-size: 2rem;
        margin: 15px auto 0; } }

body.top .index_important {
  background-color: #efefef; }
  body.top .index_important__inner {
    display: flex; }
    @media screen and (max-width: 768px) {
      body.top .index_important__inner {
        display: block; } }
  body.top .index_important__header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fe6400;
    flex-shrink: 0;
    width: 160px; }
    @media screen and (max-width: 768px) {
      body.top .index_important__header {
        width: auto;
        padding: 15px 0; } }
  body.top .index_important__body {
    padding: 8px 32px;
    flex-grow: 1; }
    @media screen and (max-width: 768px) {
      body.top .index_important__body {
        padding: 10px 20px; } }
  body.top .index_important__title {
    font-size: 1.4rem;
    color: #fff; }
  body.top .index_important__list__item {
    border-top: 1px dashed #333; }
    body.top .index_important__list__item:first-child {
      border-top: none; }
    body.top .index_important__list__item a {
      display: flex;
      text-decoration: none;
      transition: opacity .3s ease;
      line-height: 1.6;
      padding-top: 4px;
      padding-bottom: 6px; }
      body.top .index_important__list__item a:hover {
        opacity: 0.8; }
      body.top .index_important__list__item a.permalink::after {
        content: none; }
      body.top .index_important__list__item a.permalink.-blank .index_important__list__item__title::after {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-size: 1rem;
        margin-left: 4px; }
      body.top .index_important__list__item a.permalink.-pdf .index_important__list__item__title::after {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-size: 1.4rem;
        color: #fe6400;
        margin-left: 4px; }
    body.top .index_important__list__item__date {
      width: 90px;
      flex-shrink: 0; }

body.top .index_carousel {
  margin-top: 40px;
  padding-bottom: 40px; }
  @media screen and (max-width: 768px) {
    body.top .index_carousel {
      margin-top: 60px;
      margin-bottom: -20px; } }
  body.top .index_carousel__inner {
    width: 1060px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 768px) {
      body.top .index_carousel__inner {
        width: auto; } }
  @media screen and (max-width: 768px) {
    body.top .index_carousel__item {
      padding: 0 27px; } }
  body.top .index_carousel__item__thumb {
    width: 910px;
    margin: 0 auto; }
    @media screen and (max-width: 768px) {
      body.top .index_carousel__item__thumb {
        width: auto; } }
    @media screen and (min-width: 769px) {
      body.top .index_carousel__item__thumb img.hidden_pc {
        display: none !important; } }
    @media screen and (max-width: 768px) {
      body.top .index_carousel__item__thumb img.hidden_sp {
        display: none !important; } }
  body.top .index_carousel .slick-prev,
  body.top .index_carousel .slick-next {
    appearance: none;
    border: none;
    background: #000;
    text-indent: -5000px;
    width: 50px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    cursor: pointer; }
    @media screen and (min-width: 769px) {
      body.top .index_carousel .slick-prev,
      body.top .index_carousel .slick-next {
        transition: opacity 0.4s ease; }
        body.top .index_carousel .slick-prev:hover,
        body.top .index_carousel .slick-next:hover {
          opacity: 0.7;
          text-decoration: none; }
        body.top .index_carousel .slick-prev.current:hover,
        body.top .index_carousel .slick-next.current:hover {
          opacity: 1; } }
    @media screen and (max-width: 768px) {
      body.top .index_carousel .slick-prev,
      body.top .index_carousel .slick-next {
        width: 38px;
        height: 76px; } }
    body.top .index_carousel .slick-prev::after,
    body.top .index_carousel .slick-next::after {
      position: relative;
      top: -2px;
      font-size: 2rem;
      color: #fff; }
      @media screen and (max-width: 768px) {
        body.top .index_carousel .slick-prev::after,
        body.top .index_carousel .slick-next::after {
          font-size: 1.8rem; } }
    body.top .index_carousel .slick-prev:focus,
    body.top .index_carousel .slick-next:focus {
      outline: none; }
  body.top .index_carousel .slick-prev {
    left: 0; }
    body.top .index_carousel .slick-prev::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
  body.top .index_carousel .slick-next {
    right: 0; }
    body.top .index_carousel .slick-next::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
  body.top .index_carousel .slick-dots {
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 100%;
    text-align: center;
    height: 5px; }
    body.top .index_carousel .slick-dots li {
      display: inline-block;
      padding: 0 3px;
      height: 5px; }
      body.top .index_carousel .slick-dots li.slick-active button {
        background-color: #fe6400; }
    body.top .index_carousel .slick-dots button {
      appearance: none;
      width: 40px;
      height: 5px;
      border: none;
      background: #d9d9d9;
      text-indent: -5000px;
      transition: background-color .3s ease; }
      @media screen and (max-width: 768px) {
        body.top .index_carousel .slick-dots button {
          width: 30px; } }
      body.top .index_carousel .slick-dots button:focus {
        outline: none; }

body.top .index_content {
  padding-bottom: 100px; }
  @media screen and (max-width: 768px) {
    body.top .index_content {
      padding-top: 0;
      padding-bottom: 80px; } }
  body.top .index_content__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1060px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 768px) {
      body.top .index_content__inner {
        width: auto; } }
  body.top .index_content__content {
    margin-top: 40px;
    width: 507px; }
    @media screen and (max-width: 768px) {
      body.top .index_content__content {
        width: auto;
        margin-top: 60px; } }
    body.top .index_content__content__header {
      position: relative; }
      body.top .index_content__content__header a {
        display: block;
        text-decoration: none;
        transition: opacity 1s ease; }
        body.top .index_content__content__header a:hover {
          opacity: .8; }
          body.top .index_content__content__header a:hover .index_content__thumb img {
            transform: scale(1.1); }
    body.top .index_content__content__body {
      margin-top: 35px; }
      @media screen and (max-width: 768px) {
        body.top .index_content__content__body {
          padding: 0 15px; } }
  body.top .index_content__thumb {
    overflow: hidden; }
    body.top .index_content__thumb img {
      transition: transform 1s ease; }
  body.top .index_content__title {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 56px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    background: #fff;
    color: #333; }
    body.en.top .index_content__title {
      width: 250px; }
    @media screen and (max-width: 768px) {
      body.top .index_content__title {
        width: 185px; }
        body.en.top .index_content__title {
          width: 200px; } }
  body.top .index_content__btn {
    position: absolute;
    right: 0;
    bottom: -20px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    width: 220px;
    height: 64px;
    background: #fe6400;
    color: #fff;
    font-size: 1.3rem;
    box-sizing: border-box; }
    body.top .index_content__btn::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.8rem; }
  body.top .index_content__list {
    margin-top: -15px;
    display: flex;
    flex-wrap: wrap; }
    body.top .index_content__list__item {
      margin-right: 15px;
      margin-top: 15px; }
      body.top .index_content__list__item:last-child {
        margin-right: 0; }
      body.top .index_content__list__item a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #333;
        font-size: 1.6rem;
        transition: opacity 0.4s ease; }
        body.top .index_content__list__item a:hover {
          opacity: 0.8;
          text-decoration: none; }
        body.top .index_content__list__item a.current:hover {
          opacity: 1; }
        @media screen and (max-width: 768px) {
          body.top .index_content__list__item a {
            font-size: 1.3rem; } }
        body.top .index_content__list__item a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          color: #fe6400;
          margin-right: 10px; }

body.top .index_news {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f5f5f5; }
  @media screen and (max-width: 768px) {
    body.top .index_news {
      padding-top: 40px;
      padding-bottom: 40px; } }
  body.top .index_news__inner {
    width: 980px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 768px) {
      body.top .index_news__inner {
        width: auto;
        padding: 0 15px; } }
  body.top .index_news__title {
    font-size: 2.8rem;
    text-align: center; }
    @media screen and (max-width: 768px) {
      body.top .index_news__title {
        font-size: 2.1rem; } }
  body.top .index_news__ruby {
    margin-top: 6px;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    color: #fe6400;
    font-family: "Ropa Sans", sans-serif; }
    @media screen and (max-width: 768px) {
      body.top .index_news__ruby {
        font-size: 1.5rem; } }
  body.top .index_news__body {
    margin-top: 40px; }
    @media screen and (max-width: 768px) {
      body.top .index_news__body {
        margin-top: 25px; } }
  body.top .index_news__tab {
    display: flex; }
    @media screen and (max-width: 768px) {
      body.top .index_news__tab {
        overflow-x: scroll;
        padding-left: 1px;
        padding-bottom: 13px; } }
    body.top .index_news__tab__item {
      width: 20%; }
      @media screen and (max-width: 768px) {
        body.top .index_news__tab__item {
          width: 120px;
          flex-grow: 1;
          flex-shrink: 0; } }
      body.top .index_news__tab__item a {
        position: relative;
        display: flex;
        justify-content: center;
        background: #fff;
        padding: 7px;
        font-size: 1.3rem;
        text-align: center;
        color: #000;
        text-decoration: none;
        border: 1px #000 solid;
        margin-left: -1px;
        transition: color .2s ease, border-color .2s ease; }
        @media screen and (max-width: 768px) {
          body.top .index_news__tab__item a {
            align-items: center;
            font-size: 1rem;
            padding: 0 0;
            height: 32px; } }
        body.top .index_news__tab__item a:hover {
          border-color: #fe6400;
          color: #fe6400;
          z-index: 1;
          opacity: 1; }
          body.top .index_news__tab__item a:hover::after {
            opacity: 1; }
        body.top .index_news__tab__item a::after {
          content: '';
          position: absolute;
          bottom: -14px;
          left: 50%;
          display: block;
          border-top: 7px #fe6400 solid;
          border-right: 5px transparent solid;
          border-bottom: 7px transparent solid;
          border-left: 5px transparent solid;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity .2s ease; }
      body.top .index_news__tab__item.-current a {
        border-color: #fe6400;
        color: #fe6400;
        z-index: 1; }
        body.top .index_news__tab__item.-current a::after {
          opacity: 1; }
  body.top .index_news__list {
    margin-top: 20px;
    margin-bottom: 40px; }
    @media screen and (max-width: 768px) {
      body.top .index_news__list {
        margin-top: 0;
        margin-bottom: 30px; } }
    body.top .index_news__list__item {
      margin-top: 5px;
      border-bottom: 1px #000 dotted; }
      @media screen and (max-width: 768px) {
        body.top .index_news__list__item {
          margin-top: 0; } }
      body.top .index_news__list__item a {
        display: flex;
        align-items: center;
        padding: 10px 0;
        color: #333;
        text-decoration: none;
        transition: opacity 0.4s ease; }
        body.top .index_news__list__item a:hover {
          opacity: 0.8;
          text-decoration: none; }
        body.top .index_news__list__item a.current:hover {
          opacity: 1; }
        @media screen and (max-width: 768px) {
          body.top .index_news__list__item a {
            flex-wrap: wrap;
            padding: 15px 0; } }
        @media screen and (max-width: 768px) {
          body.top .index_news__list__item a::after {
            order: 4; } }
    body.top .index_news__list__date {
      flex-shrink: 0;
      width: 100px; }
      @media screen and (max-width: 768px) {
        body.top .index_news__list__date {
          order: 2;
          width: 50%; } }
    body.top .index_news__list__category {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      padding: 0 10px;
      flex-shrink: 0;
      background: #000;
      border-radius: 3px;
      color: #fff;
      font-size: 1rem;
      margin-right: 26px; }
      @media screen and (max-width: 768px) {
        body.top .index_news__list__category {
          width: auto;
          order: 1;
          margin-right: 20px; } }
    @media screen and (max-width: 768px) {
      body.top .index_news__list__title {
        order: 3; } }

body.top .index_banner {
  background-color: #f5f5f5;
  padding-bottom: 80px; }
  body.en.top .index_banner {
    padding-top: 60px; }
    @media screen and (max-width: 768px) {
      body.en.top .index_banner {
        padding-top: 40px; } }
  @media screen and (max-width: 768px) {
    body.top .index_banner {
      padding-bottom: 70px; } }
  body.top .index_banner__item {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: 10px 15px; }
    @media screen and (max-width: 768px) {
      body.top .index_banner__item {
        width: 277px;
        margin: 10px 12px; } }
  body.top .index_banner .slick-prev,
  body.top .index_banner .slick-next {
    appearance: none;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100px;
    border: none;
    background-color: #000;
    cursor: pointer;
    text-indent: -5000px;
    transition: opacity 0.4s ease; }
    body.top .index_banner .slick-prev:hover,
    body.top .index_banner .slick-next:hover {
      opacity: 0.8;
      text-decoration: none; }
    body.top .index_banner .slick-prev.current:hover,
    body.top .index_banner .slick-next.current:hover {
      opacity: 1; }
    @media screen and (max-width: 768px) {
      body.top .index_banner .slick-prev,
      body.top .index_banner .slick-next {
        width: 38px;
        height: 76px; } }
    body.top .index_banner .slick-prev::after,
    body.top .index_banner .slick-next::after {
      color: #fff; }
  body.top .index_banner .slick-prev {
    left: 0; }
    body.top .index_banner .slick-prev::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem; }
      @media screen and (max-width: 768px) {
        body.top .index_banner .slick-prev::after {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.8rem; } }
  body.top .index_banner .slick-next {
    right: 0; }
    body.top .index_banner .slick-next::after {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem; }
      @media screen and (max-width: 768px) {
        body.top .index_banner .slick-next::after {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.8rem; } }

/* About */
body.about .about_content__lead {
  text-align: center;
  font-size: 2.8rem;
  color: #333;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    body.about .about_content__lead {
      font-size: 2rem; } }
  body.about .about_content__lead span {
    color: #fe6400;
    font-size: 3.8rem;
    font-family: "Ropa Sans", sans-serif; }
    @media screen and (max-width: 768px) {
      body.about .about_content__lead span {
        font-size: 3rem; } }

body.about_human .support_credo {
  margin-top: 70px;
  margin-bottom: 35px; }
  @media screen and (max-width: 768px) {
    body.about_human .support_credo {
      margin-top: 40px;
      margin-bottom: 20px; } }
  body.about_human .support_credo__body {
    position: relative;
    width: 500px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 28px 20px 30px;
    border: 1px #e2e2e2 solid;
    text-align: center; }
    @media screen and (max-width: 768px) {
      body.about_human .support_credo__body {
        width: auto;
        padding: 20px; } }
    body.about_human .support_credo__body::after {
      content: '';
      position: absolute;
      left: 50%;
      top: -3px;
      transform: translateX(-50%);
      width: 160px;
      height: 5px;
      background: #fe6400; }
      @media screen and (max-width: 768px) {
        body.about_human .support_credo__body::after {
          width: 120px;
          height: 3px;
          top: -2px; } }
  body.about_human .support_credo__lead {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      body.about_human .support_credo__lead {
        margin-top: 10px;
        margin-bottom: 10px; } }
    body.en.about_human .support_credo__lead img {
      width: 260px;
      height: auto; }
    @media screen and (max-width: 768px) {
      body.about_human .support_credo__lead img {
        width: 88px;
        height: auto; }
        body.en.about_human .support_credo__lead img {
          width: 130px; } }
  body.about_human .support_credo__desc {
    font-size: 2rem;
    color: #fff;
    font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif; }
    @media screen and (max-width: 768px) {
      body.about_human .support_credo__desc {
        font-size: 1.4rem; } }

/* Company */
body.company_rinen .rinen_desc {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.4; }
  @media screen and (max-width: 768px) {
    body.company_rinen .rinen_desc {
      font-size: 1.4rem;
      line-height: 1.8; } }

body.company_rinen .rinen_credo {
  margin-top: 50px;
  margin-bottom: 80px; }
  @media screen and (max-width: 768px) {
    body.company_rinen .rinen_credo {
      margin-top: 20px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    body.company_rinen .rinen_credo__thumb img {
      width: 292px; } }

body.company_rinen .rinen_access__data {
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    body.company_rinen .rinen_access__data {
      margin-top: -20px; } }
  body.company_rinen .rinen_access__data dt {
    clear: left;
    float: left;
    width: 160px;
    height: 26px;
    font-size: 2rem;
    font-family: "Ropa Sans", sans-serif;
    font-weight: bold;
    letter-spacing: .1em;
    margin-top: 10px;
    color: #333; }
    @media screen and (max-width: 768px) {
      body.company_rinen .rinen_access__data dt {
        float: none;
        font-size: 1.8rem;
        height: auto;
        width: auto;
        margin-top: 20px; } }
    body.company_rinen .rinen_access__data dt:first-letter {
      color: #fe6400;
      font-size: 2.4rem; }
      @media screen and (max-width: 768px) {
        body.company_rinen .rinen_access__data dt:first-letter {
          font-size: 2.2rem; } }
  body.company_rinen .rinen_access__data dd {
    height: 26px;
    line-height: 26px;
    margin-left: 160px;
    font-size: 1.6rem;
    margin-top: 10px;
    color: #333; }
    @media screen and (max-width: 768px) {
      body.company_rinen .rinen_access__data dd {
        float: none;
        height: auto;
        line-height: 1.6;
        margin-left: 1em;
        font-size: 1.4rem;
        margin-top: 0; } }

body.en.company_officer .page_header__inner {
  width: 500px; }
  @media screen and (max-width: 768px) {
    body.en.company_officer .page_header__inner {
      width: auto; } }

@media screen and (max-width: 768px) {
  body.company_officer div.sec .col_6 {
    padding-top: 15px; } }

@media screen and (min-width: 769px) {
  body.en.company_officer .officer_content {
    display: flex;
    flex-direction: column;
    height: 100%; } }

body.company_officer .officer_content__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 100%; }
  @media screen and (min-width: 769px) {
    body.en.company_officer .officer_content__body {
      flex-grow: 1; } }

@media screen and (max-width: 768px) {
  body.company_officer .officer_content__thumb img {
    width: 100%; } }

body.company_officer .officer_content__career {
  font-size: 1.4rem;
  color: #444444;
  line-height: 1.6;
  margin-bottom: 10px; }
  body.company_officer .officer_content__career.-lg {
    font-size: 1.6rem; }
    @media screen and (max-width: 768px) {
      body.company_officer .officer_content__career.-lg {
        font-size: 1.4rem;
        margin-top: -15px; } }
  @media screen and (min-width: 769px) {
    body.en.company_officer .officer_content__career.-fit {
      flex-grow: 1; } }
  @media screen and (max-width: 768px) {
    body.company_officer .officer_content__career {
      margin-bottom: 5px; } }

body.company_officer .officer_content__name {
  color: #000;
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: bold; }
  body.company_officer .officer_content__name.-lg {
    font-size: 2.8rem; }
    @media screen and (max-width: 768px) {
      body.company_officer .officer_content__name.-lg {
        font-size: 1.8rem; } }
  body.company_officer .officer_content__name.-md {
    font-size: 2rem; }
    @media screen and (max-width: 768px) {
      body.company_officer .officer_content__name.-md {
        font-size: 1.8rem; } }
  @media screen and (max-width: 768px) {
    body.company_officer .officer_content__name {
      font-size: 1.8rem; } }
  body.company_officer .officer_content__name span {
    font-size: 1.4rem;
    margin-left: 1em; }

body.company_officer .officer_content__history {
  margin-top: 10px;
  color: #333;
  font-size: 1.4rem;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    body.company_officer .officer_content__history {
      margin-top: 5px; } }
  @media screen and (min-width: 769px) {
    body.en.company_officer .officer_content__history.-fit {
      flex-grow: 1; } }

body.company_history .history_content__thumb {
  margin-top: 30px;
  margin-bottom: 60px; }
  @media screen and (max-width: 768px) {
    body.company_history .history_content__thumb {
      margin-top: 10px;
      margin-bottom: 0; } }
  @media screen and (max-width: 768px) {
    body.company_history .history_content__thumb + .table_basic {
      margin-top: 30px; } }

body.company_message .message_copy__desc {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem; }
  @media screen and (max-width: 768px) {
    body.company_message .message_copy__desc {
      font-size: 1.4rem; } }

body.company_message .message_copy__lead {
  font-size: 3.2rem;
  text-align: center;
  font-weight: bold;
  color: #000000;
  margin-top: 35px; }
  @media screen and (max-width: 768px) {
    body.company_message .message_copy__lead {
      font-size: 2rem;
      line-height: 1.2;
      margin: 20px 10px 0; } }
  body.company_message .message_copy__lead img {
    margin-right: 15px; }
    @media screen and (max-width: 768px) {
      body.company_message .message_copy__lead img {
        margin: 0 0 10px; } }

body.company_message .message_copy__copy {
  color: #fe6400;
  text-align: center;
  font-size: 3.2rem;
  line-height: 1.6;
  margin-top: 40px;
  margin-bottom: 50px;
  font-weight: bold; }
  @media screen and (max-width: 768px) {
    body.company_message .message_copy__copy {
      font-size: 2rem;
      margin-top: 30px;
      margin-bottom: 25px; } }

body.company_message .message_content__thumb {
  margin-bottom: 35px; }
  @media screen and (max-width: 768px) {
    body.company_message .message_content__thumb {
      margin-bottom: 15px; } }

body.company_message .message_policy__body {
  margin-top: 30px;
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 768px) {
    body.company_message .message_policy__body {
      flex-wrap: wrap;
      margin-top: -40px; } }

body.company_message .message_policy__content {
  text-align: center; }
  body.en.company_message .message_policy__content {
    width: 33%; }
  @media screen and (max-width: 768px) {
    body.company_message .message_policy__content {
      margin-top: 40px; }
      body.en.company_message .message_policy__content {
        width: auto; } }
  @media screen and (max-width: 768px) {
    body.company_message .message_policy__content:first-child {
      width: 100%; } }

body.company_message .message_policy__title {
  margin-top: 20px;
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold; }

body.company_message .message_policy__figure {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px; }

body.company_network .network_map {
  position: relative; }
  body.company_network .network_map__nav {
    position: absolute;
    top: 90px;
    left: 20px;
    width: 480px;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 768px) {
      body.company_network .network_map__nav {
        position: static;
        width: auto;
        margin: -5px -5px 0; } }
    body.company_network .network_map__nav__item {
      width: 210px;
      margin-left: 20px;
      margin-bottom: 20px; }
      @media screen and (max-width: 768px) {
        body.company_network .network_map__nav__item {
          width: 50%;
          margin: 0;
          padding: 5px;
          box-sizing: border-box; } }
  body.company_network .network_map__thumb {
    text-align: right;
    padding-right: 40px; }
    @media screen and (max-width: 768px) {
      body.company_network .network_map__thumb {
        text-align: center;
        padding-right: 0; } }

body.company_network .network_nav {
  margin-top: 60px; }
  body.company_network .network_nav__title {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #000; }

body.company_network .office_content {
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2; }
  @media screen and (max-width: 768px) {
    body.company_network .office_content {
      padding-bottom: 60px; } }
  body.company_network .office_content__title {
    margin-top: 15px;
    color: #444444;
    line-height: 1.8;
    padding-left: 1.1em;
    text-indent: -1.1em; }
    body.company_network .office_content__title::before {
      content: '・';
      color: #fe6400; }
  body.company_network .office_content__desc {
    color: #444444;
    padding-left: 1em;
    line-height: 1.8; }
    body.company_network .office_content__desc .tags {
      background-color: #fe6400;
      border-radius: 3px;
      color: #fff;
      display: inline-block;
      font-size: 1.1rem;
      margin: 5px 7px 5px 0;
      padding: 0 4px 0 5px; }
    body.company_network .office_content__desc a {
      color: #fe6400; }
  body.company_network .office_content__link {
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (max-width: 768px) {
      body.company_network .office_content__link {
        bottom: 20px;
        top: auto; } }
    body.company_network .office_content__link a {
      color: #444444;
      text-decoration: none;
      font-size: 1.3rem; }
      body.company_network .office_content__link a::after {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-size: 1.1rem;
        margin-left: 5px; }

/* Enterprise */
@media screen and (max-width: 768px) {
  body[class*=enterprise] .page_body {
    padding-top: 38px; } }

@media screen and (max-width: 768px) {
  body[class*=enterprise] .page_desc {
    text-align: left; } }

body[class*=enterprise] .enterprise_nav {
  border-top: 1px solid #e0e0e0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-flex-direction: row;
  flex-direction: row; }
  @media screen and (min-width: 769px) {
    body[class*=enterprise] .enterprise_nav {
      padding-top: 60px;
      margin-top: 60px; }
      body[class*=enterprise] .enterprise_nav > * {
        margin-left: 20px; }
        body[class*=enterprise] .enterprise_nav > *:nth-child(3n + 1) {
          margin-left: 0; } }
  @media screen and (max-width: 768px) {
    body[class*=enterprise] .enterprise_nav {
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding-top: 20px;
      margin-top: 40px; }
      body[class*=enterprise] .enterprise_nav > * {
        width: calc(50% - 5px);
        margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    body[class*=enterprise] .enterprise_nav__body {
      margin-top: 5px; } }
  body[class*=enterprise] .enterprise_nav__caps {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center; }
    @media screen and (max-width: 768px) {
      body[class*=enterprise] .enterprise_nav__caps {
        font-size: 1.6rem; } }

@media screen and (min-width: 769px) {
  body[class*=enterprise] .enterprise_column__rhythm > *:nth-child(even) > .__img {
    -webkit-order: -1;
    order: -1;
    margin-left: 0;
    margin-right: 40px; } }

@media screen and (min-width: 769px) {
  body[class*=enterprise] .enterprise_column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-flex-direction: row;
    flex-direction: row; }
    body[class*=enterprise] .enterprise_column > .__entity {
      -webkit-flex: 1;
      flex: 1; }
    body[class*=enterprise] .enterprise_column > .__img {
      margin-left: 40px; }
    body[class*=enterprise] .enterprise_column > .__more {
      width: 100%; }
      body[class*=enterprise] .enterprise_column > .__more a {
        max-width: 248px;
        margin-left: 0; } }

@media screen and (max-width: 768px) {
  body[class*=enterprise] .enterprise_column > .__img {
    margin-top: 30px; }
    body[class*=enterprise] .enterprise_column > .__img img {
      width: 100%; } }

body[class*=enterprise] .enterprise_column__heading {
  font-size: 2.8rem;
  line-height: 1.45; }
  @media screen and (max-width: 768px) {
    body[class*=enterprise] .enterprise_column__heading {
      font-size: 1.8rem; } }

body[class*=enterprise] .enterprise_column__body {
  margin-top: 30px; }

body[class*=enterprise] .enterprise_column__text {
  font-size: 1.6rem; }

body.enterprise_logistic .logistic_figure {
  text-align: center;
  margin-top: 60px; }

body.enterprise_logistic .logistic_gallery {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  align-content: stretch;
  -webkit-flex-direction: row;
  flex-direction: row;
  margin-left: -30px;
  margin-top: -40px; }
  body.enterprise_logistic .logistic_gallery > * {
    width: calc(1 / 5 * 100% - 30px);
    margin-left: 30px;
    margin-top: 40px; }

/* Overseas */
body.overseas .table_basic tbody td {
  text-align: left !important; }

body.overseas .office_content {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2; }
  body.overseas .office_content__list {
    float: left;
    width: 570px; }
    @media screen and (max-width: 768px) {
      body.overseas .office_content__list {
        float: none;
        width: auto; } }
    body.overseas .office_content__list .office_content__title {
      margin-top: 15px;
      color: #444;
      line-height: 1.8;
      padding-left: 1.1em;
      text-indent: -1.1em; }
      body.overseas .office_content__list .office_content__title:first-child {
        margin: 0; }
      body.overseas .office_content__list .office_content__title::before {
        content: '・';
        color: #fe6400; }
    body.overseas .office_content__list .office_content__desc {
      color: #444;
      padding-left: 1em;
      line-height: 1.8; }
      body.overseas .office_content__list .office_content__desc .tags {
        background-color: #fe6400;
        border-radius: 3px;
        color: #fff;
        display: inline-block;
        font-size: 1.1rem;
        margin: 5px 7px 5px 0;
        padding: 0 4px 0 5px; }
      body.overseas .office_content__list .office_content__desc a {
        color: #fe6400; }
  body.overseas .office_content__img {
    float: right;
    width: 275px; }
    @media screen and (max-width: 768px) {
      body.overseas .office_content__img {
        float: none;
        margin: 30px auto 0; } }

/* Recruit */
body.recruit_index .index_benefit {
  margin-top: 80px; }
  @media screen and (max-width: 768px) {
    body.recruit_index .index_benefit {
      margin-top: 60px; } }

body.recruit_index .recruit_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }
  @media screen and (max-width: 768px) {
    body.recruit_index .recruit_box {
      display: block;
      margin-bottom: 20px; } }
  body.recruit_index .recruit_box__content {
    position: relative; }
    @media screen and (max-width: 768px) {
      body.recruit_index .recruit_box__content {
        margin-top: 20px;
        text-align: center; } }
  body.recruit_index .recruit_box__lead {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    padding: 12px 20px;
    font-weight: bold;
    font-size: 1.8rem;
    white-space: nowrap;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 768px) {
      body.recruit_index .recruit_box__lead {
        font-size: 1.6rem; } }

body.recruit_index .recruit_banner {
  width: 960px;
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 768px) {
    body.recruit_index .recruit_banner {
      width: auto;
      margin-top: 40px;
      margin-bottom: 40px; } }
  @media screen and (max-width: 768px) {
    body.recruit_index .recruit_banner__item {
      max-width: 510px;
      margin-left: auto;
      margin-right: auto; } }
  body.recruit_index .recruit_banner a {
    transition: opacity 0.4s ease; }
    body.recruit_index .recruit_banner a:hover {
      opacity: 0.8;
      text-decoration: none; }
    body.recruit_index .recruit_banner a.current:hover {
      opacity: 1; }

body.recruit_index .recruit_news {
  margin-top: 80px;
  margin-bottom: 80px; }
  @media screen and (max-width: 768px) {
    body.recruit_index .recruit_news {
      margin-top: 40px;
      margin-bottom: 40px; } }

body.recruit_requirements table td dl dt {
  display: inline-block;
  width: 115px; }
  body.en.recruit_requirements table td dl dt {
    width: 85px; }
  @media screen and (max-width: 768px) {
    body.recruit_requirements table td dl dt {
      width: 100px; }
      body.en.recruit_requirements table td dl dt {
        width: 70px; } }

body.recruit_requirements table td dl dd {
  display: inline-block;
  width: 170px; }
  body.en.recruit_requirements table td dl dd {
    width: auto; }

body.recruit_requirements .requirements_flow {
  display: flex;
  justify-content: space-between; }
  body.recruit_requirements .requirements_flow__item {
    position: relative;
    width: 85px;
    height: 280px;
    padding-top: 10px;
    border: 1px #e2e2e2 solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; }
    body.recruit_requirements .requirements_flow__item p {
      display: table;
      height: 100%;
      text-align: center; }
    @media screen and (max-width: 768px) {
      body.recruit_requirements .requirements_flow__item {
        width: 15%;
        margin: 0 3px;
        padding: 10px 0 20px; } }
    body.recruit_requirements .requirements_flow__item:last-child {
      border: none;
      background-color: #fe6400; }
      body.recruit_requirements .requirements_flow__item:last-child::after {
        content: none; }
      body.recruit_requirements .requirements_flow__item:last-child .requirements_flow__num,
      body.recruit_requirements .requirements_flow__item:last-child .requirements_flow__label {
        color: #fff; }
    body.recruit_requirements .requirements_flow__item::after {
      content: '';
      position: absolute;
      left: 50%;
      top: -3px;
      transform: translateX(-50%);
      display: block;
      width: 70px;
      height: 5px;
      background-color: #fe6400; }
      @media screen and (max-width: 768px) {
        body.recruit_requirements .requirements_flow__item::after {
          width: 70%;
          height: 3px;
          top: -2px; } }
  body.recruit_requirements .requirements_flow__num {
    margin-bottom: 10px;
    font-size: 2.4rem;
    color: #fe6400;
    font-weight: bold;
    font-family: "Ropa Sans", sans-serif;
    letter-spacing: .1em; }
    @media screen and (max-width: 768px) {
      body.recruit_requirements .requirements_flow__num {
        font-size: 1.2rem; } }
  body.recruit_requirements .requirements_flow__label {
    writing-mode: vertical-rl;
    font-size: 2rem;
    line-height: 1.2; }
    @media screen and (max-width: 768px) {
      body.recruit_requirements .requirements_flow__label {
        font-size: 1rem; } }
  body.recruit_requirements .requirements_flow__notes {
    margin-top: 20px; }
    @media screen and (max-width: 768px) {
      body.recruit_requirements .requirements_flow__notes {
        font-size: .6rem; }
        body.en.recruit_requirements .requirements_flow__notes {
          writing-mode: vertical-rl; } }

body.recruit_requirements .requirements_arrow {
  width: 12px;
  background-image: url("/assets/img/recruit/requirements/flow_arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 95%; }

body.recruit_welfare .welfare_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -50px; }
  @media screen and (max-width: 768px) {
    body.recruit_welfare .welfare_content {
      margin-top: -35px; } }
  body.recruit_welfare .welfare_content::after {
    content: "";
    display: block;
    width: 30%; }
  body.recruit_welfare .welfare_content__item {
    width: 30%;
    margin-top: 50px; }
    @media screen and (max-width: 768px) {
      body.recruit_welfare .welfare_content__item {
        width: auto;
        margin-top: 35px; } }
    body.recruit_welfare .welfare_content__item__thumb {
      margin-bottom: 25px;
      text-align: center; }
    body.recruit_welfare .welfare_content__item__title {
      font-weight: bold;
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        body.recruit_welfare .welfare_content__item__title {
          margin-bottom: 20px; } }

body.recruit_about .about_content__lead {
  margin-bottom: 35px;
  text-align: center;
  font-size: 2rem; }
  @media screen and (max-width: 768px) {
    body.recruit_about .about_content__lead {
      font-size: 1.4rem;
      margin-bottom: 15px;
      font-size: 1.8rem; } }
  body.recruit_about .about_content__lead span {
    display: inline-block;
    font-size: 4.8rem;
    color: #fe6400;
    padding-top: 15px;
    font-weight: bold; }
    @media screen and (max-width: 768px) {
      body.recruit_about .about_content__lead span {
        padding-top: 10px;
        font-size: 2.7rem; } }
  body.recruit_about .about_content__lead.-sm span {
    font-size: 3.2rem; }
    @media screen and (max-width: 768px) {
      body.recruit_about .about_content__lead.-sm span {
        font-size: 2rem; } }

body.recruit_about .about_content__desc {
  margin-top: 30px;
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    body.recruit_about .about_content__desc {
      font-size: 1.6rem;
      margin-top: 15px;
      text-align: left; } }

body.recruit_about .about_feature {
  margin-top: 20px; }
  @media screen and (max-width: 768px) {
    body.recruit_about .about_feature {
      margin-top: 10px; } }
  body.recruit_about .about_feature__thumb {
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      body.recruit_about .about_feature__thumb {
        margin-bottom: 10px; } }
  body.recruit_about .about_feature__desc {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center; }

/* IR */
body.ir .ir_link__parent a,
body.ir .ir_link__parent span {
  position: relative;
  display: flex;
  font-size: 1.6rem;
  color: #333;
  background: #e9e9e9;
  padding: 20px;
  text-decoration: none; }
  @media screen and (max-width: 768px) {
    body.en.ir .ir_link__parent a {
      padding: 20px 10px; } }

body.ir .ir_link__parent a {
  transition: opacity 0.4s ease; }
  body.ir .ir_link__parent a:hover {
    opacity: 0.8;
    text-decoration: none; }
  body.ir .ir_link__parent a.current:hover {
    opacity: 1; }
  body.ir .ir_link__parent a::after {
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    color: #fe6400;
    font-size: 2rem;
    transform: translateY(-50%); }
    @media screen and (max-width: 768px) {
      body.en.ir .ir_link__parent a::after {
        right: 10px; } }

body.ir .ir_link__list {
  margin-bottom: 20px; }
  body.ir .ir_link__list__item {
    border-bottom: 1px dotted #999999; }
    body.ir .ir_link__list__item a {
      position: relative;
      display: flex;
      padding: 20px;
      text-decoration: none;
      transition: opacity 0.4s ease; }
      body.ir .ir_link__list__item a:hover {
        opacity: 0.8;
        text-decoration: none; }
      body.ir .ir_link__list__item a.current:hover {
        opacity: 1; }
      @media screen and (max-width: 768px) {
        body.en.ir .ir_link__list__item a {
          padding: 20px 10px; } }
      body.ir .ir_link__list__item a::after {
        font-family: 'icon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-indent: 0;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        position: absolute;
        right: 20px;
        top: 50%;
        color: #fe6400;
        font-size: 2rem;
        transform: translateY(-50%); }
        @media screen and (max-width: 768px) {
          body.en.ir .ir_link__list__item a::after {
            right: 10px; } }

body.ir_highlight .ir_graph__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

body.ir_highlight .ir_graph__content {
  margin-bottom: 50px;
  width: 410px; }
  @media screen and (max-width: 768px) {
    body.ir_highlight .ir_graph__content {
      width: auto;
      margin-bottom: 20px; } }

body.ir_highlight .ir_graph__title {
  color: #fe6400;
  font-size: 2rem;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    body.ir_highlight .ir_graph__title {
      font-size: 1.6rem; } }

body.ir_highlight .ir_graph__thumb img {
  max-width: 100%;
  height: auto; }

body.ir_highlight .ir_graph__desc {
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    body.ir_highlight .ir_graph__desc {
      margin-bottom: 20px; } }

body.ir_highlight .ir_graph__notes {
  margin-top: 10px;
  text-indent: -1.8em;
  padding-left: 1.8em; }
  @media screen and (max-width: 768px) {
    body.ir_highlight .ir_graph__notes {
      font-size: 1.2rem; } }

body.ir_highlight .table_ir {
  position: relative;
  margin-bottom: 40px; }
  @media screen and (max-width: 768px) {
    body.ir_highlight .table_ir {
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    body.ir_highlight .table_ir__inner {
      overflow-x: auto;
      padding-bottom: 20px;
      -webkit-overflow-scrolling: touch; } }
  body.ir_highlight .table_ir__content {
    width: 100%;
    border-collapse: separate; }
    body.ir_highlight .table_ir__content td,
    body.ir_highlight .table_ir__content th {
      position: relative;
      padding: 15px;
      font-size: 1.4rem;
      color: #333;
      font-weight: normal;
      line-height: 1.6; }
      @media screen and (max-width: 768px) {
        body.ir_highlight .table_ir__content td,
        body.ir_highlight .table_ir__content th {
          white-space: nowrap; } }
    body.ir_highlight .table_ir__content thead th {
      position: relative;
      background-color: #e2e2e2;
      border-right: 1px #fff solid;
      border-bottom: 1px #fff solid; }
      @media screen and (max-width: 768px) {
        body.ir_highlight .table_ir__content thead th {
          padding: 10px; } }
      body.ir_highlight .table_ir__content thead th:first-child {
        width: 180px; }
        @media screen and (max-width: 768px) {
          body.ir_highlight .table_ir__content thead th:first-child {
            width: 164px; } }
      body.ir_highlight .table_ir__content thead th:last-child {
        background-color: #ffefe5;
        color: #fe6400;
        font-weight: bold; }
        body.ir_highlight .table_ir__content thead th:last-child span {
          color: #fe6400; }
        body.ir_highlight .table_ir__content thead th:last-child::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 4px;
          background-color: #fe6400; }
    body.ir_highlight .table_ir__content tbody tr:last-child th,
    body.ir_highlight .table_ir__content tbody tr:last-child td {
      border-bottom: 1px #e2e2e2 solid; }
    body.ir_highlight .table_ir__content tbody th,
    body.ir_highlight .table_ir__content tbody td {
      padding: 15px;
      border-top: 1px #e2e2e2 solid;
      vertical-align: middle; }
      @media screen and (max-width: 768px) {
        body.ir_highlight .table_ir__content tbody th,
        body.ir_highlight .table_ir__content tbody td {
          padding: 10px; } }
    body.ir_highlight .table_ir__content tbody th {
      text-align: left;
      background-color: #efefef; }
      @media screen and (max-width: 768px) {
        body.ir_highlight .table_ir__content tbody th {
          font-size: 1.2rem; } }
    body.ir_highlight .table_ir__content tbody td {
      text-align: right;
      border-right: 1px #e2e2e2 solid;
      font-size: 1.4rem; }
      @media screen and (max-width: 768px) {
        body.ir_highlight .table_ir__content tbody td {
          font-size: 1.2rem; } }
      body.ir_highlight .table_ir__content tbody td:last-child {
        border-right: none;
        background-color: #ffefe5;
        color: #fe6400;
        font-weight: bold; }
      body.ir_highlight .table_ir__content tbody td.-lg {
        font-size: 1.6rem; }
        @media screen and (max-width: 768px) {
          body.ir_highlight .table_ir__content tbody td.-lg {
            font-size: 1.4rem; } }
    @media screen and (max-width: 768px) {
      body.ir_highlight .table_ir__content.-original tbody th {
        opacity: 0; } }
    body.ir_highlight .table_ir__content.-fixed {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none; }
      @media screen and (min-width: 769px) {
        body.ir_highlight .table_ir__content.-fixed {
          display: none; } }
      body.ir_highlight .table_ir__content.-fixed tbody td {
        visibility: hidden; }
      body.ir_highlight .table_ir__content.-fixed thead th {
        visibility: hidden; }
        body.ir_highlight .table_ir__content.-fixed thead th:first-child {
          visibility: visible; }

body.company_investor .investor_copy__desc {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 20px; }
  @media screen and (max-width: 768px) {
    body.company_investor .investor_copy__desc {
      font-size: 1.4rem;
      margin-bottom: 10px;
      text-align: left; } }

body.company_investor .investor_copy__lead {
  font-size: 3.2rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.4; }
  @media screen and (max-width: 768px) {
    body.company_investor .investor_copy__lead {
      text-align: left;
      font-size: 2rem; } }

body.company_investor .investor_copy__notes {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    body.company_investor .investor_copy__notes {
      margin-bottom: 25px;
      text-align: left;
      font-size: 1.2rem; } }

body.ir_chukei .chukei_intro {
  margin-bottom: 80px; }
  @media screen and (max-width: 768px) {
    body.ir_chukei .chukei_intro {
      margin-bottom: 40px; } }
  body.ir_chukei .chukei_intro__lead {
    text-align: center; }
    @media screen and (max-width: 768px) {
      body.ir_chukei .chukei_intro__lead {
        margin: 0 10px; } }
  body.ir_chukei .chukei_intro__list {
    margin-top: 40px;
    border-bottom: 1px solid #e2e2e2; }
    @media screen and (max-width: 768px) {
      body.ir_chukei .chukei_intro__list {
        margin-top: 20px; } }
    body.ir_chukei .chukei_intro__list__item {
      line-height: 1.6;
      font-size: 2rem;
      font-weight: bold;
      padding: 30px 60px;
      border-top: 1px solid #e2e2e2; }
      @media screen and (max-width: 768px) {
        body.ir_chukei .chukei_intro__list__item {
          padding: 20px;
          font-size: 1.5rem; } }

body.ir_chukei .table_ir {
  position: relative; }
  @media screen and (max-width: 768px) {
    body.ir_chukei .table_ir__inner {
      overflow-x: auto;
      padding-bottom: 20px;
      -webkit-overflow-scrolling: touch; } }
  body.ir_chukei .table_ir__content {
    width: 100%;
    border-collapse: separate; }
    body.ir_chukei .table_ir__content td,
    body.ir_chukei .table_ir__content th {
      position: relative;
      padding: 15px;
      font-size: 1.4rem;
      color: #333;
      font-weight: normal;
      line-height: 1.6; }
      @media screen and (max-width: 768px) {
        body.ir_chukei .table_ir__content td,
        body.ir_chukei .table_ir__content th {
          font-size: 1.2rem;
          white-space: nowrap; } }
    body.ir_chukei .table_ir__content thead th {
      width: 25%;
      position: relative;
      background-color: #ea5504;
      border-right: 1px #fff solid;
      border-bottom: 1px #fff solid;
      color: #FFF;
      font-weight: bold; }
      @media screen and (max-width: 768px) {
        body.ir_chukei .table_ir__content thead th {
          padding: 10px; } }
      body.ir_chukei .table_ir__content thead th:first-child {
        width: 180px; }
        @media screen and (max-width: 768px) {
          body.ir_chukei .table_ir__content thead th:first-child {
            width: 164px; } }
      body.ir_chukei .table_ir__content thead th.-orange {
        background-color: #ffefe5;
        color: #fe6400;
        font-weight: bold; }
        body.ir_chukei .table_ir__content thead th.-orange span {
          color: #fe6400; }
        body.ir_chukei .table_ir__content thead th.-orange::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 4px;
          background-color: #fe6400; }
    body.ir_chukei .table_ir__content tbody tr:last-child th,
    body.ir_chukei .table_ir__content tbody tr:last-child td {
      border-bottom: 1px #e2e2e2 solid; }
    body.ir_chukei .table_ir__content tbody tr:nth-child(odd) {
      background: #f7d1cb; }
    body.ir_chukei .table_ir__content tbody tr:nth-child(even) {
      background: #fbe9e7; }
    body.ir_chukei .table_ir__content tbody th,
    body.ir_chukei .table_ir__content tbody td {
      padding: 15px;
      border-top: 1px #FFF solid;
      border-right: 1px #FFF solid;
      vertical-align: middle; }
      @media screen and (max-width: 768px) {
        body.ir_chukei .table_ir__content tbody th,
        body.ir_chukei .table_ir__content tbody td {
          padding: 10px; } }
    body.ir_chukei .table_ir__content tbody th {
      text-align: left;
      /*background-color: #efefef;*/ }
      @media screen and (max-width: 768px) {
        body.ir_chukei .table_ir__content tbody th {
          font-size: 1.2rem; } }
    body.ir_chukei .table_ir__content tbody td {
      font-size: 1.6rem;
      text-align: right; }
      @media screen and (max-width: 768px) {
        body.ir_chukei .table_ir__content tbody td {
          font-size: 1.2rem; } }
      body.ir_chukei .table_ir__content tbody td.-orange {
        background-color: #ffefe5;
        color: #fe6400;
        font-weight: bold; }
      body.ir_chukei .table_ir__content tbody td:last-child {
        border-right: none; }

/* Overseas */
body.socialenvironment_philanthropy .cms__body .thumb img {
  width: 100%; }

body.socialenvironment_sdgs .sdgs_theme {
  padding: 20px;
  border: 1px solid #cccccc; }
  body.socialenvironment_sdgs .sdgs_theme h3 {
    font-size: 1.6em;
    text-align: center;
    border-bottom: 2px solid #fe6400;
    padding-bottom: 10px;
    margin-bottom: 15px; }

/* Sitemap */
body.sitemap .sitemap_content {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    body.sitemap .sitemap_content {
      display: block; } }
  body.sitemap .sitemap_content__list {
    width: 27%; }
    @media screen and (max-width: 768px) {
      body.sitemap .sitemap_content__list {
        width: auto; } }
    body.sitemap .sitemap_content__list__item {
      margin-bottom: 30px; }
      body.en .sitemap_content__list:last-child .sitemap_content__list__item:last-child {
        margin-left: 15px; }
      body.sitemap .sitemap_content__list__item > a {
        color: #fe6400;
        text-decoration: none;
        font-size: 1.6rem;
        font-weight: bold;
        transition: opacity 0.4s ease; }
        body.sitemap .sitemap_content__list__item > a:hover {
          opacity: 0.7;
          text-decoration: none; }
        body.sitemap .sitemap_content__list__item > a.current:hover {
          opacity: 1; }
        body.en .sitemap_content__list:last-child .sitemap_content__list__item:last-child > a {
          margin-left: -15px; }
        body.sitemap .sitemap_content__list__item > a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          margin-right: 5px;
          font-weight: normal; }
  body.sitemap .sitemap_content__child_list {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px dotted #b2b2b2; }
    body.sitemap .sitemap_content__child_list__item {
      margin-top: 10px;
      margin-bottom: 10px; }
      body.en .sitemap_content__child_list__item {
        margin-left: 15px; }
      body.sitemap .sitemap_content__child_list__item > a {
        color: #333;
        text-decoration: none;
        transition: opacity 0.4s ease; }
        body.sitemap .sitemap_content__child_list__item > a:hover {
          opacity: 0.7;
          text-decoration: none; }
        body.sitemap .sitemap_content__child_list__item > a.current:hover {
          opacity: 1; }
        body.en .sitemap_content__child_list__item > a {
          margin-left: -15px; }
        body.sitemap .sitemap_content__child_list__item > a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1rem;
          margin-right: 5px;
          font-weight: normal;
          color: #fe6400; }
  body.sitemap .sitemap_content__grandchild_list {
    padding-left: 30px; }
    body.en .sitemap_content__grandchild_list {
      padding-left: 16px; }
    body.sitemap .sitemap_content__grandchild_list__item {
      margin-top: 10px;
      margin-bottom: 10px; }
      body.sitemap .sitemap_content__grandchild_list__item > a {
        color: #333;
        text-decoration: none;
        transition: opacity 0.4s ease; }
        body.sitemap .sitemap_content__grandchild_list__item > a:hover {
          opacity: 0.7;
          text-decoration: none; }
        body.sitemap .sitemap_content__grandchild_list__item > a.current:hover {
          opacity: 1; }

/* Search */
body.search input:focus,
body.search button:focus,
body.search select:focus {
  outline: none; }

body.search .mf_finder_searchBox_misc {
  display: none; }

body.search .mf_finder_searchBox_suggest_items {
  display: none; }

body.search .mf_finder_searchBox_doctype::after {
  top: 20px;
  right: 14px; }

body.search .mf_finder_searchBox_doctype_select {
  height: 100%;
  font-size: 1.6rem;
  padding: 10px 20px;
  background-color: #efefef;
  border: none;
  border-right: 1px solid #ccc;
  border-radius: 0; }

body.search .mf_finder_organic_doc_body span {
  font-size: 1.2rem; }

body.search .mf_finder_searchBox_selects {
  display: none; }

body.search .mf_finder_organic_doc_url span {
  word-break: break-word;
  line-height: 1.6; }

body.search .mf_finder_searchBox_submit {
  padding: 10px 30px;
  cursor: pointer;
  background-color: #fe6400;
  color: #fff;
  border-radius: 0; }
  body.search .mf_finder_searchBox_submit:hover {
    background-color: #fe6400; }
  body.search .mf_finder_searchBox_submit span {
    display: none; }

body.search .mf_finder_organic_header {
  flex-direction: row-reverse !important; }

body.search .mf_finder_searchBox {
  margin: 0 0 50px !important; }
  @media screen and (max-width: 768px) {
    body.search .mf_finder_searchBox {
      margin: 0 0 20px !important; } }

body.search .mf_finder_organic_header_wrapper {
  margin: 0 !important; }

body.search .mf_finder_query,
body.search .mf_finder_organic_range_from,
body.search .mf_finder_organic_range_to,
body.search .mf_finder_organic_total {
  color: #333 !important;
  font-size: 1.4rem !important; }
  body.search .mf_finder_query::before, body.search .mf_finder_query::after,
  body.search .mf_finder_organic_range_from::before,
  body.search .mf_finder_organic_range_from::after,
  body.search .mf_finder_organic_range_to::before,
  body.search .mf_finder_organic_range_to::after,
  body.search .mf_finder_organic_total::before,
  body.search .mf_finder_organic_total::after {
    font-size: 1.4rem !important;
    color: #333 !important; }

body.search .mf_finder_organic_pager {
  justify-content: center; }

body.search .mf_finder_organic_pager_wrapper {
  margin-top: 60px; }

body.search .mf_finder_logo {
  text-align: right; }

body.search .mf_finder_pager_items {
  display: flex !important;
  margin-bottom: 20px; }
  body.search .mf_finder_pager_items li {
    margin: 0;
    margin-left: -1px; }
    body.search .mf_finder_pager_items li:hover {
      z-index: 1; }
    body.search .mf_finder_pager_items li a {
      display: flex;
      transition: color .3s ease, border-color .3s ease;
      border: 1px solid #333;
      border-radius: 0; }
      body.search .mf_finder_pager_items li a:hover {
        color: #fe6400;
        border-color: #fe6400;
        z-index: 1; }
    body.search .mf_finder_pager_items li span {
      display: flex;
      justify-content: center;
      padding: 8px 10px 8px 10px !important;
      width: 32px;
      box-sizing: border-box;
      vertical-align: bottom; }

body.search .mf_finder_pager_item_before_pages a,
body.search .mf_finder_pager_item_after_pages a {
  background-color: #fff;
  color: #333; }

body.search .mf_finder_pager_item_first a,
body.search .mf_finder_pager_item_prev a,
body.search .mf_finder_pager_item_next a,
body.search .mf_finder_pager_item_last a {
  background-color: transparent;
  color: #333; }

body.search .mf_finder_pager_item_current {
  color: #fe6400;
  border: 1px solid #fe6400;
  z-index: 1; }

body.search .mf_finder_searchBox_query_input {
  padding: 20px;
  font-size: 1.6rem;
  background-color: #efefef;
  border: none; }

body.search .mf_finder_organic_header {
  font-size: 1.4rem !important; }

body.search .mf_finder_organic_doc_title {
  color: #fe6400;
  font-size: 1.6rem !important;
  border: none !important; }

body.search .mf_finder_organic_doc_title_wrapper {
  color: #fe6400; }

body.search .mf_finder_organic_docs {
  margin-top: 20px; }

body.search .mf_finder_organic_doc {
  padding: 20px 0;
  border: none; }
  body.search .mf_finder_organic_doc:first-child {
    border-top: 1px solid #e2e2e2; }

body.search .mf_finder_organic_doc_contents_wrapper {
  margin-left: 20px; }

body.search .mf_finder_link,
body.search .mf_finder_organic_doc_url_wrapper {
  transition: opacity .2s ease; }
  body.search .mf_finder_link:hover,
  body.search .mf_finder_organic_doc_url_wrapper:hover {
    text-decoration: none !important;
    opacity: .8; }
    body.search .mf_finder_link:hover .mf_finder_organic_doc_title,
    body.search .mf_finder_link:hover .mf_finder_organic_doc_url,
    body.search .mf_finder_organic_doc_url_wrapper:hover .mf_finder_organic_doc_title,
    body.search .mf_finder_organic_doc_url_wrapper:hover .mf_finder_organic_doc_url {
      border-bottom: none;
      text-decoration: none !important; }
      body.search .mf_finder_link:hover .mf_finder_organic_doc_title span,
      body.search .mf_finder_link:hover .mf_finder_organic_doc_url span,
      body.search .mf_finder_organic_doc_url_wrapper:hover .mf_finder_organic_doc_title span,
      body.search .mf_finder_organic_doc_url_wrapper:hover .mf_finder_organic_doc_url span {
        border-bottom: none;
        text-decoration: none !important; }

body.search .mf_finder_mark {
  color: #333; }

body.search .mf_finder_organic_doc_zoom {
  display: none !important; }

/* Utility */
/*for modern browser*/
.clear_fix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  font-size: 0;
  line-height: 0;
  visibility: hidden; }

/*for IE 5.5-7*/
.clear_fix {
  zoom: 1; }

.clear_both {
  clear: both;
  height: 1px;
  font-size: 1px;
  text-align: center;
  line-height: 0; }

.clear {
  clear: both; }

.align_l {
  text-align: left !important; }

.align_r {
  text-align: right !important; }

.align_c {
  text-align: center !important; }

.valign_m {
  vertical-align: middle; }

.valign_t {
  vertical-align: top; }

.valign_b {
  vertical-align: bottom; }

.float_r {
  float: right;
  display: inline; }

.float_l {
  float: left;
  display: inline; }

/*

font

*/
.smaller {
  font-size: 85% !important; }

.larger {
  font-size: larger !important; }

.bold {
  font-weight: bold !important; }

.weight_normal {
  font-weight: normal !important; }

.style_normal {
  font-style: normal !important; }

/*

line height

*/
.lh_l {
  line-height: 1.75 !important; }

.lh_m {
  line-height: 1.5 !important; }

.lh_s {
  line-height: 1.3 !important; }

.lh_xs {
  line-height: 1 !important; }

/*

Color

*/
/*

display用クラス

PC向け
.none
.block
.inline_block
.inline
.table
.table_cell

タブレット向け
.tablet_none
.tablet_block
.tablet_inline_block
.tablet_inline
.tablet_table
.tablet_table_cell

スマホ向け
.sp_none
.sp_block
.sp_inline_block
.sp_inline
.sp_table
.sp_table_cell

*/
.none {
  display: none !important; }

.block {
  display: block !important; }

.inline_block {
  display: inline-block  !important; }

.inline {
  display: inline !important; }

.table {
  display: table !important; }

.table_cell {
  display: table-cell !important; }

.show_touch {
  display: none; }

html.is_touch .show_touch {
  display: block; }

@media screen and (max-width: 1199px) {
  .liquid_none {
    display: none !important; } }

@media screen and (max-width: 1140px) {
  .tablet_none {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .sp_none {
    display: none !important; } }

@media screen and (max-width: 426px) {
  .small_none {
    display: none !important; } }

@media screen and (max-width: 1199px) {
  .liquid_block {
    display: block !important; }
  .liquid_inline_block {
    display: inline-block !important; }
  .liquid_inline {
    display: inline !important; }
  .liquid_table {
    display: table !important; }
  .liquid_cell {
    display: table-cell !important; } }

@media screen and (max-width: 1140px) {
  .tablet_block {
    display: block !important; }
  .tablet_inline_block {
    display: inline-block !important; }
  .tablet_inline {
    display: inline !important; }
  .tablet_table {
    display: table !important; }
  .tablet_cell {
    display: table-cell !important; } }

@media screen and (max-width: 768px) {
  .sp_block {
    display: block !important; }
  .sp_inline_block {
    display: inline-block !important; }
  .sp_inline {
    display: inline !important; }
  .sp_table {
    display: table !important; }
  .sp_cell {
    display: table-cell !important; } }

@media screen and (max-width: 426px) {
  .small_block {
    display: block !important; }
  .small_inline_block {
    display: inline-block !important; }
  .small_inline {
    display: inline !important; }
  .small_table {
    display: table !important; }
  .small_cell {
    display: table-cell !important; } }

.hidden_pc {
  display: none; }
  @media screen and (max-width: 768px) {
    .hidden_pc {
      display: block; } }

.hidden_sp {
  display: none; }
  @media screen and (min-width: 769px) {
    .hidden_sp {
      display: inline-block; } }

/*

fluid image

*/
.fluid_img {
  max-width: 100%;
  height: auto;
  width: auto\9;
  /* IE8 */ }

/*

justify layout

```html
<ul class="just_layout">
	<li class ="just_layout__item"></li>
	<li class ="just_layout__item"></li>
</ul>
```

*/
.just_layout {
  text-align: justify;
  text-justify: distribute-all-lines;
  zoom: 1;
  display: block;
  line-height: 0; }
  .just_layout:after {
    line-height: 0;
    visibility: hidden;
    content: "";
    display: inline-block;
    width: 100%; }
  .just_layout__item {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    line-height: normal; }

* html .just_layout .just_layout__item {
  display: inline;
  zoom: 1; }

*:first-child + html .just_layout .just_layout__item {
  display: inline;
  zoom: 1; }

/*

margin and padding

5刻みで上下左右のマージンとパディングのクラスを定義
$start_value x 5の値から、$end_value x 5 までを設定

```class
.mb-5
.mt-5
.mr-5
.ml-5

.mb0
.mt0
.mr0
.ml0

.mb5
.mt5
.mr5
.ml5

.mb10
.mt10
.mr10
.ml10

.pb-5
.pt-5
.pr-5
.pl-5

.pb0
.pt0
.pr0
.pl0

.pb5
.pt5
.pr5
.pl5

.pb10
.pt10
.pr10
.pl10
```

レスポンシブ用に$breakpointsのキーを接頭辞にしたクラスも書き出します。
```class
.tablet_mb10
.sp_mb10
```

*/
.mb-50 {
  margin-bottom: -50px !important; }

.mt-50 {
  margin-top: -50px !important; }

.mr-50 {
  margin-right: -50px !important; }

.ml-50 {
  margin-left: -50px !important; }

.mb-45 {
  margin-bottom: -45px !important; }

.mt-45 {
  margin-top: -45px !important; }

.mr-45 {
  margin-right: -45px !important; }

.ml-45 {
  margin-left: -45px !important; }

.mb-40 {
  margin-bottom: -40px !important; }

.mt-40 {
  margin-top: -40px !important; }

.mr-40 {
  margin-right: -40px !important; }

.ml-40 {
  margin-left: -40px !important; }

.mb-35 {
  margin-bottom: -35px !important; }

.mt-35 {
  margin-top: -35px !important; }

.mr-35 {
  margin-right: -35px !important; }

.ml-35 {
  margin-left: -35px !important; }

.mb-30 {
  margin-bottom: -30px !important; }

.mt-30 {
  margin-top: -30px !important; }

.mr-30 {
  margin-right: -30px !important; }

.ml-30 {
  margin-left: -30px !important; }

.mb-25 {
  margin-bottom: -25px !important; }

.mt-25 {
  margin-top: -25px !important; }

.mr-25 {
  margin-right: -25px !important; }

.ml-25 {
  margin-left: -25px !important; }

.mb-20 {
  margin-bottom: -20px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mr-20 {
  margin-right: -20px !important; }

.ml-20 {
  margin-left: -20px !important; }

.mb-15 {
  margin-bottom: -15px !important; }

.mt-15 {
  margin-top: -15px !important; }

.mr-15 {
  margin-right: -15px !important; }

.ml-15 {
  margin-left: -15px !important; }

.mb-10 {
  margin-bottom: -10px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mr-10 {
  margin-right: -10px !important; }

.ml-10 {
  margin-left: -10px !important; }

.mb-5 {
  margin-bottom: -5px !important; }

.mt-5 {
  margin-top: -5px !important; }

.mr-5 {
  margin-right: -5px !important; }

.ml-5 {
  margin-left: -5px !important; }

.mb0 {
  margin-bottom: 0px !important; }

.mt0 {
  margin-top: 0px !important; }

.mr0 {
  margin-right: 0px !important; }

.ml0 {
  margin-left: 0px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.ml5 {
  margin-left: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.ml10 {
  margin-left: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mt15 {
  margin-top: 15px !important; }

.mr15 {
  margin-right: 15px !important; }

.ml15 {
  margin-left: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.ml20 {
  margin-left: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.ml25 {
  margin-left: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mt30 {
  margin-top: 30px !important; }

.mr30 {
  margin-right: 30px !important; }

.ml30 {
  margin-left: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mt35 {
  margin-top: 35px !important; }

.mr35 {
  margin-right: 35px !important; }

.ml35 {
  margin-left: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mt40 {
  margin-top: 40px !important; }

.mr40 {
  margin-right: 40px !important; }

.ml40 {
  margin-left: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mt45 {
  margin-top: 45px !important; }

.mr45 {
  margin-right: 45px !important; }

.ml45 {
  margin-left: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mt50 {
  margin-top: 50px !important; }

.mr50 {
  margin-right: 50px !important; }

.ml50 {
  margin-left: 50px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.mt55 {
  margin-top: 55px !important; }

.mr55 {
  margin-right: 55px !important; }

.ml55 {
  margin-left: 55px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mt60 {
  margin-top: 60px !important; }

.mr60 {
  margin-right: 60px !important; }

.ml60 {
  margin-left: 60px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.ml65 {
  margin-left: 65px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.mt70 {
  margin-top: 70px !important; }

.mr70 {
  margin-right: 70px !important; }

.ml70 {
  margin-left: 70px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.mt75 {
  margin-top: 75px !important; }

.mr75 {
  margin-right: 75px !important; }

.ml75 {
  margin-left: 75px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.mt80 {
  margin-top: 80px !important; }

.mr80 {
  margin-right: 80px !important; }

.ml80 {
  margin-left: 80px !important; }

@media screen and (max-width: 1199px) {
  .liquid_mb-50 {
    margin-bottom: -50px !important; }
  .liquid_mt-50 {
    margin-top: -50px !important; }
  .liquid_mr-50 {
    margin-right: -50px !important; }
  .liquid_ml-50 {
    margin-left: -50px !important; }
  .liquid_mb-45 {
    margin-bottom: -45px !important; }
  .liquid_mt-45 {
    margin-top: -45px !important; }
  .liquid_mr-45 {
    margin-right: -45px !important; }
  .liquid_ml-45 {
    margin-left: -45px !important; }
  .liquid_mb-40 {
    margin-bottom: -40px !important; }
  .liquid_mt-40 {
    margin-top: -40px !important; }
  .liquid_mr-40 {
    margin-right: -40px !important; }
  .liquid_ml-40 {
    margin-left: -40px !important; }
  .liquid_mb-35 {
    margin-bottom: -35px !important; }
  .liquid_mt-35 {
    margin-top: -35px !important; }
  .liquid_mr-35 {
    margin-right: -35px !important; }
  .liquid_ml-35 {
    margin-left: -35px !important; }
  .liquid_mb-30 {
    margin-bottom: -30px !important; }
  .liquid_mt-30 {
    margin-top: -30px !important; }
  .liquid_mr-30 {
    margin-right: -30px !important; }
  .liquid_ml-30 {
    margin-left: -30px !important; }
  .liquid_mb-25 {
    margin-bottom: -25px !important; }
  .liquid_mt-25 {
    margin-top: -25px !important; }
  .liquid_mr-25 {
    margin-right: -25px !important; }
  .liquid_ml-25 {
    margin-left: -25px !important; }
  .liquid_mb-20 {
    margin-bottom: -20px !important; }
  .liquid_mt-20 {
    margin-top: -20px !important; }
  .liquid_mr-20 {
    margin-right: -20px !important; }
  .liquid_ml-20 {
    margin-left: -20px !important; }
  .liquid_mb-15 {
    margin-bottom: -15px !important; }
  .liquid_mt-15 {
    margin-top: -15px !important; }
  .liquid_mr-15 {
    margin-right: -15px !important; }
  .liquid_ml-15 {
    margin-left: -15px !important; }
  .liquid_mb-10 {
    margin-bottom: -10px !important; }
  .liquid_mt-10 {
    margin-top: -10px !important; }
  .liquid_mr-10 {
    margin-right: -10px !important; }
  .liquid_ml-10 {
    margin-left: -10px !important; }
  .liquid_mb-5 {
    margin-bottom: -5px !important; }
  .liquid_mt-5 {
    margin-top: -5px !important; }
  .liquid_mr-5 {
    margin-right: -5px !important; }
  .liquid_ml-5 {
    margin-left: -5px !important; }
  .liquid_mb0 {
    margin-bottom: 0px !important; }
  .liquid_mt0 {
    margin-top: 0px !important; }
  .liquid_mr0 {
    margin-right: 0px !important; }
  .liquid_ml0 {
    margin-left: 0px !important; }
  .liquid_mb5 {
    margin-bottom: 5px !important; }
  .liquid_mt5 {
    margin-top: 5px !important; }
  .liquid_mr5 {
    margin-right: 5px !important; }
  .liquid_ml5 {
    margin-left: 5px !important; }
  .liquid_mb10 {
    margin-bottom: 10px !important; }
  .liquid_mt10 {
    margin-top: 10px !important; }
  .liquid_mr10 {
    margin-right: 10px !important; }
  .liquid_ml10 {
    margin-left: 10px !important; }
  .liquid_mb15 {
    margin-bottom: 15px !important; }
  .liquid_mt15 {
    margin-top: 15px !important; }
  .liquid_mr15 {
    margin-right: 15px !important; }
  .liquid_ml15 {
    margin-left: 15px !important; }
  .liquid_mb20 {
    margin-bottom: 20px !important; }
  .liquid_mt20 {
    margin-top: 20px !important; }
  .liquid_mr20 {
    margin-right: 20px !important; }
  .liquid_ml20 {
    margin-left: 20px !important; }
  .liquid_mb25 {
    margin-bottom: 25px !important; }
  .liquid_mt25 {
    margin-top: 25px !important; }
  .liquid_mr25 {
    margin-right: 25px !important; }
  .liquid_ml25 {
    margin-left: 25px !important; }
  .liquid_mb30 {
    margin-bottom: 30px !important; }
  .liquid_mt30 {
    margin-top: 30px !important; }
  .liquid_mr30 {
    margin-right: 30px !important; }
  .liquid_ml30 {
    margin-left: 30px !important; }
  .liquid_mb35 {
    margin-bottom: 35px !important; }
  .liquid_mt35 {
    margin-top: 35px !important; }
  .liquid_mr35 {
    margin-right: 35px !important; }
  .liquid_ml35 {
    margin-left: 35px !important; }
  .liquid_mb40 {
    margin-bottom: 40px !important; }
  .liquid_mt40 {
    margin-top: 40px !important; }
  .liquid_mr40 {
    margin-right: 40px !important; }
  .liquid_ml40 {
    margin-left: 40px !important; }
  .liquid_mb45 {
    margin-bottom: 45px !important; }
  .liquid_mt45 {
    margin-top: 45px !important; }
  .liquid_mr45 {
    margin-right: 45px !important; }
  .liquid_ml45 {
    margin-left: 45px !important; }
  .liquid_mb50 {
    margin-bottom: 50px !important; }
  .liquid_mt50 {
    margin-top: 50px !important; }
  .liquid_mr50 {
    margin-right: 50px !important; }
  .liquid_ml50 {
    margin-left: 50px !important; }
  .liquid_mb55 {
    margin-bottom: 55px !important; }
  .liquid_mt55 {
    margin-top: 55px !important; }
  .liquid_mr55 {
    margin-right: 55px !important; }
  .liquid_ml55 {
    margin-left: 55px !important; }
  .liquid_mb60 {
    margin-bottom: 60px !important; }
  .liquid_mt60 {
    margin-top: 60px !important; }
  .liquid_mr60 {
    margin-right: 60px !important; }
  .liquid_ml60 {
    margin-left: 60px !important; }
  .liquid_mb65 {
    margin-bottom: 65px !important; }
  .liquid_mt65 {
    margin-top: 65px !important; }
  .liquid_mr65 {
    margin-right: 65px !important; }
  .liquid_ml65 {
    margin-left: 65px !important; }
  .liquid_mb70 {
    margin-bottom: 70px !important; }
  .liquid_mt70 {
    margin-top: 70px !important; }
  .liquid_mr70 {
    margin-right: 70px !important; }
  .liquid_ml70 {
    margin-left: 70px !important; }
  .liquid_mb75 {
    margin-bottom: 75px !important; }
  .liquid_mt75 {
    margin-top: 75px !important; }
  .liquid_mr75 {
    margin-right: 75px !important; }
  .liquid_ml75 {
    margin-left: 75px !important; }
  .liquid_mb80 {
    margin-bottom: 80px !important; }
  .liquid_mt80 {
    margin-top: 80px !important; }
  .liquid_mr80 {
    margin-right: 80px !important; }
  .liquid_ml80 {
    margin-left: 80px !important; } }

@media screen and (max-width: 1140px) {
  .tablet_mb-50 {
    margin-bottom: -50px !important; }
  .tablet_mt-50 {
    margin-top: -50px !important; }
  .tablet_mr-50 {
    margin-right: -50px !important; }
  .tablet_ml-50 {
    margin-left: -50px !important; }
  .tablet_mb-45 {
    margin-bottom: -45px !important; }
  .tablet_mt-45 {
    margin-top: -45px !important; }
  .tablet_mr-45 {
    margin-right: -45px !important; }
  .tablet_ml-45 {
    margin-left: -45px !important; }
  .tablet_mb-40 {
    margin-bottom: -40px !important; }
  .tablet_mt-40 {
    margin-top: -40px !important; }
  .tablet_mr-40 {
    margin-right: -40px !important; }
  .tablet_ml-40 {
    margin-left: -40px !important; }
  .tablet_mb-35 {
    margin-bottom: -35px !important; }
  .tablet_mt-35 {
    margin-top: -35px !important; }
  .tablet_mr-35 {
    margin-right: -35px !important; }
  .tablet_ml-35 {
    margin-left: -35px !important; }
  .tablet_mb-30 {
    margin-bottom: -30px !important; }
  .tablet_mt-30 {
    margin-top: -30px !important; }
  .tablet_mr-30 {
    margin-right: -30px !important; }
  .tablet_ml-30 {
    margin-left: -30px !important; }
  .tablet_mb-25 {
    margin-bottom: -25px !important; }
  .tablet_mt-25 {
    margin-top: -25px !important; }
  .tablet_mr-25 {
    margin-right: -25px !important; }
  .tablet_ml-25 {
    margin-left: -25px !important; }
  .tablet_mb-20 {
    margin-bottom: -20px !important; }
  .tablet_mt-20 {
    margin-top: -20px !important; }
  .tablet_mr-20 {
    margin-right: -20px !important; }
  .tablet_ml-20 {
    margin-left: -20px !important; }
  .tablet_mb-15 {
    margin-bottom: -15px !important; }
  .tablet_mt-15 {
    margin-top: -15px !important; }
  .tablet_mr-15 {
    margin-right: -15px !important; }
  .tablet_ml-15 {
    margin-left: -15px !important; }
  .tablet_mb-10 {
    margin-bottom: -10px !important; }
  .tablet_mt-10 {
    margin-top: -10px !important; }
  .tablet_mr-10 {
    margin-right: -10px !important; }
  .tablet_ml-10 {
    margin-left: -10px !important; }
  .tablet_mb-5 {
    margin-bottom: -5px !important; }
  .tablet_mt-5 {
    margin-top: -5px !important; }
  .tablet_mr-5 {
    margin-right: -5px !important; }
  .tablet_ml-5 {
    margin-left: -5px !important; }
  .tablet_mb0 {
    margin-bottom: 0px !important; }
  .tablet_mt0 {
    margin-top: 0px !important; }
  .tablet_mr0 {
    margin-right: 0px !important; }
  .tablet_ml0 {
    margin-left: 0px !important; }
  .tablet_mb5 {
    margin-bottom: 5px !important; }
  .tablet_mt5 {
    margin-top: 5px !important; }
  .tablet_mr5 {
    margin-right: 5px !important; }
  .tablet_ml5 {
    margin-left: 5px !important; }
  .tablet_mb10 {
    margin-bottom: 10px !important; }
  .tablet_mt10 {
    margin-top: 10px !important; }
  .tablet_mr10 {
    margin-right: 10px !important; }
  .tablet_ml10 {
    margin-left: 10px !important; }
  .tablet_mb15 {
    margin-bottom: 15px !important; }
  .tablet_mt15 {
    margin-top: 15px !important; }
  .tablet_mr15 {
    margin-right: 15px !important; }
  .tablet_ml15 {
    margin-left: 15px !important; }
  .tablet_mb20 {
    margin-bottom: 20px !important; }
  .tablet_mt20 {
    margin-top: 20px !important; }
  .tablet_mr20 {
    margin-right: 20px !important; }
  .tablet_ml20 {
    margin-left: 20px !important; }
  .tablet_mb25 {
    margin-bottom: 25px !important; }
  .tablet_mt25 {
    margin-top: 25px !important; }
  .tablet_mr25 {
    margin-right: 25px !important; }
  .tablet_ml25 {
    margin-left: 25px !important; }
  .tablet_mb30 {
    margin-bottom: 30px !important; }
  .tablet_mt30 {
    margin-top: 30px !important; }
  .tablet_mr30 {
    margin-right: 30px !important; }
  .tablet_ml30 {
    margin-left: 30px !important; }
  .tablet_mb35 {
    margin-bottom: 35px !important; }
  .tablet_mt35 {
    margin-top: 35px !important; }
  .tablet_mr35 {
    margin-right: 35px !important; }
  .tablet_ml35 {
    margin-left: 35px !important; }
  .tablet_mb40 {
    margin-bottom: 40px !important; }
  .tablet_mt40 {
    margin-top: 40px !important; }
  .tablet_mr40 {
    margin-right: 40px !important; }
  .tablet_ml40 {
    margin-left: 40px !important; }
  .tablet_mb45 {
    margin-bottom: 45px !important; }
  .tablet_mt45 {
    margin-top: 45px !important; }
  .tablet_mr45 {
    margin-right: 45px !important; }
  .tablet_ml45 {
    margin-left: 45px !important; }
  .tablet_mb50 {
    margin-bottom: 50px !important; }
  .tablet_mt50 {
    margin-top: 50px !important; }
  .tablet_mr50 {
    margin-right: 50px !important; }
  .tablet_ml50 {
    margin-left: 50px !important; }
  .tablet_mb55 {
    margin-bottom: 55px !important; }
  .tablet_mt55 {
    margin-top: 55px !important; }
  .tablet_mr55 {
    margin-right: 55px !important; }
  .tablet_ml55 {
    margin-left: 55px !important; }
  .tablet_mb60 {
    margin-bottom: 60px !important; }
  .tablet_mt60 {
    margin-top: 60px !important; }
  .tablet_mr60 {
    margin-right: 60px !important; }
  .tablet_ml60 {
    margin-left: 60px !important; }
  .tablet_mb65 {
    margin-bottom: 65px !important; }
  .tablet_mt65 {
    margin-top: 65px !important; }
  .tablet_mr65 {
    margin-right: 65px !important; }
  .tablet_ml65 {
    margin-left: 65px !important; }
  .tablet_mb70 {
    margin-bottom: 70px !important; }
  .tablet_mt70 {
    margin-top: 70px !important; }
  .tablet_mr70 {
    margin-right: 70px !important; }
  .tablet_ml70 {
    margin-left: 70px !important; }
  .tablet_mb75 {
    margin-bottom: 75px !important; }
  .tablet_mt75 {
    margin-top: 75px !important; }
  .tablet_mr75 {
    margin-right: 75px !important; }
  .tablet_ml75 {
    margin-left: 75px !important; }
  .tablet_mb80 {
    margin-bottom: 80px !important; }
  .tablet_mt80 {
    margin-top: 80px !important; }
  .tablet_mr80 {
    margin-right: 80px !important; }
  .tablet_ml80 {
    margin-left: 80px !important; } }

@media screen and (max-width: 768px) {
  .sp_mb-50 {
    margin-bottom: -50px !important; }
  .sp_mt-50 {
    margin-top: -50px !important; }
  .sp_mr-50 {
    margin-right: -50px !important; }
  .sp_ml-50 {
    margin-left: -50px !important; }
  .sp_mb-45 {
    margin-bottom: -45px !important; }
  .sp_mt-45 {
    margin-top: -45px !important; }
  .sp_mr-45 {
    margin-right: -45px !important; }
  .sp_ml-45 {
    margin-left: -45px !important; }
  .sp_mb-40 {
    margin-bottom: -40px !important; }
  .sp_mt-40 {
    margin-top: -40px !important; }
  .sp_mr-40 {
    margin-right: -40px !important; }
  .sp_ml-40 {
    margin-left: -40px !important; }
  .sp_mb-35 {
    margin-bottom: -35px !important; }
  .sp_mt-35 {
    margin-top: -35px !important; }
  .sp_mr-35 {
    margin-right: -35px !important; }
  .sp_ml-35 {
    margin-left: -35px !important; }
  .sp_mb-30 {
    margin-bottom: -30px !important; }
  .sp_mt-30 {
    margin-top: -30px !important; }
  .sp_mr-30 {
    margin-right: -30px !important; }
  .sp_ml-30 {
    margin-left: -30px !important; }
  .sp_mb-25 {
    margin-bottom: -25px !important; }
  .sp_mt-25 {
    margin-top: -25px !important; }
  .sp_mr-25 {
    margin-right: -25px !important; }
  .sp_ml-25 {
    margin-left: -25px !important; }
  .sp_mb-20 {
    margin-bottom: -20px !important; }
  .sp_mt-20 {
    margin-top: -20px !important; }
  .sp_mr-20 {
    margin-right: -20px !important; }
  .sp_ml-20 {
    margin-left: -20px !important; }
  .sp_mb-15 {
    margin-bottom: -15px !important; }
  .sp_mt-15 {
    margin-top: -15px !important; }
  .sp_mr-15 {
    margin-right: -15px !important; }
  .sp_ml-15 {
    margin-left: -15px !important; }
  .sp_mb-10 {
    margin-bottom: -10px !important; }
  .sp_mt-10 {
    margin-top: -10px !important; }
  .sp_mr-10 {
    margin-right: -10px !important; }
  .sp_ml-10 {
    margin-left: -10px !important; }
  .sp_mb-5 {
    margin-bottom: -5px !important; }
  .sp_mt-5 {
    margin-top: -5px !important; }
  .sp_mr-5 {
    margin-right: -5px !important; }
  .sp_ml-5 {
    margin-left: -5px !important; }
  .sp_mb0 {
    margin-bottom: 0px !important; }
  .sp_mt0 {
    margin-top: 0px !important; }
  .sp_mr0 {
    margin-right: 0px !important; }
  .sp_ml0 {
    margin-left: 0px !important; }
  .sp_mb5 {
    margin-bottom: 5px !important; }
  .sp_mt5 {
    margin-top: 5px !important; }
  .sp_mr5 {
    margin-right: 5px !important; }
  .sp_ml5 {
    margin-left: 5px !important; }
  .sp_mb10 {
    margin-bottom: 10px !important; }
  .sp_mt10 {
    margin-top: 10px !important; }
  .sp_mr10 {
    margin-right: 10px !important; }
  .sp_ml10 {
    margin-left: 10px !important; }
  .sp_mb15 {
    margin-bottom: 15px !important; }
  .sp_mt15 {
    margin-top: 15px !important; }
  .sp_mr15 {
    margin-right: 15px !important; }
  .sp_ml15 {
    margin-left: 15px !important; }
  .sp_mb20 {
    margin-bottom: 20px !important; }
  .sp_mt20 {
    margin-top: 20px !important; }
  .sp_mr20 {
    margin-right: 20px !important; }
  .sp_ml20 {
    margin-left: 20px !important; }
  .sp_mb25 {
    margin-bottom: 25px !important; }
  .sp_mt25 {
    margin-top: 25px !important; }
  .sp_mr25 {
    margin-right: 25px !important; }
  .sp_ml25 {
    margin-left: 25px !important; }
  .sp_mb30 {
    margin-bottom: 30px !important; }
  .sp_mt30 {
    margin-top: 30px !important; }
  .sp_mr30 {
    margin-right: 30px !important; }
  .sp_ml30 {
    margin-left: 30px !important; }
  .sp_mb35 {
    margin-bottom: 35px !important; }
  .sp_mt35 {
    margin-top: 35px !important; }
  .sp_mr35 {
    margin-right: 35px !important; }
  .sp_ml35 {
    margin-left: 35px !important; }
  .sp_mb40 {
    margin-bottom: 40px !important; }
  .sp_mt40 {
    margin-top: 40px !important; }
  .sp_mr40 {
    margin-right: 40px !important; }
  .sp_ml40 {
    margin-left: 40px !important; }
  .sp_mb45 {
    margin-bottom: 45px !important; }
  .sp_mt45 {
    margin-top: 45px !important; }
  .sp_mr45 {
    margin-right: 45px !important; }
  .sp_ml45 {
    margin-left: 45px !important; }
  .sp_mb50 {
    margin-bottom: 50px !important; }
  .sp_mt50 {
    margin-top: 50px !important; }
  .sp_mr50 {
    margin-right: 50px !important; }
  .sp_ml50 {
    margin-left: 50px !important; }
  .sp_mb55 {
    margin-bottom: 55px !important; }
  .sp_mt55 {
    margin-top: 55px !important; }
  .sp_mr55 {
    margin-right: 55px !important; }
  .sp_ml55 {
    margin-left: 55px !important; }
  .sp_mb60 {
    margin-bottom: 60px !important; }
  .sp_mt60 {
    margin-top: 60px !important; }
  .sp_mr60 {
    margin-right: 60px !important; }
  .sp_ml60 {
    margin-left: 60px !important; }
  .sp_mb65 {
    margin-bottom: 65px !important; }
  .sp_mt65 {
    margin-top: 65px !important; }
  .sp_mr65 {
    margin-right: 65px !important; }
  .sp_ml65 {
    margin-left: 65px !important; }
  .sp_mb70 {
    margin-bottom: 70px !important; }
  .sp_mt70 {
    margin-top: 70px !important; }
  .sp_mr70 {
    margin-right: 70px !important; }
  .sp_ml70 {
    margin-left: 70px !important; }
  .sp_mb75 {
    margin-bottom: 75px !important; }
  .sp_mt75 {
    margin-top: 75px !important; }
  .sp_mr75 {
    margin-right: 75px !important; }
  .sp_ml75 {
    margin-left: 75px !important; }
  .sp_mb80 {
    margin-bottom: 80px !important; }
  .sp_mt80 {
    margin-top: 80px !important; }
  .sp_mr80 {
    margin-right: 80px !important; }
  .sp_ml80 {
    margin-left: 80px !important; } }

@media screen and (max-width: 426px) {
  .small_mb-50 {
    margin-bottom: -50px !important; }
  .small_mt-50 {
    margin-top: -50px !important; }
  .small_mr-50 {
    margin-right: -50px !important; }
  .small_ml-50 {
    margin-left: -50px !important; }
  .small_mb-45 {
    margin-bottom: -45px !important; }
  .small_mt-45 {
    margin-top: -45px !important; }
  .small_mr-45 {
    margin-right: -45px !important; }
  .small_ml-45 {
    margin-left: -45px !important; }
  .small_mb-40 {
    margin-bottom: -40px !important; }
  .small_mt-40 {
    margin-top: -40px !important; }
  .small_mr-40 {
    margin-right: -40px !important; }
  .small_ml-40 {
    margin-left: -40px !important; }
  .small_mb-35 {
    margin-bottom: -35px !important; }
  .small_mt-35 {
    margin-top: -35px !important; }
  .small_mr-35 {
    margin-right: -35px !important; }
  .small_ml-35 {
    margin-left: -35px !important; }
  .small_mb-30 {
    margin-bottom: -30px !important; }
  .small_mt-30 {
    margin-top: -30px !important; }
  .small_mr-30 {
    margin-right: -30px !important; }
  .small_ml-30 {
    margin-left: -30px !important; }
  .small_mb-25 {
    margin-bottom: -25px !important; }
  .small_mt-25 {
    margin-top: -25px !important; }
  .small_mr-25 {
    margin-right: -25px !important; }
  .small_ml-25 {
    margin-left: -25px !important; }
  .small_mb-20 {
    margin-bottom: -20px !important; }
  .small_mt-20 {
    margin-top: -20px !important; }
  .small_mr-20 {
    margin-right: -20px !important; }
  .small_ml-20 {
    margin-left: -20px !important; }
  .small_mb-15 {
    margin-bottom: -15px !important; }
  .small_mt-15 {
    margin-top: -15px !important; }
  .small_mr-15 {
    margin-right: -15px !important; }
  .small_ml-15 {
    margin-left: -15px !important; }
  .small_mb-10 {
    margin-bottom: -10px !important; }
  .small_mt-10 {
    margin-top: -10px !important; }
  .small_mr-10 {
    margin-right: -10px !important; }
  .small_ml-10 {
    margin-left: -10px !important; }
  .small_mb-5 {
    margin-bottom: -5px !important; }
  .small_mt-5 {
    margin-top: -5px !important; }
  .small_mr-5 {
    margin-right: -5px !important; }
  .small_ml-5 {
    margin-left: -5px !important; }
  .small_mb0 {
    margin-bottom: 0px !important; }
  .small_mt0 {
    margin-top: 0px !important; }
  .small_mr0 {
    margin-right: 0px !important; }
  .small_ml0 {
    margin-left: 0px !important; }
  .small_mb5 {
    margin-bottom: 5px !important; }
  .small_mt5 {
    margin-top: 5px !important; }
  .small_mr5 {
    margin-right: 5px !important; }
  .small_ml5 {
    margin-left: 5px !important; }
  .small_mb10 {
    margin-bottom: 10px !important; }
  .small_mt10 {
    margin-top: 10px !important; }
  .small_mr10 {
    margin-right: 10px !important; }
  .small_ml10 {
    margin-left: 10px !important; }
  .small_mb15 {
    margin-bottom: 15px !important; }
  .small_mt15 {
    margin-top: 15px !important; }
  .small_mr15 {
    margin-right: 15px !important; }
  .small_ml15 {
    margin-left: 15px !important; }
  .small_mb20 {
    margin-bottom: 20px !important; }
  .small_mt20 {
    margin-top: 20px !important; }
  .small_mr20 {
    margin-right: 20px !important; }
  .small_ml20 {
    margin-left: 20px !important; }
  .small_mb25 {
    margin-bottom: 25px !important; }
  .small_mt25 {
    margin-top: 25px !important; }
  .small_mr25 {
    margin-right: 25px !important; }
  .small_ml25 {
    margin-left: 25px !important; }
  .small_mb30 {
    margin-bottom: 30px !important; }
  .small_mt30 {
    margin-top: 30px !important; }
  .small_mr30 {
    margin-right: 30px !important; }
  .small_ml30 {
    margin-left: 30px !important; }
  .small_mb35 {
    margin-bottom: 35px !important; }
  .small_mt35 {
    margin-top: 35px !important; }
  .small_mr35 {
    margin-right: 35px !important; }
  .small_ml35 {
    margin-left: 35px !important; }
  .small_mb40 {
    margin-bottom: 40px !important; }
  .small_mt40 {
    margin-top: 40px !important; }
  .small_mr40 {
    margin-right: 40px !important; }
  .small_ml40 {
    margin-left: 40px !important; }
  .small_mb45 {
    margin-bottom: 45px !important; }
  .small_mt45 {
    margin-top: 45px !important; }
  .small_mr45 {
    margin-right: 45px !important; }
  .small_ml45 {
    margin-left: 45px !important; }
  .small_mb50 {
    margin-bottom: 50px !important; }
  .small_mt50 {
    margin-top: 50px !important; }
  .small_mr50 {
    margin-right: 50px !important; }
  .small_ml50 {
    margin-left: 50px !important; }
  .small_mb55 {
    margin-bottom: 55px !important; }
  .small_mt55 {
    margin-top: 55px !important; }
  .small_mr55 {
    margin-right: 55px !important; }
  .small_ml55 {
    margin-left: 55px !important; }
  .small_mb60 {
    margin-bottom: 60px !important; }
  .small_mt60 {
    margin-top: 60px !important; }
  .small_mr60 {
    margin-right: 60px !important; }
  .small_ml60 {
    margin-left: 60px !important; }
  .small_mb65 {
    margin-bottom: 65px !important; }
  .small_mt65 {
    margin-top: 65px !important; }
  .small_mr65 {
    margin-right: 65px !important; }
  .small_ml65 {
    margin-left: 65px !important; }
  .small_mb70 {
    margin-bottom: 70px !important; }
  .small_mt70 {
    margin-top: 70px !important; }
  .small_mr70 {
    margin-right: 70px !important; }
  .small_ml70 {
    margin-left: 70px !important; }
  .small_mb75 {
    margin-bottom: 75px !important; }
  .small_mt75 {
    margin-top: 75px !important; }
  .small_mr75 {
    margin-right: 75px !important; }
  .small_ml75 {
    margin-left: 75px !important; }
  .small_mb80 {
    margin-bottom: 80px !important; }
  .small_mt80 {
    margin-top: 80px !important; }
  .small_mr80 {
    margin-right: 80px !important; }
  .small_ml80 {
    margin-left: 80px !important; } }

.pb0 {
  padding-bottom: 0px !important; }

.pt0 {
  padding-top: 0px !important; }

.pr0 {
  padding-right: 0px !important; }

.pl0 {
  padding-left: 0px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pt5 {
  padding-top: 5px !important; }

.pr5 {
  padding-right: 5px !important; }

.pl5 {
  padding-left: 5px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pt10 {
  padding-top: 10px !important; }

.pr10 {
  padding-right: 10px !important; }

.pl10 {
  padding-left: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pt15 {
  padding-top: 15px !important; }

.pr15 {
  padding-right: 15px !important; }

.pl15 {
  padding-left: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pt20 {
  padding-top: 20px !important; }

.pr20 {
  padding-right: 20px !important; }

.pl20 {
  padding-left: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pt25 {
  padding-top: 25px !important; }

.pr25 {
  padding-right: 25px !important; }

.pl25 {
  padding-left: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pt30 {
  padding-top: 30px !important; }

.pr30 {
  padding-right: 30px !important; }

.pl30 {
  padding-left: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pt35 {
  padding-top: 35px !important; }

.pr35 {
  padding-right: 35px !important; }

.pl35 {
  padding-left: 35px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pt40 {
  padding-top: 40px !important; }

.pr40 {
  padding-right: 40px !important; }

.pl40 {
  padding-left: 40px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pt45 {
  padding-top: 45px !important; }

.pr45 {
  padding-right: 45px !important; }

.pl45 {
  padding-left: 45px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pt50 {
  padding-top: 50px !important; }

.pr50 {
  padding-right: 50px !important; }

.pl50 {
  padding-left: 50px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pt55 {
  padding-top: 55px !important; }

.pr55 {
  padding-right: 55px !important; }

.pl55 {
  padding-left: 55px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pt60 {
  padding-top: 60px !important; }

.pr60 {
  padding-right: 60px !important; }

.pl60 {
  padding-left: 60px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pt65 {
  padding-top: 65px !important; }

.pr65 {
  padding-right: 65px !important; }

.pl65 {
  padding-left: 65px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pt70 {
  padding-top: 70px !important; }

.pr70 {
  padding-right: 70px !important; }

.pl70 {
  padding-left: 70px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pt75 {
  padding-top: 75px !important; }

.pr75 {
  padding-right: 75px !important; }

.pl75 {
  padding-left: 75px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pt80 {
  padding-top: 80px !important; }

.pr80 {
  padding-right: 80px !important; }

.pl80 {
  padding-left: 80px !important; }

@media screen and (max-width: 1199px) {
  .liquid_pb0 {
    padding-bottom: 0px !important; }
  .liquid_pt0 {
    padding-top: 0px !important; }
  .liquid_pr0 {
    padding-right: 0px !important; }
  .liquid_pl0 {
    padding-left: 0px !important; }
  .liquid_pb5 {
    padding-bottom: 5px !important; }
  .liquid_pt5 {
    padding-top: 5px !important; }
  .liquid_pr5 {
    padding-right: 5px !important; }
  .liquid_pl5 {
    padding-left: 5px !important; }
  .liquid_pb10 {
    padding-bottom: 10px !important; }
  .liquid_pt10 {
    padding-top: 10px !important; }
  .liquid_pr10 {
    padding-right: 10px !important; }
  .liquid_pl10 {
    padding-left: 10px !important; }
  .liquid_pb15 {
    padding-bottom: 15px !important; }
  .liquid_pt15 {
    padding-top: 15px !important; }
  .liquid_pr15 {
    padding-right: 15px !important; }
  .liquid_pl15 {
    padding-left: 15px !important; }
  .liquid_pb20 {
    padding-bottom: 20px !important; }
  .liquid_pt20 {
    padding-top: 20px !important; }
  .liquid_pr20 {
    padding-right: 20px !important; }
  .liquid_pl20 {
    padding-left: 20px !important; }
  .liquid_pb25 {
    padding-bottom: 25px !important; }
  .liquid_pt25 {
    padding-top: 25px !important; }
  .liquid_pr25 {
    padding-right: 25px !important; }
  .liquid_pl25 {
    padding-left: 25px !important; }
  .liquid_pb30 {
    padding-bottom: 30px !important; }
  .liquid_pt30 {
    padding-top: 30px !important; }
  .liquid_pr30 {
    padding-right: 30px !important; }
  .liquid_pl30 {
    padding-left: 30px !important; }
  .liquid_pb35 {
    padding-bottom: 35px !important; }
  .liquid_pt35 {
    padding-top: 35px !important; }
  .liquid_pr35 {
    padding-right: 35px !important; }
  .liquid_pl35 {
    padding-left: 35px !important; }
  .liquid_pb40 {
    padding-bottom: 40px !important; }
  .liquid_pt40 {
    padding-top: 40px !important; }
  .liquid_pr40 {
    padding-right: 40px !important; }
  .liquid_pl40 {
    padding-left: 40px !important; }
  .liquid_pb45 {
    padding-bottom: 45px !important; }
  .liquid_pt45 {
    padding-top: 45px !important; }
  .liquid_pr45 {
    padding-right: 45px !important; }
  .liquid_pl45 {
    padding-left: 45px !important; }
  .liquid_pb50 {
    padding-bottom: 50px !important; }
  .liquid_pt50 {
    padding-top: 50px !important; }
  .liquid_pr50 {
    padding-right: 50px !important; }
  .liquid_pl50 {
    padding-left: 50px !important; }
  .liquid_pb55 {
    padding-bottom: 55px !important; }
  .liquid_pt55 {
    padding-top: 55px !important; }
  .liquid_pr55 {
    padding-right: 55px !important; }
  .liquid_pl55 {
    padding-left: 55px !important; }
  .liquid_pb60 {
    padding-bottom: 60px !important; }
  .liquid_pt60 {
    padding-top: 60px !important; }
  .liquid_pr60 {
    padding-right: 60px !important; }
  .liquid_pl60 {
    padding-left: 60px !important; }
  .liquid_pb65 {
    padding-bottom: 65px !important; }
  .liquid_pt65 {
    padding-top: 65px !important; }
  .liquid_pr65 {
    padding-right: 65px !important; }
  .liquid_pl65 {
    padding-left: 65px !important; }
  .liquid_pb70 {
    padding-bottom: 70px !important; }
  .liquid_pt70 {
    padding-top: 70px !important; }
  .liquid_pr70 {
    padding-right: 70px !important; }
  .liquid_pl70 {
    padding-left: 70px !important; }
  .liquid_pb75 {
    padding-bottom: 75px !important; }
  .liquid_pt75 {
    padding-top: 75px !important; }
  .liquid_pr75 {
    padding-right: 75px !important; }
  .liquid_pl75 {
    padding-left: 75px !important; }
  .liquid_pb80 {
    padding-bottom: 80px !important; }
  .liquid_pt80 {
    padding-top: 80px !important; }
  .liquid_pr80 {
    padding-right: 80px !important; }
  .liquid_pl80 {
    padding-left: 80px !important; } }

@media screen and (max-width: 1140px) {
  .tablet_pb0 {
    padding-bottom: 0px !important; }
  .tablet_pt0 {
    padding-top: 0px !important; }
  .tablet_pr0 {
    padding-right: 0px !important; }
  .tablet_pl0 {
    padding-left: 0px !important; }
  .tablet_pb5 {
    padding-bottom: 5px !important; }
  .tablet_pt5 {
    padding-top: 5px !important; }
  .tablet_pr5 {
    padding-right: 5px !important; }
  .tablet_pl5 {
    padding-left: 5px !important; }
  .tablet_pb10 {
    padding-bottom: 10px !important; }
  .tablet_pt10 {
    padding-top: 10px !important; }
  .tablet_pr10 {
    padding-right: 10px !important; }
  .tablet_pl10 {
    padding-left: 10px !important; }
  .tablet_pb15 {
    padding-bottom: 15px !important; }
  .tablet_pt15 {
    padding-top: 15px !important; }
  .tablet_pr15 {
    padding-right: 15px !important; }
  .tablet_pl15 {
    padding-left: 15px !important; }
  .tablet_pb20 {
    padding-bottom: 20px !important; }
  .tablet_pt20 {
    padding-top: 20px !important; }
  .tablet_pr20 {
    padding-right: 20px !important; }
  .tablet_pl20 {
    padding-left: 20px !important; }
  .tablet_pb25 {
    padding-bottom: 25px !important; }
  .tablet_pt25 {
    padding-top: 25px !important; }
  .tablet_pr25 {
    padding-right: 25px !important; }
  .tablet_pl25 {
    padding-left: 25px !important; }
  .tablet_pb30 {
    padding-bottom: 30px !important; }
  .tablet_pt30 {
    padding-top: 30px !important; }
  .tablet_pr30 {
    padding-right: 30px !important; }
  .tablet_pl30 {
    padding-left: 30px !important; }
  .tablet_pb35 {
    padding-bottom: 35px !important; }
  .tablet_pt35 {
    padding-top: 35px !important; }
  .tablet_pr35 {
    padding-right: 35px !important; }
  .tablet_pl35 {
    padding-left: 35px !important; }
  .tablet_pb40 {
    padding-bottom: 40px !important; }
  .tablet_pt40 {
    padding-top: 40px !important; }
  .tablet_pr40 {
    padding-right: 40px !important; }
  .tablet_pl40 {
    padding-left: 40px !important; }
  .tablet_pb45 {
    padding-bottom: 45px !important; }
  .tablet_pt45 {
    padding-top: 45px !important; }
  .tablet_pr45 {
    padding-right: 45px !important; }
  .tablet_pl45 {
    padding-left: 45px !important; }
  .tablet_pb50 {
    padding-bottom: 50px !important; }
  .tablet_pt50 {
    padding-top: 50px !important; }
  .tablet_pr50 {
    padding-right: 50px !important; }
  .tablet_pl50 {
    padding-left: 50px !important; }
  .tablet_pb55 {
    padding-bottom: 55px !important; }
  .tablet_pt55 {
    padding-top: 55px !important; }
  .tablet_pr55 {
    padding-right: 55px !important; }
  .tablet_pl55 {
    padding-left: 55px !important; }
  .tablet_pb60 {
    padding-bottom: 60px !important; }
  .tablet_pt60 {
    padding-top: 60px !important; }
  .tablet_pr60 {
    padding-right: 60px !important; }
  .tablet_pl60 {
    padding-left: 60px !important; }
  .tablet_pb65 {
    padding-bottom: 65px !important; }
  .tablet_pt65 {
    padding-top: 65px !important; }
  .tablet_pr65 {
    padding-right: 65px !important; }
  .tablet_pl65 {
    padding-left: 65px !important; }
  .tablet_pb70 {
    padding-bottom: 70px !important; }
  .tablet_pt70 {
    padding-top: 70px !important; }
  .tablet_pr70 {
    padding-right: 70px !important; }
  .tablet_pl70 {
    padding-left: 70px !important; }
  .tablet_pb75 {
    padding-bottom: 75px !important; }
  .tablet_pt75 {
    padding-top: 75px !important; }
  .tablet_pr75 {
    padding-right: 75px !important; }
  .tablet_pl75 {
    padding-left: 75px !important; }
  .tablet_pb80 {
    padding-bottom: 80px !important; }
  .tablet_pt80 {
    padding-top: 80px !important; }
  .tablet_pr80 {
    padding-right: 80px !important; }
  .tablet_pl80 {
    padding-left: 80px !important; } }

@media screen and (max-width: 768px) {
  .sp_pb0 {
    padding-bottom: 0px !important; }
  .sp_pt0 {
    padding-top: 0px !important; }
  .sp_pr0 {
    padding-right: 0px !important; }
  .sp_pl0 {
    padding-left: 0px !important; }
  .sp_pb5 {
    padding-bottom: 5px !important; }
  .sp_pt5 {
    padding-top: 5px !important; }
  .sp_pr5 {
    padding-right: 5px !important; }
  .sp_pl5 {
    padding-left: 5px !important; }
  .sp_pb10 {
    padding-bottom: 10px !important; }
  .sp_pt10 {
    padding-top: 10px !important; }
  .sp_pr10 {
    padding-right: 10px !important; }
  .sp_pl10 {
    padding-left: 10px !important; }
  .sp_pb15 {
    padding-bottom: 15px !important; }
  .sp_pt15 {
    padding-top: 15px !important; }
  .sp_pr15 {
    padding-right: 15px !important; }
  .sp_pl15 {
    padding-left: 15px !important; }
  .sp_pb20 {
    padding-bottom: 20px !important; }
  .sp_pt20 {
    padding-top: 20px !important; }
  .sp_pr20 {
    padding-right: 20px !important; }
  .sp_pl20 {
    padding-left: 20px !important; }
  .sp_pb25 {
    padding-bottom: 25px !important; }
  .sp_pt25 {
    padding-top: 25px !important; }
  .sp_pr25 {
    padding-right: 25px !important; }
  .sp_pl25 {
    padding-left: 25px !important; }
  .sp_pb30 {
    padding-bottom: 30px !important; }
  .sp_pt30 {
    padding-top: 30px !important; }
  .sp_pr30 {
    padding-right: 30px !important; }
  .sp_pl30 {
    padding-left: 30px !important; }
  .sp_pb35 {
    padding-bottom: 35px !important; }
  .sp_pt35 {
    padding-top: 35px !important; }
  .sp_pr35 {
    padding-right: 35px !important; }
  .sp_pl35 {
    padding-left: 35px !important; }
  .sp_pb40 {
    padding-bottom: 40px !important; }
  .sp_pt40 {
    padding-top: 40px !important; }
  .sp_pr40 {
    padding-right: 40px !important; }
  .sp_pl40 {
    padding-left: 40px !important; }
  .sp_pb45 {
    padding-bottom: 45px !important; }
  .sp_pt45 {
    padding-top: 45px !important; }
  .sp_pr45 {
    padding-right: 45px !important; }
  .sp_pl45 {
    padding-left: 45px !important; }
  .sp_pb50 {
    padding-bottom: 50px !important; }
  .sp_pt50 {
    padding-top: 50px !important; }
  .sp_pr50 {
    padding-right: 50px !important; }
  .sp_pl50 {
    padding-left: 50px !important; }
  .sp_pb55 {
    padding-bottom: 55px !important; }
  .sp_pt55 {
    padding-top: 55px !important; }
  .sp_pr55 {
    padding-right: 55px !important; }
  .sp_pl55 {
    padding-left: 55px !important; }
  .sp_pb60 {
    padding-bottom: 60px !important; }
  .sp_pt60 {
    padding-top: 60px !important; }
  .sp_pr60 {
    padding-right: 60px !important; }
  .sp_pl60 {
    padding-left: 60px !important; }
  .sp_pb65 {
    padding-bottom: 65px !important; }
  .sp_pt65 {
    padding-top: 65px !important; }
  .sp_pr65 {
    padding-right: 65px !important; }
  .sp_pl65 {
    padding-left: 65px !important; }
  .sp_pb70 {
    padding-bottom: 70px !important; }
  .sp_pt70 {
    padding-top: 70px !important; }
  .sp_pr70 {
    padding-right: 70px !important; }
  .sp_pl70 {
    padding-left: 70px !important; }
  .sp_pb75 {
    padding-bottom: 75px !important; }
  .sp_pt75 {
    padding-top: 75px !important; }
  .sp_pr75 {
    padding-right: 75px !important; }
  .sp_pl75 {
    padding-left: 75px !important; }
  .sp_pb80 {
    padding-bottom: 80px !important; }
  .sp_pt80 {
    padding-top: 80px !important; }
  .sp_pr80 {
    padding-right: 80px !important; }
  .sp_pl80 {
    padding-left: 80px !important; } }

@media screen and (max-width: 426px) {
  .small_pb0 {
    padding-bottom: 0px !important; }
  .small_pt0 {
    padding-top: 0px !important; }
  .small_pr0 {
    padding-right: 0px !important; }
  .small_pl0 {
    padding-left: 0px !important; }
  .small_pb5 {
    padding-bottom: 5px !important; }
  .small_pt5 {
    padding-top: 5px !important; }
  .small_pr5 {
    padding-right: 5px !important; }
  .small_pl5 {
    padding-left: 5px !important; }
  .small_pb10 {
    padding-bottom: 10px !important; }
  .small_pt10 {
    padding-top: 10px !important; }
  .small_pr10 {
    padding-right: 10px !important; }
  .small_pl10 {
    padding-left: 10px !important; }
  .small_pb15 {
    padding-bottom: 15px !important; }
  .small_pt15 {
    padding-top: 15px !important; }
  .small_pr15 {
    padding-right: 15px !important; }
  .small_pl15 {
    padding-left: 15px !important; }
  .small_pb20 {
    padding-bottom: 20px !important; }
  .small_pt20 {
    padding-top: 20px !important; }
  .small_pr20 {
    padding-right: 20px !important; }
  .small_pl20 {
    padding-left: 20px !important; }
  .small_pb25 {
    padding-bottom: 25px !important; }
  .small_pt25 {
    padding-top: 25px !important; }
  .small_pr25 {
    padding-right: 25px !important; }
  .small_pl25 {
    padding-left: 25px !important; }
  .small_pb30 {
    padding-bottom: 30px !important; }
  .small_pt30 {
    padding-top: 30px !important; }
  .small_pr30 {
    padding-right: 30px !important; }
  .small_pl30 {
    padding-left: 30px !important; }
  .small_pb35 {
    padding-bottom: 35px !important; }
  .small_pt35 {
    padding-top: 35px !important; }
  .small_pr35 {
    padding-right: 35px !important; }
  .small_pl35 {
    padding-left: 35px !important; }
  .small_pb40 {
    padding-bottom: 40px !important; }
  .small_pt40 {
    padding-top: 40px !important; }
  .small_pr40 {
    padding-right: 40px !important; }
  .small_pl40 {
    padding-left: 40px !important; }
  .small_pb45 {
    padding-bottom: 45px !important; }
  .small_pt45 {
    padding-top: 45px !important; }
  .small_pr45 {
    padding-right: 45px !important; }
  .small_pl45 {
    padding-left: 45px !important; }
  .small_pb50 {
    padding-bottom: 50px !important; }
  .small_pt50 {
    padding-top: 50px !important; }
  .small_pr50 {
    padding-right: 50px !important; }
  .small_pl50 {
    padding-left: 50px !important; }
  .small_pb55 {
    padding-bottom: 55px !important; }
  .small_pt55 {
    padding-top: 55px !important; }
  .small_pr55 {
    padding-right: 55px !important; }
  .small_pl55 {
    padding-left: 55px !important; }
  .small_pb60 {
    padding-bottom: 60px !important; }
  .small_pt60 {
    padding-top: 60px !important; }
  .small_pr60 {
    padding-right: 60px !important; }
  .small_pl60 {
    padding-left: 60px !important; }
  .small_pb65 {
    padding-bottom: 65px !important; }
  .small_pt65 {
    padding-top: 65px !important; }
  .small_pr65 {
    padding-right: 65px !important; }
  .small_pl65 {
    padding-left: 65px !important; }
  .small_pb70 {
    padding-bottom: 70px !important; }
  .small_pt70 {
    padding-top: 70px !important; }
  .small_pr70 {
    padding-right: 70px !important; }
  .small_pl70 {
    padding-left: 70px !important; }
  .small_pb75 {
    padding-bottom: 75px !important; }
  .small_pt75 {
    padding-top: 75px !important; }
  .small_pr75 {
    padding-right: 75px !important; }
  .small_pl75 {
    padding-left: 75px !important; }
  .small_pb80 {
    padding-bottom: 80px !important; }
  .small_pt80 {
    padding-top: 80px !important; }
  .small_pr80 {
    padding-right: 80px !important; }
  .small_pl80 {
    padding-left: 80px !important; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0; }
  .row.-reverse {
    flex-direction: row-reverse; }
  .row.-sm {
    margin: -10px -5px 0; }
    .row.-sm > .col, .row.-sm > .col_13, .row.-sm > .col_12, .row.-sm > .col_11, .row.-sm > .col_10, .row.-sm > .col_9, .row.-sm > .col_8, .row.-sm > .col_7, .row.-sm > .col_6, .row.-sm > .col_5, .row.-sm > .col_4, .row.-sm > .col_3, .row.-sm > .col_2_5, .row.-sm > .col_2, .row.-sm > .col_1, .row.-sm > .col_unit {
      padding-top: 10px;
      padding-right: 5px;
      padding-left: 5px; }
  .row.-md {
    margin: -30px -20px 0; }
    .row.-md > .col, .row.-md > .col_13, .row.-md > .col_12, .row.-md > .col_11, .row.-md > .col_10, .row.-md > .col_9, .row.-md > .col_8, .row.-md > .col_7, .row.-md > .col_6, .row.-md > .col_5, .row.-md > .col_4, .row.-md > .col_3, .row.-md > .col_2_5, .row.-md > .col_2, .row.-md > .col_1, .row.-md > .col_unit {
      padding-top: 30px;
      padding-right: 20px;
      padding-left: 20px; }
  .row.-c {
    justify-content: center; }
  .row.-half {
    flex-wrap: nowrap;
    width: calc(50% + 10px); }
    @media screen and (max-width: 768px) {
      .row.-half {
        width: auto; } }
  @media screen and (max-width: 768px) {
    .row.-sp_md {
      margin: -12px -6px 0; }
      .row.-sp_md .col, .row.-sp_md .col_13, .row.-sp_md .col_12, .row.-sp_md .col_11, .row.-sp_md .col_10, .row.-sp_md .col_9, .row.-sp_md .col_8, .row.-sp_md .col_7, .row.-sp_md .col_6, .row.-sp_md .col_5, .row.-sp_md .col_4, .row.-sp_md .col_3, .row.-sp_md .col_2_5, .row.-sp_md .col_2, .row.-sp_md .col_1, .row.-sp_md .col_unit {
        padding-top: 12px;
        padding-right: 6px;
        padding-left: 6px; } }
  .title_box + .row {
    margin-top: 0; }
    @media screen and (max-width: 768px) {
      .title_box + .row {
        margin-top: -10px; } }

.col, .col_13, .col_12, .col_11, .col_10, .col_9, .col_8, .col_7, .col_6, .col_5, .col_4, .col_3, .col_2_5, .col_2, .col_1, .col_unit {
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box; }
  .col.-flex-c, .-flex-c.col_13, .-flex-c.col_12, .-flex-c.col_11, .-flex-c.col_10, .-flex-c.col_9, .-flex-c.col_8, .-flex-c.col_7, .-flex-c.col_6, .-flex-c.col_5, .-flex-c.col_4, .-flex-c.col_3, .-flex-c.col_2_5, .-flex-c.col_2, .-flex-c.col_1, .-flex-c.col_unit {
    display: flex;
    justify-content: center; }
  .col .list_link:first-child .list_link__item:first-child, .col_13 .list_link:first-child .list_link__item:first-child, .col_12 .list_link:first-child .list_link__item:first-child, .col_11 .list_link:first-child .list_link__item:first-child, .col_10 .list_link:first-child .list_link__item:first-child, .col_9 .list_link:first-child .list_link__item:first-child, .col_8 .list_link:first-child .list_link__item:first-child, .col_7 .list_link:first-child .list_link__item:first-child, .col_6 .list_link:first-child .list_link__item:first-child, .col_5 .list_link:first-child .list_link__item:first-child, .col_4 .list_link:first-child .list_link__item:first-child, .col_3 .list_link:first-child .list_link__item:first-child, .col_2_5 .list_link:first-child .list_link__item:first-child, .col_2 .list_link:first-child .list_link__item:first-child, .col_1 .list_link:first-child .list_link__item:first-child, .col_unit .list_link:first-child .list_link__item:first-child {
    margin-top: 0; }
  @media screen and (min-width: 769px) {
    .col.-pc_separate, .-pc_separate.col_13, .-pc_separate.col_12, .-pc_separate.col_11, .-pc_separate.col_10, .-pc_separate.col_9, .-pc_separate.col_8, .-pc_separate.col_7, .-pc_separate.col_6, .-pc_separate.col_5, .-pc_separate.col_4, .-pc_separate.col_3, .-pc_separate.col_2_5, .-pc_separate.col_2, .-pc_separate.col_1, .-pc_separate.col_unit {
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }

.col_13 {
  flex: 1 1 auto; }

.col_12 {
  flex: 100% 0 0;
  max-width: 100%; }
  @media screen and (max-width: 768px) {
    .col_12 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_11 {
  flex: 91.666% 0 0;
  max-width: 91.666%; }
  @media screen and (max-width: 768px) {
    .col_11 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_10 {
  flex: 83.333% 0 0;
  max-width: 83.333%; }
  @media screen and (max-width: 768px) {
    .col_10 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_9 {
  flex: 75% 0 0;
  max-width: 75%; }
  @media screen and (max-width: 768px) {
    .col_9 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_8 {
  flex: 66.666% 0 0;
  max-width: 66.666%; }
  @media screen and (max-width: 768px) {
    .col_8 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_7 {
  flex: 58.333% 0 0;
  max-width: 58.333%; }
  @media screen and (max-width: 768px) {
    .col_7 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_6 {
  flex: 50% 0 0;
  max-width: 50%; }
  @media screen and (max-width: 768px) {
    .col_6 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_5 {
  flex: 41.666% 0 0;
  max-width: 41.666%; }
  @media screen and (max-width: 768px) {
    .col_5 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_4 {
  flex: 33.333% 0 0;
  max-width: 33.333%; }
  @media screen and (max-width: 768px) {
    .col_4 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_3 {
  flex: 25% 0 0;
  max-width: 25%; }
  @media screen and (max-width: 768px) {
    .col_3 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_2_5 {
  flex: 25% 0 0;
  max-width: 25%;
  padding: 30px 0 0 0; }
  @media screen and (max-width: 768px) {
    .col_2_5 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_2 {
  flex: 16.666% 0 0;
  max-width: 16.666%; }
  @media screen and (max-width: 768px) {
    .col_2 {
      flex: 100% 0 0;
      max-width: 100%; } }

.col_1 {
  flex: 8.333% 0 0;
  max-width: 8.333%; }
  @media screen and (max-width: 768px) {
    .col_1 {
      flex: 100% 0 0;
      max-width: 100%; } }

@media screen and (max-width: 768px) {
  .col_sm_8 {
    flex: 66.666% 0 0;
    max-width: 66.666%; } }

@media screen and (max-width: 768px) {
  .col_sm_6 {
    flex: 50% 0 0;
    max-width: 50%; } }

@media screen and (max-width: 768px) {
  .col_sm_12 {
    flex: 100% 0 0;
    max-width: 100%; } }

@media screen and (max-width: 768px) {
  .col_sm_10 {
    flex: 83.333% 0 0;
    max-width: 83.333%; } }

@media screen and (max-width: 768px) {
  .col_sm_5 {
    flex: 41.666% 0 0;
    max-width: 41.666%; } }

@media screen and (max-width: 768px) {
  .col_sm_4 {
    flex: 33.333% 0 0;
    max-width: 33.333%; } }

@media screen and (max-width: 768px) {
  .col_sm_3 {
    flex: 25% 0 0;
    max-width: 25%; } }

@media screen and (max-width: 768px) {
  .col_sm_2 {
    flex: 16.666% 0 0;
    max-width: 16.666%; } }

@media screen and (max-width: 768px) {
  .col_sm_1 {
    flex: 8.333% 0 0;
    max-width: 8.333%; } }

/* sustainability */
.sustainability {
  /*関連するリンク共通設定*/ }
  .sustainability.top .title_secondly {
    text-align: center;
    border-left: none;
    margin: 80px 0; }
    @media screen and (max-width: 768px) {
      .sustainability.top .title_secondly {
        font-size: 2.0rem;
        background: url(/assets/img/common/ico_dots_title.png) 0 50% repeat-x;
        margin: 40px 0; } }
    .sustainability.top .title_secondly.mt100 {
      margin: 100px 0 80px; }
      @media screen and (max-width: 768px) {
        .sustainability.top .title_secondly.mt100 {
          margin: 60px 0 40px; } }
    @media screen and (max-width: 768px) {
      .sustainability.top .title_secondly .title_secondly__inner {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        background: #fff; } }
  @media screen and (max-width: 768px) {
    .sustainability .index_nav__content {
      margin-bottom: 35px; } }
  .sustainability .title_fourthly.related_title {
    margin: 90px 0 35px;
    padding-top: 80px;
    border-top: 1px solid #dcdcdc; }
  .sustainability .related .index_nav__content__body ul {
    margin-top: 50px; }
    .sustainability .related .index_nav__content__body ul li {
      margin-top: 20px; }
      @media screen and (max-width: 768px) {
        .sustainability .related .index_nav__content__body ul li {
          margin-top: 15px; } }
      .sustainability .related .index_nav__content__body ul li > a {
        color: #333;
        font-size: 1.6rem;
        font-weight: bold;
        text-decoration: none;
        text-indent: -1.5em;
        margin-left: 1.5em;
        display: block;
        transition: opacity 0.4s ease; }
        .sustainability .related .index_nav__content__body ul li > a:hover {
          opacity: 0.7;
          text-decoration: none; }
        .sustainability .related .index_nav__content__body ul li > a.current:hover {
          opacity: 1; }
        .sustainability .related .index_nav__content__body ul li > a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1.8rem;
          margin-right: 5px;
          font-weight: normal;
          color: #fe6400; }
  .sustainability.top .related .index_nav__inner {
    width: 1060px; }
    @media screen and (max-width: 768px) {
      .sustainability.top .related .index_nav__inner {
        width: 100%; } }
    .sustainability.top .related .index_nav__inner .index_nav__content {
      width: 330px; }
      @media screen and (max-width: 768px) {
        .sustainability.top .related .index_nav__inner .index_nav__content {
          width: 100%; } }
  .sustainability .related .index_nav__inner {
    width: 865px; }
    @media screen and (max-width: 768px) {
      .sustainability .related .index_nav__inner {
        width: 100%; } }
    .sustainability .related .index_nav__inner .index_nav__content {
      width: 275px; }
      @media screen and (max-width: 768px) {
        .sustainability .related .index_nav__inner .index_nav__content {
          width: 100%; } }
  .sustainability .anchor_link {
    width: 100%;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 30px 0 15px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px; }
    @media screen and (max-width: 768px) {
      .sustainability .anchor_link {
        padding: 15px 0 0;
        margin-bottom: 30px; } }
    .sustainability .anchor_link li {
      font-size: 1.6rem;
      font-weight: bold;
      margin-right: 50px;
      text-indent: -2rem;
      padding-left: 2rem;
      margin-bottom: 15px; }
      @media screen and (max-width: 768px) {
        .sustainability .anchor_link li {
          font-size: 1.3rem;
          margin-right: 40px;
          margin-bottom: 15px; } }
      .sustainability .anchor_link li a {
        text-decoration: none;
        color: #333;
        position: relative;
        padding-left: 20px; }
        .sustainability .anchor_link li a:hover {
          color: #fe6400; }
        .sustainability .anchor_link li a::before {
          font-family: 'icon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          text-indent: 0;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          color: #fe6400; }
  .sustainability .border_box {
    border: 1px solid #dcdcdc;
    padding: 50px;
    margin-top: 30px; }
    @media screen and (max-width: 768px) {
      .sustainability .border_box {
        padding: 30px; } }
    .sustainability .border_box h3 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 20px; }
      .sustainability .border_box h3 span {
        color: #fe6400; }
      @media screen and (max-width: 768px) {
        .sustainability .border_box h3 {
          font-size: 1.7rem;
          margin-bottom: 10px; } }
    .sustainability .border_box .right {
      text-align: right; }
  .sustainability h4 {
    border-left: 3px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1.6rem;
    padding: 8px 0 10px 25px;
    margin: 40px 0 20px;
    display: block; }
    .sustainability h4 p {
      text-indent: -1.0em;
      margin-right: 1.0em; }
    .sustainability h4 span {
      color: #bbbbbb;
      margin-right: 5px; }
  .sustainability .policy {
    background: #f7f7f7;
    padding: 50px; }
    @media screen and (max-width: 768px) {
      .sustainability .policy {
        padding: 25px; } }
    .sustainability .policy p {
      text-align: center;
      margin-bottom: 35px; }
      @media screen and (max-width: 768px) {
        .sustainability .policy p {
          text-align: left; } }
    .sustainability .policy ol {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.8; }
      .sustainability .policy ol li {
        margin-bottom: 15px;
        text-indent: -1em;
        padding-left: 1em; }
  .sustainability table {
    width: 100%;
    margin: 40px 0 50px; }
    @media screen and (max-width: 768px) {
      .sustainability table {
        margin: 20px 0 30px; } }
    .sustainability table tr > * {
      border-left: #808080 solid 1px; }
    .sustainability table tr:first-child {
      border-top: #808080 solid 1px; }
      @media screen and (max-width: 768px) {
        .sustainability table tr:first-child td {
          text-align: center; } }
    .sustainability table th {
      border-right: #808080 solid 1px;
      border-bottom: #808080 solid 1px;
      background: #ffeec5;
      padding: 20px 15px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .sustainability table th {
          width: 40%;
          padding: 15px 10px; } }
    .sustainability table td {
      border-right: #808080 solid 1px;
      border-bottom: #808080 solid 1px;
      padding: 20px 15px; }
      @media screen and (max-width: 768px) {
        .sustainability table td {
          width: 30%;
          padding: 15px 10px; } }
  @media screen and (max-width: 768px) {
    .sustainability .pc_only {
      display: none; } }
  .sustainability .sp_only {
    display: none; }
    @media screen and (max-width: 768px) {
      .sustainability .sp_only {
        display: block; } }
  @media screen and (max-width: 768px) {
    .sustainability #sec_01,
    .sustainability #sec_02,
    .sustainability #sec_03,
    .sustainability #sec_04 {
      display: block;
      margin-top: -47px;
      padding-top: 47px; } }

.commitment .-sustainability_commitment {
  background-image: url(/assets/img/sustainability/commitment/img_visual.jpg); }

.commitment .commitment_main_img {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 865px; }
  @media screen and (max-width: 768px) {
    .commitment .commitment_main_img {
      width: 100%; } }
  .commitment .commitment_main_img .title_fourthly {
    position: absolute;
    left: 430px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    text-align: left; }
    @media screen and (max-width: 768px) {
      .commitment .commitment_main_img .title_fourthly {
        left: 55%; } }

.sustainability.environment .row {
  margin: 0 0 0 -15px; }
  .sustainability.environment .row div {
    margin-left: -2px; }

.sustainability.environment .intertek_img.pc_only {
  float: right;
  width: 240px;
  padding: 0 0 20px 30px; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .intertek_img.pc_only {
      display: none; } }

.sustainability.environment .intertek_img.sp_only {
  display: none; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .intertek_img.sp_only {
      display: block;
      width: 250px;
      margin: 25px auto 0; } }

.sustainability.environment .intertek_img img {
  width: 100%; }

.sustainability.environment .border_box .thumb img {
  width: 656px; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .border_box .thumb img {
      width: 100%; } }

.sustainability.environment h3 {
  margin-top: 50px; }

.sustainability.environment p .orange {
  color: #fe5e00; }

.sustainability.environment table td:nth-child(n+2) {
  text-align: right; }

.sustainability.environment dl {
  border: #4d97ce solid 1px;
  margin-top: 30px; }
  @media screen and (max-width: 768px) {
    .sustainability.environment dl {
      margin-top: 20px; } }
  .sustainability.environment dl.wide dd {
    display: inline-block;
    width: 49%;
    padding: 25px 0 30px;
    box-sizing: border-box;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .sustainability.environment dl.wide dd {
        display: block;
        width: auto;
        padding: 20px 25px; } }
    .sustainability.environment dl.wide dd span {
      display: block;
      font-weight: bold;
      font-size: 1.8rem; }
      .sustainability.environment dl.wide dd span:first-child {
        margin-bottom: 20px; }
      .sustainability.environment dl.wide dd span img {
        width: 340px; }
        @media screen and (max-width: 768px) {
          .sustainability.environment dl.wide dd span img {
            width: 100%; } }
  .sustainability.environment dl.middle {
    width: 550px;
    margin: 30px auto 0; }
    @media screen and (max-width: 768px) {
      .sustainability.environment dl.middle {
        width: 100%;
        margin-top: 20px; } }
    .sustainability.environment dl.middle dd {
      text-align: center;
      font-size: 1.6rem; }
      @media screen and (max-width: 768px) {
        .sustainability.environment dl.middle dd {
          text-align: left;
          font-size: 1.4rem;
          line-height: 1.8; } }
  .sustainability.environment dl dt {
    background: #4d97ce;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 2rem; }
    @media screen and (max-width: 768px) {
      .sustainability.environment dl dt {
        padding: 10px; } }
  .sustainability.environment dl dd {
    padding: 25px 30px; }

.sustainability.environment .col-2 {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .col-2 {
      display: block; } }
  .sustainability.environment .col-2 dl {
    width: 420px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .col-2 dl {
        width: 100%;
        margin-top: 20px; } }
    .sustainability.environment .col-2 dl dd {
      font-size: 1.6rem;
      line-height: 1.8; }
      @media screen and (max-width: 768px) {
        .sustainability.environment .col-2 dl dd {
          font-size: 1.4rem; } }

.sustainability.environment .topics {
  background: #f7f7f7;
  padding: 50px;
  text-align: center;
  margin-top: 90px; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .topics {
      padding: 30px 25px;
      margin-top: 40px; } }
  .sustainability.environment .topics p {
    margin-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .topics p {
        text-align: left; } }
    .sustainability.environment .topics p.topics_title {
      margin-bottom: 25px; }
      @media screen and (max-width: 768px) {
        .sustainability.environment .topics p.topics_title {
          text-align: center; } }
      .sustainability.environment .topics p.topics_title strong {
        font-weight: bold;
        color: #fe5e00;
        display: block;
        font-size: 2.6rem;
        line-height: 125%; }
    .sustainability.environment .topics p.text {
      text-align: left; }
    .sustainability.environment .topics p.caption {
      margin: 10px 0 0; }
      @media screen and (max-width: 768px) {
        .sustainability.environment .topics p.caption {
          text-align: center; } }
  .sustainability.environment .topics .action_list {
    margin-bottom: 70px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .topics .action_list {
        margin-bottom: 30px; } }
    .sustainability.environment .topics .action_list img {
      width: 764px; }
      @media screen and (max-width: 768px) {
        .sustainability.environment .topics .action_list img {
          width: 100%; } }
  .sustainability.environment .topics .sdgs img {
    width: 340px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .topics .sdgs img {
        width: 60%; } }

.sustainability.environment .col_3 {
  padding-right: 0; }
  .sustainability.environment .col_3 img {
    width: 100%; }

.sustainability.environment .col_4 {
  padding-right: 0; }
  .sustainability.environment .col_4 img {
    width: 100%; }

.sustainability.environment .col_6.img {
  padding: 30px 0 0 20px; }

.sustainability.environment .col_6 .thumb {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .sustainability.environment .col_6 .thumb {
      display: block; } }
  .sustainability.environment .col_6 .thumb img:first-child {
    width: 203px;
    margin-right: 10px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .col_6 .thumb img:first-child {
        width: 100%;
        margin: 0 0 10px 0; } }
  .sustainability.environment .col_6 .thumb img:last-child {
    width: 216px; }
    @media screen and (max-width: 768px) {
      .sustainability.environment .col_6 .thumb img:last-child {
        width: 100%; } }

@media screen and (max-width: 768px) {
  .sustainability.environment .mt80 {
    margin-top: 40px !important; } }

.social .policy .policy_title {
  text-align: center;
  font-weight: bold;
  color: #ff6400;
  font-size: 1.8rem;
  margin-bottom: 30px; }

.social .policy p {
  text-align: left;
  margin-bottom: 0; }

.social .social_orange_title {
  text-align: center;
  color: #ff6400;
  margin-bottom: 10px;
  font-size: 1.8rem !important; }

@media screen and (max-width: 768px) {
  .social.customers .title_fourthly {
    text-align: center; } }

.social.customers .iso_block {
  display: flex;
  justify-content: space-between;
  width: 765px;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .social.customers .iso_block {
      display: block;
      width: 100%;
      padding: 0; } }
  .social.customers .iso_block .iso {
    width: 485px; }
    @media screen and (max-width: 768px) {
      .social.customers .iso_block .iso {
        width: 80%;
        padding: 20px 0 0 0 !important;
        margin: 0 auto; } }
  .social.customers .iso_block .iso_img {
    width: 260px; }
    @media screen and (max-width: 768px) {
      .social.customers .iso_block .iso_img {
        width: 250px;
        margin: 20px auto 40px; } }

.social.customers ol {
  font-weight: 700;
  font-size: 1.6rem;
  margin-top: 30px;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .social.customers ol {
      font-size: 1.4rem;
      margin-top: 20px; } }
  .social.customers ol li {
    margin-top: 10px;
    text-indent: -1.5em;
    padding-left: 1.5em; }

.social.customers .border_box {
  padding: 45px 105px 85px; }
  @media screen and (max-width: 768px) {
    .social.customers .border_box {
      padding: 30px; } }

.social.customers .caption {
  font-size: 1.4rem; }

.social.customers .center_photo {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    .social.customers .center_photo {
      display: block; } }
  .social.customers .center_photo .photo {
    width: 275px; }
    @media screen and (max-width: 768px) {
      .social.customers .center_photo .photo {
        width: 100%;
        margin: 30px auto 0; } }

.social.customers .customers_photo {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px; }
  @media screen and (max-width: 768px) {
    .social.customers .customers_photo {
      display: block; } }
  .social.customers .customers_photo .photo:first-child {
    width: 300px; }
    @media screen and (max-width: 768px) {
      .social.customers .customers_photo .photo:first-child {
        width: 100%; } }
  .social.customers .customers_photo .photo:nth-child(2) {
    width: 350px; }
    @media screen and (max-width: 768px) {
      .social.customers .customers_photo .photo:nth-child(2) {
        width: 100%; } }
  .social.customers .customers_photo .photo:last-child {
    width: 175px; }
    @media screen and (max-width: 768px) {
      .social.customers .customers_photo .photo:last-child {
        width: 100%;
        max-width: 250px; } }
  @media screen and (max-width: 768px) {
    .social.customers .customers_photo .photo {
      margin: 30px auto 0; } }

@media screen and (max-width: 768px) {
  .social.employee .title_fourthly {
    text-align: center; } }

.social.employee .border_box {
  margin-bottom: 60px; }
  .social.employee .border_box h3 {
    text-indent: -1.2em;
    margin-left: 1.2em; }
  .social.employee .border_box figure {
    max-width: 600px;
    margin: 40px auto; }
  .social.employee .border_box dl {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
    border-top: 1px solid #dcdcdc;
    padding: 10px 0;
    line-height: 1.8; }
    @media screen and (max-width: 768px) {
      .social.employee .border_box dl {
        display: block;
        font-size: 1.3rem; } }
    .social.employee .border_box dl.top {
      border-top: none; }
    .social.employee .border_box dl dt {
      font-weight: bold;
      width: 8.0em; }
      @media screen and (max-width: 768px) {
        .social.employee .border_box dl dt {
          width: 100%; } }
    .social.employee .border_box dl:last-child {
      margin-bottom: -10px; }
    @media screen and (max-width: 768px) {
      .social.employee .border_box dl .indent {
        display: inline-block;
        text-indent: -1.0em;
        margin-left: 1.0em; } }

.social.employee .callenge_image {
  max-width: 750px;
  margin: 50px auto 60px; }

@media screen and (max-width: 768px) {
  .social.employee .women_image {
    max-width: 325px;
    margin: 0 auto; } }

.social.employee .sp_scroll {
  margin: 30px 0 40px; }
  @media screen and (max-width: 768px) {
    .social.employee .sp_scroll {
      margin: 10px 0;
      overflow: scroll; } }
  .social.employee .sp_scroll table {
    margin: 10px 0; }
    @media screen and (max-width: 768px) {
      .social.employee .sp_scroll table {
        width: 800px; } }
    @media screen and (max-width: 768px) {
      .social.employee .sp_scroll table th, .social.employee .sp_scroll table td {
        padding: 10px 3px; }
        .social.employee .sp_scroll table th:first-child, .social.employee .sp_scroll table td:first-child {
          width: 35%; }
        .social.employee .sp_scroll table th:nth-child(2), .social.employee .sp_scroll table td:nth-child(2) {
          width: 13%; }
        .social.employee .sp_scroll table th:nth-child(3), .social.employee .sp_scroll table td:nth-child(3) {
          width: 13%; }
        .social.employee .sp_scroll table th:nth-child(4), .social.employee .sp_scroll table td:nth-child(4) {
          width: 13%; }
        .social.employee .sp_scroll table th:nth-child(5), .social.employee .sp_scroll table td:nth-child(5) {
          width: 13%; }
        .social.employee .sp_scroll table th:last-child, .social.employee .sp_scroll table td:last-child {
          width: 13%; } }
    .social.employee .sp_scroll table td {
      text-align: right; }
      .social.employee .sp_scroll table td:first-child {
        text-align: left; }

.social.employee h4 {
  padding: 8px 0 10px 15px; }
  .social.employee h4.social_orange_title {
    text-align: left;
    margin-bottom: 10px;
    border: none;
    padding: 0; }

.social.employee .skill {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px; }
  .social.employee .skill .list {
    width: 360px;
    padding: 30px;
    font-size: 1.6rem;
    font-weight: bold;
    border: 1px solid #dcdcdc;
    margin-bottom: 20px; }
    .social.employee .skill .list h4 {
      margin-top: 0px; }
      @media screen and (max-width: 768px) {
        .social.employee .skill .list h4 {
          font-size: 1.4rem !important;
          margin-bottom: 5px; } }
    @media screen and (max-width: 768px) {
      .social.employee .skill .list {
        width: 100%;
        font-size: 1.3rem; } }

@media screen and (max-width: 768px) {
  .social.suppliers .title_fourthly {
    font-size: 2.0rem;
    margin-bottom: 30px; } }

.social.suppliers .border_box ._title {
  text-align: center;
  color: #ff6400;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .social.suppliers .border_box ._title {
      text-align: left; } }

.social.suppliers .border_box li {
  font-size: 1.6rem;
  text-indent: -1.5rem;
  padding-left: 1.5rem;
  line-height: 190%;
  margin-bottom: 30px; }
  .social.suppliers .border_box li:first-child {
    margin-bottom: 20px; }
    .social.suppliers .border_box li:first-child::before {
      content: "1.";
      color: #ff6400;
      font-weight: bold; }
  .social.suppliers .border_box li:nth-child(2) {
    font-size: 1.4rem;
    line-height: 145%;
    text-indent: 0; }
    @media screen and (max-width: 768px) {
      .social.suppliers .border_box li:nth-child(2) {
        line-height: 190%; } }
  .social.suppliers .border_box li:last-child::before {
    content: "2.";
    color: #ff6400;
    font-weight: bold; }

.social.suppliers .border_box dl {
  margin-bottom: 30px;
  font-size: 1.6rem; }
  .social.suppliers .border_box dl dt {
    font-weight: bold;
    color: #ff6400;
    margin-bottom: 10px; }
  .social.suppliers .border_box dl dd {
    line-height: 190%; }

.social.suppliers .border_box .date {
  text-align: right;
  font-size: 1.6rem; }

.social.suppliers .col, .social.suppliers .col_13, .social.suppliers .col_12, .social.suppliers .col_11, .social.suppliers .col_10, .social.suppliers .col_9, .social.suppliers .col_8, .social.suppliers .col_7, .social.suppliers .col_6, .social.suppliers .col_5, .social.suppliers .col_4, .social.suppliers .col_3, .social.suppliers .col_2_5, .social.suppliers .col_2, .social.suppliers .col_1, .social.suppliers .col_unit {
  display: flex;
  justify-content: space-between;
  padding: 0; }
  @media screen and (max-width: 768px) {
    .social.suppliers .col, .social.suppliers .col_13, .social.suppliers .col_12, .social.suppliers .col_11, .social.suppliers .col_10, .social.suppliers .col_9, .social.suppliers .col_8, .social.suppliers .col_7, .social.suppliers .col_6, .social.suppliers .col_5, .social.suppliers .col_4, .social.suppliers .col_3, .social.suppliers .col_2_5, .social.suppliers .col_2, .social.suppliers .col_1, .social.suppliers .col_unit {
      display: block; } }
  .social.suppliers .col .text_box, .social.suppliers .col_13 .text_box, .social.suppliers .col_12 .text_box, .social.suppliers .col_11 .text_box, .social.suppliers .col_10 .text_box, .social.suppliers .col_9 .text_box, .social.suppliers .col_8 .text_box, .social.suppliers .col_7 .text_box, .social.suppliers .col_6 .text_box, .social.suppliers .col_5 .text_box, .social.suppliers .col_4 .text_box, .social.suppliers .col_3 .text_box, .social.suppliers .col_2_5 .text_box, .social.suppliers .col_2 .text_box, .social.suppliers .col_1 .text_box, .social.suppliers .col_unit .text_box {
    display: inline-block;
    width: 560px; }
    @media screen and (max-width: 768px) {
      .social.suppliers .col .text_box, .social.suppliers .col_13 .text_box, .social.suppliers .col_12 .text_box, .social.suppliers .col_11 .text_box, .social.suppliers .col_10 .text_box, .social.suppliers .col_9 .text_box, .social.suppliers .col_8 .text_box, .social.suppliers .col_7 .text_box, .social.suppliers .col_6 .text_box, .social.suppliers .col_5 .text_box, .social.suppliers .col_4 .text_box, .social.suppliers .col_3 .text_box, .social.suppliers .col_2_5 .text_box, .social.suppliers .col_2 .text_box, .social.suppliers .col_1 .text_box, .social.suppliers .col_unit .text_box {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; } }
  .social.suppliers .col .img_box, .social.suppliers .col_13 .img_box, .social.suppliers .col_12 .img_box, .social.suppliers .col_11 .img_box, .social.suppliers .col_10 .img_box, .social.suppliers .col_9 .img_box, .social.suppliers .col_8 .img_box, .social.suppliers .col_7 .img_box, .social.suppliers .col_6 .img_box, .social.suppliers .col_5 .img_box, .social.suppliers .col_4 .img_box, .social.suppliers .col_3 .img_box, .social.suppliers .col_2_5 .img_box, .social.suppliers .col_2 .img_box, .social.suppliers .col_1 .img_box, .social.suppliers .col_unit .img_box {
    display: inline-block;
    width: 275px;
    padding-top: 12px; }
    @media screen and (max-width: 768px) {
      .social.suppliers .col .img_box, .social.suppliers .col_13 .img_box, .social.suppliers .col_12 .img_box, .social.suppliers .col_11 .img_box, .social.suppliers .col_10 .img_box, .social.suppliers .col_9 .img_box, .social.suppliers .col_8 .img_box, .social.suppliers .col_7 .img_box, .social.suppliers .col_6 .img_box, .social.suppliers .col_5 .img_box, .social.suppliers .col_4 .img_box, .social.suppliers .col_3 .img_box, .social.suppliers .col_2_5 .img_box, .social.suppliers .col_2 .img_box, .social.suppliers .col_1 .img_box, .social.suppliers .col_unit .img_box {
        width: 100%;
        padding: 0; } }
    .social.suppliers .col .img_box img, .social.suppliers .col_13 .img_box img, .social.suppliers .col_12 .img_box img, .social.suppliers .col_11 .img_box img, .social.suppliers .col_10 .img_box img, .social.suppliers .col_9 .img_box img, .social.suppliers .col_8 .img_box img, .social.suppliers .col_7 .img_box img, .social.suppliers .col_6 .img_box img, .social.suppliers .col_5 .img_box img, .social.suppliers .col_4 .img_box img, .social.suppliers .col_3 .img_box img, .social.suppliers .col_2_5 .img_box img, .social.suppliers .col_2 .img_box img, .social.suppliers .col_1 .img_box img, .social.suppliers .col_unit .img_box img {
      width: 100%; }

.governance .policy .policy_title {
  text-align: center;
  font-weight: bold;
  color: #ff6400;
  font-size: 1.8rem;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .governance .policy .policy_title {
      margin-bottom: 20px; } }

.governance .policy p {
  text-align: left;
  margin-bottom: 0; }

.governance .policy.type01 dl {
  font-size: 1.6rem;
  line-height: 1.6; }
  @media screen and (max-width: 768px) {
    .governance .policy.type01 dl {
      font-size: 1.4rem; } }
  .governance .policy.type01 dl:first-child {
    margin-bottom: 25px; }
  .governance .policy.type01 dl dt {
    display: inline-block;
    width: 100px;
    font-weight: bold;
    color: #ff6400;
    vertical-align: top; }
    @media screen and (max-width: 768px) {
      .governance .policy.type01 dl dt {
        display: block;
        width: 100%; } }
  .governance .policy.type01 dl dd {
    display: inline-block;
    width: 650px; }
    @media screen and (max-width: 768px) {
      .governance .policy.type01 dl dd {
        display: block;
        width: 100%; } }
    .governance .policy.type01 dl dd ul li {
      margin-top: 5px;
      text-indent: -1em;
      padding-left: 1em; }
      .governance .policy.type01 dl dd ul li::before {
        content: "●";
        color: #ff6400;
        font-size: 1.2rem;
        margin-right: 5px; }

.governance .policy.type02 {
  margin-top: 50px; }
  @media screen and (max-width: 768px) {
    .governance .policy.type02 {
      margin-top: 25px; } }
  .governance .policy.type02 .policy_title {
    text-align: left;
    font-size: 1.6rem;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .governance .policy.type02 .policy_title {
        margin-bottom: 10px; } }
  .governance .policy.type02 dl {
    font-size: 1.6rem;
    margin-bottom: 30px; }
    .governance .policy.type02 dl:last-child {
      margin: 0; }
  .governance .policy.type02 dt {
    font-weight: bold;
    margin-bottom: 10px; }
  .governance .policy.type02 dd {
    line-height: 1.8; }
  .governance .policy.type02 ul li {
    margin-top: 10px;
    text-indent: -1em;
    padding-left: 1em; }
    .governance .policy.type02 ul li::before {
      content: "●";
      color: #ff6400;
      font-size: 1.2rem;
      margin-right: 5px; }

.governance h3.title_sixthly {
  margin-top: 50px; }
  @media screen and (max-width: 768px) {
    .governance h3.title_sixthly {
      margin-top: 30px; } }

.governance h3.title_thirdly {
  margin-top: 40px; }
  @media screen and (max-width: 768px) {
    .governance h3.title_thirdly {
      margin-top: 20px; } }

.governance .caption {
  font-size: 1.4rem;
  margin-top: 10px; }
  @media screen and (max-width: 768px) {
    .governance .caption {
      text-indent: -1em;
      padding-left: 1em; } }

.governance .border_box {
  padding: 30px;
  margin-bottom: 30px; }
  @media screen and (max-width: 768px) {
    .governance .border_box {
      padding: 10px 15px;
      margin-bottom: 20px; } }
  .governance .border_box strong {
    font-size: 1.6rem; }
  .governance .border_box p {
    font-weight: bold;
    color: #ff6400;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .governance .border_box p {
        margin-bottom: 10px; } }
  .governance .border_box ol {
    font-size: 1.6rem; }
    .governance .border_box ol li {
      margin-bottom: 10px;
      line-height: 1.4em;
      text-indent: -1em;
      padding-left: 1em; }

.governance .link_text {
  margin-top: 10px;
  font-size: 1.6rem;
  text-indent: -1.25em;
  padding-left: 1.25em; }
  .governance .link_text a {
    text-decoration: none;
    color: #333;
    transition: opacity 0.4s ease; }
    .governance .link_text a:hover {
      opacity: 0.7;
      text-decoration: none; }
    .governance .link_text a.current:hover {
      opacity: 1; }
    .governance .link_text a::before {
      font-family: 'icon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      text-indent: 0;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      font-size: 1.4rem;
      margin-right: 5px;
      color: #fe6400; }

.governance.corporate .sp_scroll {
  margin-bottom: 10px; }
  @media screen and (max-width: 768px) {
    .governance.corporate .sp_scroll {
      overflow: scroll; } }
  .governance.corporate .sp_scroll table {
    margin-bottom: 10px; }
    @media screen and (max-width: 768px) {
      .governance.corporate .sp_scroll table {
        width: 900px; } }
    .governance.corporate .sp_scroll table th {
      font-size: 1.4rem;
      padding: 20px 5px;
      vertical-align: middle; }
      @media screen and (max-width: 768px) {
        .governance.corporate .sp_scroll table th {
          padding: 10px 3px; }
          .governance.corporate .sp_scroll table th:first-child {
            width: 10%; }
          .governance.corporate .sp_scroll table th:nth-child(2) {
            width: 7%; }
          .governance.corporate .sp_scroll table th:nth-child(3) {
            width: 26%; }
          .governance.corporate .sp_scroll table th:nth-child(4) {
            width: 10%; }
          .governance.corporate .sp_scroll table th:nth-child(5) {
            width: 12%; }
          .governance.corporate .sp_scroll table th:nth-child(6) {
            width: 10%; }
          .governance.corporate .sp_scroll table th:nth-child(7) {
            width: 10%; }
          .governance.corporate .sp_scroll table th:last-child {
            width: 15%; } }
    .governance.corporate .sp_scroll table td {
      font-size: 1.4rem;
      padding: 20px 5px;
      vertical-align: middle;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .governance.corporate .sp_scroll table td {
          padding: 10px 3px; }
          .governance.corporate .sp_scroll table td:first-child {
            width: 10%; }
          .governance.corporate .sp_scroll table td:nth-child(2) {
            width: 7%; }
          .governance.corporate .sp_scroll table td:nth-child(3) {
            width: 13%; }
          .governance.corporate .sp_scroll table td:nth-child(4) {
            width: 13%; }
          .governance.corporate .sp_scroll table td:nth-child(5) {
            width: 10%; }
          .governance.corporate .sp_scroll table td:nth-child(6) {
            width: 12%; }
          .governance.corporate .sp_scroll table td:nth-child(7) {
            width: 10%; }
          .governance.corporate .sp_scroll table td:nth-child(8) {
            width: 10%; }
          .governance.corporate .sp_scroll table td:last-child {
            width: 15%; } }
      .governance.corporate .sp_scroll table td.circle {
        font-size: 1.8rem; }

@media screen and (max-width: 768px) {
  .governance.corporate .text + .text {
    margin-top: 1rem; } }

.governance.risk-management .col, .governance.risk-management .col_13, .governance.risk-management .col_12, .governance.risk-management .col_11, .governance.risk-management .col_10, .governance.risk-management .col_9, .governance.risk-management .col_8, .governance.risk-management .col_7, .governance.risk-management .col_6, .governance.risk-management .col_5, .governance.risk-management .col_4, .governance.risk-management .col_3, .governance.risk-management .col_2_5, .governance.risk-management .col_2, .governance.risk-management .col_1, .governance.risk-management .col_unit {
  display: flex;
  justify-content: space-between;
  padding: 0; }
  @media screen and (max-width: 768px) {
    .governance.risk-management .col, .governance.risk-management .col_13, .governance.risk-management .col_12, .governance.risk-management .col_11, .governance.risk-management .col_10, .governance.risk-management .col_9, .governance.risk-management .col_8, .governance.risk-management .col_7, .governance.risk-management .col_6, .governance.risk-management .col_5, .governance.risk-management .col_4, .governance.risk-management .col_3, .governance.risk-management .col_2_5, .governance.risk-management .col_2, .governance.risk-management .col_1, .governance.risk-management .col_unit {
      display: block; } }
  .governance.risk-management .col .text_box, .governance.risk-management .col_13 .text_box, .governance.risk-management .col_12 .text_box, .governance.risk-management .col_11 .text_box, .governance.risk-management .col_10 .text_box, .governance.risk-management .col_9 .text_box, .governance.risk-management .col_8 .text_box, .governance.risk-management .col_7 .text_box, .governance.risk-management .col_6 .text_box, .governance.risk-management .col_5 .text_box, .governance.risk-management .col_4 .text_box, .governance.risk-management .col_3 .text_box, .governance.risk-management .col_2_5 .text_box, .governance.risk-management .col_2 .text_box, .governance.risk-management .col_1 .text_box, .governance.risk-management .col_unit .text_box {
    display: inline-block;
    width: 576px; }
    @media screen and (max-width: 768px) {
      .governance.risk-management .col .text_box, .governance.risk-management .col_13 .text_box, .governance.risk-management .col_12 .text_box, .governance.risk-management .col_11 .text_box, .governance.risk-management .col_10 .text_box, .governance.risk-management .col_9 .text_box, .governance.risk-management .col_8 .text_box, .governance.risk-management .col_7 .text_box, .governance.risk-management .col_6 .text_box, .governance.risk-management .col_5 .text_box, .governance.risk-management .col_4 .text_box, .governance.risk-management .col_3 .text_box, .governance.risk-management .col_2_5 .text_box, .governance.risk-management .col_2 .text_box, .governance.risk-management .col_1 .text_box, .governance.risk-management .col_unit .text_box {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; } }
  .governance.risk-management .col .img_box, .governance.risk-management .col_13 .img_box, .governance.risk-management .col_12 .img_box, .governance.risk-management .col_11 .img_box, .governance.risk-management .col_10 .img_box, .governance.risk-management .col_9 .img_box, .governance.risk-management .col_8 .img_box, .governance.risk-management .col_7 .img_box, .governance.risk-management .col_6 .img_box, .governance.risk-management .col_5 .img_box, .governance.risk-management .col_4 .img_box, .governance.risk-management .col_3 .img_box, .governance.risk-management .col_2_5 .img_box, .governance.risk-management .col_2 .img_box, .governance.risk-management .col_1 .img_box, .governance.risk-management .col_unit .img_box {
    display: inline-block;
    width: 227px; }
    @media screen and (max-width: 768px) {
      .governance.risk-management .col .img_box, .governance.risk-management .col_13 .img_box, .governance.risk-management .col_12 .img_box, .governance.risk-management .col_11 .img_box, .governance.risk-management .col_10 .img_box, .governance.risk-management .col_9 .img_box, .governance.risk-management .col_8 .img_box, .governance.risk-management .col_7 .img_box, .governance.risk-management .col_6 .img_box, .governance.risk-management .col_5 .img_box, .governance.risk-management .col_4 .img_box, .governance.risk-management .col_3 .img_box, .governance.risk-management .col_2_5 .img_box, .governance.risk-management .col_2 .img_box, .governance.risk-management .col_1 .img_box, .governance.risk-management .col_unit .img_box {
        display: block;
        width: 60%;
        margin: 0 auto; } }
    .governance.risk-management .col .img_box img, .governance.risk-management .col_13 .img_box img, .governance.risk-management .col_12 .img_box img, .governance.risk-management .col_11 .img_box img, .governance.risk-management .col_10 .img_box img, .governance.risk-management .col_9 .img_box img, .governance.risk-management .col_8 .img_box img, .governance.risk-management .col_7 .img_box img, .governance.risk-management .col_6 .img_box img, .governance.risk-management .col_5 .img_box img, .governance.risk-management .col_4 .img_box img, .governance.risk-management .col_3 .img_box img, .governance.risk-management .col_2_5 .img_box img, .governance.risk-management .col_2 .img_box img, .governance.risk-management .col_1 .img_box img, .governance.risk-management .col_unit .img_box img {
      width: 100%; }
  @media screen and (max-width: 768px) {
    .governance.risk-management .col .caption, .governance.risk-management .col_13 .caption, .governance.risk-management .col_12 .caption, .governance.risk-management .col_11 .caption, .governance.risk-management .col_10 .caption, .governance.risk-management .col_9 .caption, .governance.risk-management .col_8 .caption, .governance.risk-management .col_7 .caption, .governance.risk-management .col_6 .caption, .governance.risk-management .col_5 .caption, .governance.risk-management .col_4 .caption, .governance.risk-management .col_3 .caption, .governance.risk-management .col_2_5 .caption, .governance.risk-management .col_2 .caption, .governance.risk-management .col_1 .caption, .governance.risk-management .col_unit .caption {
      text-indent: 0em;
      padding-left: 0em;
      text-align: center; } }

.governance.risk-management .col2 {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-top: 35px; }
  @media screen and (max-width: 768px) {
    .governance.risk-management .col2 {
      display: block; } }
  .governance.risk-management .col2 .img_box1 {
    display: inline-block;
    width: 275px; }
    @media screen and (max-width: 768px) {
      .governance.risk-management .col2 .img_box1 {
        display: block;
        width: 70%;
        margin: 0 auto 20px; } }
    .governance.risk-management .col2 .img_box1 img {
      width: 100%; }
  .governance.risk-management .col2 .img_box2 {
    display: inline-block;
    width: 565px; }
    @media screen and (max-width: 768px) {
      .governance.risk-management .col2 .img_box2 {
        display: block;
        width: 100%;
        margin-bottom: 20px; } }
    .governance.risk-management .col2 .img_box2 img {
      width: 100%; }
