.page_header {
	background: 50% 50% no-repeat;
	background-size: cover;
	padding: 0 20px;


	&.-about {

		&::after {
			@include mq(sp, true) {
				background: url(/assets/img/about/img_visual.jpg) 50% 50% no-repeat #222;
				background-size: cover;
			}
			@include mq(sp) {
				background: url(/assets/img/about/img_visual_sp.jpg) 50% 40px no-repeat #222;
				background-size: cover;
			}
		}
	}

	&.-about_technical {

		&::after {
			@include mq(sp, true) {
				background: url(/assets/img/about/technical/img_visual.jpg) 50% 50% no-repeat #222;
				background-size: cover;
			}
			@include mq(sp) {
				background: url(/assets/img/about/technical/img_visual_sp.jpg) 50% 40px no-repeat #222;
				background-size: cover;
			}
		}
	}

	&.-about_procurement {

		&::after {
			@include mq(sp, true) {
				background: url(/assets/img/about/procurement/img_visual.jpg) 50% 50% no-repeat #222;
				background-size: cover;
			}
			@include mq(sp) {
				background: url(/assets/img/about/procurement/img_visual_sp.jpg) 50% 40px no-repeat #222;
				background-size: cover;
			}
		}
	}

	&.-about_information {

		&::after {
			@include mq(sp, true) {
				background: url(/assets/img/about/information/img_visual.jpg) 50% 50% no-repeat #222;
				background-size: cover;
			}
			@include mq(sp) {
				background: url(/assets/img/about/information/img_visual_sp.jpg) 50% 40px no-repeat #222;
				background-size: cover;
			}
		}
	}

	&.-about_human {

		&::after {
			@include mq(sp, true) {
				background: url(/assets/img/about/human/img_visual.jpg) 50% 50% no-repeat #222;
				background-size: cover;
			}
			@include mq(sp) {
				background: url(/assets/img/about/human/img_visual_sp.jpg) 50% 40px no-repeat #222;
				background-size: cover;
			}
		}
	}

	&.-company {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/img_visual_sp.jpg);
		}
	}

	&.-company_profile {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/profile/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/profile/img_visual_sp.jpg);
		}
	}

	&.-company_history {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/history/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/history/img_visual_sp.jpg);
		}
	}

	&.-company_network {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/network/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/network/img_visual_sp.jpg);
		}
	}

	&.-company_officer {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/officer/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/officer/img_visual_sp.jpg);
		}
	}

	&.-company_rinen {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/rinen/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/rinen/img_visual_sp.jpg);
		}
	}

	&.-company_message {
		@include mq(sp, true) {
			background-image: url(/assets/img/company/message/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/company/message/img_visual_sp.jpg);
		}
	}

	&.-ir {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/img_visual_sp.jpg);
		}
	}

	&.-ir_qualification {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/qualification/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/qualification/img_visual_sp.jpg);
		}
	}

	&.-ir_stockholderinformation {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/stockholderinformation/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/stockholderinformation/img_visual.jpg);
		}
	}

	&.-ir_investor {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/investor/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/investor/img_visual_sp.jpg);
		}
	}

	&.-ir_calendar {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/calendar/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/calendar/img_visual_sp.jpg);
		}
	}

	&.-ir_electron {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/electron/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/electron/img_visual_sp.jpg);
		}
	}

	&.-ir_faq {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/faq/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/faq/img_visual_sp.jpg);
		}
	}

	&.-ir_governance {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/governance/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/governance/img_visual_sp.jpg);
		}
	}

	&.-ir_highlight {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/highlight/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/highlight/img_visual_sp.jpg);
		}
	}

	&.-ir_library {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/library/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/library/img_visual_sp.jpg);
		}
	}

	&.-ir_documents {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/documents/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/documents/img_visual_sp.jpg);
		}
	}

	&.-ir_chukei {
		@include mq(sp, true) {
			background-image: url(/assets/img/ir/chukei/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/ir/chukei/img_visual_sp.jpg);
		}
	}

	&.-enterprise {
		@include mq(sp, true) {
			background-image: url(/assets/img/enterprise/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/enterprise/img_visual_sp.jpg);
		}
	}

	&.-enterprise_logistic {
		@include mq(sp, true) {
			background-image: url(/assets/img/enterprise/products/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/enterprise/products/img_visual_sp.jpg);
		}
	}

	&.-enterprise_service{
		@include mq(sp, true) {
			background-image: url(/assets/img/enterprise/service/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/enterprise/service/img_visual_sp.jpg);
		}
	}

	&.-enterprise_proposal{
		@include mq(sp, true) {
			background-image: url(/assets/img/enterprise/solution/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/enterprise/solution/img_visual_sp.jpg);
		}
	}

	&.-enterprise_new-territory{
		@include mq(sp, true) {
			background-image: url(/assets/img/enterprise/new-territory/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/enterprise/new-territory/img_visual_sp.jpg);
		}
	}

	&.-information{
		@include mq(sp, true) {
			background-image: url(/assets/img/information/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/information/img_visual_sp.jpg);
		}
	}

	&.-recruit {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/img_visual_sp.jpg);
		}
	}

	&.-recruit_about {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/about/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/about/img_visual_sp.jpg);
		}
	}

	&.-recruit_faq {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/faq/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/faq/img_visual_sp.jpg);
		}
	}

	&.-recruit_welfare {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/welfare/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/welfare/img_visual_sp.jpg);
		}
	}

	&.-recruit_requirements {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/requirements/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/requirements/img_visual_sp.jpg);
		}
	}

	&.-recruit_entry {
		@include mq(sp, true) {
			background-image: url(/assets/img/recruit/entry/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/recruit/entry/img_visual_sp.jpg);
		}
	}

	&.-socialenvironment {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/img_visual_sp.jpg);
		}
	}

	&.-socialenvironment_commitment {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/commitment/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/commitment/img_visual_sp.jpg);
		}
	}

	&.-socialenvironment_environment {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/environment/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/environment/img_visual_sp.jpg);
		}
	}

	&.-socialenvironment_philanthropy {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/philanthropy/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/philanthropy/img_visual_sp.jpg);
		}
	}

	&.-socialenvironment_iso {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/iso/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/iso/img_visual_sp.jpg);
		}
	}
	
	&.-socialenvironment_sdgs {
		@include mq(sp, true) {
			background-image: url(/assets/img/socialenvironment/sdgs/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/socialenvironment/sdgs/img_visual_sp.jpg);
		}
	}
	
	&.-sustainability {
		@include mq(sp, true) {
			background-image: url(/assets/img/sustainability/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/sustainability/img_visual_sp.jpg);
		}
		
        .page_header{
            &__title{
				letter-spacing: 0.05em;

                span{
                    display: block;
					@include mq(sp, true) {
						&:first-child{
							font-size: 3.6rem;
						}
						&:last-child{
							font-size: 2.4rem;
						}
					}
					@include mq(sp) {
						&:first-child{
							font-size: 2.4rem;
						}
						&:last-child{
							font-size: 1.8rem;
						}
					}
                }
                &::before{
                    background: none;
                }
            }
        }
		&.-environment{
			
			@include mq(sp, true) {
				background-image: url(/assets/img/sustainability/environment/img_visual.jpg);
			}

			@include mq(sp) {
				background-image: url(/assets/img/sustainability/environment/img_visual_sp.jpg);
			}
		}
		&.-social {
			@include mq(sp, true) {
				background-image: url(/assets/img/sustainability/society-customers/img_visual.jpg);
			}

			@include mq(sp) {
				background-image: url(/assets/img/sustainability/society-customers/img_visual_sp.jpg);
			}
		}
		&.-governance {
			@include mq(sp, true) {
				background-image: url(/assets/img/sustainability/governance-corporate/img_visual.jpg);
			}

			@include mq(sp) {
				background-image: url(/assets/img/sustainability/governance-corporate/img_visual_sp.jpg);
			}
		}
	}
	

	&.-notfound {
		@include mq(sp, true) {
			background-image: url(/assets/img/404/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/404/img_visual_sp.jpg);
		}
	}

	&.-personal {
		@include mq(sp, true) {
			background-image: url(/assets/img/personal/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/personal/img_visual_sp.jpg);
		}
	}

	&.-policy {
		@include mq(sp, true) {
			background-image: url(/assets/img/policy/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/policy/img_visual_sp.jpg);
		}
	}

	&.-sitemap {
		@include mq(sp, true) {
			background-image: url(/assets/img/sitemap/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/sitemap/img_visual_sp.jpg);
		}
	}

	&.-search {
		@include mq(sp, true) {
			background-image: url(/assets/img/search/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/search/img_visual_sp.jpg);
		}
	}

	&.-inquiry {
		@include mq(sp, true) {
			background-image: url(/assets/img/inquiry/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/inquiry/img_visual_sp.jpg);
		}
	}

	&.-inquiry_account {
		@include mq(sp, true) {
			background-image: url(/assets/img/inquiry/account/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/inquiry/account/img_visual_sp.jpg);
		}
	}

	&.-inquiry_ir {
		@include mq(sp, true) {
			background-image: url(/assets/img/inquiry/ir/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/inquiry/ir/img_visual_sp.jpg);
		}
	}

	&.-inquiry_recruit {
		@include mq(sp, true) {
			background-image: url(/assets/img/inquiry/recruit/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/inquiry/recruit/img_visual_sp.jpg);
		}
	}

	&.-inquiry_other {
		@include mq(sp, true) {
			background-image: url(/assets/img/inquiry/other/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/inquiry/other/img_visual_sp.jpg);
		}
	}

	&.-overseas{
		@include mq(sp, true) {
			background-image: url(/assets/img/overseas/img_visual.jpg);
		}

		@include mq(sp) {
			background-image: url(/assets/img/overseas/img_visual_sp.jpg);
		}
	}

	&.-lg {
		position: relative;
		padding-top: 150px;
		padding-bottom: 80px;
		background: none;
		// background-attachment: fixed;

		@include mq(sp) {
			padding-top: 60px;
			padding-bottom: 35px;
			// background: none;
		}

		&::after {
			// @include mq(sp) {
				content: '';
				position: fixed;
				top: 0;
				left: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
			// }
		}

		.page_header__inner {
			flex-direction: column;
			height: auto;

			@include mq(sp) {
				height: auto;
			}
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 980px;
		height: 400px;
		margin-left: auto;
		margin-right: auto;

		@include mq(sp) {
			width: auto;
			height: 200px;
		}
	}

	&__body {
		position: relative;
		text-align: center;
		padding-bottom: 20px;
		padding-left: 10px;

		@at-root body.en .page_header__body {
			width: 100%;
		}

		@include mq(sp) {
			padding-bottom: 20px;

			@at-root body.en .page_header__body {
				width: auto;
			}
		}

		&.test {
			padding-bottom: 25px;
			padding-top: 0;

			@include mq(sp) {
				padding-bottom: 17px;
				padding-top: 0;
			}
		}
	}

	&__title {
		margin-bottom: 10px;
		font-size: 3.2rem;
		letter-spacing: .2em;
		color: #000;

		@at-root body.en .page_header__title {
			letter-spacing: .03em;
		}

		@include mq(sp) {
			font-size: 2.4rem;
			margin-bottom: 5px;
			letter-spacing: .1em;
		}

		&::before {
			content: '';
			display: block;
			width: 60px;
			height: 2px;
			background-color: $color_orange_main;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);

			@include mq(sp) {
				width: 44px;
			}
		}


		&-test {
			margin-bottom: 10px;
			font-size: 3.2rem;
			letter-spacing: .3em;
			color: #000;

			@include mq(sp) {
				font-size: 2.4rem;
				margin-bottom: 5px;
				letter-spacing: .1em;
			}

			&::after {
				content: '';
				display: block;
				width: 60px;
				height: 2px;
				background-color: $color_orange_main;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);

				@include mq(sp) {
					width: 44px;
				}
			}

			&.-sp_white {
				@include mq(sp) {
					color: #fff;
				}
			}

			&.-pc_white {
				@include mq(sp, true) {
					color: #fff;
				}
			}
		}

		&.-sp_white {
			@include mq(sp) {
				color: #fff;
			}
		}

		&.-pc_white {
			@include mq(sp, true) {
				color: #fff;
			}
		}
	}

	&__footer {
		margin-top: 80px;

		@at-root body.en .page_header__footer {
			width: 100%;
		}

		@include mq(sp) {
			margin-top: 40px;

			@at-root body.en .page_header__footer {
				width: auto;
			}
		}
	}

	&__lead {
		font-size: 3.2rem;
		text-align: center;
		font-weight: bold;
		color: #fff;
		line-height: 1.4;

		@include mq(sp) {
			font-size: 2rem;
		}
	}

	&__desc {
		margin-top: 20px;
		line-height: 2;
		font-size: 1.8rem;
		color: #fff;
		text-align: center;

		@include mq(sp) {
			margin-top: 10px;
			font-size: 1.4rem;
			text-align: left;
			line-height: 1.8;
		}

	}

	&__box {
		width: $width_site_lg;
		margin: 100px auto 0;
		background-color: #fff;
		padding: 80px 100px;
		box-sizing: border-box;

		@include mq(sp) {
			width: auto;
			margin: 35px auto 0;
			padding: 40px 50px;

			@at-root body.en .page_header__box {
				padding: 40px 30px;
			}
		}

		&.-md {
			max-width: 865px;

			@include mq(sp) {
				padding: 30px 20px;
			}
		}

		&.-transparent {
			background: rgba(#fff, .9);
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;

			@include mq(sp) {
				flex-direction: column;
				margin-bottom: 30px;
			}

		}

		&__copy {
			background: $color_orange_main;
			color: #fff;
			font-size: 2.4rem;
			font-weight: bold;
			width: 400px;
			padding-top: 10px;
			padding-bottom: 10px;
			text-align: center;

			@at-root body.en .page_header__box__copy {
				font-size: 2.2rem;
				line-height: 1.4;
			}

			@include mq(sp) {
				font-size: 1.8rem;
				width: 100%;

				@at-root body.en .page_header__box__copy {
					font-size: 1.8rem;
					line-height: 1.3;
				}
			}

		}

		&__cross {
			color: $color_orange_main;
			font-size: 3rem;

			@include mq(sp) {
				font-size: 2rem;
			}
		}

		&__lead {
			font-size: 3.2rem;
			font-weight: bold;
			text-align: center;
			line-height: 1.5;

			@at-root body.en .page_header__box__lead {
				font-size: 2.8rem;
			}

			@include mq(sp) {
				font-size: 2rem;

				@at-root body.en .page_header__box__lead {
					font-size: 1.8rem;
				}
			}

			span {
				color: $color_orange_main;
			}
		}
	}
}
