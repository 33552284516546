/*

display用クラス

PC向け
.none
.block
.inline_block
.inline
.table
.table_cell

タブレット向け
.tablet_none
.tablet_block
.tablet_inline_block
.tablet_inline
.tablet_table
.tablet_table_cell

スマホ向け
.sp_none
.sp_block
.sp_inline_block
.sp_inline
.sp_table
.sp_table_cell

*/

.none{
	display: none !important;
}
.block{
	display: block !important;
}
.inline_block{
	display:inline-block  !important;
}
.inline{
	display: inline !important;
}
.table{
	display:table !important;
}
.table_cell{
	display:table-cell !important;
}

.show_touch {
	display: none;
}
html.is_touch .show_touch {
	display: block;
}

@each $key, $value in $breakpoints {
	@include mq($key) {
		.#{$key}_none{
			display:none !important;
		}
	}
}

@each $key, $value in $breakpoints {
	@include mq($key) {
		.#{$key}_block{
			display:block !important;
		}
		.#{$key}_inline_block{
			display:inline-block !important;
		}
		.#{$key}_inline{
			display:inline !important;
		}
		.#{$key}_table{
			display:table !important;
		}
		.#{$key}_cell{
			display:table-cell !important;
		}
	}
}

.hidden_pc {
	display: none;
	@include mq(sp) {
		display: block;
	}
}
.hidden_sp {
	display: none;
	@include mq(sp, true) {
		display: inline-block;
	}
}
