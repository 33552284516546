.pagination {
	display: flex;
	justify-content: center;
	// border-top: 1px $color_gray_02 solid;
	padding-top: 80px;

	@include mq(sp) {
		padding-top: 40px;
	}

	&--sm {
		@extend .pagination;
		border-top: none;
		padding-top: 60px;
		padding-bottom: 30px;

		@include mq(sp) {
			padding-top: 30px;
			padding-bottom: 20px;
		}
	}

	&__item {
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			// font-family: $ff_roboto;
			color: $color_text;
			text-decoration: none;
			// border-top: 1px $color_gray_02 solid;
			// border-right: 1px $color_gray_02 solid;
			// border-bottom: 1px $color_gray_02 solid;
			transition: color .3s ease,
				background-color .3s ease;

			@include mq(sp) {
				width: 36px;
				height: 36px;
			}

			&:hover {
				color: #fff;
				// background-color: $color_category_blue;
			}
		}

		&:first-child {

			a {
				// border-left: 1px $color_gray_02 solid;
			}
		}

		&--current {
			@extend .pagination__item;

			a {
				color: #fff;
				// background: $color_category_blue;
			}
		}
	}

	&__prev,
	&__prev_last,
	&__next,
	&__next_last {
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			text-decoration: none;
			color: $color_text;
			transition: color .3s ease,
				background-color .3s ease;

			&:hover {
				color: #fff;
				// background-color: $color_category_blue;
			}

			@include mq(sp) {
				width: 36px;
				height: 36px;
			}
		}
	}

	&__prev {
		transform: rotate(180deg);
		margin-right: 10px;
		// border: 1px $color_gray_02 solid;
		font-size: 1rem;

		& + .pagination__item {
			a {
				// border-left: 1px $color_gray_02 solid;
			}
		}
	}

	&__prev_last {
		// border: 1px $color_gray_02 solid;
		border-right: none;
		font-size: 1.2rem;
	}

	&__next {
		margin-left: 10px;
		// border: 1px $color_gray_02 solid;
		font-size: 1rem;
	}

	&__next_last {
		// border: 1px $color_gray_02 solid;
		border-left: none;
		font-size: 1.2rem;
	}
}
