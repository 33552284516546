.product_card{
	display: table;
	text-decoration: none;

	&__img{
		display: table-cell;
		img{
			max-width: 100%;
			height: auto;
		}
	}

	* + &__body{
		margin-top: 10px;
	}
	&__body{
		display: table-caption;
		caption-side:bottom;
	}
	&__heading{
		font-size: 1.6rem;
		& + *{
			margin-top: 10px;
		}
	}

	&__text{
		font-size: 1.2rem;
	}
}