body.recruit_welfare {
	.welfare_content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: -50px;

		@include mq(sp) {
			margin-top: -35px;
		}

		&::after {
			content:"";
			display: block;
			width:30%;
		}

		&__item {
			width: 30%;
			margin-top: 50px;

			@include mq(sp) {
				width: auto;
				margin-top: 35px;
			}

			&__thumb {
				margin-bottom: 25px;
				text-align: center;
			}

			&__title {
				font-weight: bold;
				text-align: center;
				font-size: 1.8rem;
				margin-bottom: 25px;

				@include mq(sp) {
					margin-bottom: 20px;
				}
			}
		}
	}
}