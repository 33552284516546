body.recruit_index {
	.index_benefit {
		margin-top: 80px;

		@include mq(sp) {
			margin-top: 60px;
		}
	}

	.recruit_box {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;

		@include mq(sp) {
			display: block;
			margin-bottom: 20px;
		}

		&__content {
			position: relative;

			@include mq(sp) {
				margin-top: 20px;
				text-align: center;
			}
		}

		&__lead {
			position: absolute;
			top: 50%;
			left: 50%;
			background: #fff;
			padding: 12px 20px;
			font-weight: bold;
			font-size: 1.8rem;
			white-space: nowrap;
			transform: translate(-50%, -50%);

			@include mq(sp) {
				font-size: 1.6rem;
			}
		}
	}

	.recruit_banner {
		width: 960px;
		margin-top: 80px;
		margin-right: auto;
		margin-left: auto;

		@include mq(sp) {
			width: auto;
			margin-top: 40px;
			margin-bottom: 40px;
		}

		&__item {
			@include mq(sp) {
				max-width: 510px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		a {
			@include hover_opacity;
		}
	}

	.recruit_news {
		margin-top: 80px;
		margin-bottom: 80px;

		@include mq(sp) {
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
}
