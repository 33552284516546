.form {
	margin-top: 30px;

	&__body {
		color: $color_text;

		input[type="checkbox"] {
			margin-right: 10px;
		}

		input[type="radio"] {
			position: relative;
			top: -2px;
			margin-right: 10px;
			margin: 6px 10px;
		}

		input[type="text"] {
			appearance: none;
			border-radius: 0;
			padding: 5px;
			font-size: 1.6rem;
			border: 1px solid #666666;
			margin-right: 15px;

			@include mq(sp) {
				box-sizing: border-box;
				max-width: 290px;
				margin-right: 10px;
			}

			&:focus {
				outline: none;
			}

			&[name="Public::Application::Userenquete_D__P__D_zip2"] {
				& + .ss_rightComment {
					display: inline-block;
					margin-top: 0;

				}
			}
		}

		textarea {
			appearance: none;
			border-radius: 0;
			padding: 5px;
			font-size: 1.6rem;
			border: 1px solid #666666;
			width: 100%;
			max-width: 500px;
			min-height: 150px;
			box-sizing: border-box;

			&:focus {
				outline: none;
			}
		}

		.ss_rightComment {
			color: #999999;
			font-size: 1.4rem;

			@include mq(sp) {
				display: block;
				margin-top: 5px;
				font-size: 1.2rem;
			}
		}

		.ss_bottomComment {
			width: 100%;
			margin-top: 5px;
			color: #999999;
			font-size: 1.4rem;

			@include mq(sp) {
				display: block;
				margin-top: 5px;
				width: auto;
			}
		}

		.ss_upComment {
			width: 100%;
			margin-bottom: 5px;
			color: #999999;
			font-size: 1.4rem;

			@include mq(sp) {
				display: block;
				margin-top: 5px;
				width: auto;
			}
		}

		.ss_leftComment {
			margin-right: 15px;

			@include mq(sp) {
				margin-right: 10px;
			}
		}

		.ss_input {
			@include mq(sp) {
				margin-top: 5px;
				margin-bottom: 5px;
			}
		}

		.label {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.1rem;
			color: $color_orange_main;
		}

		.ss_error_msg {
			color: $color_red_01;
			font-size: 1.2rem;
			margin-bottom: 10px;
		}
		.label-warning {
			display: none;

			&.ss_notnull {
				display: block;
			}
		}
	}

	&__content {
		display: flex;
		border-bottom: 1px solid $color_gray_border;

		@include mq(sp) {
			display: block;
		}
		
		&:first-child {
			border-top: 1px solid $color_gray_border;
		}

		&__header {
			display: flex;
			align-items: center;
			position: relative;
			flex-shrink: 0;
			width: 230px;
			background: #efefef;
			padding: 15px 60px 15px 15px;
			box-sizing: border-box;

			@include mq(sp) {
				width: 100%;
				padding: 15px 40px 15px 15px;
			}
		}

		&__body {
			flex-grow: 1;
			display: flex;
			align-items: center;
			padding: 15px 0 15px 30px;

			@include mq(sp) {
				padding: 15px 0;
			}
		}

		&__require {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.1rem;
			color: $color_orange_main;
		}

		&__title {
			font-size: 1.6rem;
			color: $color_text;
			line-height: 1.4;
		}

		&__item {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 1.6rem;
		}

		&__prefix {
			font-size: 1.6rem;
			color: $color_text;

			.input_text + & {
				@include mq(sp) {
					margin-left: 20px;
				}
			}
		}

		&__example {
			color: #999999;
			font-size: 1.4rem;

			@include mq(sp) {
				margin-top: 5px;
				width: 100%;
			}
		}

		&__notes {
			width: 100%;
			margin-top: 5px;
			color: #999999;
			font-size: 1.4rem;
		}

		&__error {
			width: 100%;
			margin-top: 5px;
			color: #e00;
			font-size: 1.4rem;
		}

		&__unit {
			margin-right: 10px;
			margin-left: -15px;

			@include mq(sp) {
				margin-left: 10px;
			}
		}
	}

	&__footer {
		
		margin-top: 40px;

		& > .btn {
			display: flex;
			justify-content: center;
			

			button {
				margin: 0 20px;
				font-size: 1.6rem;

				@include mq(sp) {
					margin: 0 10px;
				}

				&.btn-large {
					background: $color_gray_01;
					color: $color_link;
				}

				&.btn-primary {
					background: $color_orange_main;
					color: #fff;
				}
			}
		}
	}
}