body.search {

	input,
	button,
	select {
		&:focus {
			outline: none;
		}
	}
	.mf_finder_searchBox_misc {
		display: none;
	}

	.mf_finder_searchBox_suggest_items {
		display: none;
	}

	.mf_finder_searchBox_doctype {
		&::after {
			top: 20px;
			right: 14px;
		}
	}

	.mf_finder_searchBox_doctype_select {
		height: 100%;
		font-size: 1.6rem;
		padding: 10px 20px;
		background-color: #efefef;
		border: none;
		border-right: 1px solid #ccc;
		border-radius: 0;
	}

	.mf_finder_organic_doc_body {
		span {
			font-size: 1.2rem;
		}
	}

	.mf_finder_searchBox_selects {
		display: none;
	}

	.mf_finder_organic_doc_url {
		span {
			word-break: break-word;
			line-height: 1.6;
		}
	}

	.mf_finder_organic_doc {

	}

	.mf_finder_searchBox_submit {
		padding: 10px 30px;
		cursor: pointer;
		background-color: $color_orange_main;
		color: #fff;
		border-radius: 0;
		

		&:hover {
			background-color: $color_orange_main;
		}

		span {
			display: none;
		}
	}

	.mf_finder_organic_header {
		flex-direction: row-reverse !important;
	}

	.mf_finder_searchBox {
		margin: 0 0 50px !important;

		@include mq(sp) {
			margin: 0 0 20px !important;
		}
	}

	.mf_finder_organic_header_wrapper {
		margin: 0 !important;
	}

	.mf_finder_query,
	.mf_finder_organic_range_from,
	.mf_finder_organic_range_to,
	.mf_finder_organic_total {
		color: $color_text !important;
		font-size: 1.4rem !important;

		&::before,
		&::after {
			font-size: 1.4rem !important;
			color: $color_text !important;
		}
	}

	.mf_finder_organic_pager {
		justify-content: center;
	}

	.mf_finder_organic_pager_wrapper {
		margin-top: 60px;
	}

	.mf_finder_logo {
		text-align: right;
	}

	.mf_finder_pager_items {
		display: flex !important;
		margin-bottom: 20px;

		li {
			margin: 0;
			margin-left: -1px;

			&:hover {
				z-index: 1;
			}

			a {
				display: flex;
				transition: color .3s ease,
					border-color .3s ease;
				border: 1px solid #333;
				border-radius: 0;

				&:hover {
					color: $color_orange_main;
					border-color: $color_orange_main;
					z-index: 1;
				}
			}

			span {
				display: flex;
				justify-content: center;
				padding: 8px 10px 8px 10px !important;
				width: 32px;
				box-sizing: border-box;
				vertical-align: bottom;
				
			}
			
		}
	}

	.mf_finder_pager_item_before_pages,
	.mf_finder_pager_item_after_pages {
		a {
			background-color: #fff;
			color: $color_text;
		}
	}

	.mf_finder_pager_item_first,
	.mf_finder_pager_item_prev,
	.mf_finder_pager_item_next,
	.mf_finder_pager_item_last {
		a {
			background-color: transparent;
			color: $color_text;
		}
	}

	.mf_finder_pager_item_current {
		color: $color_orange_main;
		border: 1px solid $color_orange_main;
		z-index: 1;
	}
	
	.mf_finder_searchBox_query_input {
		padding: 20px;
		font-size: 1.6rem;
		background-color: #efefef;
		border: none;
	}

	.mf_finder_organic_header {
		font-size: 1.4rem !important;
	}

	.mf_finder_organic_doc_title {
		color: $color_orange_main;
		font-size: 1.6rem !important;
		border: none !important;
	}

	.mf_finder_organic_doc_title_wrapper {
		color: $color_orange_main;
	}

	.mf_finder_searchBox_doctype {
		
	}

	.mf_finder_organic_docs {
		margin-top: 20px;
	}

	.mf_finder_organic_doc {
		padding: 20px 0;
		border: none;

		&:first-child {
			border-top: 1px solid $color_gray_border;
		}
	}

	.mf_finder_organic_doc_contents_wrapper {
		margin-left: 20px;
	}

	.mf_finder_link,
	.mf_finder_organic_doc_url_wrapper {
		transition: opacity .2s ease;

		&:hover {
			text-decoration: none !important;
			opacity: .8;

			.mf_finder_organic_doc_title,
			.mf_finder_organic_doc_url {
				border-bottom: none;
				text-decoration: none !important;

				span {
					border-bottom: none;
					text-decoration: none !important;
				}
			}
		}
	}

	.mf_finder_mark {
		color: $color_text;
	}

	.mf_finder_organic_doc_zoom {
		display: none !important;
	}

}