body.recruit_requirements {

	table {
		td {
			dl {
				dt {
					display: inline-block;
					width: 115px;

					@at-root body.en.recruit_requirements table td dl dt {
						width: 85px;
					}

					@include mq(sp) {
						width: 100px;

						@at-root body.en.recruit_requirements table td dl dt {
							width: 70px;
						}
					}
				}
				dd {
					display: inline-block;
					width: 170px;

					@at-root body.en.recruit_requirements table td dl dd {
						width: auto;
					}
				}
			}
		}
	}

	.requirements_flow {
		display: flex;
		justify-content: space-between;

		&__item {
			position: relative;
			width: 85px;
			height:280px;
			padding-top: 10px;
			border: 1px $color_gray_border solid;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			
			p {
				display:table;
				height:100%;
				text-align: center;
			}

			@include mq(sp) {
				width: 15%;
				margin: 0 3px;
				padding: 10px 0 20px;
			}

			&:last-child {
				border: none;
				background-color: $color_orange_main;

				&::after {
					content: none;
				}

				.requirements_flow__num,
				.requirements_flow__label {
					color: #fff;
				}
			}

			&::after {
				content: '';
				position: absolute;
				left: 50%;
				top: -3px;
				transform: translateX(-50%);
				display: block;
				width: 70px;
				height: 5px;
				background-color: $color_orange_main;

				@include mq(sp) {
					width: 70%;
					height: 3px;
					top: -2px;
				}
			}
		}

		&__num {
			margin-bottom: 10px;
			font-size: 2.4rem;
			color: $color_orange_main;
			font-weight: bold;
			font-family: $ff_en;
			letter-spacing: .1em;

			@include mq(sp) {
				font-size: 1.2rem;
			}
		}

		&__label {
			writing-mode: vertical-rl;
			font-size: 2rem;
			line-height: 1.2;

			@include mq(sp) {
				font-size: 1rem;
			}
		}

		&__notes {
			margin-top: 20px;

			@include mq(sp) {
				font-size: .6rem;

				@at-root body.en.recruit_requirements .requirements_flow__notes {
					writing-mode: vertical-rl;
				}
			}
		}
	}
	
	.requirements_arrow {
		width:12px;
		background-image: url("/assets/img/recruit/requirements/flow_arrow.png");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 95%;
	}
}
