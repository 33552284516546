.page_body {
	padding-top: 60px;
	padding-bottom: 180px;
	background-color: #fff;

	@include mq(sp) {
		padding-top: 30px;
		padding-bottom: 80px;
	}

	&__inner {
		margin-right: auto;
		margin-left: auto;
		width: $width_site_lg;

		@include mq(sp) {
			width: auto;
			padding-right: 15px;
			padding-left: 15px;
		}

		&.-md {
			width: 865px;

			@include mq(sp) {
				width: auto;
			}
		}

		&.-second {
			margin-top: 80px;
		}
	}

	.sec:first-child,
	.list_link:first-child {
		margin-top: 0;
	}

	.sec:last-child {
		margin-bottom: 0;
	}


}
