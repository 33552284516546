.input_radio {
	
	margin-right: 20px;

	input {
		margin-right: 10px;
	}

	span {
		display: flex;
		align-items: center;
		color: $color_text;
		font-size: 1.6rem;
	}
}