.list_link {
	margin-top: 30px;
	margin-bottom: 30px;

	@include mq(sp) {
		margin-top: 18px;
		margin-bottom: 18px;
	}

	&.-md {
		margin-top: 20px;
		margin-bottom: 20px;

		.list_link__item {
			a {
				font-size: 1.4rem;
			}
		}
	}

	&.-sm {
		margin-top: 0;
		margin-bottom: 0;
		
		.list_link__item {

			@include mq(sp) {
				margin: 10px 0;
			}

			a {
				font-size: 1.4rem;
			}
		}
	}

	&__item {
		margin: 18px 0;

		@include mq(sp) {
			margin: 10px 0;
		}

		&:first-child {
			@include mq(sp) {
				margin-top: 0;
			}
		}

		a {
			position: relative;
			display: inline-block;
			text-decoration: none;
			color: $color_text;
			padding-left: 20px;
			font-size: 1.6rem;
			line-height: $basic_lineheight;
			@include hover_opacity(.7);

			@include mq(sp) {
				font-size: 1.4rem;
			}

			&::before {
				@include icon($arrow_right);
				font-size: 1rem;
				position: absolute;
				left: 0;
				top: 9px;
				color: $color_orange_main;

				@include mq(sp) {
					top: 6px;
					font-size: 1.2rem;
				}
			}
		}
	}

	&__pdf {
		font-size: 1.4rem;
		margin-left: 10px;

		@include mq(sp) {
			display: block;
			margin-left: 0;
		}

		&::after {
			@include icon($pdf);
			font-size: 1.8rem;
			color: $color_red_01;
			position: relative;
			top: 3px;

			@include mq(sp) {
				vertical-align: middle;
				top: 0;
			}
		}
	}
}
