.input_text {
	appearance: none;
	border-radius: 0;
	padding: 5px;
	font-size: 1.6rem;
	border: 1px solid #666666;
	margin-right: 25px;
	width: 100%;
	max-width: 300px;

	@include mq(sp) {
		margin-right: 0;
		max-width: 100%;
	}

	&:focus {
		outline: none;
	}

	&.-sm {
		max-width: 60px;
	}

	&.-md {
		max-width: 100px;

		@include mq(sp) {
			max-width: 100%;
		}
	}

	&.-sp_half {
		@include mq(sp) {
			width: 29%;
		}
	}
}