body.about {
	.about_content {
		&__lead {
			text-align: center;
			font-size: 2.8rem;
			color: $color_text;
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 30px;

			@include mq(sp) {
				font-size: 2rem;
			}

			span {
				color: $color_orange_main;
				font-size: 3.8rem;
				font-family: $ff_en;

				@include mq(sp) {
					font-size: 3rem;
				}
			}
		}
	}
}