body.sitemap {
	.sitemap_content {
		display: flex;
		justify-content: space-between;

		@include mq(sp) {
			display: block;
		}

		&__list {
			width: 27%;

			@include mq(sp) {
				width: auto;
			}

			&__item {
				margin-bottom: 30px;

				@at-root body.en .sitemap_content__list:last-child {
					.sitemap_content__list__item:last-child {
						margin-left: 15px;
					}
				}

				& > a {
					color: $color_orange_main;
					text-decoration: none;
					font-size: 1.6rem;
					font-weight: bold;
					@include hover-opacity(0.7);

					@at-root body.en .sitemap_content__list:last-child {
						.sitemap_content__list__item:last-child > a {
							margin-left: -15px;
						}
					}

					&::before {
						@include icon($arrow_right);
						font-size: 1rem;
						margin-right: 5px;
						font-weight: normal;
					}
				}
			}
		}

		&__child_list {
			margin-top: 10px;
			padding-top: 5px;
			border-top: 1px dotted #b2b2b2;

			&__item {
				margin-top: 10px;
				margin-bottom: 10px;

				@at-root body.en .sitemap_content__child_list__item {
					margin-left: 15px;
				}

				& > a {
					color: $color_text;
					text-decoration: none;
					@include hover-opacity(0.7);

					@at-root body.en .sitemap_content__child_list__item > a {
						margin-left: -15px;
					}

					&::before {
						@include icon($arrow_right);
						font-size: 1rem;
						margin-right: 5px;
						font-weight: normal;
						color: $color_orange_main;
					}
				}
			}
		}

		&__grandchild_list {
			padding-left: 30px;

			@at-root body.en .sitemap_content__grandchild_list {
				padding-left: 16px;
			}

			&__item {
				margin-top: 10px;
				margin-bottom: 10px;

				& > a {
					color: $color_text;
					text-decoration: none;
					@include hover-opacity(0.7);
				}
			}
		}
	}
}