.sec_inner {
	margin-right: auto;
	margin-left: auto;
	width: $width_site_lg;

	@include mq(sp) {
		width: auto;
		margin-right: 15px;
		margin-left: 15px;
	}

	&.-md {
		width: 865px;

		@include mq(sp) {
			width: auto;
		}
	}

	&.-hang {
		padding-left: 30px;

		@include mq(sp) {
			padding-left: 10px;
		}
	}

	&.-sp_full {
		@include mq(sp) {
			margin-right: 0;
			margin-left: 0;
		}
	}
}
