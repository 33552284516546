.social {
	
    .policy{
        .policy_title{
            text-align: center;
            font-weight: bold;
            color: #ff6400;
            font-size: 1.8rem;
            margin-bottom: 30px;
        }
        p{
            text-align: left;
            margin-bottom: 0;
        }
    }
	
	.social_orange_title{
			text-align: center;
			color: #ff6400;
			margin-bottom: 10px;
			font-size: 1.8rem !important;
		}

	&.customers{
		.title_fourthly {
			@include mq(sp) {
				text-align: center;
		}
		}
		.iso_block {
			display:flex;
			justify-content: space-between;
			width:765px;
			margin:0 auto;
			@include mq(sp) {
				display:block;
				width:100%;
				padding:0;
			}
			.iso {
			width:485px;
			@include mq(sp) {
				width:80%;
				padding:20px 0 0 0 !important;
				margin:0 auto;
		}
		}
		.iso_img{
			width:260px;
			@include mq(sp) {
				width:250px;
				margin:20px auto 40px;
		}
		}
		}
		
		ol {
			font-weight: 700;
			font-size: 1.6rem;
			margin-top:30px;
			line-height:1.8;
			@include mq(sp) {
				font-size: 1.4rem;
				margin-top:20px;
		}
			li{
				margin-top:10px;
				text-indent: -1.5em;
				padding-left: 1.5em;
			}
		}
		
		.border_box {
			padding:45px 105px 85px;
			@include mq(sp) {
				padding:30px;
			}
		}
		.caption {
			font-size: 1.4rem;
		}
		.center_photo{
			display:flex;
			justify-content: space-between;
			margin-top:30px;
			margin-bottom:50px;
			@include mq(sp) {
				display:block;
			}
			.photo {
				width:275px;
				@include mq(sp) {
				width:100%;
				margin:30px auto 0;
			}
			}
		}
		.customers_photo {
			display:flex;
			justify-content: space-between;
			margin-top:30px;
			margin-bottom:50px;
			@include mq(sp) {
				display:block;
			}
			.photo {
			&:first-child {
				width:300px;
				@include mq(sp) {
				width:100%;
			}
			}
			&:nth-child(2) {
				width:350px;
				@include mq(sp) {
				width:100%;
			}
			}
			&:last-child {
				width:175px;
				@include mq(sp) {
				width:100%;
				max-width:250px;
			}
			}
				@include mq(sp) {
				margin:30px auto 0;
			}
			}
		}
	}
    &.employee{
		
		.title_fourthly {
			@include mq(sp) {
				text-align: center;
		}
		}
		.border_box {
			margin-bottom:60px;
			h3{
				text-indent:-1.2em;
				margin-left:1.2em;
			}
		    figure {
			max-width:600px;
			margin:40px auto;
		}
			dl {
				display:flex;
				flex-wrap:wrap;
				font-size:1.6rem;
				border-top:1px solid #dcdcdc;
				padding:10px 0;
				line-height: 1.8;
				@include mq(sp) {
					display:block;
					font-size:1.3rem;
				}
				&.top{
					border-top:none;
				}
				dt{
					font-weight:bold;
					width:8.0em;
					@include mq(sp) {
					width:100%
				}
				}
				&:last-child {
					margin-bottom:-10px;
				}
				.indent{
					@include mq(sp) {
					display:inline-block;
					text-indent:-1.0em;
					margin-left:1.0em;
						}
				}
			}
		}
		.callenge_image {
			max-width:750px;
			margin:50px auto 60px;
		}
		.women_image {
			@include mq(sp) {
			max-width:325px;
			margin:0 auto;
		}
		}
		.sp_scroll{
			margin:30px 0 40px;
			@include mq(sp) {
				margin:10px 0;
				overflow: scroll;
			}
			table{
				margin:10px 0;
				@include mq(sp) {
				width:800px;
			}
				th,td{
				@include mq(sp) {
						padding: 10px 3px;
						&:first-child{ width: 35%;}
						&:nth-child(2){ width: 13%;}
						&:nth-child(3){ width: 13%;}
						&:nth-child(4){ width: 13%;}
						&:nth-child(5){ width: 13%;}
						&:last-child{ width: 13%;}
					}
				}
				td{
					text-align:right;
					&:first-child{
						text-align:left;
					}
				}
			}
		}
		h4{
		padding:8px 0 10px 15px;
		&.social_orange_title{
			text-align: left;
			margin-bottom: 10px;
			border:none;
			padding:0;
		}
			}
		.skill {
			display:flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 60px;
			.list {
				width:360px;
				padding:30px;
				font-size:1.6rem;
				font-weight:bold;
				border:1px solid #dcdcdc;
				margin-bottom: 20px;
				h4 {margin-top:0px;
				@include mq(sp) {
					font-size:1.4rem !important;
					margin-bottom: 5px;
					}
				}
				@include mq(sp) {
					width:100%;
					font-size:1.3rem;
					}
			}
		}
    }
	&.suppliers{
		
		.title_fourthly{
			@include mq(sp) {
				font-size: 2.0rem;
				margin-bottom: 30px;
			}
		}
		.border_box{
			._title{
				text-align: center;
				color: #ff6400;
				font-size: 1.6rem;
				font-weight: bold;
				margin-bottom: 30px;
				@include mq(sp) {
					text-align: left;
				}
			}
			li{
				font-size: 1.6rem;
				text-indent: -1.5rem;
				padding-left: 1.5rem;
				line-height: 190%;
				margin-bottom: 30px;
				
				&:first-child{
					margin-bottom: 20px;
					&::before{
						content: "1.";
						color: #ff6400;
						font-weight: bold;
					}
				}
				&:nth-child(2){
					font-size: 1.4rem;
					line-height: 145%;
					text-indent: 0;
					@include mq(sp) {
						line-height: 190%;
					}
				}
				&:last-child{
					&::before{
						content: "2.";
						color: #ff6400;
						font-weight: bold;
					}
				}
			}
			
			dl{
				margin-bottom: 30px;
				font-size: 1.6rem;
				
				dt{
					font-weight: bold;
					color: #ff6400;
					margin-bottom: 10px;
				}
				dd{
					line-height: 190%;
				}
			}
			
			.date{
				text-align: right;
				font-size:1.6rem;
			}
		}
		
		.col{
			display: flex;
			justify-content: space-between;
			padding: 0;
			
			@include mq(sp) {
				display: block;
			}
			
			.text_box{
				display: inline-block;
				width: 560px;
				@include mq(sp) {
					width: 100%;
					padding: 0;
					margin-bottom: 20px;
				}
			}
			.img_box{
				display: inline-block;
				width: 275px;
				padding-top: 12px;
				@include mq(sp) {
					width: 100%;
					padding: 0;
				}
				img{
					width: 100%;
				}
			}	
		}
	}
}
