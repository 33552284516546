.title_eighthly {
	font-size: 1.6rem;
	color: $color_text;
	margin-bottom: 20px;
	line-height: 1.6;
	background-color: #eeeeee;
	font-weight: normal;
	padding: 10px 20px;

	@include mq(sp) {
		font-size: 1.4rem;
	}

}
