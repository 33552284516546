.input_textarea {
	appearance: none;
	border-radius: 0;
	padding: 5px;
	font-size: 1.6rem;
	border: 1px solid #666666;
	width: 100%;
	max-width: 500px;
	min-height: 150px;

	&:focus {
		outline: none;
	}
}