.cms {
	&__header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 5px 0 5px 25px;
		border-left: 5px $color_orange_main solid;

		@include mq(sp) {
			padding-left: 12px;
		}
	}
	&__body {
		margin-top: 35px;

		@include mq(sp) {
			margin-top: 30px;
		}

		h1 {
			margin-top: 30px;
			margin-bottom: 10px;
			font-size: 2.4rem;
			line-height: 1.6;

			@include mq(sp) {
				font-size: 2rem;
			}
		}
		h2 {
			margin-top: 25px;
			margin-bottom: 10px;
			font-size: 1.8rem;
			line-height: 1.6;
		}
		h3 {
			margin-top: 25px;
			margin-bottom: 10px;
			font-size: 1.6rem;
			line-height: 1.6;
			color: $color_orange_main;
		}
		p {
			line-height: 2;
			font-size: 1.6rem;
			margin: 1em 0;

			@include mq(sp) {
				font-size: 1.4rem;
				line-height: 1.8;
			}
		}
		ul {
			padding-left: 20px;

			li {
				list-style: disc;
			}
		}
		ol {
			padding-left: 20px;

			li {
				list-style: decimal;
			}
		}

		li {
			line-height: 2;

			@include mq(sp) {
				line-height: 1.8;
			}
		}
		img {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}
	&__footer {
		margin-top: 60px;

		@include mq(sp) {
			margin-top: 30px;
		}
	}

	&__category {
		display: flex;
		justify-content: center;
		font-size: 1rem;
		background: #000;
		color: #fff;
		padding: 2px 20px;
		border-radius: 3px;
		margin-right: 20px;

		@include mq(sp) {
			margin-right: 10px;
		}

	}

	&__date {
		@include mq(sp) {
			font-size: 1.2rem;
		}
	}

	&__title {
		width: 100%;
		font-size: 2.8rem;
		margin-top: 10px;

		@include mq(sp) {
			font-size: 1.7rem;
		}
	}
}
