.index_nav {

	&__inner {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: $width_site_lg;
		margin-left: auto;
		margin-right: auto;
		margin-top: -50px;

		@include mq(sp) {
			width: auto;
			margin-top: -20px;
		}
	}

	&__content {
		margin-top: 50px;
		width: 510px;

		@include mq(sp) {
			width: auto;
			max-width: 510px;
			margin: 20px auto 0;
		}

		&.-lg {
			width: auto;
			margin-top: 0;

			@include mq(sp) {
				width: calc(100% + 30px);
				max-width: none;
				margin-top: 20px;
				margin: 20px -15px 0;
			}

			.index_nav__thumb {
				img {
					max-width: 100%;
				}
			}
		}

		&.-md {
			width: 330px;

			@include mq(sp) {
				width: auto;
				max-width: 510px;
				margin: 20px auto 0;
			}

			.index_nav__thumb {
				img {
					max-width: 100%;
					width: 100%;
				}
			}

			.index_nav__btn {
				width: 180px;
				height: 50px;
			}
		}

		&__header {
			position: relative;

			a {
				display: block;
				text-decoration: none;
				transition: opacity 1s ease;

				&:hover {
					opacity: .8;

					.index_nav__thumb {
						img {
							transform: scale(1.1);
						}
					}
				}
			}
		}

		&__body {
			margin-top: 35px;
		}
	}

	&__thumb {
		overflow: hidden;

		img {
			transition: transform 1s ease;
		}
	}

	&__title {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 195px;
		height: 56px;
		padding: 0 20px;
		box-sizing: border-box;
		text-align: center;
		font-size: 2rem;
		font-weight: bold;
		background: #fff;
		color: $color_text;
		white-space: nowrap;

		@at-root body.en .index_nav__title {
			min-width: 250px;
			height: 60px;
			white-space: nowrap;
		}

		@include mq(sp) {
			min-width: 185px;
			font-size: 1.8rem;
			height: 48px;

			@at-root body.en .index_nav__title {
				height: 58px;
			}
		}

		&.-wide {
			width: 300px;

			@at-root body.en .index_nav__title.-wide {
				width: 330px;
			}

			@include mq(sp) {
				width: auto;

				@at-root body.en .index_nav__title.-wide {
					width: 240px;
				}

				@at-root body.en.enterprise .index_nav__content:first-child .index_nav__title.-wide {
					height: 63px;
				}
			}
		}

		&.-lg {
			font-size: 3.2rem;
			padding: 0 30px;
			height: 72px;

			@include mq(sp) {
				font-size: 1.6rem;
				height: 40px;
				padding: 0 20px;
				min-width: 0;
				width: auto;
			}
		}
	}

	&__titles {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		min-width: 300px;
		padding: 25px 20px;
		box-sizing: border-box;
		text-align: center;
		background: rgba(#fff, .9);
		white-space: nowrap;

		@include mq(sp) {
			min-width: 230px;
			padding: 15px 0;
		}

		&__main {
			font-size: 2.4rem;
			font-weight: bold;
			color: $color_text;
			letter-spacing: .1em;

			@at-root body.en .index_nav__titles__main {
				letter-spacing: 0;
			}

			@include mq(sp) {
				font-size: 1.8rem;

				@at-root body.en .index_nav__titles__main {
					font-size: 1.6rem;
				}
			}

			&.-icon_01 {
				padding-top: 48px;
				background: url(/assets/img/about/img_content_icon_01.png) 50% 0 no-repeat;
				background-size: 40px 41px;

				@include mq(sp) {
					padding-top: 30px;
					background-size: 30px auto;
				}
			}
		}

		&__sub {
			margin-top: 5px;
			font-size: 1.4rem;
			color: $color_text;

			@include mq(sp) {
				font-size: 1.2rem;
			}
		}

	}

	&__btn {
		position: absolute;
		right: 0;
		bottom: -20px;
		display: flex;
		align-items: center;
		padding-left: 30px;
		width: 220px;
		height: 64px;
		background: $color_orange_main;
		color: #fff;
		font-size: 1.3rem;
		box-sizing: border-box;
		font-weight: 700;

		@include mq(sp) {
			width: 200px;
			height: 50px;
			right: 15px;
			font-size: 1.2rem;
		}

		&::after {
			@include icon($arrow_lg_right);
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.8rem;
		}

		&.-threeD {
			box-shadow: 0px 8px 7px 3px rgba(0,0,0,.15);
			&::before {
				border-bottom: 4px solid #ff3600;
				content: "";
				display: block;
				height: 100%;
				position: absolute;
				left: 0;
				bottom: -4px;
				width: 100%;
			}
		}
	}

	&__box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 10px;
		bottom: -20px;
		width: 99px;
		height: 90px;
		background: url(/assets/img/about/img_content_icon_02.png) 0 0 no-repeat;
		background-size: 99px 90px;
		color: #fff;
		font-size: 1.2rem;
		box-sizing: border-box;
		padding-right: 20px;
		padding-top: 26px;

		@include mq(sp) {
			font-size: 1rem;
			width: 75px;
			height: 68px;
			padding-top: 20px;
			padding-right: 15px;
			background-size: 100% auto;
		}

		&::after {
			@include icon($arrow_lg_right);
			transform: translateY(-50%);
			font-size: 1.8rem;
			margin-top: 15px;

			@include mq(sp) {
				margin-top: 10px;
				font-size: 1.4rem;
			}
		}
	}

	&__list {
		margin-top: -15px;
		display: flex;
		flex-wrap: wrap;


		&__item {
			margin-right: 15px;
			margin-top: 15px;

			&:last-child {
				margin-right: 0;
			}

			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: $color_text;
				font-size: 1.6rem;
				@include hover_opacity;

				@include mq(sp) {
					font-size: 1.3rem;
				}

				&::before {
					@include icon($arrow_right);
					font-size: 1rem;
					color: $color_orange_main;
					margin-right: 10px;
				}
			}
		}
	}
}
