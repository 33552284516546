.title_sixthly {
	font-size: 1.8rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 30px;
	padding: 6px 0 6px 14px;
	border-left: 3px $color_orange_main solid;

	@include mq(sp) {
		font-size: 1.5rem;
		margin-bottom: 25px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	&.-md {
		margin-bottom: 20px;
	}
}
