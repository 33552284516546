body.socialenvironment_sdgs {

	.sdgs_theme {
		padding:20px;
		border:1px solid #cccccc;
		h3 {
		font-size:1.6em;
	    text-align:center;
	    border-bottom:2px solid #fe6400;
		padding-bottom:10px;
	    margin-bottom:15px;
		}
	}
}