.title_secondly {
	font-size: 2.8rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 30px;
	padding: 6px 0 6px 0;
	border-left: 5px $color_orange_main solid;
	background: url(/assets/img/common/ico_dots_title.png) 0 50% repeat-x;

	@include mq(sp) {
		font-size: 1.7rem;
		margin-bottom: 25px;
		padding: 0;
		border-width: 5px;
		background: none;
	}

	&__inner {
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		background: #fff;
		line-height: 1.6;

		@at-root body.en .title_secondly__notes {
			margin-right: 30px;
		}

		@include mq(sp) {
			padding-left: 10px;
			padding-right: 10px;
			background: none;
		}
	}

	&__notes {
		display: inline-block;
		position: relative;
		left: -10px;
		top: -6px;
		padding-right: 10px;
		font-size: 1.2rem;
		background: #fff;

		@include mq(sp) {
			top: 0;
			padding-left: 10px;

			@at-root body.en .title_secondly__notes {
				left: auto;
				top: -6px;
			}
		}
	}
}
