body.company_history {
	.history_content {
		&__thumb {
			margin-top: 30px;
			margin-bottom: 60px;

			@include mq(sp) {
				margin-top: 10px;
				margin-bottom: 0;
			}

			& + .table_basic {
				@include mq(sp) {
					margin-top: 30px;
				}
			}
		}
	}
}
