.nav_entry {
	display: flex;
	margin-bottom: 60px;
	padding-left: 1px;

	@include mq(sp) {
		overflow-x: auto;
		margin-bottom: 30px;
		padding-bottom: 14px;
		-webkit-overflow-scrolling: touch;
	}

	&__item {
		position: relative;
		width: 20%;
		margin-left: -1px;

		@include mq(sp) {
			width: auto;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			border: 1px #000 solid;
			font-size: 1.3rem;
			text-decoration: none;
			color: #000;
			transition: color .2s ease,
				border-color .2s ease;

			@include mq(sp) {
				height: 40px;
				padding: 0 20px;
				white-space: nowrap;
				font-size: 1.1rem;

			}

			&::after {
				content: '';
				position: absolute;
				bottom: -14px;
				left: 50%;
				display: block;
				border-top: 7px $color_orange_main solid;
				border-right: 5px transparent solid;
				border-bottom: 7px transparent solid;
				border-left: 5px transparent solid;
				transform: translateX(-50%);
				opacity: 0;
				transition: opacity .2s ease;
			}
		}
		&:hover,
		&.-current {
			z-index: 1;

			a {
				color: $color_orange_main;
				border-color: $color_orange_main;

				&::after {
					opacity: 1;
				}
			}
		}
	}
}
