.title_box {
	margin-bottom: 10px;
	font-size: 2.8rem;
	font-weight: bold;
	color: $color_text;
	line-height: 1.4;
	padding-left: 50px;
	background: url(/assets/img/common/img_content_icon_02.png) 0 0 no-repeat;
	background-size: 37px auto;

	@include mq(sp) {
		font-size: 2rem;
		padding-left: 35px;
		background-size: 25px auto;
	}
}