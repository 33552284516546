.page_to_top {
	text-align: right;
	margin-top: 10px;

	a {
		text-decoration: none;
		font-size: 1.3rem;

		&::before {
			@include icon($page_to_top);
			font-size: 1rem;
			font-weight: bold;
			color: $color_orange_main;
			margin-right: 5px;
		}
		
	}
}