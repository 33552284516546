.thumb {
	text-align: center;

	img {
		max-width: 100%;
		height: auto;
	}

	figcaption {
		margin-top: 10px;
		text-align: left;

		&.-sm {
			margin-top: 5px;
			font-size: 1.2rem;
		}
	}
}
