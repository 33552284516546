.data_basic {
	dt,
	dd {
		line-height: $basic_lineheight;
	}

	dd {
		margin-left: 2em;

		@include mq(sp) {
			margin-left: 1em;
		}
	}
}
