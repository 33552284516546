.page_link {

	.row {
		.col_4 {
			padding-bottom: 30px;

			@include mq(sp) {
				flex: 50% 0 0;
				max-width: 50%;
				padding-right: 5px;
				padding-left: 5px;
				padding-bottom: 0;
			}
		}
	}

	&__item {
		position: relative;

		@include mq(sp) {
			max-width: 510px;
			margin: 0 auto 20px;
		}

		a {
			display: block;
			transition: opacity 1s ease;

			&:hover {
				opacity: .8;

				.page_link__thumb {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}

	&__thumb {
		overflow: hidden;

		img {
			max-width: 100%;
			height: auto;
			transition: transform 1s ease;
		}
	}

	&__body {
		position: absolute;
		left: 50%;
		top: 50%;
		background: rgba(#fff, .9);
		transform: translate(-50%, -50%);
		min-height: 28px;
		min-width: 215px;
		padding-top: 10px;
		padding-bottom: 10px;

		@include mq(sp) {
			min-height: 21px;
			min-width: 120px;
			padding-top: 8px;
			padding-bottom: 8px;
		}

		&.-lg {
			@include mq(sp) {
				min-width: 120px;
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
	}

	&__label {
		color: $color_text;
		font-size: 1.8rem;
		font-weight: bold;
		text-align: center;
		line-height: 1.4;

		@at-root body.en .page_link__label.-about {
			line-height: 1.1;
		}

		@include mq(sp) {
			font-size: 1.4rem;

			@at-root body.en .page_link__label {
				font-size: 1.3rem;
			}
		}
	}

	&__ruby {
		font-size: 1.3rem;
		color: $color_text;
		text-align: center;

		@at-root body.en .page_link__ruby {
			line-height: 1.2;
			margin-top: 6px;
		}

		@include mq(sp) {
			font-size: 1rem;
			line-height: 1.2;
			margin-top: 2px;

			@at-root body.en .page_link__ruby {
				text-align: left;
			}
		}
	}

	&__btn {
		position: absolute;
		right: 0;
		bottom: -23px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		width: 165px;
		height: 46px;
		background: $color_orange_main;
		color: #fff;
		font-size: 1.2rem;
		box-sizing: border-box;

		@include mq(sp) {
			padding-left: 10px;
			width: 100px;
			height: 32px;
			right: 0;
			bottom: -16px;
			font-size: 1rem;

			&::after {
				right: 10px;
				font-size: 1.3rem;
			}
		}

		&::after {
			@include icon($arrow_lg_right);
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.8rem;
		}
	}
}