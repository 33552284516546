.row {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -10px 0;

  &.-reverse {
    flex-direction: row-reverse;
  }

  &.-sm {
    margin: -10px -5px 0;

    & > .col {
      padding-top: 10px;
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  &.-md {
    margin: -30px -20px 0;

    & > .col {
      padding-top: 30px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &.-c {
    justify-content: center;
  }

  &.-half {
    flex-wrap: nowrap;
    width: calc(50% + 10px);
    @include mq(sp) {
      width: auto;
    }
  }
  &.-sp_md {
    @include mq(sp) {
      margin: -12px -6px 0;

      .col {
        padding-top: 12px;
        padding-right: 6px;
        padding-left: 6px;
      }
    }
  }

  .title_box + & {
    margin-top: 0;
    
    @include mq(sp) {
      margin-top: -10px;
    }
  }
}

.col {
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;

  &.-flex-c {
    display: flex;
    justify-content: center;
  }

  .list_link {
    &:first-child {
      .list_link__item {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &.-pc_separate {
    @include mq(sp, true) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.col_13 {
  @extend .col;
  flex: 1 1 auto;
}

.col_12 {
  @extend .col;
  flex: 100% 0 0;
  max-width: 100%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_11 {
  @extend .col;
  flex: 91.666% 0 0;
  max-width: 91.666%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_10 {
  @extend .col;
  flex: 83.333% 0 0;
  max-width: 83.333%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_9 {
  @extend .col;
  flex: 75% 0 0;
  max-width: 75%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_8 {
  @extend .col;
  flex: 66.666% 0 0;
  max-width: 66.666%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_7 {
  @extend .col;
  flex: 58.333% 0 0;
  max-width: 58.333%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_6 {
  @extend .col;
  flex: 50% 0 0;
  max-width: 50%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_5 {
  @extend .col;
  flex: 41.666% 0 0;
  max-width: 41.666%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_4 {
  @extend .col;
  flex: 33.333% 0 0;
  max-width: 33.333%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_3 {
  @extend .col;
  flex: 25% 0 0;
  max-width: 25%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_2_5 {
  @extend .col;
  flex: 25% 0 0;
  max-width: 25%;
  padding: 30px 0 0 0;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_2 {
  @extend .col;
  flex: 16.666% 0 0;
  max-width: 16.666%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_1 {
  @extend .col;
  flex: 8.333% 0 0;
  max-width: 8.333%;

  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_unit {
  @extend .col;

}

.col_sm_8 {
  @include mq(sp) {
    flex: 66.666% 0 0;
    max-width: 66.666%;
  }
}

.col_sm_6 {
  @include mq(sp) {
    flex: 50% 0 0;
    max-width: 50%;
  }
}

.col_sm_12 {
  @include mq(sp) {
    flex: 100% 0 0;
    max-width: 100%;
  }
}

.col_sm_10 {
  @include mq(sp) {
    flex: 83.333% 0 0;
    max-width: 83.333%;
  }
}

.col_sm_5 {
  @include mq(sp) {
    flex: 41.666% 0 0;
    max-width: 41.666%;
  }
}

.col_sm_4 {
  @include mq(sp) {
    flex: 33.333% 0 0;
    max-width: 33.333%;
  }
}

.col_sm_3 {
  @include mq(sp) {
    flex: 25% 0 0;
    max-width: 25%;
  }
}

.col_sm_2 {
  @include mq(sp) {
    flex: 16.666% 0 0;
    max-width: 16.666%;
  }
}
.col_sm_1 {
  @include mq(sp) {
    flex: 8.333% 0 0;
    max-width: 8.333%;
  }
}
