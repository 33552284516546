.commitment {
	.-sustainability_commitment {
		background-image: url(/assets/img/sustainability/commitment/img_visual.jpg);
	}
	.commitment_main_img {
		position:relative;
		margin-right: auto;
        margin-left: auto;
        width: 865px;
		@include mq(sp) {
			width:100%;
		}
		.title_fourthly {
			position:absolute;
			left:430px;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			text-align:left;
			@include mq(sp) {
			left:55%;
		}
		}
	}
}