body[class*=enterprise] {
	.page_body{
		@include mq(sp){
			padding-top: 38px;
		}
	}
	.page_desc{
		@include mq(sp){
			text-align: left;
		}
	}
	.enterprise_nav{
		border-top: 1px solid $color_border;

		@include display-flex;
		@include flex-wrap(wrap);
		@include justify-content(flex-start);
		@include align-items(stretch);
		@include align-content(stretch);
		@include flex-direction(row);

		@include mq(sp, true){
			padding-top: 60px;
			margin-top: 60px;
			> *{
				margin-left: 20px;
				&:nth-child(3n + 1){
					margin-left: 0;
				}
			}
		}
		@include mq(sp){
			@include justify-content(space-between);
			padding-top: 40px - 20px;
			margin-top: 40px;
			> *{
				width: calc(50% - 5px);
				margin-top: 20px;
			}
		}

		&__collision{
			@extend .product_card;
		}

		&__img{
			@extend .product_card__img;
		}

		&__body{
			@extend .product_card__body;
			@include mq(sp){
				margin-top: 5px;
			}
		}

		&__caps{
			font-size: 1.8rem;
			font-weight: bold;
			text-align: center;
			@include mq(sp){
				font-size: 1.6rem;
			}
		}
	}

	.enterprise_column{

		$_root: '.enterprise_column';
		&__rhythm{
			@include mq(sp, true){
				> *:nth-child(even){
					> .__img{
						@include order(-1);
						margin-left: 0;
						margin-right: 40px;
					}
				}
			}
		}

		@include mq(sp, true){

			@include display-flex;
			@include flex-wrap(wrap);
			@include justify-content(space-between);
			@include align-items(stretch);
			@include align-content(stretch);
			@include flex-direction(row);

			> .__entity{
				@include flex(1);
			}
			> .__img{
				margin-left: 40px;
			}
			> .__more{
				width: 100%;
				a{
					max-width: 248px;
					margin-left: 0;
				}
			}

		}

		@include mq(sp){
			> .__img{
				margin-top: 30px;
				img{
					width: 100%;
				}
			}
		}

		&__heading{
			font-size: 2.8rem;
			line-height: 1.45;
			@include mq(sp){
				font-size: 1.8rem;
			}
		}

		&__body{
			margin-top: 30px;
		}
		&__text{
			font-size: 1.6rem;
		}

	}
}
