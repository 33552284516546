body.company_message {
	.message_copy {
		&__desc {
			text-align: center;
			font-weight: bold;
			font-size: 1.6rem;

			@include mq(sp) {
				font-size: 1.4rem;
			}
		}

		&__lead {
			font-size: 3.2rem;
			text-align: center;
			font-weight: bold;
			color: #000000;
			margin-top: 35px;

			@include mq(sp) {
				font-size: 2rem;
				line-height: 1.2;
				margin: 20px 10px 0;
			}

			img {
				margin-right: 15px;

				@include mq(sp) {
					margin: 0 0 10px;
				}
			}
		}

		&__copy {
			color: $color_orange_main;
			text-align: center;
			font-size: 3.2rem;
			line-height: 1.6;
			margin-top: 40px;
			margin-bottom: 50px;
			font-weight: bold;

			@include mq(sp) {
				font-size: 2rem;
				margin-top: 30px;
				margin-bottom: 25px;
			}
		}
	}

	.message_content {
		&__thumb {
			margin-bottom: 35px;

			@include mq(sp) {
				margin-bottom: 15px;
			}
		}
	}

	.message_policy {
		&__body {
			margin-top: 30px;
			display: flex;
			justify-content: space-around;

			@include mq(sp) {
				flex-wrap: wrap;
				margin-top: -40px;
			}
		}

		&__content {
			text-align: center;

			@at-root body.en.company_message .message_policy__content {
				width: 33%;
			}

			@include mq(sp) {
				margin-top: 40px;

				@at-root body.en.company_message .message_policy__content {
					width: auto;
				}
			}

			&:first-child {
				@include mq(sp) {
					width: 100%;
				}
			}
		}

		&__title {
			margin-top: 20px;
			text-align: center;
			font-size: 1.8rem;
			font-weight: bold;
		}

		&__figure {
			text-align: center;
			margin-top: 35px;
			margin-bottom: 35px;
		}
	}
}
