body.about_human {
	.support_credo {
		margin-top: 70px;
		margin-bottom: 35px;

		@include mq(sp) {
			margin-top: 40px;
			margin-bottom: 20px;
		}

		&__body {
			position: relative;
			width: 500px;
			box-sizing: border-box;
			margin: 0 auto;
			padding: 28px 20px 30px;
			border: 1px $color_gray_border solid;
			text-align: center;

			@include mq(sp) {
				width: auto;
				padding: 20px;
			}

			&::after {
				content: '';
				position: absolute;
				left: 50%;
				top: -3px;
				transform: translateX(-50%);
				width: 160px;
				height: 5px;
				background: $color_orange_main;

				@include mq(sp) {
					width: 120px;
					height: 3px;
					top: -2px;
				}
			}
		}

		&__lead {
			margin-top: 20px;
			margin-bottom: 20px;

			@include mq(sp) {
				margin-top: 10px;
				margin-bottom: 10px;
			}

			img {
				@at-root body.en.about_human .support_credo__lead img {
					width: 260px;
    				height: auto;
				}

				@include mq(sp) {
					width: 88px;
					height: auto;

					@at-root body.en.about_human .support_credo__lead img {
						width: 130px;
					}
				}
			}
		}

		&__desc {
			font-size: 2rem;
			color: #fff;
			font-family: $ff_serif;

			@include mq(sp) {
				font-size: 1.4rem;
			}
		}
	}
}