.text {
	font-size: 1.6rem;
	line-height: 1.9;
	color: $color_text;

	@include mq(sp) {
		font-size: 1.4rem;
		line-height: 1.8;
	}

	& + & {
		@include mq(sp, true) {
			margin-top: 1em;
		}
	}

	&.-lg {
		font-size: 2rem;
		font-weight: bold;

		& + .text {
			margin-top: 1.5em;
		}
	}

	&.-md {
		font-size: 1.4rem;
	}

	&.-sm {
		font-size: 1.2rem;
	}

	&.-c {
		text-align: center;
	}

	&.-r {
		text-align: right;
	}

	&.-sp_l {
		@include mq(sp) {
			text-align: left;
		}
	}

	&.-sp_t {
		@include mq(sp) {
			margin-top: 1em;
		}
	}

	&.-hang {
		text-indent: -1em;
		padding-left: 1em;
	}

	&.-summary {
		margin-bottom: 60px;

		@include mq(sp) {
			margin-bottom: 20px;
		}
	}

	&.-lead {
		margin-bottom: 2em;
		margin-top: 2em;

		.col > &:first-child {
			margin-top: 0;
		}
	}

	&.-desc {
		margin-bottom: 20px;
	}

	&.-number {
		margin-left: 20px;
	}
}
