body.socialenvironment_philanthropy {

	.cms__body {

		.thumb {

			img {
				width: 100%;
			}
		}
	}
}