/*

base font size

*/

$basic_font_size : 13, 81.25%;

/* $basic_font_size : 14, 87.5%; */

/* $basic_font_size : 15, 93.75%; */

/* $basic_font_size : 16, 100%; */

$basic_line_height: 2.333;

// $ff_roboto: 'Roboto', sans-serif;
$ff_serif: Georgia,游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$ff_en: 'Ropa Sans', sans-serif;
// $ff_bitter: 'Bitter', serif;
