body.ir_chukei {
	.chukei_intro {
		margin-bottom: 80px;

		@include mq(sp) {
			margin-bottom: 40px;
		}

		&__lead {
			text-align: center;

			@include mq(sp) {
				margin: 0 10px;
			}
		}

		&__list {
			margin-top: 40px;
			border-bottom: 1px solid $color_gray_border;

			@include mq(sp) {
				margin-top: 20px;
			}

			&__item {
				line-height: 1.6;
				font-size: 2rem;
				font-weight: bold;
				padding: 30px 60px;
				border-top: 1px solid $color_gray_border;

				@include mq(sp) {
					padding: 20px;
					font-size: 1.5rem;
				}
			}
		}
	}

	.table_ir {
		position: relative;

		&__inner {
			@include mq(sp) {
				overflow-x: auto;
				padding-bottom: 20px;
				-webkit-overflow-scrolling: touch;
			}
		}

		&__content {
			width: 100%;
			border-collapse: separate;

			td,
			th {
				position: relative;
				padding: 15px;
				font-size: 1.4rem;
				color: $color_text;
				font-weight: normal;
				line-height: 1.6;

				@include mq(sp) {
					font-size: 1.2rem;
					white-space: nowrap;
				}
			}

			thead {
				th {
					width: 25%;
					position: relative;
					background-color: #ea5504;
					border-right: 1px #fff solid;
					border-bottom: 1px #fff solid;
					color:#FFF;
					font-weight:bold;
					@include mq(sp) {
						padding: 10px;
					}

					&:first-child {
						width: 180px;

						@include mq(sp) {
							width: 164px;
						}
					}

					&.-orange {
						background-color: #ffefe5;
						color: $color_orange_main;
						font-weight: bold;

						span {
							color: $color_orange_main;
						}

						&::after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							display: block;
							width: 100%;
							height: 4px;
							background-color: $color_orange_main;
						}
					}
				}
			}

			tbody {
				tr {
					&:last-child {
						th,
						td {
							border-bottom: 1px $color_gray_border solid;
						}
					}
					&:nth-child(odd){
						background:#f7d1cb;
					}
					&:nth-child(even){
						background:#fbe9e7;
					}
				}

				th,
				td {
					padding: 15px;
					border-top: 1px #FFF solid;
					border-right: 1px #FFF solid;
					vertical-align: middle;

					@include mq(sp) {
						padding: 10px;
					}
				}

				th {
					text-align: left;
					/*background-color: #efefef;*/

					@include mq(sp) {
						// opacity: 0;
						font-size: 1.2rem;
					}
				}

				td {
					font-size: 1.6rem;
					text-align: right;

					@include mq(sp) {
						font-size: 1.2rem;
					}

					&.-orange {
						// border-right: none;
						background-color: #ffefe5;
						color: $color_orange_main;
						font-weight: bold;
					}

					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}

	
}
