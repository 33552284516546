body.ir_highlight {
	.ir_graph {
		&__body {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__content {
			margin-bottom: 50px;
			width: 410px;

			@include mq(sp) {
				width: auto;
				margin-bottom: 20px;
			}
		}

		&__title {
			color: $color_orange_main;
			font-size: 2rem;
			margin-bottom: 20px;

			@include mq(sp) {
				font-size: 1.6rem;
			}
		}

		&__thumb {
			img {
				max-width: 100%;
				height: auto;
			}
		}

		&__desc {
			margin-bottom: 50px;

			@include mq(sp) {
				margin-bottom: 20px;
			}
		}

		&__notes {
			margin-top: 10px;
			text-indent: -1.8em;
			padding-left: 1.8em;

			@include mq(sp) {
				font-size: 1.2rem;
			}
		}
	}

	.table_ir {
		position: relative;
		margin-bottom: 40px;

		@include mq(sp) {
			margin-bottom: 20px;
		}

		&__inner {
			@include mq(sp) {
				overflow-x: auto;
				padding-bottom: 20px;
				-webkit-overflow-scrolling: touch;
			}
		}

		&__content {
			width: 100%;
			border-collapse: separate;

			td,
			th {
				position: relative;
				padding: 15px;
				font-size: 1.4rem;
				color: $color_text;
				font-weight: normal;
				line-height: 1.6;


				@include mq(sp) {
					white-space: nowrap;
				}

				&:first-child {
					@include mq(sp) {
					}
				}
			}

			thead {
				th {

					position: relative;
					background-color: #e2e2e2;
					border-right: 1px #fff solid;
					border-bottom: 1px #fff solid;

					@include mq(sp) {
						padding: 10px;
					}

					&:first-child {
						width: 180px;

						@include mq(sp) {
							width: 164px;
						}
					}

					&:last-child {
						background-color: #ffefe5;
						color: $color_orange_main;
						font-weight: bold;

						span {
							color: $color_orange_main;
						}

						&::after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							display: block;
							width: 100%;
							height: 4px;
							background-color: $color_orange_main;
						}
					}
				}
			}

			tbody {
				tr {
					&:last-child {
						th,
						td {
							border-bottom: 1px $color_gray_border solid;
						}
					}
				}

				th,
				td {
					padding: 15px;
					border-top: 1px $color_gray_border solid;
					vertical-align: middle;

					@include mq(sp) {
						padding: 10px;
					}
				}

				th {
					text-align: left;
					background-color: #efefef;

					@include mq(sp) {
						font-size: 1.2rem;
					}
				}

				td {
					text-align: right;
					border-right: 1px $color_gray_border solid;
					font-size: 1.4rem;

					@include mq(sp) {
						font-size: 1.2rem;
					}

					&:last-child {
						border-right: none;
						background-color: #ffefe5;
						color: $color_orange_main;
						font-weight: bold;
					}

					&.-lg {
						font-size: 1.6rem;

						@include mq(sp) {
							font-size: 1.4rem;
						}
					}
				}
			}

			&.-original {
				@include mq(sp) {
					tbody {
						th {
							opacity: 0;
						}
					}
				}
			}

			&.-fixed {

				@include mq(sp, true) {
					display: none;
				}

				position: absolute;
				left: 0;
				top: 0;
				pointer-events: none;

				tbody {
					td {
						visibility: hidden;
					}
				}

				thead {
					th {
						visibility: hidden;

						&:first-child {
							visibility: visible;
						}
					}
				}
			}

		
		}
	}

}
