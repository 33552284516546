.list_entry {
	margin-top: 30px;
	margin-bottom: 30px;

	@include mq(sp) {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	&__item {
		display: flex;
		align-items: center;
		padding: 16px 0;
		border-top: 1px #999 dotted;

		@include mq(sp) {
			flex-wrap: wrap;
		}

		&:last-child {
			border-bottom: 1px #999 dotted;
		}
	}

	&__date {
		@include mq(sp) {
			order: 2;
		}
		& + .list_entry__title {
			margin-left: 100px;

			@include mq(sp) {
				margin-left: 0;
			}
		}
	}

	&__category {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		width: 120px;
		margin: 0 16px;
		border-radius: 3px;
		background: #000;
		color: #fff;
		font-size: 1rem;

		@include mq(sp) {
			order: 1;
			width: auto;
			padding: 0 10px;
			margin-left: 0;
		}
	}

	&__title {

		@include mq(sp) {
			order: 3;
			width: 100%;
		}

		a {
			display: block;
			color: $color_text;
			text-decoration: none;
			transition: color .3s ease;

			&:hover {
				color: $color_orange_main;
			}
		}
	}
}
