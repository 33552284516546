.title_primary {
	text-align: left;
	font-size: 2.8rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 30px;

	border-left: 5px solid $color_orange_main;
	padding: 5px;
	line-height: 1.45;
	padding-left: 25px;

	@include display-flex;
	@include flex-wrap(nowrap);
	@include justify-content(space-between);
	@include align-items(center);
	@include align-content(center);
	@include flex-direction(row);

	&:after{

		// 装飾

		$_size: 3px;
		$_color: $color_orange_main;
		$_deg: -60deg;
		@include flex(1 0 40%);
		content: "";
		display: block;
		height: 3px;
		background-image:linear-gradient($_deg, transparent 10%, $_color 10%, $_color 30%, transparent 30%, transparent 75%, $_color 75%, $_color);
		background-size: $_size $_size;

		margin-left: 30px;
	}

	@include mq(sp) {
		font-size: 2.2rem;
		margin-bottom: 20px;
		padding-left: 15px;
	}
}
