.sustainability {
	&.top{
		.title_secondly {
			text-align: center;
			border-left:none;
			margin:80px 0;
			@include mq(sp) {
					font-size:2.0rem;
					background: url(/assets/img/common/ico_dots_title.png) 0 50% repeat-x;
					margin:40px 0;
				}
			&.mt100 {
				margin:100px 0 80px;
				@include mq(sp) {
					margin:60px 0 40px;
				}
			}
			.title_secondly__inner {
				@include mq(sp) {
					display: inline-block;
					padding-left: 10px;
					padding-right: 10px;
					background: #fff;
				}
			}
		}
	}
	
	.index_nav__content{
	@include mq(sp) {
				margin-bottom:35px;
			}
		}
	
/*関連するリンク共通設定*/
	.title_fourthly.related_title {
		margin:90px 0 35px;
		padding-top:80px;
		border-top: 1px solid #dcdcdc;
	}

.related {
	.index_nav__content__body ul{
				margin-top: 50px;
		li {
				margin-top: 20px;
	           @include mq(sp) {
				margin-top: 15px;
			}
				& > a {
					color: $color_text;
					font-size: 1.6rem;
					font-weight: bold;
					text-decoration: none;
					text-indent:-1.5em;
					margin-left:1.5em;
					display:block;
					@include hover-opacity(0.7);

					&::before {
						@include icon($arrow_right);
						font-size: 1.8rem;
						margin-right: 5px;
						font-weight: normal;
						color: $color_orange_main;
					}
				}
			}
	}

}
&.top .related .index_nav__inner{
		width:1060px;
	@include mq(sp) {
		width:100%;
	}
		.index_nav__content {
			width:330px;
	@include mq(sp) {
		width:100%;
	}
		}
	}
.related .index_nav__inner{
		width:865px;
	@include mq(sp) {
		width:100%;
	}
		.index_nav__content {
			width:275px;
	@include mq(sp) {
		width:100%;
	}
		}
	}
	.anchor_link {
		width:100%;
		border-top:1px solid #dcdcdc;
		border-bottom:1px solid #dcdcdc;
		padding:30px 0 15px;
		display:flex;
		flex-wrap:wrap;
		margin-bottom:60px;
		@include mq(sp) {
			padding:15px 0 0;
			margin-bottom:30px;
		}
		li{
			font-size:1.6rem;
			font-weight:bold;
			margin-right:50px;
			text-indent: -2rem;
            padding-left: 2rem;
			margin-bottom:15px;
			@include mq(sp) {
			font-size:1.3rem;
			margin-right:40px;
			margin-bottom:15px;
		}
			a {
				text-decoration: none;
			    color:#333;
				position: relative;
				padding-left:20px;
				&:hover{
				color: $color_orange_main;	
				}
				&::before {
				@include icon($cret_down);
				position: absolute;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
				color: $color_orange_main;
			}
			}
		}
	}
	
	.border_box{
		border:1px solid #dcdcdc;
		padding:50px;
		margin-top:30px;
		@include mq(sp) {
			padding:30px;
		}
		h3 {
			font-size:2.4rem;
			font-weight:bold;
			margin-bottom:20px;
			span {
				color: $color_orange_main;
			}
			@include mq(sp) {
			font-size:1.7rem;
			margin-bottom:10px;
		}
		}
		.right {
			text-align:right;
		}
	}
	
	h4 {
		border-left:3px solid #dcdcdc;
		border-bottom:1px solid #dcdcdc;
		font-size:1.6rem;
		padding:8px 0 10px 25px;
		margin:40px 0 20px;
		display:block;
		p{
		text-indent:-1.0em;
		margin-right:1.0em;
		}
		span {
			color:#bbbbbb;
			margin-right:5px;
		}
	}
	
	.policy{
		background: #f7f7f7;
		padding: 50px;
		@include mq(sp) {
			padding: 25px;
		}
		
		p{
			text-align: center;
			margin-bottom: 35px;
			@include mq(sp) {
				text-align: left;
			}
		}
		ol{
			font-weight: bold;
			font-size: 1.6rem;
			line-height:1.8;
			li{
				margin-bottom: 15px;
				text-indent: -1em;
				padding-left: 1em;
			}
		}
	}
	
	table{
		width: 100%;
		margin: 40px 0 50px;
		@include mq(sp) {
			margin: 20px 0 30px;
		}
		
		tr{
			>*{
				border-left: #808080 solid 1px;
			}
			&:first-child{
				border-top: #808080 solid 1px;
				td{
					@include mq(sp) {
						text-align: center;
					}
				}
			}
		}
				
		th{
			border-right: #808080 solid 1px;
			border-bottom: #808080 solid 1px;
			background: #ffeec5;
			padding: 20px 15px;
			text-align: center;
			@include mq(sp) {
				width: 40%;
				padding: 15px 10px;
			}
		}
		td{
			border-right: #808080 solid 1px;
			border-bottom: #808080 solid 1px;
			padding: 20px 15px;
			@include mq(sp) {
				width: 30%;
				padding: 15px 10px;
			}
		}
	}
	
	.pc_only{
		@include mq(sp) {
			display: none;
		}
	}
	.sp_only{
		display: none;
		@include mq(sp) {
			display: block;
		}
	}
  #sec_01,
  #sec_02,
  #sec_03,
  #sec_04{
  @include mq(sp) {
   display: block;
   margin-top:-47px;
   padding-top:47px;
   }
}
}