.sustainability.environment {
	
	.row{
		margin: 0 0 0 -15px;
		
		div{
			margin-left: -2px;
		}
	}
		
	.intertek_img{
		&.pc_only{
			float: right;
			width: 240px;
			padding: 0 0 20px 30px;
			@include mq(sp) {
				display: none;
			}
		}
		&.sp_only{
			display: none;
			@include mq(sp) {
				display: block;
				width: 250px;
				margin: 25px auto 0;
			}
		}
		img{
			width: 100%;
		}
	}
	
	.border_box{
		.thumb{
			img{
				width: 656px;
				@include mq(sp) {
					width: 100%;
				}
			}
		}
	}
	
	
	h3{
		margin-top: 50px;
	}
	p{
		.orange{
			color: #fe5e00;
		}
	}
	table{
		td{			
			&:nth-child(n+2){
				text-align: right;
			}
		}
	}
	
	dl{
		border: #4d97ce solid 1px;
		margin-top: 30px;
		@include mq(sp) {
			margin-top: 20px;
		}
		
		&.wide{
			
			dd{
				display: inline-block;
				width: 49%;
				padding: 25px 0 30px;
				box-sizing: border-box;
				text-align: center;
				@include mq(sp) {
					display: block;
					width: auto;
					padding: 20px 25px;
				}
				span{
					display: block;
					font-weight: bold;
					font-size: 1.8rem;
					
					&:first-child{
						margin-bottom: 20px;
					}
					img{
						width: 340px;
						@include mq(sp) {
							width: 100%;
						}
					}
				}
			}
		}
		&.middle{
			width: 550px;
			margin: 30px auto 0;
			@include mq(sp) {
				width: 100%;
				margin-top: 20px;
			}
			
			dd{
				text-align: center;
				font-size:1.6rem;
				@include mq(sp) {
					text-align: left;
					font-size:1.4rem;
					line-height: 1.8;
				}
			}
		}
		
		dt{
			background: #4d97ce;
			padding: 20px;
			text-align: center;
			color: #fff;
			font-weight: bold;
			font-size: 2rem;
			@include mq(sp) {
				padding: 10px;
			}
		}
		dd{
			padding: 25px 30px;
		}
	}
	
	.col-2{
		display: flex;
		justify-content: space-between;
		@include mq(sp) {
			display: block;
		}
		dl{
			width: 420px;
			@include mq(sp) {
				width: 100%;
				margin-top: 20px;
			}
			dd{
				font-size:1.6rem;
				line-height: 1.8;
				@include mq(sp) {
			    font-size:1.4rem;
				}
			}
		}
	}
	
	.topics{
		background: #f7f7f7;
		padding: 50px;
		text-align: center;
		margin-top: 90px;
		@include mq(sp) {
			padding: 30px 25px;
			margin-top: 40px;
		}
		
		p{
			margin-bottom: 40px;
			@include mq(sp) {
				text-align: left;
			}
			
			&.topics_title{
				margin-bottom: 25px;
				@include mq(sp) {
					text-align: center;
				}

				strong{
					font-weight: bold;
					color: #fe5e00;
					display: block;
					font-size: 2.6rem;
					line-height: 125%;
				}
			}
			&.text{
				text-align:left;
			}
			&.caption{
				margin: 10px 0 0;
				@include mq(sp) {
					text-align: center;
				}
			}
		}
		.action_list{
            margin-bottom: 70px;
			@include mq(sp) {
				margin-bottom: 	30px;
			}
			img{
				width: 764px;
				@include mq(sp) {
					width: 100%;
				}
			}
		}
		.sdgs{
			img{
				width: 340px;
				@include mq(sp) {
					width: 60%;
				}
			}
		}
	} 
	.col_3{
		padding-right: 0;
		img{
			width: 100%;
		}
	}
	.col_4{
		padding-right: 0;
		img{
			width: 100%;
		}
	}
	.col_6{
		&.img{
			padding: 30px 0 0 20px;
		}
		.thumb{
			display: flex;
			justify-content: space-between;
			@include mq(sp) {
				display: block;
			}
			img{
				&:first-child{
					width: 203px;
					margin-right: 10px;
					@include mq(sp) {
						width: 100%;
						margin: 0 0 10px 0;
					}
				}
				&:last-child{
					width: 216px;
					@include mq(sp) {
						width: 100%;
					}
				}
			}
		}
	}
	
	.mt80{
		@include mq(sp) {
			margin-top: 40px!important;
		}
	}
	
}