.permalink {

	&.-orange {
		color: $color_orange_main;
	}

	&.-lg {
		&::after {
			font-size: 1.8rem;
		}
	}

	&.-pdf {
		&::after {
			@include icon($pdf);
			color: #e00;
			margin-left: 4px;
			position: relative;
			top: 0;
		}
	}

	&.-blank {
		&::after {
			@include icon($blank);
			color: $color_text;
			margin-left: 4px;
			font-size: 1rem;
			position: relative;
			top: 0;
		}
	}
}
