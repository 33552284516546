.governance {
	
      .policy{
		  .policy_title{
			  text-align: center;
			  font-weight: bold;
			  color: #ff6400;
			  font-size: 1.8rem;
			  margin-bottom: 30px;
			  @include mq(sp) {
				  margin-bottom: 20px;
			  }
		  }
          p{
              text-align: left;
			  margin-bottom: 0;
          }
		  
		  &.type01{
			  dl{
				  font-size:1.6rem;
				  line-height: 1.6;
				  @include mq(sp) {
						  font-size:1.4rem;
					  }
				  &:first-child{
					  margin-bottom: 25px;
				  }
				  
				  dt{
					  display: inline-block;
					  width: 100px;
					  font-weight: bold;
					  color: #ff6400;
					  vertical-align: top;
					  @include mq(sp) {
						  display: block;
						  width: 100%;
					  }
				  }
				  dd{
					  display: inline-block;
					  width: 650px;
					  @include mq(sp) {
						  display: block;
						  width: 100%;
					  }
					  
					  ul{
						  li{
							  margin-top: 5px;
							  text-indent: -1em;
							  padding-left: 1em;
							  &::before{
								  content: "●";
								  color: #ff6400;
								  font-size: 1.2rem;
								  margin-right: 5px;
							  }
						  }
					  }
				  }
			  }
		  }
		  &.type02{
			  margin-top: 50px;
			  @include mq(sp) {
				  margin-top: 25px;
			  }
			  
			  .policy_title{
				  text-align: left;
				  font-size: 1.6rem;
				  margin-bottom: 20px;
				  @include mq(sp) {
					  margin-bottom: 10px;
				  }
			  }
			  
			  dl{
				  font-size: 1.6rem;
				  margin-bottom: 30px;
				  &:last-child{
					  margin: 0;
				  }
			  }
			  dt{
				  font-weight: bold;
				  margin-bottom: 10px;
			  }
			  dd{
				  line-height: 1.8;
			  }
              ul{
                  li{
                      margin-top: 10px;
					  text-indent: -1em;
					  padding-left: 1em;
                      &::before{
                          content: "●";
                          color: #ff6400;
                          font-size: 1.2rem;
                          margin-right: 5px;
                      }
                  }
              }
		  }
      }
	
	h3{
		&.title_sixthly{
			margin-top: 50px;
			@include mq(sp) {
				margin-top: 30px;
			}
		}
		&.title_thirdly{
			margin-top: 40px;
			@include mq(sp) {
				margin-top: 20px;
			}
		}
	}
	
	.caption{
		font-size: 1.4rem;
		margin-top: 10px;
		@include mq(sp) {
			text-indent: -1em;
			padding-left: 1em;
		}
	}
	
	.border_box{
		padding: 30px;
		margin-bottom: 30px;
		@include mq(sp) {
			padding: 10px 15px;
			margin-bottom: 20px;
		}
		strong {
			font-size:1.6rem;
		}
		p{
			font-weight: bold;
			color: #ff6400;
			margin-bottom: 20px;
			@include mq(sp) {
				margin-bottom: 10px;
			}
		}
		ol{
			font-size: 1.6rem;
			li{
				margin-bottom: 10px;
				line-height: 1.4em;
				text-indent: -1em;
				padding-left: 1em;
			}
		}
	}
	
	.link_text{
		margin-top: 10px;
		font-size:1.6rem;
		text-indent: -1.25em;
		padding-left: 1.25em;
		a{
			text-decoration: none;
			color: #333;
			@include hover-opacity(0.7);
			&::before{
				@include icon($arrow_right);
						font-size: 1.4rem;
						margin-right: 5px;
						color: $color_orange_main;
			}
		}
	}
	
	&.corporate{
		.sp_scroll{
			margin-bottom:10px;
			@include mq(sp) {
				overflow: scroll;
			}

			table{
				margin-bottom:10px;

				@include mq(sp) {
					width: 900px;
				}
				th{
					font-size: 1.4rem;
					padding: 20px 5px;
					vertical-align: middle;
					@include mq(sp) {
						padding: 10px 3px;
						&:first-child{ width: 10%;}
						&:nth-child(2){ width: 7%;}
						&:nth-child(3){ width: 26%;}
						&:nth-child(4){ width: 10%;}
						&:nth-child(5){ width: 12%;}
						&:nth-child(6){ width: 10%;}
						&:nth-child(7){ width: 10%;}
						&:last-child{ width: 15%;}
					}
				}
				td{
					font-size: 1.4rem;
					padding: 20px 5px;
					vertical-align: middle;
					text-align: center;
					@include mq(sp) {
						padding: 10px 3px;
						&:first-child{ width: 10%;}
						&:nth-child(2){ width: 7%;}
						&:nth-child(3){ width: 13%;}
						&:nth-child(4){ width: 13%;}
						&:nth-child(5){ width: 10%;}
						&:nth-child(6){ width: 12%;}
						&:nth-child(7){ width: 10%;}
						&:nth-child(8){ width: 10%;}
						&:last-child{ width: 15%;}
					}
					&.circle{
						font-size: 1.8rem;
					}
				}
			}
		}
		.text+.text {
			@include mq(sp) {
				margin-top:1rem;
			}
		}
	}
	&.risk-management{
		
		.col{
			display: flex;
			justify-content: space-between;
			padding: 0;
			
			@include mq(sp) {
				display: block;
			}
			
			.text_box{
				display: inline-block;
				width: 576px;
				@include mq(sp) {
					width: 100%;
					padding: 0;
					margin-bottom: 20px;
				}
			}
			.img_box{
				display: inline-block;
				width: 227px;
				@include mq(sp) {
					display: block;
					width: 60%;
					margin: 0 auto;
				}
				img{
					width: 100%;
				}
			}
			
			.caption {
				@include mq(sp) {
				text-indent: 0em;
				padding-left: 0em;
				text-align: center;
					}
			}
		}
		.col2{
			display: flex;
			justify-content: space-between;
			padding: 0;
			margin-top: 35px;
			
			@include mq(sp) {
				display: block;
			}
			
			.img_box1{
                display: inline-block;
                width: 275px;
                @include mq(sp) {
					display: block;
                    width: 70%;
					margin: 0 auto 20px;
                }
                img{
                    width: 100%;
                }
			}
			.img_box2{
                display: inline-block;
                width: 565px;
                @include mq(sp) {
					display: block;
                    width: 100%;
                    margin-bottom: 20px;
                }
                img{
                    width: 100%;
                }
			}
		}
		
	}
}
