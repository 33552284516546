.title_fourthly {
	font-size: 2.8rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 30px;
	text-align: center;
	line-height: 1.4;

	&.-lg {
		font-size: 3.2rem;

		@include mq(sp) {
			font-size: 2.1rem;
		}
	}

	&.-sp_l {
		@include mq(sp) {
			text-align: left;
		}
	}

	@include mq(sp) {
		font-size: 1.8rem;
		margin-bottom: 20px;
	}

	&__ruby {
		color: $color_orange_main;
		font-size: 1.8rem;

		@include mq(sp) {
			font-size: 1.4rem;
		}
	}
}
