.global_nav {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: $color_gray_01;
	transform: translateX(-100%);
	transition: transform .2s ease;

	@include mq(sp) {
		height: auto;
		width: 100%;
		top: 47px;
		transform: none;
		display: none;
	}

	.nav_btn:hover + &,
	&:hover,
	&.-top {
		@include mq(sp, true) {
			transform: translateX(0);
		}
	}

	&.-open {
		@include mq(sp) {
			display: block;
		}
	}

	&__inner {
		display: flex;
		flex-direction: column;
		height: 100%;

		@at-root body.en .global_nav__inner {
			width: 220px;
		}

		@include mq(sp) {
			height: 100vh;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			padding: 0 0 200px;
			box-sizing: border-box;

			@at-root body.en .global_nav__inner {
				width: auto;
			}
		}
	}

	&__logo {
		position: relative;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		width: 220px;
		height: 140px;
		box-sizing: border-box;
		box-shadow: 0 3px 1px rgba(#000, .1);

		@include mq(sp) {
			display: none;
		}
	}

	&__list {
		&__item {

			@include mq(sp) {
				position: relative;
			}

			&.hover {
				&>a {
					@include mq(sp, true) {
						color: #fff;
						background-color: $color_orange_main;
					}
				}

				.global_nav__child_list {
					@include mq(sp, true) {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			&>a {
				position: relative;
				display: flex;
				align-items: center;
				height: 55px;
				padding-left: 25px;
				text-decoration: none;
				font-size: 1.5rem;
				border-bottom: 1px #cdcdcd solid;
				font-weight: bold;
				color: #333;
				transition: color .2s ease,
				background-color .2s ease;

				@include mq(sp) {
					height: auto;
					padding: 15px 25px;
					font-size: 1.4rem;
				}

				&:hover {
					@include mq(sp, true) {
						color: #fff;
						background-color: $color_orange_main;
					}
				}

				&::after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 5px;
					height: 100%;
					background: rgba(#000, .2);

					@include mq(sp) {
						width: 3px;
					}
				}

				&.-active {
					.global_nav__child_list {
						@include mq(sp) {
							display: block;
						}
					}
				}
			}

			&__toggle {
				display: none;

				@include mq(sp) {
					display: block;
					position: absolute;
					right: 20px;
					top: 14px;
					width: 20px;
					height: 20px;
					background: #fff;
					pointer-events: none;
				}

				&::before,
				&::after {
					content: '';
					position: absolute;
					left: 5px;
					top: 9px;
					display: block;
					height: 2px;
					width: 10px;
					background: #333;
					transition: transform .2s ease;
					pointer-events: none;
				}

				&::after {
					transform: rotate(90deg);
				}

				&.-active {
					&::before,
					&::after {
						transform: rotate(360deg);
					}
				}
			}
		}
	}

	&__child_list {
		overflow-y: auto;
		position: absolute;
		left: 220px;
		top: 0;
		z-index: 1;
		-webkit-overflow-scrolling: touch;
		width: 260px;
		height: 100%;
		padding-top: 110px;
		box-sizing: border-box;
		background: $color_orange_main;
		box-shadow: -2px 0 1px rgba(#000, .1);
		opacity: 0;
		visibility: hidden;
		transition: opacity .2s ease,
		visibility .2s ease;

		@include mq(sp) {
			width: 100%;
		}

		// .global_nav__list__item:nth-child(2) & {
		// 	padding-top: 217px;
		// }

		// .global_nav__list__item:nth-child(3) & {
		// 	padding-top: 285px;
		// }

		// .global_nav__list__item:nth-child(4) & {
		// 	padding-top: 353px;
		// }

		// .global_nav__list__item:nth-child(5) & {
		// 	padding-top: 421px;
		// }

		// .global_nav__list__item:nth-child(6) & {
		// 	padding-top: 150px;
		// }

		// .global_nav__list__item:nth-child(7) & {
		// 	padding-top: 557px;
		// }

		.global_nav__list__item > a:hover + &,
		&:hover {
			@include mq(sp, true) {
				opacity: 1;
				visibility: visible;
			}
		}

		.global_nav__list__item:nth-child(2) &,
		.global_nav__list__item:nth-child(3) &,
		.global_nav__list__item:nth-child(4) &,
		.global_nav__list__item:nth-child(5) &,
		.global_nav__list__item:nth-child(6) &,
		.global_nav__list__item:nth-child(7) & {
			@include mq(sp) {
				padding-top: 0;
			}
		}

		@include mq(sp) {
			display: none;
			position: static;
			opacity: 1;
			visibility: visible;
			background: none;
			box-shadow: none;
			padding-top: 0;
			background: rgba(#fff,.6);
		}

		&__item {
			& > a {
				display: flex;
				color: #fff;
				padding: 10px 25px;
				text-decoration: none;
				font-size: 1.5rem;
				font-weight: bold;
				transition: background-color .2s ease;

				&:hover {
					@include mq(sp, true) {
						background-color: $color_red_01;
					}
				}

				@include mq(sp) {
					font-size: 1.3rem;
					color: $color_text;
					font-weight: normal;
					border-top: 1px solid $color_gray_01;
				}
			}
		}
	}

	&__grandchild_list {
		margin-bottom: 20px;

		&__item {
			margin-left: 25px;

			& > a {
				position: relative;
				left: 0;
				display: flex;
				padding: 5px 0;
				color: #fff;
				text-decoration: none;
				font-size: 1.2rem;
				font-weight: bold;
				transition: padding .2s ease;

				&:hover {
					@include mq(sp, true) {
						padding-left: 10px;
					}
				}

				&::before {
					@include icon($arrow-right);
					font-size: 1rem;
					margin-right: 4px;

					@include mq(sp) {
						color: $color_orange_main;
						position: relative;
						top: 3px;
					}
				}

				@include mq(sp) {
					font-size: 1.3rem;
					color: $color_text;
					font-weight: normal;
				}
			}
		}
	}

	&__link {
		flex-grow: 1;
		margin: 20px 10px 0;
		display: flex;
		justify-content: space-around;

		&__item {
			a {
				text-decoration: none;
				color: #333;

				@include hover_opacity;
			}
		}
	}

	&__lang {
		margin: 0 10px 15px;

		&__list {
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: flex-end;
			justify-content: flex-end;
			-webkit-align-items: flex-end;
			align-items: flex-end;

			@include mq(sp) {
				margin-top: 40px;
				justify-content: center;
			}

			&__item {
				margin-right: 1px;
				width: 20%;

				a,
				span {
					display: block;
					background-color: #fff;
					text-decoration: none;
					font-family: $ff_en;
					font-weight: bold;
					text-align: center;
					padding: 3px 0;
					color: #000;
					transition: color .2s ease,
						background-color .2s ease;
				}

				a:hover,
				&.-current span {
					background-color: $color_orange_main;
					color: #fff;
				}
			}

		}
	}
}

html.is_touch .is_touch_disable>a {
	@include mq(sp, true) {
		pointer-events: none;
	}
}