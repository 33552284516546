.btn {
	a,
	button {
		appearance: none;
		position: relative;
		display: flex;
		border: none;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 220px;
		height: 62px;
		margin-right: auto;
		margin-left: auto;
		font-size: 1.3rem;
		text-decoration: none;
		box-sizing: border-box;
		cursor: pointer;
		transition: color .2s ease,
		background-color .2s ease,
		border-color .2s ease;

		@at-root body.en.personal .btn.-orange a {
			max-width: 250px;
		}

		@include mq(sp) {
			max-width: 240px;
		}
	}

	&.-border {
		a,
		button {
			background-color: #fff;
			color: $color_orange_main;
			border: 2px solid $color_orange_main;
			@include hover_opacity;
		}
	}

	&.-orange {
		a,
		button {
			background-color: $color_orange_main;
			color: #fff;
			@include hover_opacity;
		}
	}

	&.-arrow {
		a,
		button {
			&::after {
				@include icon($arrow_lg_right);
				font-size: 1.8rem;
				margin-left: 10px;
			}
		}
	}

	&.-blank-r {
		a,
		button {
			justify-content: flex-start;
			padding: 0 30px;

			@at-root body.en .btn.-blank-r a,
			body.en .btn.-blank-r button {
				padding: 0 20px;
			}

			@include mq(sp) {
				padding: 0 20px;
			}

			&::after {
				@include icon($blank);
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.2rem;

				@at-root body.en .btn.-blank-r a::after,
				body.en .btn.-blank-r button::after {
					right: 15px;
				}

				@include mq(sp) {
					right: 15px;
				}
			}
		}
	}

	&.-arrow-r {
		a,
		button {
			justify-content: flex-start;
			padding: 0 30px;

			@at-root body.en .btn.-arrow-r a,
			body.en .btn.-arrow-r button {
				padding: 0 20px;
			}

			@include mq(sp) {
				padding: 0 20px;
			}

			&::after {
				@include icon($arrow_lg_right);
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.8rem;

				@at-root body.en .btn.-arrow-r a::after,
				body.en .btn.-arrow-r button::after {
					right: 15px;
				}

				@include mq(sp) {
					right: 15px;
				}
			}
		}
	}

	&.-arrow-c {
		a,
		button {
			justify-content: center;
			padding: 0 30px;

			@include mq(sp) {
				padding: 0 20px;
			}

			&::after {
				@include icon($arrow_lg_right);
				position: absolute;
				right: 25px;
				top: 50%;
				transform: translateY(-50%);
				font-size: 1.8rem;

				@include mq(sp) {
					right: 15px;
				}
			}
		}
	}

	&.-right {
		a,
		button {
			margin-right: 0;
		}
	}

	&.-left {
		a,
		button {
			margin-left: 0;
		}
	}

	&.-sp-right {
		a,
		button {
			@include mq(sp) {
				margin-right: 0;
			}
		}
	}

	&.-full {
		a,
		button {
			max-width: none;
		}
	}

	

	&.-wide-md {
		a,
		button { 
			max-width: 310px;
		}
	}

	&.-md {
		a,
		button {
			height: 55px;
		}
	}

	&.-lg {
		a,
		button {
			height: 100px;
			font-size: 2.2rem;

			@include mq(sp) {
				height: 75px;
				font-size: 1.6rem;
			}
		}
	}

	&.-sp_p {
		a,
		button {
			@include mq(sp) {
				padding: 0 15px;
			}
		}
	}

	&.-fz16 {
		a,
		button {
			font-size: 1.6rem;

			@include mq(sp) {
				font-size: 1.2rem;
			}
		}
	}

	&.-page_nav {
		a,
		button {
			position: relative;
			color: #000;
			border: 1px #000 solid;
			font-size: 1.4rem;
			height: 50px;
			text-align: center;

			@include mq(sp) {
				text-align: center;
				font-size: 1.2rem;
				padding-right: 15px;

				@at-root body.en .btn.-page_nav a,
				.btn.-page_nav button {
					font-size: 1rem;
				}
			}

			&:hover {
				color: $color_orange_main;
				border-color: $color_orange_main;
			}

			&::after {
				@include icon($cret_down);
				position: absolute;
				right: 12px;
				top: 50%;
				transform: translateY(-50%);

				@include mq(sp) {

					@at-root body.en .btn.-page_nav a::after,
					.btn.-page_nav button::after {
						right: 6px;
					}
				}
			}
		}

		&.-lg {
			a,
			button {
				font-size: 1.6rem;

				@include mq(sp) {
					font-size: 1.3rem;
					height: 40px;

				}
			}
		}

		&.-orange {
			a,
			button {
				border: none;
				color: #fff;
			}
		}

		&.-blue {
			a,
			button {
				border: none;
				color: #fff;
				background-color: #3eaecc;
			}
		}

		&.-yellow {
			a,
			button {
				border: none;
				color: #fff;
				background-color: #ecb700;
			}
		}

		&.-green {
			a,
			button {
				border: none;
				color: #fff;
				background-color: #4dbc67;
			}
		}

		&.-purple {
			a,
			button {
				border: none;
				color: #fff;
				background-color: #7989cf;
			}
		}
	}

	&.-page_tab {
		a,
		button {
			height: 52px;
			max-width: none;
			font-size: 1.4rem;
			color: #000;
			border: 1px #000 solid;
			transition: color .2s ease,
				border-color .2s ease;

			@include mq(sp) {
				@at-root body.en .btn.-page_tab a,
				.btn.-page_tab button {
					font-size: 1rem;
					text-align: center;
				}
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -16px;
				left: 50%;
				transform: translateX(-50%);
				display: block;
				opacity: 0;
				border-top: 8px #fff solid;
				border-right: 5px transparent solid;
				border-bottom: 8px transparent solid;
				border-left: 5px transparent solid;
				transition: opacity .2s ease,
					border-top-color .2s ease;

			}
		}

		&.-current,
		&:hover {
			a,
			button {
				color: $color_orange_main;
				border-color: $color_orange_main;

				&::after {
					opacity: 1;
					border-top-color: $color_orange_main;
				}
			}
		}
	}

	.text + & {
		margin-top: 20px;
	}
}
