body.ir {

	.ir_link {
		&__parent {

			a,
			span {
				position: relative;
				display: flex;
				font-size: 1.6rem;
				color: $color_text;
				background: #e9e9e9;
				padding: 20px;
				text-decoration: none;

				@include mq(sp) {

					@at-root body.en.ir .ir_link__parent a {
						padding: 20px 10px;
					}
				}
			}

			a {
				@include hover_opacity;

				&::after {
					@include icon($arrow_lg_right);
					position: absolute;
					right: 20px;
					top: 50%;
					color: $color_orange_main;
					font-size: 2rem;
					transform: translateY(-50%);

					@include mq(sp) {

						@at-root body.en.ir .ir_link__parent a::after {
							right: 10px;
						}
					}
				}
			}
		}

		&__list {
			margin-bottom: 20px;

			&__item {
				border-bottom: 1px dotted #999999;

				a {
					position: relative;
					display: flex;
					padding: 20px;
					text-decoration: none;
					@include hover_opacity;

					@include mq(sp) {

						@at-root body.en.ir .ir_link__list__item a {
							padding: 20px 10px;
						}
					}

					&::after {
						@include icon($arrow_lg_right);
						position: absolute;
						right: 20px;
						top: 50%;
						color: $color_orange_main;
						font-size: 2rem;
						transform: translateY(-50%);

						@include mq(sp) {

							@at-root body.en.ir .ir_link__list__item a::after {
								right: 10px;
							}
						}
					}
				}
			}
		}
	}

}
