body.company_network {
	.network_map {
		position: relative;

		&__nav {
			position: absolute;
			top: 90px;
			left: 20px;
			width: 480px;
			display: flex;
			flex-wrap: wrap;

			@include mq(sp) {
				position: static;
				width: auto;
				margin: -5px -5px 0;
			}

			&__item {
				width: 210px;
				margin-left: 20px;
				margin-bottom: 20px;

				@include mq(sp) {
					width: 50%;
					margin: 0;
					padding: 5px;
					box-sizing: border-box;
				}
			}
		}

		&__thumb {
			text-align: right;
			padding-right: 40px;

			@include mq(sp) {
				text-align: center;
				padding-right: 0;
			}
		}
	}
	.network_nav {
		margin-top: 60px;

		&__title {
			margin-bottom: 20px;
			font-size: 1.8rem;
			color: #000;
		}
	}

	.office_content {
		position: relative;
		margin-top: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $color_gray_border;

		@include mq(sp) {
			padding-bottom: 60px;
		}

		&__title {
			margin-top: 15px;
			color: #444444;
			line-height: 1.8;
			padding-left: 1.1em;
			text-indent: -1.1em;

			&::before {
				content: '・';
				color: $color_orange_main;
			}
		}

		&__desc {
			color: #444444;
			padding-left: 1em;
			line-height: 1.8;

			.tags {
				background-color: #fe6400;
				border-radius: 3px;
				color: #fff;
				display: inline-block;
				font-size: 1.1rem;
				margin: 5px 7px 5px 0;
				padding: 0 4px 0 5px;
			}

			a {
				color: #fe6400;
			}
		}

		&__link {
			position: absolute;
			top: 0;
			right: 0;

			@include mq(sp) {
				bottom: 20px;
				top: auto;
			}

			a {
				color: #444444;
				text-decoration: none;
				font-size: 1.3rem;

				&::after {
					@include icon($blank);
					font-size: 1.1rem;
					margin-left: 5px;
				}
			}
		}
	}
}
