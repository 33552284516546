.breadcrumb {

	@include mq(sp) {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		background-color: #fff;
	}

	&__list {
		padding: 10px 15px;

		@include mq(sp) {
			padding: 6px 15px;
			white-space: nowrap;
		}
	}

	&__item {
		display: inline-block;
		font-size: 1.1rem;

		&::before {
			content: '/';
			margin: 0 10px;
			color: $color_text;
		}

		&:first-child {
			&::before {
				content: none;
			}
		}

		&:last-child {
			@include mq(sp) {
				margin-right: 15px;
			}
		}
	}

	a {

		@include hover_opacity;
	}

	span {
		color: $color_text;
	}
}

.about,
.about_human,
.about_technical,
.about_procurement,
.about_information {

	.breadcrumb {
		position: absolute;
		top: 0;
		background-color: rgba(0,0,0,0);

		@include mq(sp) {
			top: 45px;
		}

		&__item {

			@include mq(sp) {
				font-size: 1rem;
			}

			&::before {
				color: #eee;
			}
		}

		a {
			color: #eee;
		}

		span {
			color: #fff;
		}
	}
}