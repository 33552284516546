.get_to_know {
	margin-top: 70px;
	margin-bottom: -180px;
	padding-top: 50px;
	padding-bottom: 120px;
	background-color: #f7f7f7;

	@include mq(sp) {
		margin-top: 40px;
		padding-top: 40px;
		padding-bottom: 80px;
		margin-bottom: -80px;
	}
}