.title_number {
	position: relative;
	font-size: 1.6rem;
	font-weight: bold;
	color: $color_text;
	font-weight: bold;
	margin-top: 20px;
	margin-bottom: 10px;
	padding-left: 25px;
	line-height: 1.8;

	@include mq(sp) {
		font-size: 1.4rem;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		counter-increment: section;
		content: counter(section) ". ";
		font-weight: normal;
	}
}
