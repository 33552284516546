.title_thirdly {
	font-size: 1.6rem;
	font-weight: bold;
	color: $color_text;
	margin-bottom: 30px;
	padding: 4px 0 12px 12px;
	border-left: 4px #dcdcdc solid;
	border-bottom: 1px #dcdcdc solid;

	@include mq(sp) {
		font-size: 1.8rem;
		margin-bottom: 20px;
		padding: 4px 0 8px 12px;
		border-left-width: 3px;
	}

	span {
		font-size: 1.4rem;
	}

	&.-accordion {
		position: relative;
		padding-left: 30px;
		transition: border-color .3s ease;
		font-weight: normal;

		@include mq(sp) {
			font-size: 1.4rem;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 15px;
			top: 8px;
			display: block;
			width: 2px;
			height: 10px;
			background: $color_gray_border;
			transition: transform .3s ease,
				background-color .3s ease;
		}

		&::after {
			transform: rotate(90deg);
		}

		&.-active {
			border-color: $color_orange_main;

			&::before,
			&::after {
				transform: rotate(450deg);
				background-color: $color_orange_main;
			}
		}
	}
}
